import { Badge, Drawer, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import { useToggle, useTranslate } from 'hooks';
import { Button } from 'modules/ui';

import * as Styled from './ApmListFiltersMobileDrawer.styles';
import { ApmListFiltersForm } from '../ApmListFiltersForm';
import { useActiveFiltersCount, useApmListFilterForm } from '../hooks';

export const ApmListFiltersMobileDrawer = () => {
    const translate = useTranslate();
    const [filtersDrawerOpen, toggleFiltersDrawer] = useToggle(false);
    const activeFiltersCount = useActiveFiltersCount();
    const { handleFormReset } = useApmListFilterForm({});

    return (
        <>
            <Styled.ButtonsContainer>
                <Badge badgeContent={activeFiltersCount} color="primary">
                    <Button onClick={() => toggleFiltersDrawer()} variant="contained" startIcon={<FilterAltIcon />}>
                        {translate('general.filters')}
                    </Button>
                </Badge>
                {activeFiltersCount > 0 && (
                    <Button onClick={handleFormReset} variant="text">
                        {translate('general.filters.reset')}
                    </Button>
                )}
            </Styled.ButtonsContainer>

            <Drawer anchor="top" open={filtersDrawerOpen} onClose={() => toggleFiltersDrawer()}>
                <Styled.DrawerContentContainer>
                    <Styled.DrawerHeader>
                        <Typography variant="h5">{translate('general.filters')}</Typography>
                        <IconButton onClick={() => toggleFiltersDrawer()} size="large">
                            <CloseIcon />
                        </IconButton>
                    </Styled.DrawerHeader>

                    <ApmListFiltersForm closeDrawer={() => toggleFiltersDrawer(false)} />
                </Styled.DrawerContentContainer>
            </Drawer>
        </>
    );
};
