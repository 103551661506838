import { useEffect } from 'react';
import { useRecordContext } from 'react-admin';
import { useForm, type SubmitHandler } from 'react-hook-form';

import config from 'config';

import { createObjectSanitizer, isEmptyString } from 'utils';

import { useApmApiAction } from '../../../hooks';

import type { apmIssueTypes } from '../../../constants';
import type { Apm } from '../../../types';

interface ReportApmIssueValues {
    type: (typeof apmIssueTypes)[number]['value'] | '';
    description: string;
}

interface ReportIssueOptions {
    handleClose: () => void;
}

const sanitizeFormData = createObjectSanitizer(isEmptyString);

export const useReportIssueModal = ({ handleClose }: ReportIssueOptions) => {
    const apmRecord = useRecordContext<Apm>();
    const form = useForm<ReportApmIssueValues>({
        defaultValues: { type: '', description: '' },
        mode: 'onChange',
    });

    const [apiState, reportIssue] = useApmApiAction(config.adminApi.endpoints.reportApmIssue, apmRecord?.id, {
        successMsg: 'apm.reportIssue.success',
        errorMsg: 'apm.reportIssue.failure',
        onSuccess: () => {
            form.reset();
            handleClose();
        },
    });

    const onSubmit: SubmitHandler<ReportApmIssueValues> = async data => {
        await reportIssue(sanitizeFormData(data));
    };

    const onCancel = () => {
        handleClose();
        form.reset();
    };

    const isTypeOther = form.watch('type') === 'Other';

    useEffect(() => {
        form.trigger('description');
    }, [form, isTypeOther]);

    return { form, onSubmit, onCancel, apiState, isTypeOther };
};
