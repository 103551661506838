import type { FC } from 'react';
import { useWatch } from 'react-hook-form';
import { ReferenceInput, AutocompleteInput } from 'react-admin';
import type { ReferenceInputProps, AutocompleteInputProps } from 'react-admin';

import { ResourceKey } from 'constants/index';
import { OptionLocation, useOptionLocation } from 'modules/ui';

import type { ExtendedLocation } from '../../../types';

type StopType = { id: string };

interface Values {
    depot: StopType;
    stops: StopType[];
}

interface StopProps
    extends Omit<ReferenceInputProps, 'reference' | 'children'>,
        Pick<AutocompleteInputProps, 'label' | 'validate'> {
    source: string;
    className?: string;
}

const Stop: FC<StopProps> = ({ className, fullWidth = true, label, validate, ...props }) => {
    const { depot, stops }: Values = { depot: useWatch({ name: 'depot' }), stops: useWatch({ name: 'stops' }) };

    const { inputText, matchSuggestion } = useOptionLocation();

    return (
        <ReferenceInput {...props} reference={ResourceKey.LOCATION}>
            <AutocompleteInput
                label={label}
                validate={validate}
                optionText={<OptionLocation />}
                inputText={inputText}
                matchSuggestion={(filterValue: string, suggestion?: ExtendedLocation) => {
                    if (suggestion === undefined) {
                        return false;
                    }

                    /**
                     * Kinda hacky way to exclude already selected locations from the suggestions list
                     */
                    if (depot?.id === suggestion.id || (stops ?? []).some(stop => stop?.id === suggestion.id)) {
                        return false;
                    }

                    return matchSuggestion(filterValue, suggestion);
                }}
                className={className}
                fullWidth
            />
        </ReferenceInput>
    );
};

export default Stop;
