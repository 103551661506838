import type { TranslationMessages } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import countries from 'i18n-iso-countries';

import enInterface from 'ra-language-english';
import elInterface from 'ra-language-greek';
import hrInterface from 'ra-language-croatian';
import bgInterface from 'ra-language-bulgarian';

import enDomain from 'translations/en.json';
import elDomain from 'translations/el.json';
import hrDomain from 'translations/hr.json';
import bgDomain from 'translations/bg.json';

import { Locale } from './constants';

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
countries.registerLocale(require('i18n-iso-countries/langs/el.json'));
countries.registerLocale(require('i18n-iso-countries/langs/hr.json'));
countries.registerLocale(require('i18n-iso-countries/langs/bg.json'));

export type MessageKey = keyof typeof enDomain;

export const isMessageKey = (key: string): key is MessageKey => key in enDomain;

/**
 * @example
 * getNormalizedLocale('en-US') === 'en'
 */
function getNormalizedLocale(lang: string) {
    return lang.split('-')[0];
}

function getDefaultLocale() {
    const locales = new Set(Object.values(Locale)) as Set<string>;
    const lang = navigator.languages.find(lang => locales.has(getNormalizedLocale(lang)));

    return lang ? getNormalizedLocale(lang) : Locale.EN;
}

export const i18nProvider = polyglotI18nProvider(locale => {
    const enMessages: TranslationMessages = { ...enInterface, ...enDomain };

    switch (locale) {
        case Locale.EN:
            return enMessages;

        case Locale.EL:
            return { ...elInterface, ...elDomain };

        case Locale.HR:
            return { ...hrInterface, ...hrDomain };

        case Locale.BG:
            return { ...bgInterface, ...bgDomain };

        default:
            return enMessages;
    }
}, getDefaultLocale());
