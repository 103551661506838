import { FunctionField } from 'react-admin';
import { Tooltip } from '@mui/material';

import type { Apm } from 'modules/apm/types';

interface UUIDFieldProps {
    source?: string;
    label?: string;
    sortable?: boolean;
}

const UUIDField = (props: UUIDFieldProps) => {
    return (
        <FunctionField
            {...props}
            render={(record: any) => {
                const { uuid } = record as Apm;

                if (uuid === undefined || uuid === null) {
                    return '–';
                }

                return (
                    <Tooltip title={uuid} placement="top">
                        <span>
                            {uuid.slice(0, 8)}...{uuid.slice(-8)}
                        </span>
                    </Tooltip>
                );
            }}
        />
    );
};

export default UUIDField;
