export enum ResourceKey {
    APM = 'apm',
    PARCELS = 'parcel',
    LANES = 'lane',
    LOCATION = 'location',
    USER = 'user',
    PARTNER = 'partner',
    DELIVERY_PARTNER = 'delivery-partner',
    REGION = 'region',
    WAREHOUSE = 'warehouse',
    APP_CONFIGURATION = 'app-configuration',
    SLA_SETTINGS = 'sla-settings',
    SUGGESTED_WAREHOUSE = 'warehouse:suggest',
    SUGGESTED_APM = 'apm:suggest',
}
