import { styled } from '@mui/material';

export const ActionsAndFiltersContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    width: '100%',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    columnGap: theme.spacing(2),

    [theme.breakpoints.up('md')]: {
        flexDirection: 'column',
        alignItems: 'unset',
        paddingBottom: 0,
    },
}));
