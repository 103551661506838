import { stringify } from 'query-string';

import config from 'config/config';

import type { ResourceKey } from 'constants/index';

import { getApiVersion } from './getApiVersion';
import type { FetchDataMethod } from './getApiVersion';

export interface ComposeUrlOptions {
    query?: any;
    includeApiBaseUrl?: boolean;
    suffix?: string;
}

export const composeUrl = (
    resource: ResourceKey,
    method: FetchDataMethod,
    { includeApiBaseUrl = true, query, suffix = '' }: ComposeUrlOptions,
) => {
    const urlQuery = query !== undefined ? `?${stringify(query)}` : '';

    const url = `${includeApiBaseUrl ? config.adminApi.url : ''}/${getApiVersion(
        resource,
        method,
    )}/${resource}${urlQuery}${suffix}`;

    return url;
};
