import { useNotify } from 'react-admin';

import { useApiReducer, useTranslate } from 'hooks';
import { adminApiClient } from 'modules/auth/services/authApiClient';
import config, { logger } from 'config';
import type { MessageKey } from 'modules/localization';

interface Options {
    successMsg: MessageKey;
    failureMsg: MessageKey;
}

export function useOpenCompartment(apmId: string, compartmentNumber?: number, options?: Options) {
    const [apiState, dispatch] = useApiReducer();
    const notify = useNotify();
    const translate = useTranslate();

    const actionTrigger = async () => {
        dispatch({ type: 'request' });

        try {
            await adminApiClient.post(config.adminApi.endpoints.openCompartment.replace('{apmId}', apmId), {
                compartmentNumber,
            });
            dispatch({ type: 'success' });
            options && notify(translate(options.successMsg, { compartmentNumber }), { type: 'success' });
        } catch (error: any) {
            logger.error(error);
            options && notify(translate(options.failureMsg, { compartmentNumber }), { type: 'error' });
            dispatch({ type: 'error', error: error as string });
        }
    };

    return [apiState, actionTrigger] as const;
}
