import { useState, Fragment } from 'react';
import { useTranslate } from 'react-admin';
import { Typography, Dialog, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { Role, rolePermissions } from '../../constants';

import { AllowedActions } from '../AllowedActions';

import * as Styled from './RolesExplanation.styles';

export interface RolesExplanationProps {}

export const RolesExplanation = (props: RolesExplanationProps) => {
    const [visible, setVisible] = useState(false);
    const onClose = () => setVisible(false);

    const translate = useTranslate();

    return (
        <>
            <Styled.Opener onClick={() => setVisible(true)}>{translate('role.explained')}</Styled.Opener>

            <Dialog open={visible} onClose={onClose} fullWidth maxWidth="lg">
                <Styled.Title>
                    {translate('role.explained')}

                    <Styled.IconButton aria-label="close" onClick={onClose}>
                        <CloseIcon />
                    </Styled.IconButton>
                </Styled.Title>
                <DialogContent>
                    {[
                        Role.SUPER_ADMIN,
                        Role.MANAGER,
                        Role.OPERATIONS_MANAGER,
                        Role.SALES_MANAGER,
                        Role.SALES_AND_OPERATION_MANAGER,
                        Role.NETWORK_AND_SALES_MANAGER,
                        Role.NETWORK_MANAGER,
                        Role.CUSTOMER_CARE,
                    ].map(role => {
                        const permissions = rolePermissions[role];

                        if (!permissions) {
                            return null;
                        }

                        return (
                            <Fragment key={role}>
                                <Styled.SectionHeader>
                                    <Typography variant="h5">{translate(`role.${role}`)}</Typography>
                                </Styled.SectionHeader>
                                {translate(`role.${role}.explanation`)}

                                <Styled.ActionsTitle variant="h6">
                                    {translate(`role.actions.title`)}
                                </Styled.ActionsTitle>
                                <AllowedActions permissions={permissions} />
                            </Fragment>
                        );
                    })}
                </DialogContent>
            </Dialog>
        </>
    );
};
