import { useEffect } from 'react';
import { Loading, useHandleAuthCallback, useTranslate } from 'react-admin';
import { Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import { Button } from 'modules/ui';

import { AuthCallbackPageContainer } from './AuthCallbackPage.styles';
import { getDescription, getTitle } from './utils';

export const AuthCallbackPage = () => {
    const { error } = useHandleAuthCallback({ refetchOnWindowFocus: false, refetchOnMount: false });
    const translate = useTranslate();
    const [, setSearchParams] = useSearchParams();

    useEffect(() => {
        setSearchParams({});
    }, [setSearchParams]);

    if (!error)
        return (
            <AuthCallbackPageContainer>
                <Loading />
            </AuthCallbackPageContainer>
        );

    const messages = {
        title: translate(getTitle(error)),
        description: translate(getDescription(error)),
        button: translate('login.callback.button'),
    };

    return (
        <AuthCallbackPageContainer>
            <Typography variant="h4" component="h1">
                {messages.title}
            </Typography>
            <Typography variant="body1">{messages.description}</Typography>
            <Button variant="contained" color="primary" href="/login" size="large">
                {messages.button}
            </Button>
        </AuthCallbackPageContainer>
    );
};
