import type { ReactNode } from 'react';
import { FunctionField } from 'react-admin';
import type { FunctionFieldProps } from 'react-admin';
import get from 'lodash/get';

import * as Styled from './LabeledField.styles';

export interface LabeledFieldProps<Entity> extends Pick<FunctionFieldProps, 'source' | 'record'> {
    label: string;
    property: keyof Entity;
    placeholder?: ReactNode;
    className?: string;
}

const LabeledField = <Entity,>({
    label,
    property,
    placeholder = '‒',
    className,
    ...props
}: LabeledFieldProps<Entity>) => {
    return (
        <FunctionField
            {...props}
            render={(record: any) => {
                const value = get(record, property);

                return (
                    <Styled.Container className={className}>
                        {label}
                        <Styled.Value>{value ?? placeholder}</Styled.Value>
                    </Styled.Container>
                );
            }}
        />
    );
};

export default LabeledField;
