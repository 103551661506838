import { styled } from '@mui/material';

export const AuthCallbackPageContainer = styled('main')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(5),

    minHeight: '100dvh',
}));
