import type { paths } from 'api/apiSchema';
import config, { logger } from 'config';
import { apiClient } from 'services';

type Endpoint = paths['/api/v1/auth-sessions:codeGet']['post'];
type RequestPayload = Endpoint['requestBody']['content']['application/json'];
type ResponseBody = Endpoint['responses']['200']['content']['application/json'] & {
    /**
     * The SMS code is returned only in the development environment
     */
    smsCode?: string;
};

export default async (payload: RequestPayload) => {
    const { challenge, smsCode: devSmsCode } = await apiClient.post<RequestPayload, ResponseBody>(
        config.api.endpoints.requestAuthCode,
        payload,
    );

    if (!challenge) {
        logger.error('Missing challenge on the server response');
        throw new Error('errors.api.500');
    }

    return { challenge, devSmsCode };
};
