import { useRecordContext, useTranslate } from 'react-admin';

const ApmEditTitle = () => {
    const translate = useTranslate();
    const record = useRecordContext();

    return <>{translate('apm.edit.title', { id: record?.id })}</>;
};

export default ApmEditTitle;
