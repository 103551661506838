import { useTranslate } from 'react-admin';
import { Typography } from '@mui/material';

import { ResourceKey } from 'constants/index';
import type { MessageKey } from 'modules/localization';

import { PermissionAll } from '../../constants';
import type { RolePermissions } from '../../constants';

import * as Styled from './AllowedActions.styles';

export interface AllowedActionsProps {
    permissions: RolePermissions;
}

const titleByResource: Omit<
    Record<ResourceKey, MessageKey>,
    | typeof ResourceKey.LOCATION
    | ResourceKey.REGION
    | typeof ResourceKey.DELIVERY_PARTNER
    | typeof ResourceKey.SUGGESTED_WAREHOUSE
    | typeof ResourceKey.SUGGESTED_APM
> = {
    [ResourceKey.PARCELS]: 'parcels.list.title',
    [ResourceKey.APM]: 'apm.list.title',
    [ResourceKey.LANES]: 'lanes.list.title',
    [ResourceKey.USER]: 'users.list.title',
    [ResourceKey.PARTNER]: 'partners.list.title',
    [ResourceKey.APP_CONFIGURATION]: 'appConfiguration.list.title',
    [ResourceKey.WAREHOUSE]: 'warehouses.list.title',
    [ResourceKey.SLA_SETTINGS]: 'slaSettings.list.title',
};

export const AllowedActions = ({ permissions }: AllowedActionsProps) => {
    const translate = useTranslate();

    if (permissions === PermissionAll) {
        return <Styled.Resources>{translate('permissions.all')}</Styled.Resources>;
    }

    return (
        <Styled.Resources>
            {(
                [
                    ResourceKey.PARCELS,
                    ResourceKey.APM,
                    ResourceKey.LANES,
                    ResourceKey.USER,
                    ResourceKey.PARTNER,
                    ResourceKey.WAREHOUSE,
                ] as const
            ).map(resource => {
                const resourcePermissions = permissions?.[resource];

                if (!resourcePermissions) {
                    return null;
                }

                return (
                    <div key={resource}>
                        <Typography variant="h6" color="textSecondary">
                            {translate(titleByResource[resource])}
                        </Typography>

                        <Styled.Actions>
                            {resourcePermissions.map(permission => (
                                <Styled.Action key={permission}>
                                    {translate(`permissions.${resource}.${permission}`)}
                                </Styled.Action>
                            ))}
                        </Styled.Actions>
                    </div>
                );
            })}
        </Styled.Resources>
    );
};
