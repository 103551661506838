import type { ReactNode } from 'react';
import { Box } from '@mui/material';
import WarningIcon from '@mui/icons-material/WarningRounded';

import * as Styled from './ParcelIssue.styles';

export interface ParcelIssueProps {
    children: ReactNode;
    note?: ReactNode;
    icon?: typeof WarningIcon;
}

export const ParcelIssue = ({ children, note, icon: Icon = WarningIcon }: ParcelIssueProps) => {
    return (
        <Styled.Card elevation={1}>
            <Icon />
            <Box display="flex" flexDirection="column">
                {children}
                {note && <Styled.Note>{note}</Styled.Note>}
            </Box>
        </Styled.Card>
    );
};
