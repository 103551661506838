import { useTranslate, ReferenceField, FunctionField } from 'react-admin';
import { Grid, Typography, Button, Box } from '@mui/material';

import { ResourceKey } from 'constants/index';

import type { DeliveryPartner, OrderContact, ParcelLocation } from '../../types';
import { isCustomLocation } from '../../utils';

import * as Styled from './ParcelAddressBox.rules';
import { DeliveryParcelIdSection } from './DeliveryParcelIdSection';
import { AddressSection } from '../AddressSection';

enum ParcelAddressBoxType {
    PICK_UP = 'pickUp',
    DELIVERY = 'delivery',
}

export interface ParcelAddressBoxProps {
    type: ParcelAddressBoxType;
    location?: Partial<ParcelLocation>;
    returnLocation?: Partial<ParcelLocation>;
    deliveryParcelId?: string;
    person?: OrderContact;
    onEdit?: () => void;
}

const ParcelAddressBox = ({
    type,
    location,
    person,
    deliveryParcelId,
    onEdit,
    returnLocation,
}: ParcelAddressBoxProps) => {
    const translate = useTranslate();

    if (!location) {
        return null;
    }

    const customLocationType = isCustomLocation(location);

    return (
        <Grid display="flex" flexDirection="column" height="100%">
            <Styled.Title component="h3" variant="h6" gutterBottom>
                {translate(`parcels.contact.${type}.address`)}
                {onEdit && (
                    <Button color="primary" size="small" onClick={() => onEdit()}>
                        {translate('action.edit')}
                    </Button>
                )}
            </Styled.Title>
            <Styled.ContactCard elevation={1}>
                <Grid container spacing={6}>
                    <Grid item xs={12} md={3}>
                        <Typography variant="subtitle1">{translate(`parcels.contact.${type}.person`)}</Typography>
                        {person && (
                            <>
                                <Typography variant="subtitle2" gutterBottom>
                                    {person.name}
                                </Typography>
                                <Typography variant="subtitle2" gutterBottom>
                                    <a href={`tel:${person.phoneNumber}`} title={person.phoneNumber}>
                                        {person.phoneNumber}
                                    </a>
                                </Typography>
                                <Typography variant="subtitle2">
                                    <a href={`mailto:${person.email}`} title={person.email}>
                                        {person.email}
                                    </a>
                                </Typography>
                            </>
                        )}
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography variant="subtitle1">{translate('parcels.contact.location')}</Typography>
                        <AddressSection location={location} />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography variant="subtitle1">{translate(`parcel.delivery.partner`)}</Typography>

                        {customLocationType && (
                            <ReferenceField
                                reference={ResourceKey.DELIVERY_PARTNER}
                                record={location}
                                source="deliveryPartner"
                            >
                                <FunctionField<DeliveryPartner>
                                    render={record => (
                                        <Typography variant="subtitle2" gutterBottom>
                                            {record?.name}
                                        </Typography>
                                    )}
                                />
                            </ReferenceField>
                        )}

                        {type === ParcelAddressBoxType.PICK_UP && (
                            <Box mt={3}>
                                <DeliveryParcelIdSection deliveryParcelId={deliveryParcelId} />
                            </Box>
                        )}
                    </Grid>

                    <Grid item xs={12} md={3}>
                        {returnLocation && (
                            <>
                                <Typography variant="subtitle1">
                                    {translate('parcels.contact.pickUp.return')}
                                </Typography>
                                <AddressSection location={returnLocation} />
                            </>
                        )}

                        {type === ParcelAddressBoxType.DELIVERY && (
                            <DeliveryParcelIdSection deliveryParcelId={deliveryParcelId} />
                        )}
                    </Grid>
                </Grid>

                {customLocationType && location.note && <Styled.Note variant="subtitle2">{location.note}</Styled.Note>}
            </Styled.ContactCard>
        </Grid>
    );
};

ParcelAddressBox.Type = ParcelAddressBoxType;

export default ParcelAddressBox;
