import { SelectInput, required, useRecordContext } from 'react-admin';
import { useWatch, useFormContext } from 'react-hook-form';
import { Tooltip, Typography, Stack } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';

import { useConfirmDialog, useTranslate } from 'hooks';

import { ApmStateChangeDialog } from '../ApmStateChangeDialog';
import type { ApmDetail, ApmState, ApmDetailUpdate } from '../../types';
import { apmStates } from '../../constants';

interface ApmStateSelectProps {}
export const ApmStateSelect = (props: ApmStateSelectProps) => {
    const translate = useTranslate();
    const { setValue } = useFormContext<ApmDetailUpdate>();
    const [stateChangeDialogOpen, openStateChangeDialog, closeStateChangeDialog] = useConfirmDialog();

    const { state: originalState } = useRecordContext<ApmDetail>();
    const currentState = useWatch<ApmDetailUpdate>({ name: 'state' });
    const stateChangeReason = useWatch<ApmDetailUpdate>({ name: 'stateChangeReason' });

    return (
        <>
            <Stack direction="row">
                <SelectInput
                    source="state"
                    label={translate('apm.list.status')}
                    choices={apmStates.map(state => ({
                        id: state,
                        name: translate(`apm.state.${state}`),
                    }))}
                    onChange={e => {
                        if (e.target.value !== originalState) {
                            openStateChangeDialog();
                        } else {
                            setValue('stateChangeReason', undefined);
                        }
                    }}
                    validate={required()}
                    sx={{ width: '100%' }}
                />
                {stateChangeReason ? (
                    <Tooltip
                        placement="right"
                        sx={{ ml: 2.5, mt: 2.5 }}
                        title={
                            <Stack p={1} spacing={1}>
                                <Typography variant="caption">
                                    {translate('apm.stateChange.changeDescription', {
                                        previousState: translate(`apm.state.${originalState as ApmState}`),
                                        currentState: translate(`apm.state.${currentState as ApmState}`),
                                    })}
                                </Typography>
                                <Typography variant="body1">{stateChangeReason}</Typography>
                            </Stack>
                        }
                    >
                        <ChatIcon />
                    </Tooltip>
                ) : null}
            </Stack>
            <ApmStateChangeDialog open={stateChangeDialogOpen} onClose={closeStateChangeDialog} />
        </>
    );
};
