import config from 'config';
import { Domain, domainByLocale, Locale, useUserRegion } from 'modules/localization';

export function useRegionalApiUrl() {
    const [region] = useUserRegion();
    const [parsedLocale] = region?.languageTag.split('-') ?? [];
    const locale = Object.values(Locale).find(l => l === parsedLocale) ?? Locale.EL;

    const currentDomain = domainByLocale[locale] ?? Domain.GR;

    const adminApiUrl = new URL(config.adminApi.url);
    const domain = adminApiUrl.host.split('.').slice(0, -1).concat([currentDomain]).join('.');

    return `${adminApiUrl.protocol}//${domain}`;
}
