import config, { logger } from 'config';

import { userRegionStorage } from 'modules/localization';

import { authApiClient } from './authApiClient';

import { credentialsStorage, userStorage } from './authStorage';

export default async () => {
    try {
        const response: { logOutUrl?: string } = await authApiClient.post(config.api.endpoints.logout);
        if (response?.logOutUrl) {
            window.location.replace(response.logOutUrl);
        }
    } catch (error) {
        logger.error(error);
    }

    userStorage.delete();
    userRegionStorage.delete();
    credentialsStorage.delete();
};
