import { Labeled, useRecordContext } from 'react-admin';
import { Paper, Typography } from '@mui/material';

import type { PartnerData } from 'modules/partners/types';
import { useTranslate } from 'hooks';

import { renderCurrentReturnLocation } from '../PartnerCurrentReturnLocation';
import { PartnerDefaultWarehouseEditable } from './PartnerDefaultWarehouseEditable';
import { PartnerReturnLocationsLabel } from '../PartnerReturnLocationsLabel';

interface PartnerDefaultWarehouseProps {
    viewOnly?: boolean;
}

export const PartnerDefaultWarehouse = ({ viewOnly = false }: PartnerDefaultWarehouseProps) => {
    const translate = useTranslate();
    const partner = useRecordContext<PartnerData>();
    const defaultWarehouse = partner?.warehouses?.find(w => w.id === partner.returnLocation?.locationId);

    return (
        <Labeled
            mt={3}
            sx={{ width: 360 }}
            label={<PartnerReturnLocationsLabel translationKey="partners.returns.warehouse.title" />}
        >
            <>
                <Typography color="textSecondary" variant="caption">
                    {translate('partners.returns.warehouse.subtitle')}
                </Typography>

                {viewOnly ? (
                    defaultWarehouse ? (
                        <Paper elevation={1} sx={{ padding: 2, marginTop: 1, backgroundColor: 'grey.100', width: 360 }}>
                            {renderCurrentReturnLocation(defaultWarehouse)}
                        </Paper>
                    ) : (
                        <Typography mt={1} color={'grey.600'}>
                            {translate('partners.returns.warehouse.notSelected')}
                        </Typography>
                    )
                ) : (
                    <PartnerDefaultWarehouseEditable />
                )}
            </>
        </Labeled>
    );
};
