import type { FC } from 'react';
import { Create, useTranslate, useNotify, useRedirect, useCreate } from 'react-admin';
import type { CreateProps } from 'react-admin';

import { ResourceKey } from 'constants/index';
import { SeoHead } from 'modules/ui';
import { useServerValidationErrors } from 'modules/errors';

import { useHandleLaneSubmit } from '../../hooks';
import LaneForm from '../LaneForm';
import LaneTitle from '../LaneTitle';

const LaneCreate: FC<CreateProps> = props => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirect = useRedirect();

    const [create] = useCreate();

    const onError = useServerValidationErrors();

    const onSuccess = () => {
        notify('lanes.create.created');
        redirect('list', ResourceKey.LANES);
    };

    const handleSubmit = useHandleLaneSubmit(create, { onSuccess, onError });

    return (
        <>
            <SeoHead title={translate('lanes.create.title')} />
            <Create title={<LaneTitle />} {...props}>
                <LaneForm onSubmit={handleSubmit} />
            </Create>
        </>
    );
};

export default LaneCreate;
