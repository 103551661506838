import { EditButton, useResourceContext } from 'react-admin';
import type { EditButtonProps } from 'react-admin';

import type { ResourceKey } from 'constants/index';
import { PermissionAction } from 'modules/roles';

import { useHasAccess } from '../../hooks';

export interface EditButtonWithPermissionsProps extends EditButtonProps {}

export const EditButtonWithPermissions = (props: EditButtonWithPermissionsProps) => {
    const resource = useResourceContext() as ResourceKey;
    const isEditAllowed = useHasAccess(resource, PermissionAction.EDIT);

    return isEditAllowed ? <EditButton {...props} /> : null;
};
