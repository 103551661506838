import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Form, required, useRecordContext } from 'react-admin';
import { Dialog, DialogContent, DialogTitle, DialogActions } from '@mui/material';

import { useTranslate } from 'hooks';
import { Button, TextInput } from 'modules/ui';

import type { ApmDetailUpdate } from '../../types';

interface ApmStateChangeDialogProps {
    open: boolean;
    onClose: () => void;
}
export const ApmStateChangeDialog = ({ open, onClose }: ApmStateChangeDialogProps) => {
    const translate = useTranslate();
    const { setValue, getValues } = useFormContext<ApmDetailUpdate>();
    const { state: originalState } = useRecordContext<ApmDetailUpdate>();

    const [previousState, setPreviousState] = useState<ApmDetailUpdate['state']>(originalState);

    const handleCancel = () => {
        setValue('state', previousState);
        onClose();
    };

    return (
        <Dialog open={open}>
            <DialogTitle>{translate('apm.stateChange.title')}</DialogTitle>
            <Form
                onSubmit={formValues => {
                    setValue('stateChangeReason', formValues.stateChangeReason);
                    setPreviousState(getValues().state);
                    onClose();
                }}
            >
                <DialogContent>
                    <TextInput
                        source="stateChangeReason"
                        label={'apm.stateChange.label'}
                        multiline
                        minRows={3}
                        validate={required('apm.stateChange.required')}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel}>{translate('general.cancel')}</Button>
                    <Button variant="contained" type="submit">
                        {translate('apm.stateChange.confirm')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
};
