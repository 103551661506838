import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button } from '@mui/material';
import { Form, SaveButton, useRecordContext } from 'react-admin';
import { useState } from 'react';

import { useTranslate } from 'hooks';

import type { Parcel } from '../../types';
import type { OrderContact, ParcelLocation, UpdateParcel } from '../../types';

import { RedirectionReasonStep } from './RedirectionReasonStep';
import { EditDeliveryAddressStep } from './EditDeliveryAddressStep';
import { getIsRedirected } from './utils';

export interface DeliveryAddressFormProps {
    saving?: boolean;
    handleSubmit?: (values: {
        recipient: OrderContact;
        destination: Partial<ParcelLocation>;
        destinationDeliveryParcelId?: string | null;
        reason?: UpdateParcel['reason'];
    }) => void;
    canEditContact: boolean;
    canEditAddress: boolean;
    closeDrawer: () => void;
}

export enum DrawerStep {
    EditDeliveryAddress,
    RedirectionReasonSelect,
}

export const DeliveryAddressForm = ({
    saving,
    handleSubmit,
    canEditAddress,
    canEditContact,
    closeDrawer,
}: DeliveryAddressFormProps) => {
    const translate = useTranslate();

    const initialLocation = useRecordContext<Parcel>().addresses?.toLocation;
    const [drawerStep, setDrawerStep] = useState<DrawerStep>(DrawerStep.EditDeliveryAddress);

    type FormValues = Required<Parcel & { reason: UpdateParcel['reason'] }>;

    const onSubmit = (values: FormValues) => {
        const location = values.addresses.toLocation!;
        const type = location.type;

        const isRedirected = getIsRedirected(location, initialLocation);

        if (isRedirected && drawerStep === DrawerStep.EditDeliveryAddress) {
            setDrawerStep(DrawerStep.RedirectionReasonSelect);
        } else {
            handleSubmit?.({
                recipient: values.recipient,
                destinationDeliveryParcelId: values.destinationDeliveryParcelId || null,
                destination: {
                    type,
                    ...(type === 'location'
                        ? { locationId: location.id }
                        : {
                              street: location.street,
                              city: location.city,
                              zip: location.postalCode,
                              countryCode: location.country,
                              deliveryPartnerId: location.deliveryPartner || null,
                          }),
                },
                ...(isRedirected && {
                    reason: values.reason,
                }),
            });
        }
    };

    const onBackClick = () => {
        if (drawerStep === DrawerStep.RedirectionReasonSelect) {
            setDrawerStep(DrawerStep.EditDeliveryAddress);
        } else {
            closeDrawer();
        }
    };

    return (
        <>
            <Box padding={3}>
                <Button color="primary" size="small" onClick={onBackClick} startIcon={<ArrowBackIcon />} sx={{ mb: 3 }}>
                    {translate(`general.back`)}
                </Button>
                {/* @ts-expect-error This component is not generic, thus I cannot give it any type. onSubmit parameter wants type FieldValues from react-hook-form that is any.*/}
                <Form onSubmit={onSubmit}>
                    {drawerStep === DrawerStep.EditDeliveryAddress && (
                        <EditDeliveryAddressStep canEditAddress={canEditAddress} canEditContact={canEditContact} />
                    )}
                    {drawerStep === DrawerStep.RedirectionReasonSelect && <RedirectionReasonStep />}

                    <Box display="flex" justifyContent="flex-end" width="100%" pt={3}>
                        <SaveButton disabled={saving} />
                    </Box>
                </Form>
            </Box>
        </>
    );
};
