export const isEmptyString = (value: any) => typeof value === 'string' && value.trim().length === 0;
export const isEmptyArray = (value: any) => Array.isArray(value) && value.length === 0;

type Omitter = (value: any) => boolean;

/**
 * @example
 * const sanitizeObject = createValuesOmitter(isNull, isUndefined, isEmptyString, isEmptyArray);
 * const obj = { a: 1, b: null, c: undefined, d: '', e: [], f: [1] };
 * const result = sanitizeObject(obj);
 * // result = { a: 1, f: [1] }
 */
export function createObjectSanitizer(...omitters: Omitter[]) {
    return function omitValues<T extends Record<string, any>>(obj: T): T {
        return Object.fromEntries(
            Object.entries(obj).filter(([_, value]) => !omitters.some(omitter => omitter(value))),
        ) as T;
    };
}
