import { useState } from 'react';
import { Confirm, useSaveContext, useTranslate } from 'react-admin';
import type { FieldValues } from 'react-hook-form';

import { useConfirmDialog } from 'hooks';

import { WarehouseForm } from '../../WarehouseForm';

export const ConfirmableEditWarehouseForm = () => {
    const translate = useTranslate();

    const { save } = useSaveContext();

    const [warehouseData, setWarehouseData] = useState<FieldValues>();
    const [confirmOpen, openConfirm, closeConfirm] = useConfirmDialog();

    return (
        <>
            <WarehouseForm
                onSubmit={data => {
                    setWarehouseData(data);
                    openConfirm();
                }}
            />
            <Confirm
                isOpen={confirmOpen}
                title={translate(`warehouse.edit.confirm.title`)}
                content={translate(`warehouse.edit.confirm.text`)}
                confirm={translate(`action.save`)}
                cancel={translate(`general.cancel`)}
                onConfirm={() => {
                    if (save && warehouseData) {
                        save(warehouseData);
                    }
                    closeConfirm();
                }}
                onClose={() => {
                    setWarehouseData(undefined);
                    closeConfirm();
                }}
            />
        </>
    );
};
