import type { FC } from 'react';
import { useTranslate, TextField, Datagrid, TextInput, NumberField, SelectField } from 'react-admin';

import { ResourceKey } from 'constants/index';
import { PermissionAction } from 'modules/roles';
import { SeoHead } from 'modules/ui';
import { EditButtonWithPermissions, ListWithPermissions, useHasAccess } from 'modules/auth';
import { parcelSizeChoices } from 'modules/parcels';
import { Pagination } from 'modules/pagination';

import EnablePartnerButton from '../EnablePartnerButton';

const partnerRowStyle = (record: any) => ({
    opacity: record.enabled ? 1 : 0.7,
    backgroundColor: record.enabled ? '#fff' : '#efefef',
});

const PartnersList: FC = props => {
    const translate = useTranslate();
    const hasDetailAccess = useHasAccess(ResourceKey.PARTNER, PermissionAction.DETAIL);

    return (
        <>
            <SeoHead title={translate('partners.list.title')} />
            <ListWithPermissions
                {...props}
                title={translate('partners.list.title')}
                filters={[<TextInput label={translate('partners.list.search')} source="q" alwaysOn />]}
                pagination={<Pagination />}
            >
                <Datagrid
                    rowStyle={partnerRowStyle}
                    rowClick={hasDetailAccess ? 'show' : undefined}
                    bulkActionButtons={false}
                >
                    <TextField source="name" label={translate('partners.name')} />
                    <TextField source="email" label={translate('partners.email')} />
                    <SelectField
                        source="typicalParcelSize"
                        label="partners.characteristics.typicalParcelSize"
                        choices={parcelSizeChoices}
                    />
                    <NumberField
                        source="parcelLargeRatio"
                        label="partners.characteristics.parcelLargeRatio"
                        options={{ style: 'percent' }}
                        textAlign="left"
                    />
                    <EditButtonWithPermissions />
                    <EnablePartnerButton />
                </Datagrid>
            </ListWithPermissions>
        </>
    );
};

export default PartnersList;
