import type { TypographyProps } from '@mui/material';
import { Typography } from '@mui/material';

import { Link, useCreatePath } from 'react-admin';

import { ResourceKey } from 'constants/index';
import { useTranslate } from 'hooks';

import { Country } from 'modules/ui';

import type { ParcelLocation } from '../../types';
import { isExtendedLocation } from '../../utils';

export interface AddressSectionProps {
    location: Partial<ParcelLocation>;
    typographyVariant?: TypographyProps['variant'];
}

export const AddressSection = ({ location, typographyVariant = 'subtitle2' }: AddressSectionProps) => {
    const translate = useTranslate();
    const createPath = useCreatePath();

    return (
        <>
            {isExtendedLocation(location) && (
                <Typography variant={typographyVariant} gutterBottom>
                    {`${translate('parcel.detail.locationId')}: ${location.id}`}

                    {location.apm && (
                        <>
                            {', '}
                            <Link
                                to={createPath({
                                    resource: ResourceKey.APM,
                                    id: location.apm.id,
                                    type: 'show',
                                })}
                            >
                                {translate('parcel.detail.apmDetail')}
                            </Link>
                        </>
                    )}
                </Typography>
            )}
            <Typography variant={typographyVariant} gutterBottom>
                {location.name}
            </Typography>
            <Typography variant={typographyVariant}>{location.street}</Typography>
            <Typography variant={typographyVariant}>
                {[location.postalCode, location.city].filter(Boolean).join(', ')}
            </Typography>
            {location.country && (
                <Typography variant={typographyVariant}>
                    <Country countryCode={location.country} />
                </Typography>
            )}
        </>
    );
};
