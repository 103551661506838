import { ReferenceArrayInput, SelectArrayInput, ReferenceInput, SelectInput } from 'react-admin';
import type {
    SelectArrayInputProps,
    ReferenceArrayInputProps,
    SelectInputProps,
    ReferenceInputProps,
} from 'react-admin';

import { ResourceKey } from 'constants/index';

type SelectMultipleRegionsProps = Omit<SelectArrayInputProps, 'source'>;
type SelectSingleRegionProps = Omit<SelectInputProps, 'source'>;

export type RegionsListInputProps =
    | (Pick<ReferenceArrayInputProps, 'source'> &
          SelectMultipleRegionsProps & {
              multiSelect?: true;
          })
    | (Pick<ReferenceInputProps, 'source'> &
          SelectSingleRegionProps & {
              multiSelect: false;
          });

const isMultiSelect = (
    props: SelectMultipleRegionsProps | SelectSingleRegionProps,
    multiSelect?: boolean,
): props is SelectMultipleRegionsProps => multiSelect === true;

export const RegionsListInput = ({ source, multiSelect = true, ...props }: RegionsListInputProps) => {
    const Reference = multiSelect ? ReferenceArrayInput : ReferenceInput;

    return (
        <Reference source={source} reference={ResourceKey.REGION}>
            {isMultiSelect(props, multiSelect) ? (
                <SelectArrayInput {...props} sx={{ width: 360, maxWidth: '100%' }} />
            ) : (
                <SelectInput optionText="name" {...props} sx={{ width: 360, maxWidth: '100%' }} />
            )}
        </Reference>
    );
};
