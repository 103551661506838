import { QueryClient } from 'react-query';

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 1000 * 60 * 3, // 3 minutes
            refetchOnWindowFocus: query => {
                return !query.isFetching() && query.isStale();
            },
            refetchOnMount: query => {
                // BE doesn't return all fields on getList() request, so we need to mark the data as stale
                // when requesting the same data via getOne() to refetch it and get all the fields.
                // See the very last "Tip" paragraph here:
                // https://marmelab.com/react-admin/Caching.html#optimistic-rendering
                if (query.queryKey.includes('getOne')) {
                    query.invalidate();
                }
                return !query.isFetching() && query.isStale();
            },
        },
    },
});
