import { usePermissions } from 'react-admin';

import { ResourceKey } from 'constants/index';
import { PermissionAll } from 'modules/roles';
import type { RolePermissions, PermissionAction } from 'modules/roles';

import { hasAccess } from '../utils';

export const useAppPermissions = () => usePermissions<RolePermissions>();

export const useHasAccess = (resource: ResourceKey, ...askedPermissions: PermissionAction[]) => {
    const { permissions } = useAppPermissions();

    return hasAccess(permissions, resource, ...askedPermissions);
};

const isResouceEnabled = (resource: ResourceKey, permissions: RolePermissions) => {
    return permissions === PermissionAll || Boolean(permissions?.[resource]);
};

export const useEnabledResources = () => {
    const { isLoading, error, permissions } = useAppPermissions();

    if (isLoading || error) {
        return [];
    }

    // The order matters here, it should follow the order of <Resource />s in App component
    return [
        ResourceKey.PARCELS,
        ResourceKey.APM,
        ResourceKey.LANES,
        ResourceKey.USER,
        ResourceKey.PARTNER,
        ResourceKey.APP_CONFIGURATION,
        ResourceKey.SLA_SETTINGS,
    ].filter(resource => isResouceEnabled(resource, permissions));
};
