import { styled, Box, Button, IconButton as MUIIconButton } from '@mui/material';

export const IconButton = styled(MUIIconButton)(({ theme }) => ({
    position: 'absolute',
    right: 0,
    top: 0,
}));

export const DescriptionParagraph = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(1),
    lineHeight: 1.7,
}));

export const ClientInfoContainer = styled(Box)(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: theme.spacing(2),
    padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
}));

export const ClientInfoSpacer = styled('hr')(({ theme }) => ({
    width: '100%',
    gridColumnStart: 'span 2',
    border: '0',
}));

export const CopyButton = styled(Button)(({ theme }) => ({
    display: 'flex',
    margin: `${theme.spacing(2)} auto`,
}));
