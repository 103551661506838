import { useListContext } from 'react-admin';
import { useForm } from 'react-hook-form';
import { isNull } from 'lodash';

import { createObjectSanitizer, isEmptyString } from 'utils';

import type { ApmState } from '../../../types';

export interface ApmListFiltersFormValues {
    locationId: string;
    uuid: string;
    identifier: string;
    name: string;
    address: string;
    limitedAvailability: boolean | null;
    laneCountFilter: string;
    state: ApmState | '';
}

const sanitizeApmFilters = createObjectSanitizer(isEmptyString, isNull);

interface UseApmListFilterFormOptions {
    onSubmit?: () => void;
    onReset?: () => void;
}

export const useApmListFilterForm = ({ onSubmit, onReset }: UseApmListFilterFormOptions) => {
    const { setFilters, filterValues } = useListContext();

    const handleFormSubmit = (values: ApmListFiltersFormValues) => {
        setFilters(sanitizeApmFilters(values), null, false);
        onSubmit?.();
    };

    const handleFormReset = () => {
        setFilters(DEFAULT_VALUES, null, false);
        form.reset(DEFAULT_VALUES);
        onReset?.();
    };

    const form = useForm<ApmListFiltersFormValues>({
        defaultValues: filterValues,
    });

    return {
        form,
        handleFormSubmit,
        handleFormReset,
    };
};

const DEFAULT_VALUES = {
    locationId: '',
    uuid: '',
    identifier: '',
    name: '',
    address: '',
    limitedAvailability: null,
    laneCountFilter: '',
    state: '',
} as const satisfies ApmListFiltersFormValues;
