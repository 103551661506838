import { useNotify } from 'react-admin';

import { useApiReducer } from 'hooks';
import type { paths } from 'api/adminApiSchema';
import type { ApiPrefix, RemoveApiPrefix } from 'types/api';
import { adminApiClient } from 'modules/auth/services/authApiClient';
import type { MessageKey } from 'modules/localization';

type ApmActionEndpoint<ApmApiPath = RemoveApiPrefix<keyof paths>> = ApmApiPath extends `/v1/apm/{apmId}:${string}`
    ? ApmApiPath
    : never;

type ApmApiParams<Endpoint extends ApmActionEndpoint> = paths[`${ApiPrefix}${Endpoint}`]['post'] extends {
    requestBody: { content: { 'application/json': object } };
}
    ? paths[`${ApiPrefix}${Endpoint}`]['post']['requestBody']['content']['application/json']
    : undefined;

interface Options {
    successMsg: MessageKey;
    errorMsg?: MessageKey;
    onSuccess?: () => void;
}

export function useApmApiAction<Endpoint extends ApmActionEndpoint, Params extends ApmApiParams<Endpoint>>(
    endpoint: Endpoint,
    apmId?: string,
    options: Options = { successMsg: 'apm.edit.saved' },
) {
    const [apiState, dispatch] = useApiReducer();
    const notify = useNotify();

    const actionTrigger = async (params?: Params) => {
        if (!apmId) {
            return;
        }

        try {
            dispatch({ type: 'request' });
            await adminApiClient.post(endpoint.replace('{apmId}', apmId), params);
            dispatch({ type: 'success' });
            notify(options.successMsg, { type: 'success' });

            options.onSuccess?.();
        } catch (error) {
            dispatch({ type: 'error', error: error as Error });
            notify(options.errorMsg ?? (error as Error).message, { type: 'error' });
        }
    };

    return [apiState, actionTrigger] as const;
}
