import { memoryStore } from 'react-admin';

import { ResourceKey } from 'constants/resources';

const storage = new Proxy(
    {},
    {
        set: (target, key, value) => {
            // Don't persist the parcels resource: parcel filters.
            // Persisting the parcel filters DE-SYNCS the parcels filters with the URL search params filters.
            if (key === ResourceKey.PARCELS) {
                return false;
            }

            return Reflect.set(target, key, value);
        },
    },
);

// Replace the default `localStorage` with just in memory storage
export const store = memoryStore(storage);
