import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

import type { ReactNode } from 'react';

import { useTranslate } from 'hooks';

import * as Styled from './ParcelSection.styles';

export interface ParcelSectionProps {
    children: ReactNode;
    gridArea: 'parcel' | 'partner' | 'payment';
    action?: ReactNode;
    noHeader?: boolean;
}

export const ParcelSection = ({ children, gridArea, action, noHeader }: ParcelSectionProps) => {
    const translate = useTranslate();

    return (
        <Styled.ParcelSectionCard variant="outlined" gridArea={gridArea}>
            {!noHeader && (
                <CardHeader
                    title={translate(`parcel.detail.section.${gridArea}`)}
                    action={action}
                    titleTypographyProps={{ variant: 'subtitle1' }}
                    sx={{ paddingBottom: 0 }}
                />
            )}

            <CardContent>{children}</CardContent>
        </Styled.ParcelSectionCard>
    );
};

ParcelSection.displayName = 'ParcelSection';
