import type { UseUpdateResult } from 'react-admin';
import { useTranslate } from 'react-admin';
import type { FieldValues } from 'react-hook-form';

import { ApiErrorCode, createUIErrorMessage, isHttpError } from 'modules/errors';
import { ResourceKey } from 'constants/index';

import type { components } from 'api/adminApiSchema';

import { createWarehouseSourcePrefix } from '../utils';

import type { CreatedPartnerData, PartnerData } from '../types';

type CannotChangeLocationParentError = { body: components['schemas']['EA4804'] };
type MutationMethod = UseUpdateResult[0];

interface MutationOptions {
    onSuccess?: (data: CreatedPartnerData) => void;
    onError?: (error: unknown) => void;
}

const isCannotChangeLocationParentError = (error: unknown): error is CannotChangeLocationParentError =>
    isHttpError(error) && error.body?.code === ApiErrorCode.CANNOT_CHANGE_LOCATION_PARENT;

export const useHandlePartnerSubmit = (mutationMethod: MutationMethod, mutationOptions: MutationOptions = {}) => {
    const translate = useTranslate();

    const handleSubmit = async (values: FieldValues) => {
        try {
            const response = await mutationMethod(
                ResourceKey.PARTNER,
                {
                    data: {
                        ...values,
                        returnApmLocation: values.returnApmLocation?.locationId ? values.returnApmLocation : null,
                    },
                    id: values.id,
                },
                { returnPromise: true },
            );

            mutationOptions.onSuccess?.(response);
        } catch (error) {
            if (isCannotChangeLocationParentError(error)) {
                const errorMessage = translate(createUIErrorMessage({ code: error.body.code }));

                return error.body.locations?.reduce((errors: Record<string, string>, errorWarehouseId: string) => {
                    const warehouseIndex =
                        (values as PartnerData).warehouses?.findIndex(warehouse => warehouse.id === errorWarehouseId) ??
                        -1;

                    if (warehouseIndex > -1) {
                        const sourcePrefix = createWarehouseSourcePrefix(warehouseIndex);
                        return {
                            ...errors,
                            [`${sourcePrefix}depot`]: errorMessage,
                        };
                    }

                    return errors;
                }, {});
            }

            mutationOptions.onError?.(error);
        }
    };
    return handleSubmit;
};
