import config from 'config';
import { ApiClient, type Options } from 'services/apiClient';
import type { RequestBody } from 'services';

import getAccessToken from './getAccessToken';

class AuthApiClient extends ApiClient {
    setToken(token: string) {
        this.headers['Authorization'] = `Bearer ${token}`;
    }

    async request<Payload extends RequestBody, Response>(
        method: RequestInit['method'],
        endpoint: string,
        payload?: Payload,
        options?: Options,
    ): Promise<Response> {
        this.setToken(await getAccessToken());

        return super.request(method, endpoint, payload, options);
    }
}

export const authApiClient = new AuthApiClient(config.api.url);
export const adminApiClient = new AuthApiClient(config.adminApi.url);
