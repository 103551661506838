import { useRecordContext, useTranslate, Confirm, useRefresh } from 'react-admin';
import { Grid, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import config from 'config';
import { Button } from 'modules/ui';

import { useConfirmDialog } from 'hooks';

import { useParcelApiAction } from '../../hooks/useParcelApiAction';
import type { Parcel } from '../../types';

export interface ParcelDirectionProps {
    // Label is added automatically by RaBox - the prop is needed out of the component
    // eslint-disable-next-line react/no-unused-prop-types
    label?: string;
}

export const ParcelDirection = (props: ParcelDirectionProps) => {
    const translate = useTranslate();

    const refresh = useRefresh();

    const { id: parcelId, direction } = useRecordContext<Parcel>();

    const [confirmOpen, openConfirm, closeConfirm] = useConfirmDialog();

    const onCancelSuccess = () => {
        closeConfirm();
        refresh();
    };

    const [api, resetDirection] = useParcelApiAction(config.adminApi.endpoints.resetDirection, parcelId, {
        successMsg: 'parcel.resetDirection.success',
        onSuccess: onCancelSuccess,
    });

    return (
        <>
            <Grid container alignItems="center" spacing={1}>
                <Grid item>
                    {direction && <Typography variant="body2">{translate(`parcel.direction.${direction}`)}</Typography>}
                </Grid>
                {direction === 'origin' && (
                    <Grid item>
                        <Button
                            color="primary"
                            size="small"
                            sx={{ py: 0 }}
                            onClick={openConfirm}
                            disabled={api.loading}
                        >
                            {translate('parcel.direction.revert')}
                        </Button>
                    </Grid>
                )}
            </Grid>
            <Confirm
                isOpen={confirmOpen}
                loading={api.loading}
                title={translate(`parcel.direction.confirm.title`)}
                content={translate(`parcel.direction.confirm.text`)}
                confirm={translate('action.confirm')}
                cancel={translate(`general.cancel`)}
                CancelIcon={ArrowBackIcon}
                onConfirm={() => resetDirection()}
                onClose={closeConfirm}
            />
        </>
    );
};
