import { Confirm, useRecordContext, useRefresh, useTranslate } from 'react-admin';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HourglassIcon from '@mui/icons-material/HourglassEmpty';

import config from 'config';
import { useConfirmDialog } from 'hooks';
import { Button } from 'modules/ui';
import type { Parcel } from 'modules/parcels/types';

import { useParcelApiAction } from '../../hooks/useParcelApiAction';

import * as Styled from './CancelParcelDeliveryButton.styles';

const cancelForbiddenStates: ReadonlyArray<Parcel['status']> = [
    'delivered',
    'lost',
    'returned',
    'expired-return',
    'canceled',
    'canceled-return',
] as const;

const CancelParcelDeliveryButton = () => {
    const translate = useTranslate();
    const { id, status, cancelationRequested }: Parcel = useRecordContext();
    const refresh = useRefresh();

    const [confirmOpen, openConfirm, closeConfirm] = useConfirmDialog();

    const onCancelSuccess = () => {
        closeConfirm();
        refresh();
    };

    const [api, cancelParcel] = useParcelApiAction(config.adminApi.endpoints.cancelParcel, id, {
        successMsg: 'parcel.detail.cancel.success',
        onSuccess: onCancelSuccess,
    });

    if (cancelationRequested) {
        return (
            <Styled.InfoText>
                <HourglassIcon />
                {translate('parcel.detail.cancel.requested')}
            </Styled.InfoText>
        );
    }

    if (!id || cancelForbiddenStates.includes(status)) {
        return null;
    }

    const buttonLabel = translate('parcel.detail.cancel');

    return (
        <>
            <Button variant="contained" type="button" size="medium" onClick={openConfirm} loading={api.loading}>
                <Styled.CancelIcon />
                {buttonLabel}
            </Button>
            <Confirm
                isOpen={confirmOpen}
                loading={api.loading}
                title={translate(`parcel.detail.cancel.confirm.title`)}
                content={translate(`parcel.detail.cancel.confirm.text`)}
                confirm={buttonLabel}
                cancel={translate(`general.back`)}
                CancelIcon={ArrowBackIcon}
                onConfirm={() => cancelParcel()}
                onClose={closeConfirm}
            />
        </>
    );
};

export default CancelParcelDeliveryButton;
