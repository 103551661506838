import login from './login';

export default async () => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    const state = params.get('state');
    const error = params.get('error');

    if (error) {
        throw new Error('error.api.azureRedirectError');
    }

    if (!code || !state) {
        return;
    }

    await login({ code, state });
};
