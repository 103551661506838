import { useState } from 'react';
import { useTranslate } from 'react-admin';
import { Box, Collapse, Typography } from '@mui/material';
import SuccessIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/AddCircle';
import RemoveIcon from '@mui/icons-material/RemoveCircle';

import type { CodPaymentImportValidationRule } from '../../../constants';

import * as Styled from './ValidationRule.styles';

export interface ValidationRuleProps {
    rule: CodPaymentImportValidationRule;
    errorParcels?: string[];
    success: boolean | null;
}

const PARCELS_CROP_LIMIT = 2;

export const ValidationRule = ({ rule, errorParcels, success }: ValidationRuleProps) => {
    const translate = useTranslate();

    const shouldDisplayExpander = errorParcels && errorParcels.length > PARCELS_CROP_LIMIT;
    const [allParcelsDisplayed, displayMoreParcels] = useState(false);

    const renderParcel = (parcel: string) => <Styled.ParcelParagraph key={parcel}>{parcel}</Styled.ParcelParagraph>;

    const CollapseTogglerIcon = allParcelsDisplayed ? RemoveIcon : AddIcon;

    return (
        <Box my={1}>
            <Typography variant="body2" display="flex" alignItems="center" fontWeight={500}>
                {success ? (
                    <SuccessIcon color="success" sx={{ pr: 0.5 }} />
                ) : errorParcels ? (
                    <ErrorIcon color="error" sx={{ pr: 0.5 }} />
                ) : null}
                {translate(`parcel.paymentImport.rule.${rule}`)}
            </Typography>

            {errorParcels && (
                <Styled.RuleErrorsDetail>
                    <Styled.ErrorsTitle>
                        {translate('parcel.paymentImport.importedParcels', { parcelsCount: errorParcels.length })}
                        <Styled.ToggleButton
                            aria-label={allParcelsDisplayed ? 'hide' : 'show'}
                            variant="text"
                            onClick={() => displayMoreParcels(!allParcelsDisplayed)}
                        >
                            {translate(`parcel.paymentImport.importedParcels.${allParcelsDisplayed ? 'hide' : 'show'}`)}
                            <CollapseTogglerIcon sx={{ ml: '0.25rem' }} />
                        </Styled.ToggleButton>
                    </Styled.ErrorsTitle>

                    {errorParcels.slice(0, PARCELS_CROP_LIMIT).map(renderParcel)}

                    {shouldDisplayExpander && (
                        <>
                            <Collapse in={allParcelsDisplayed}>
                                {errorParcels.slice(PARCELS_CROP_LIMIT).map(renderParcel)}
                            </Collapse>
                        </>
                    )}
                </Styled.RuleErrorsDetail>
            )}
        </Box>
    );
};
