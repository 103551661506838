import { useTranslate } from 'react-admin';
import type { ButtonProps } from 'react-admin';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/AddCircleOutline';

interface AddButtonProps extends ButtonProps {
    label: string;
}

const AddButton = ({ label, ...props }: AddButtonProps) => {
    const translate = useTranslate();

    return (
        <Button size="small" {...props}>
            <AddIcon sx={{ mx: 0.5 }} />
            {translate(label)}
        </Button>
    );
};

export default AddButton;
