import { useState } from 'react';
import { useNotify, useRecordContext, useRefresh } from 'react-admin';

import { ResourceKey } from 'constants/index';
import { usePatchUpdate } from 'hooks';
import { useHasAccess } from 'modules/auth';
import { PermissionAction } from 'modules/roles';
import { logger } from 'config';

import type { Parcel } from '../../types';
import { DeliveryAddressForm } from '../DeliveryAddressForm';
import ParcelAddressBox from '../ParcelAddressBox';
import { PickupAddressForm } from '../PickupAddressForm';

import * as Styled from './ParcelContacts.styles';

enum Panel {
    PICKUP,
    DELIVERY,
}

const ParcelContacts = () => {
    const notify = useNotify();
    const refresh = useRefresh();

    const { id, addresses, sender, recipient, originDeliveryParcelId, destinationDeliveryParcelId }: Parcel =
        useRecordContext();

    const [panel, showPanel] = useState<Panel | null>(null);
    const handleClosePanel = () => showPanel(null);
    const createShowPanelHandler = (panel: Panel) => () => showPanel(panel);

    const access = {
        editSender: useHasAccess(ResourceKey.PARCELS, PermissionAction.EDIT_SENDER),
        editRecipient: useHasAccess(ResourceKey.PARCELS, PermissionAction.EDIT_RECIPIENT),
        editDestination: useHasAccess(ResourceKey.PARCELS, PermissionAction.EDIT_DESTINATION),
    };

    const [updateData, { isLoading: isSaving }] = usePatchUpdate(
        ResourceKey.PARCELS,
        { id },
        {
            onSuccess: () => {
                handleClosePanel();
                refresh();
                notify(panel === Panel.PICKUP ? 'parcel.pickup.update.success' : 'parcel.delivery.update.success', {
                    type: 'success',
                });
            },
            onError: error => {
                logger.error(error);
                notify('error.api.general', { type: 'error' });
            },
            mutationMode: 'pessimistic',
        },
    );

    return (
        <>
            <Styled.ContactsStack spacing={4}>
                <ParcelAddressBox
                    location={addresses?.fromLocation}
                    returnLocation={addresses?.returnLocation}
                    person={sender}
                    type={ParcelAddressBox.Type.PICK_UP}
                    deliveryParcelId={originDeliveryParcelId}
                    onEdit={access.editSender ? createShowPanelHandler(Panel.PICKUP) : undefined}
                />

                <ParcelAddressBox
                    location={addresses?.toLocation}
                    person={recipient}
                    type={ParcelAddressBox.Type.DELIVERY}
                    deliveryParcelId={destinationDeliveryParcelId}
                    onEdit={
                        access.editDestination || access.editRecipient
                            ? createShowPanelHandler(Panel.DELIVERY)
                            : undefined
                    }
                />
            </Styled.ContactsStack>

            <Styled.Drawer anchor="right" open={panel !== null}>
                {panel === Panel.PICKUP && (
                    <PickupAddressForm
                        saving={isSaving}
                        handleSubmit={values => updateData(undefined, { data: values })}
                        canEditContact={access.editSender}
                        closeDrawer={handleClosePanel}
                    />
                )}

                {panel === Panel.DELIVERY && (
                    <DeliveryAddressForm
                        saving={isSaving}
                        handleSubmit={values => updateData(undefined, { data: values })}
                        canEditContact={access.editRecipient}
                        canEditAddress={access.editDestination}
                        closeDrawer={handleClosePanel}
                    />
                )}
            </Styled.Drawer>
        </>
    );
};

export default ParcelContacts;
