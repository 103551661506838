import { useCallback } from 'react';
import { useLocaleState } from 'react-admin';
import countries from 'i18n-iso-countries';

export const useCountry = () => {
    const [locale] = useLocaleState();

    return useCallback(
        function getCountryName(countryCode: string) {
            return countries.getName(countryCode, locale);
        },
        [locale],
    );
};
