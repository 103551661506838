import { useUpdate, useNotify, useTranslate, Confirm, useRecordContext } from 'react-admin';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';

import { Button } from 'modules/ui';
import { ResourceKey } from 'constants/index';

import { useConfirmDialog } from 'hooks';
import { useHasAccess } from 'modules/auth';
import { PermissionAction } from 'modules/roles';

import type { PartnerData } from '../../types';

const EnablePartnerButton = () => {
    const notify = useNotify();
    const translate = useTranslate();
    const [confirmOpen, openConfirm, closeConfirm] = useConfirmDialog();
    const record = useRecordContext<PartnerData>();

    const { enabled, name } = record ?? {};

    const options = {
        undoable: false,
        onSuccess() {
            closeConfirm();
            notify(`partners.${enabled ? 'disable' : 'enable'}.success`, { type: 'info', messageArgs: { name } });
        },
        onFailure() {
            notify(`partners.${enabled ? 'disable' : 'enable'}.error`, { type: 'warning', messageArgs: { name } });
        },
    };

    const [enable, { isLoading }] = useUpdate(
        ResourceKey.PARTNER,
        { id: record?.id, data: { enabled: !enabled } },

        options,
    );

    const isEnableAllowed = useHasAccess(ResourceKey.PARTNER, PermissionAction.ENABLE);

    if (!isEnableAllowed) {
        return null;
    }

    const buttonLabel = translate(`partners.${enabled ? 'disable' : 'enable'}`);

    return (
        <>
            <Button
                startIcon={isLoading ? null : enabled ? <ToggleOffIcon /> : <ToggleOnIcon />}
                onClick={openConfirm}
                loading={isLoading}
            >
                {buttonLabel}
            </Button>
            <Confirm
                isOpen={confirmOpen}
                loading={isLoading}
                title={translate(`partners.${enabled ? 'disable' : 'enable'}.confirm.title`, { name })}
                content={translate(`partners.${enabled ? 'disable' : 'enable'}.confirm.text`)}
                confirm={buttonLabel}
                onConfirm={() => enable()}
                onClose={closeConfirm}
            />
        </>
    );
};

export default EnablePartnerButton;
