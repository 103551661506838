import type { FC } from 'react';
import { ListBase, Title, useTranslate } from 'react-admin';

import { SeoHead } from 'modules/ui';

import { SimpleAppConfigurationList } from '../SimpleAppConfigurationList';

export const AppConfigurationList: FC = props => {
    const translate = useTranslate();

    return (
        <>
            <SeoHead title={translate('appConfiguration.list.title')} />
            <ListBase {...props} perPage={1000}>
                <Title title={translate('appConfiguration.list.title')} />
                <SimpleAppConfigurationList />
            </ListBase>
        </>
    );
};
