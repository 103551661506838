import { styled } from '@mui/material';

export const Container = styled('section')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fit, minmax(14rem, 1fr))`,
    gap: theme.spacing(2),
}));

export const ButtonContainer = styled('div')(({ theme }) => ({
    gridColumn: '-2/-1',

    display: 'flex',
    gap: theme.spacing(1),
    justifyContent: 'flex-end',

    height: theme.spacing(6),

    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
}));
