import { Stack, Typography } from '@mui/material';
import { RadioButtonGroupInput, required } from 'react-admin';

import { useTranslate } from 'hooks';

export const RedirectionReasonStep = () => {
    const translate = useTranslate();

    const choices = ['parcel-redirection-customer-requested', 'parcel-redirection-capacity-reasons'].map(
        redirectionReason => ({
            id: redirectionReason,
            name: `parcel.delivery.redirection.${redirectionReason}`,
        }),
    );

    return (
        <Stack gap={2}>
            <Typography variant="h6" component="h1">
                {translate('parcel.delivery.redirection.title')}
            </Typography>

            <Typography variant="body1" sx={{ maxWidth: '25rem' }}>
                {translate('parcel.delivery.redirection.description')}
            </Typography>

            <RadioButtonGroupInput
                source="reason"
                choices={choices}
                row={false}
                label="parcel.delivery.redirection.label"
                validate={required('parcel.delivery.redirection.required')}
            />
        </Stack>
    );
};
