import { useState } from 'react';

import { useApiReducer } from 'hooks';
import type { components } from 'api/adminApiSchema';
import { adminApiClient } from 'modules/auth/services/authApiClient';
import { ApiErrorCode } from 'modules/errors';
import config from 'config';

interface Options {
    onSuccess?: () => void;
}

type LockerSyncCompartmentDeletionError = components['schemas']['EA4806'];
type LockerSyncExistingCompartmentUpdateError = components['schemas']['EA4807'];

export type SyncLockerApiError = LockerSyncCompartmentDeletionError | LockerSyncExistingCompartmentUpdateError;

const isSyncLockerApiError = (error: unknown): error is SyncLockerApiError =>
    (error as SyncLockerApiError).code !== undefined && (error as SyncLockerApiError).compartmentNumbers !== undefined;

const isLockerSyncCompartmentDeletionError = (error: LockerSyncCompartmentDeletionError): error is SyncLockerApiError =>
    error.code === ApiErrorCode.SYNC_LOCKER_COMPARTMENT_DELETION;

const isLockerSyncExistingCompartmentUpdateError = (
    error: LockerSyncExistingCompartmentUpdateError,
): error is SyncLockerApiError => error.code === ApiErrorCode.SYNC_LOCKER_EXISTING_COMPARTMENT_UPDATE;

export function useSyncLocker(apmId?: string, options: Options = {}) {
    const [apiState, dispatch] = useApiReducer();
    const [syncLockerApiError, setSyncLockerApiError] = useState<SyncLockerApiError | null>(null);

    const reset = () => {
        dispatch({ type: 'reset' });
        resetError();
    };
    const resetError = () => setSyncLockerApiError(null);

    const actionTrigger = async () => {
        if (!apmId) {
            return;
        }

        try {
            dispatch({ type: 'request' });
            await adminApiClient.request(
                'POST',
                config.adminApi.endpoints.syncLocker.replace('{apmId}', apmId),
                undefined,
                {
                    rawError: true,
                },
            );
            dispatch({ type: 'success' });

            options.onSuccess?.();
        } catch (error) {
            if (
                isSyncLockerApiError(error) &&
                (isLockerSyncCompartmentDeletionError(error) || isLockerSyncExistingCompartmentUpdateError(error))
            ) {
                setSyncLockerApiError({
                    code: error.code,
                    compartmentNumbers: error.compartmentNumbers,
                });
            }
            dispatch({ type: 'error', error: error as Error });
        }
    };

    return [apiState, actionTrigger, reset, syncLockerApiError] as const;
}
