import { useEffect } from 'react';
import { RadioButtonGroupInput, required } from 'react-admin';
import { useWatch, useFormContext, useFormState } from 'react-hook-form';

import type { CreateLaneData } from 'modules/lanes/types';
import { InputHelperTextWithIcon } from 'modules/ui';

export enum LaneType {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
}

const INPUT_NAME = 'type';

const requiredValidator = required();

const choices = Object.values(LaneType).map(type => ({
    id: type,
    name: `lanes.type.${type}`,
}));

interface LaneTypeRadioInputProps {}

export const LaneTypeRadioInput = (props: LaneTypeRadioInputProps) => {
    const { errors } = useFormState<CreateLaneData>();
    const selectedOption = useWatch({ name: INPUT_NAME, defaultValue: LaneType.PRIMARY }) as LaneType;
    const { clearErrors } = useFormContext();

    // Clear errors for stops when switching to secondary lane type
    useEffect(() => {
        if (selectedOption === LaneType.SECONDARY && errors?.stops) {
            errors.stops.forEach?.((error, stopIndex) => {
                if (error) {
                    clearErrors(`stops.${stopIndex}.id`);
                }
            });
        }
    }, [selectedOption, errors, clearErrors]);

    return (
        <RadioButtonGroupInput
            source="type"
            label="lanes.type"
            validate={requiredValidator}
            choices={choices}
            defaultValue={LaneType.PRIMARY}
            helperText={<InputHelperTextWithIcon text={`lanes.type.${selectedOption}.description`} />}
        />
    );
};
