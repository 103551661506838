import type { FC } from 'react';
import { AppBar as RAAppBar } from 'react-admin';

import { CurrentRegionSwitcher } from 'modules/localization';

import * as Styled from './AppBar.styles';

const AppBar: FC = props => {
    return (
        <RAAppBar {...props}>
            <Styled.Logo />
            <Styled.Title variant="h6" color="inherit" id="react-admin-title" />
            <Styled.Spacer />

            <CurrentRegionSwitcher />
        </RAAppBar>
    );
};

export default AppBar;
