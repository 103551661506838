import { SelectField } from 'react-admin';
import type { SelectFieldProps } from 'react-admin';

import { BinaryChoice, yesNoChoices } from 'constants/index';
import type { ChoiceNames } from 'modules/ui/types';

const values = {
    [BinaryChoice.YES]: true,
    [BinaryChoice.NO]: false,
};

export interface YesNoInputProps extends Pick<SelectFieldProps, 'source' | 'label'> {
    choiceNames?: ChoiceNames;
}

export const YesNoField = ({ choiceNames, ...props }: YesNoInputProps) => {
    const choices = yesNoChoices.map(choice => ({
        ...choice,
        id: values[choice.id],
        name: choiceNames?.[choice.id] ?? choice.name,
    }));

    return <SelectField {...props} choices={choices} />;
};
