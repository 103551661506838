import { styled } from '@mui/material';

export const InfoText = styled('span')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    paddingInline: theme.spacing(0.5),
    marginTop: theme.spacing(1),
    fontSize: theme.typography.body2.fontSize,
}));
