import { useUpdate } from 'react-admin';
import type { RaRecord, UpdateParams, UseUpdateOptions, UseUpdateResult } from 'react-admin';

// Modification of react-admin's useUpdate for http PATCH
// https://marmelab.com/react-admin/doc/4.0/useUpdate.html
export const usePatchUpdate = <RecordType extends RaRecord = any, MutationError = unknown>(
    resource?: string,
    params: Partial<UpdateParams<RecordType>> = {},
    options: UseUpdateOptions<RecordType, MutationError> = {},
): UseUpdateResult<RecordType, boolean, MutationError> => {
    const [mutate, api] = useUpdate(resource, params, {
        mutationMode: 'optimistic',
        ...options,
    });

    const patchMutate = (_?: string, updateData: Partial<UpdateParams<RecordType>> = {}) =>
        mutate(resource, { ...updateData, meta: { patch: true } });

    return [patchMutate, api];
};
