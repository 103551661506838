import { useState } from 'react';
import type { ReactNode } from 'react';
import { useRecordContext, useTranslate, useNotify } from 'react-admin';
import { Grid, Typography, MenuItem } from '@mui/material';
import identity from 'lodash/identity';

import type { ResourceKey } from 'constants/index';

import { usePatchUpdate } from 'hooks';
import { logger } from 'config';

import * as Styled from './InlineEditableField.styles';

interface InlineEditableBaseProps {
    // Label is added automatically by RaBox - the prop is needed out of the component
    // eslint-disable-next-line react/no-unused-prop-types
    label?: string;

    resource: ResourceKey;
    isEditPermission?: boolean;
    source: string;
    successMessage?: string;
    errorMessage?: string;
    formatDisplayValue?: (value: string) => ReactNode;
    cancellable?: boolean;
    editLabel?: string;
}

interface InlineEditableInputProps extends InlineEditableBaseProps {
    type?: 'input';
}
interface InlineEditableSelectProps extends InlineEditableBaseProps {
    type: 'select';
    items: { value: any; label: ReactNode }[];
}

export type InlineEditableFieldProps = InlineEditableInputProps | InlineEditableSelectProps;

export const InlineEditableField = (props: InlineEditableFieldProps) => {
    const {
        resource,
        isEditPermission = false,
        source,
        successMessage,
        formatDisplayValue = identity,
        cancellable = true,
        editLabel = 'action.edit',
        errorMessage = 'error.api.general',
    } = props;

    const translate = useTranslate();
    const notify = useNotify();

    const record = useRecordContext({ resource });
    const value = record[source];

    const [editMode, setEditMode] = useState(false);
    const [fieldValue, setFieldValue] = useState(value);

    const enableEditMode = () => setEditMode(true);
    const disableEditMode = () => setEditMode(false);

    const [updateValue, { isLoading }] = usePatchUpdate(
        resource,
        { id: record.id, data: { [source]: fieldValue }, previousData: record },
        {
            onSuccess: () => {
                disableEditMode();
                successMessage && notify(successMessage, { type: 'success' });
            },
            onError: error => {
                logger.error(error);
                notify(errorMessage, { type: 'error' });
            },
            mutationMode: 'pessimistic',
        },
    );

    return (
        <div>
            {editMode ? (
                <>
                    <Grid container width="fit-content" alignItems="flex-start" flexDirection="column" spacing={1}>
                        <Grid item>
                            {props.type === 'select' ? (
                                <Styled.Select
                                    value={fieldValue}
                                    onChange={e => setFieldValue(e.target.value)}
                                    variant="standard"
                                    size="small"
                                >
                                    {props.items.map(({ value, label }) => (
                                        <MenuItem value={value}>{label}</MenuItem>
                                    ))}
                                </Styled.Select>
                            ) : (
                                <Styled.Input
                                    value={fieldValue}
                                    size="small"
                                    onChange={e => setFieldValue(e.target.value)}
                                />
                            )}
                        </Grid>
                        <Grid item width="100%">
                            <Grid container justifyContent="flex-end">
                                {cancellable && (
                                    <Grid item>
                                        <Styled.ChangeButton
                                            color="error"
                                            size="small"
                                            onClick={() => {
                                                disableEditMode();
                                                // Restore the last value
                                                setFieldValue(value);
                                            }}
                                            disabled={isLoading}
                                        >
                                            {translate('general.cancel')}
                                        </Styled.ChangeButton>
                                    </Grid>
                                )}
                                <Grid item>
                                    <Styled.ChangeButton
                                        color="primary"
                                        size="small"
                                        onClick={() => updateValue()}
                                        disabled={isLoading}
                                        loading={isLoading}
                                    >
                                        {translate('ra.action.save')}
                                    </Styled.ChangeButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            ) : (
                <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                        <Typography variant="body2">{formatDisplayValue(value)}</Typography>
                    </Grid>
                    {isEditPermission && (
                        <Grid item>
                            <Styled.ChangeButton color="primary" size="small" onClick={enableEditMode}>
                                {translate(editLabel)}
                            </Styled.ChangeButton>
                        </Grid>
                    )}
                </Grid>
            )}
        </div>
    );
};
