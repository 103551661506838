import { useListContext } from 'react-admin';

import { Container, NonClickableContainer, RAPagination } from './Pagination.styles';

const Pagination = () => {
    const { isFetching } = useListContext();

    return (
        <Container>
            {isFetching && <NonClickableContainer />}
            <RAPagination isFetching={isFetching} />
        </Container>
    );
};

export default Pagination;
