import { FunctionField, useTranslate } from 'react-admin';
import { Grid } from '@mui/material';
import { useState } from 'react';

import type { Parcel } from 'modules/parcels/types';
import { Button } from 'modules/ui';

import { EditParcelSizeDialog } from './EditParcelSizeDialog';

export interface ParcelSizeProps {
    // Label is added automatically by RaBox - the prop is needed out of the component
    // eslint-disable-next-line react/no-unused-prop-types
    label?: string;
    isEditPermission?: boolean;
}

export const ParcelSize = ({ isEditPermission }: ParcelSizeProps) => {
    const translate = useTranslate();

    const [dialogVisible, setDialogVisible] = useState(false);
    const handleOpenDialog = () => setDialogVisible(true);
    const handleCloseDialog = () => setDialogVisible(false);

    return (
        <Grid container alignItems="center" spacing={1}>
            <Grid item>
                <FunctionField<Parcel>
                    source="compartmentSize"
                    render={record => translate(`parcel.compartmentSize.${record?.compartmentSize || 0}`)}
                />
            </Grid>
            {isEditPermission && (
                <>
                    <Grid item>
                        <Button color="primary" size="small" sx={{ py: 0 }} onClick={handleOpenDialog}>
                            {translate('action.edit')}
                        </Button>
                    </Grid>
                    <EditParcelSizeDialog visible={dialogVisible} onClose={handleCloseDialog} />
                </>
            )}
        </Grid>
    );
};
