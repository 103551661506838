import { ArrayField, Datagrid, DateField, TextField } from 'react-admin';

import { ApmState } from '../ApmState';

interface ApmHistoryLogProps {}
export const ApmHistoryLog = (props: ApmHistoryLogProps) => {
    return (
        <ArrayField source="history">
            <Datagrid bulkActionButtons={false}>
                <TextField source="user" label={'apm.stateHistory.user'} />
                <ApmState source="state" label={'apm.stateHistory.state'} />
                <DateField source="createTime" label={'apm.stateHistory.date'} />
                <TextField source="reason" label={'apm.stateHistory.reason'} />
            </Datagrid>
        </ArrayField>
    );
};
