import { EditButtonWithPermissions } from 'modules/auth';

import { useUserCanManageLane } from '../../hooks';

export const LaneEditButton = () => {
    const userCanEditLane = useUserCanManageLane();

    if (!userCanEditLane) {
        return null;
    }

    return <EditButtonWithPermissions />;
};
