import { styled } from '@mui/material';
import { FormControl, FormControlLabel } from '@mui/material';

export const SelectFormControl = styled(FormControl)(() => ({
    display: 'flex',
}));

export const SelectFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    alignItems: 'start',
    margin: theme.spacing(1, 0),
    color: theme.palette.grey[600],
}));
