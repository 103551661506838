import {
    RadioButtonGroupInput,
    SelectField,
    useTranslate,
    SelectInput,
    FunctionField,
    Labeled,
    required,
} from 'react-admin';
import { Typography, Stack } from '@mui/material';
import { BoxedShowLayout } from 'ra-compact-ui';
import InfoIcon from '@mui/icons-material/InfoRounded';

import { ResourceKey, BinaryChoice } from 'constants/index';
import { RatioField, RatioInput, YesNoInput, YesNoField } from 'modules/ui';
import type { ChoiceNames } from 'modules/ui/types';
import { ParcelSize, parcelSizeChoices } from 'modules/parcels';
import { useHasAccess } from 'modules/auth';
import { PermissionAction } from 'modules/roles';

import { WebhookInfo } from '../WebhookInfo';
import { WebhookForm } from '../WebhookForm';
import { PartnerTypeRadioInput, typeChoices } from '../PartnerTypeRadioInput';
import { PartnerType, partnerVipChoices } from '../../constants';
import type { PartnerData } from '../../types';

import * as Styled from './PartnerCharacteristics.styles';
import { PartnerVipOverdueSelect } from '../PartnerVipOverdueSelect';

export interface PartnerCharacteristicsProps {
    viewOnly?: boolean;
}

const parcelsAllowedChoices = Array.from({ length: 10 }, (_, index) => ({
    id: index + 1,
    name: (index + 1).toString(),
}));

enum PartnerAddressCheckAccess {
    AvailableApms = 'AVAILABLE_APMS',
    IgnoreApmAvailability = 'IGNORE_APM_AVAILABILITY',
    NotAllowed = 'NOT_ALLOWED',
}

const addressCheckAccessTypes = [
    PartnerAddressCheckAccess.AvailableApms,
    PartnerAddressCheckAccess.IgnoreApmAvailability,
    PartnerAddressCheckAccess.NotAllowed,
];

export const PartnerCharacteristics = ({ viewOnly = false }: PartnerCharacteristicsProps) => {
    const translate = useTranslate();

    const hasAccessToPartnerCharacteristics = useHasAccess(
        ResourceKey.PARTNER,
        viewOnly ? PermissionAction.DETAIL : PermissionAction.EDIT,
    );

    if (!hasAccessToPartnerCharacteristics) {
        return null;
    }

    const typeLabel = translate('partners.characteristics.type');
    const trustLabel = translate('partners.characteristics.trustParcelSize');
    const trustCustomerReturnsLabel = translate('partners.characteristics.trustParcelSizeForCustomerReturns');
    const receivePinToPickupLabel = translate('partners.characteristics.receiveCustomerPinToPickupParcel');
    const sizeLabel = translate('partners.characteristics.typicalParcelSize');
    const largeRatioLabel = translate('partners.characteristics.parcelLargeRatio');
    const mediumRatioLabel = translate('partners.characteristics.parcelMediumRatio');
    const allowedParcelsLabel = translate('partners.characteristics.parcelsAllowed');
    const freeCustomerReturnsLabel = translate('partners.characteristics.freeCustomerReturns');
    const createsUselessOrdersLabel = translate('partners.characteristics.createsUselessOrders');
    const addressCheckAccessLabel = translate('partners.characteristics.addressCheckAccess');
    const vipLabel = translate('partners.characteristics.vip');
    const overdueLabel = translate('partners.characteristics.overdue');

    const trustCustomerReturnsChoiceNames: ChoiceNames = {
        [BinaryChoice.NO]: 'partners.characteristics.trustParcelSizeForCustomerReturns.choice.no',
    };

    const Container = viewOnly ? BoxedShowLayout : Stack;

    return (
        <Container sx={{ padding: 2 }}>
            <Typography variant="h6" component="h3">
                {translate('partners.characteristics.title')}
            </Typography>

            {viewOnly ? (
                <SelectField source="type" label={typeLabel} choices={typeChoices} />
            ) : (
                <PartnerTypeRadioInput
                    source="type"
                    label={typeLabel}
                    defaultValue={PartnerType.ESHOP}
                    helperText={
                        <Styled.Hint>
                            <InfoIcon /> {translate('partners.characteristics.type.hint')}
                        </Styled.Hint>
                    }
                />
            )}
            {viewOnly ? (
                <YesNoField source="trustParcelSize" label={trustLabel} />
            ) : (
                <YesNoInput source="trustParcelSize" label={trustLabel} defaultValue={BinaryChoice.NO} />
            )}
            {viewOnly ? (
                <YesNoField
                    source="trustParcelSizeForCustomerReturns"
                    label={trustCustomerReturnsLabel}
                    choiceNames={trustCustomerReturnsChoiceNames}
                />
            ) : (
                <YesNoInput
                    source="trustParcelSizeForCustomerReturns"
                    label={trustCustomerReturnsLabel}
                    defaultValue={BinaryChoice.NO}
                    choiceNames={trustCustomerReturnsChoiceNames}
                />
            )}
            {viewOnly ? (
                <SelectField source="typicalParcelSize" label={sizeLabel} choices={parcelSizeChoices} />
            ) : (
                <RadioButtonGroupInput
                    source="typicalParcelSize"
                    label={sizeLabel}
                    choices={parcelSizeChoices}
                    defaultValue={ParcelSize.L}
                    translate="no"
                    parse={(v: string) => parseInt(v)}
                />
            )}
            {viewOnly ? (
                <YesNoField source="receiveCustomerPinToPickupParcel" label={receivePinToPickupLabel} />
            ) : (
                <YesNoInput
                    source="receiveCustomerPinToPickupParcel"
                    label={receivePinToPickupLabel}
                    defaultValue={BinaryChoice.NO}
                />
            )}
            {viewOnly ? (
                <RatioField source="parcelLargeRatio" label={largeRatioLabel} />
            ) : (
                <RatioInput source="parcelLargeRatio" label={largeRatioLabel} fullWidth />
            )}
            {viewOnly ? (
                <RatioField source="parcelMediumRatio" label={mediumRatioLabel} />
            ) : (
                <RatioInput source="parcelMediumRatio" label={mediumRatioLabel} fullWidth />
            )}
            {viewOnly ? (
                <FunctionField<PartnerData>
                    source="maximumOrderParcelCount"
                    label={allowedParcelsLabel}
                    render={record => {
                        const value = record?.maximumOrderParcelCount;
                        return value === 0 ? translate('partners.characteristics.parcelsAllowed.notDefined') : value;
                    }}
                />
            ) : (
                <SelectInput
                    source="maximumOrderParcelCount"
                    label={allowedParcelsLabel}
                    choices={parcelsAllowedChoices}
                    emptyValue={0}
                    emptyText={translate('partners.characteristics.parcelsAllowed.notDefined')}
                    translate="no"
                    fullWidth
                    defaultValue={3}
                />
            )}
            {viewOnly ? <WebhookInfo /> : <WebhookForm />}
            {viewOnly ? (
                <YesNoField source="freeCustomerReturns" label={freeCustomerReturnsLabel} />
            ) : (
                <YesNoInput
                    source="freeCustomerReturns"
                    label={freeCustomerReturnsLabel}
                    defaultValue={BinaryChoice.YES}
                />
            )}
            {viewOnly ? (
                <YesNoField source="createsUselessOrders" label={createsUselessOrdersLabel} />
            ) : (
                <YesNoInput
                    source="createsUselessOrders"
                    label={createsUselessOrdersLabel}
                    defaultValue={BinaryChoice.NO}
                />
            )}
            {viewOnly ? (
                <SelectField
                    source="addressCheckAccess"
                    label={addressCheckAccessLabel}
                    choices={addressCheckAccessTypes.map(x => ({
                        id: x,
                        name: translate(`partners.characteristics.addressCheckAccess.${x}`),
                    }))}
                />
            ) : (
                <Labeled label={addressCheckAccessLabel}>
                    <SelectInput
                        source="addressCheckAccess"
                        label=""
                        choices={addressCheckAccessTypes.map(x => ({
                            id: x,
                            name: translate(`partners.characteristics.addressCheckAccess.${x}`),
                        }))}
                        validate={required()}
                        fullWidth
                    />
                </Labeled>
            )}
            {viewOnly ? (
                <Stack spacing={1.5}>
                    <Labeled>
                        <SelectField source="vip" label={vipLabel} choices={partnerVipChoices} />
                    </Labeled>
                    <Labeled>
                        <YesNoField source="overdue" label={overdueLabel} />
                    </Labeled>
                </Stack>
            ) : (
                <PartnerVipOverdueSelect vipLabel={vipLabel} overdueLabel={overdueLabel} />
            )}
        </Container>
    );
};
