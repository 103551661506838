import { BoxedShowLayout } from 'ra-compact-ui';
import { styled } from '@mui/material';

export const BoxedShowLayoutItem = styled(BoxedShowLayout)(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),

    '&:last-child': {
        borderBottom: 0,
    },
}));
