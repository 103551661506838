import { useSimpleFormIterator } from 'react-admin';

import { useWarehouseEdit } from './useWarehouseEdit';

export const usePartnerWarehouseDiscard = (warehouseIndex: number) => {
    const { remove } = useSimpleFormIterator();
    const [, setEditedWarehouse] = useWarehouseEdit();

    return function handleDiscard() {
        remove(warehouseIndex);

        setEditedWarehouse(null);
    };
};
