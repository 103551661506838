import { ApiVersion, ResourceKey } from 'constants/index';

export type FetchDataMethod =
    | 'getList'
    | 'getOne'
    | 'getMany'
    | 'getManyReference'
    | 'create'
    | 'update'
    | 'updateMany'
    | 'delete'
    | 'deleteMany';

export const getApiVersion = (resource: ResourceKey, method: FetchDataMethod) => {
    if (resource === ResourceKey.PARCELS && method === 'getList') {
        return ApiVersion.V2;
    }

    return ApiVersion.V1;
};
