import type { FC } from 'react';
import { useTranslate, required } from 'react-admin';

import StopsList from './StopsList';
import * as Styled from './RoutePlan.styles';

const requiredValidator = required();

const RoutePlan: FC = () => {
    const translate = useTranslate();

    return (
        <>
            <Styled.Title variant="h6" component="h3">
                {translate('lanes.routes.title')}
            </Styled.Title>

            <Styled.DepotSelect
                source="depot.id"
                label={translate('lanes.depot')}
                filter={{ type: 'depot' }}
                perPage={50}
                validate={requiredValidator}
            />

            <StopsList />
        </>
    );
};

export default RoutePlan;
