import type { FC } from 'react';
import { Button as MaterialButton, CircularProgress } from '@mui/material';
import type { ButtonProps } from '@mui/material';

export interface ButtonOwnProps extends ButtonProps {
    loading?: boolean;
}

const Button: FC<ButtonOwnProps> = ({ loading, children, ...props }) => {
    return (
        <MaterialButton {...props}>
            {loading && <CircularProgress size={16} color="inherit" sx={{ mr: 1 }} />}
            {children}
        </MaterialButton>
    );
};

export default Button;
