import type { FC } from 'react';
import { NumberInput, useTranslate } from 'react-admin';
import type { InputProps } from 'react-admin';
import { Grid } from '@mui/material';
interface LatLngInputProps extends Pick<InputProps, 'validate' | 'onBlur'> {
    source: string;
    className?: string;
}

const LatLngInput: FC<LatLngInputProps> = ({ source, className, validate, onBlur }) => {
    const translate = useTranslate();

    return (
        <Grid container spacing={2} className={className}>
            <Grid item xs={6}>
                <NumberInput
                    source={`${source}.lat`}
                    label={translate('general.coordinates.latitude')}
                    validate={validate}
                    onBlur={onBlur}
                />
            </Grid>
            <Grid item xs={6}>
                <NumberInput
                    source={`${source}.lng`}
                    label={translate('general.coordinates.longitude')}
                    validate={validate}
                    onBlur={onBlur}
                />
            </Grid>
        </Grid>
    );
};

export default LatLngInput;
