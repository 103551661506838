import { useNotify, useRecordContext } from 'react-admin';
import type { UseUpdateOptions } from 'react-admin';

import { usePatchUpdate } from 'hooks';
import { ResourceKey } from 'constants/index';
import type { Parcel } from 'modules/parcels/types';
import { logger } from 'config';

type MutationOptions = Pick<UseUpdateOptions, 'onSuccess'>;

export function useUpdateParcelNote(note: string | null, mutationOptions?: MutationOptions) {
    const record = useRecordContext<Parcel>({ resource: ResourceKey.PARCELS });
    const notify = useNotify();

    return usePatchUpdate(
        ResourceKey.PARCELS,
        // We can't send note: undefined as the undefined value is ommited from being sent to server
        // On the other hand note: null doesn't comply to Partial type
        { id: record.id, data: { note: note as any }, previousData: record },
        {
            onSuccess: (...args) => {
                if (typeof mutationOptions?.onSuccess === 'function') {
                    mutationOptions.onSuccess(...args);
                }

                notify('parcels.note.success', { type: 'success' });
            },
            onError: error => {
                logger.error(error);
                notify('parcels.note.error', { type: 'error' });
            },
            mutationMode: 'pessimistic',
        },
    );
}
