import { ListBase, Title } from 'react-admin';

import { useTranslate } from 'hooks';
import { SeoHead } from 'modules/ui';

import { SlaSettingsPage } from '../SlaSettingsPage';

export const SlaSettingsList = () => {
    const translate = useTranslate();

    return (
        <>
            <SeoHead title={translate('slaSettings.list.title')} />
            <ListBase queryOptions={{ meta: { hasNoPagination: true } }}>
                <Title title={translate('slaSettings.list.title')} />
                <SlaSettingsPage />
            </ListBase>
        </>
    );
};
