import { useRecordContext, useTranslate } from 'react-admin';

import { Chip, Tooltip, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/WarningRounded';

export interface ApmAvailabilityWarningProps {
    compact?: boolean;
}

export const ApmAvailabilityWarning = ({ compact = false }: ApmAvailabilityWarningProps) => {
    const record = useRecordContext();
    const translate = useTranslate();

    const label = translate('apm.availability.limited');

    if (!record?.limitedAvailability) {
        return null;
    }

    if (compact) {
        return (
            <Tooltip title={<Typography variant="body2">{label}</Typography>} placement="top">
                <WarningIcon />
            </Tooltip>
        );
    }

    return <Chip icon={<WarningIcon />} label={label} />;
};
