import { useTranslate } from 'react-admin';
import type { FunctionFieldProps } from 'react-admin';
import { useFormContext, useController } from 'react-hook-form';

import * as Styled from './DefaultWarehouseForReturns.styles';

const RETURN_LOCATION_FIELD = 'returnLocation';
const setValueOptions = { shouldDirty: true, shouldTouch: true };

export interface DefaultWarehouseForReturnsProps extends Pick<FunctionFieldProps, 'source' | 'record'> {}

export const DefaultWarehouseForReturns = ({ source, record }: DefaultWarehouseForReturnsProps) => {
    const translate = useTranslate();

    const { setValue } = useFormContext();
    const {
        field: { value: returnLocation },
    } = useController({ name: RETURN_LOCATION_FIELD });

    const handleMakeDefault = () => {
        const locationId = record.id;
        setValue('returnLocation.locationId', locationId);
        setValue(RETURN_LOCATION_FIELD, { locationId }, setValueOptions);
    };

    const handleCancel = () => {
        setValue('returnLocation.locationId', null);
        setValue(RETURN_LOCATION_FIELD, null, setValueOptions);
    };

    if (!record?.id) {
        return (
            <Styled.Container>
                <Styled.Disabled>{translate('partners.warehouse.returnLocation.noPartnerId')}</Styled.Disabled>
            </Styled.Container>
        );
    }

    if (record?.deleted) {
        return (
            <Styled.Container>
                <Styled.Disabled>{translate('partners.warehouse.returnLocation.disabled')}</Styled.Disabled>
            </Styled.Container>
        );
    }

    if (returnLocation?.locationId !== record?.id) {
        return (
            <Styled.Container>
                <Styled.Action onClick={handleMakeDefault}>
                    {translate('partners.warehouse.returnLocation.makeDefault')}
                </Styled.Action>
            </Styled.Container>
        );
    }

    return (
        <Styled.Container>
            <span>{translate('partners.warehouse.returnLocation.isDefault')}</span>
            <Styled.Action onClick={handleCancel}>
                {translate('partners.warehouse.returnLocation.cancel')}
            </Styled.Action>
        </Styled.Container>
    );
};
