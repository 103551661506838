import { styled } from '@mui/material';

export const Container = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: 'white',
}));

export const Side = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
}));
