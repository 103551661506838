import type { Theme as MuiTheme } from '@mui/material/styles';
import { merge } from 'lodash';
import { type RaThemeOptions, defaultTheme } from 'react-admin';

const extendedTheme = {
    palette: {
        primary: { main: '#002a80' },
        secondary: { main: '#77bb56' },
        error: { main: '#d20000' },
        alternateTextColor: { main: '#ffffff' },
        grey: { main: '#e0e0e0' },
        background: { default: '#f7f7f3' },
    },
    metrics: {
        formItemWrapperWidth: 376,
        formInputBaseWidth: 360,
    },
};

const theme = merge(defaultTheme, extendedTheme);

type ExtendedTheme = typeof extendedTheme;
export type Theme = ExtendedTheme & RaThemeOptions & MuiTheme;

declare module '@mui/material/styles' {
    interface Theme extends ExtendedTheme {}
}

export default theme;
