import { useRecordContext } from 'react-admin';

import type { components } from 'api/adminApiSchema';

import { OptionWithAddress } from '../OptionWithAddress';

type ExtendedLocation = components['schemas']['ExtendedLocation'];

export const OptionLocation = () => {
    const record = useRecordContext<ExtendedLocation>();

    if (!record) {
        return null;
    }

    return (
        <OptionWithAddress>
            <strong>{record.locationType}</strong>
            {record.name}
        </OptionWithAddress>
    );
};
