import { useRecordContext, useTranslate } from 'react-admin';

const LaneTitle = () => {
    const translate = useTranslate();
    const record = useRecordContext();
    const name = record?.name;

    return <>{translate(`lanes.${name ? 'edit' : 'create'}.title`, { name })}</>;
};

export default LaneTitle;
