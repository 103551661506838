import { Labeled, useTranslate, useChoicesContext } from 'react-admin';
import { Paper, Typography, Box, Stack } from '@mui/material';

import { Address } from 'modules/ui';

import type { ExtendedLocation, ApmState } from '../../../types';

export const LocationCardData = ({ location }: { location: ExtendedLocation }) => {
    const translate = useTranslate();
    return (
        <Paper elevation={1} sx={{ padding: 2, backgroundColor: 'grey.200' }}>
            <Stack spacing={2}>
                <Labeled label={translate('address.title')}>
                    <Typography variant="subtitle2">{location.name}</Typography>
                </Labeled>

                <Box display="grid" sx={{ gridTemplateColumns: 'repeat(5, auto)', gap: 2 }}>
                    <Address address={location} />

                    <Labeled label={translate('address.locationId')}>
                        <Typography variant="subtitle2">{location.id}</Typography>
                    </Labeled>
                    <Labeled label={translate('address.apmId')}>
                        <Typography variant="subtitle2">{location.apm?.id}</Typography>
                    </Labeled>
                    <Labeled label={translate('address.apmState')}>
                        <Typography variant="subtitle2">{getApmState(translate, location.apm?.state)}</Typography>
                    </Labeled>
                    <Labeled label={translate('address.locationPublicIdentifier')}>
                        <Typography variant="subtitle2">{location.identifier}</Typography>
                    </Labeled>
                </Box>
            </Stack>
        </Paper>
    );
};

export const LocationCard = () => {
    const { selectedChoices } = useChoicesContext();
    const location: ExtendedLocation | undefined = selectedChoices?.[0];

    if (!location) {
        return null;
    }

    return <LocationCardData location={location} />;
};

const getApmState = (translate: (key: string) => string, state?: ApmState): string => {
    const emoji = getApmStateEmoji(state);
    return state ? `${emoji} ${translate(`apm.state.${state}`)}` : emoji;
};

const getApmStateEmoji = (state?: ApmState): string => {
    switch (state) {
        case 'boxnow-ready':
            return '🟢';
        case 'only-delivery':
        case 'only-pickup':
            return '🟡';
        case 'not-operational':
        case 'deactivated':
            return '🔴';
        default:
            return '⚪';
    }
};
