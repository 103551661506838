import { styled, Box, Button } from '@mui/material';

export const ErrorsTitle = styled(Box)(({ theme }) => ({
    color: theme.palette.grey[600],
    fontSize: '0.9rem',
}));

export const ToggleButton = styled(Button)(({ theme }) => ({
    marginLeft: '2rem',
    fontSize: '1em',
    textTransform: 'none',
    color: 'inherit',
}));

export const ParcelParagraph = styled('p')(({ theme }) => ({
    margin: 0,
    padding: '0.25rem',

    fontSize: '0.9em',
}));

export const RuleErrorsDetail = styled('div')(({ theme }) => ({
    paddingLeft: '1.75rem',
}));
