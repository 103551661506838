import { useNotify } from 'react-admin';

import { usePatchUpdate } from 'hooks';
import { ResourceKey } from 'constants/index';
import { logger } from 'config';

import type { Parcel, PaymentState, UpdateParcel } from '../../../types';

export default function useEditPaymentState(paymentState: PaymentState, parcelId?: string, onSuccess?: () => void) {
    const notify = useNotify();

    const [updateData, { isLoading: inProgress }] = usePatchUpdate<Parcel>(
        ResourceKey.PARCELS,
        { id: parcelId },
        {
            onSuccess: () => {
                onSuccess?.();
                notify('parcel.detail.payment.change.success', { type: 'success' });
            },
            onError: error => {
                logger.error(error);
                notify('parcel.detail.payment.change.error', { type: 'error' });
            },
            mutationMode: 'pessimistic',
        },
    );

    const trigger = async () => {
        if (!parcelId) {
            return;
        }

        // "paymentState" property doesn't exist on the "Parcel" resource.
        // Therefore, the UpdateParcel type assertion is needed.
        await updateData(undefined, {
            data: { paymentState } as UpdateParcel,
        });
    };

    return {
        trigger,
        inProgress,
    };
}
