import { TextInput, NullableBooleanInput, SelectInput } from 'react-admin';
import { FormProvider } from 'react-hook-form';

import { useTranslate } from 'hooks';
import { Button } from 'modules/ui';

import * as Styled from './ApmListFiltersForm.styles';
import { apmStates } from '../../../constants';
import { useApmListFilterForm, useActiveFiltersCount } from '../hooks';

export interface ApmListFiltersFormProps {
    closeDrawer?: () => void;
}

export const ApmListFiltersForm = ({ closeDrawer }: ApmListFiltersFormProps) => {
    const translate = useTranslate();
    const { form, handleFormSubmit, handleFormReset } = useApmListFilterForm({
        onSubmit: closeDrawer,
        onReset: closeDrawer,
    });
    const activeFiltersCount = useActiveFiltersCount();

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(handleFormSubmit)} onReset={handleFormReset}>
                <Styled.Container>
                    <TextInput label={translate('apm.list.locationId')} source="locationId" helperText={false} />
                    <TextInput label={translate('apm.list.uuid')} source="uuid" helperText={false} />
                    <TextInput label={translate('apm.list.identifier')} source="identifier" helperText={false} />
                    <TextInput label={translate('apm.list.name')} source="name" helperText={false} />
                    <TextInput label={translate('apm.list.address')} source="address" helperText={false} />
                    <NullableBooleanInput
                        label={translate('apm.availability.limited')}
                        source="limitedAvailability"
                        nullLabel="general.all"
                        falseLabel="general.no"
                        trueLabel="general.yes"
                        fullWidth
                        helperText={false}
                    />
                    <SelectInput
                        source="laneCountFilter"
                        choices={[
                            { id: '=0', name: translate('apm.list.lanes.none') },
                            { id: '=1', name: translate('apm.list.lanes.one') },
                            { id: '>1', name: translate('apm.list.lanes.more') },
                        ]}
                        helperText={false}
                    />
                    <SelectInput
                        source="state"
                        choices={apmStates.map(state => ({ id: state, name: translate(`apm.state.${state}`) }))}
                        emptyText={translate('general.all')}
                        helperText={false}
                    />

                    <Styled.ButtonContainer>
                        {activeFiltersCount > 0 && (
                            <Button type="reset" variant="text" color="primary">
                                {translate('action.reset')}
                            </Button>
                        )}
                        <Button type="submit" variant="contained" color="primary">
                            {translate('action.apply')}
                        </Button>
                    </Styled.ButtonContainer>
                </Styled.Container>
            </form>
        </FormProvider>
    );
};
