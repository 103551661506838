import { Confirm as RAConfirm, ConfirmClasses } from 'react-admin';
import { styled, Box } from '@mui/material';

export const Grid = styled(Box)(({ theme }) => ({
    display: 'inline-grid',
    gap: theme.spacing(3),
    gridTemplateColumns: '1fr 1fr',

    marginTop: theme.spacing(4),
}));

export const Confirm = styled(RAConfirm)(({ theme }) => ({
    [`& .${ConfirmClasses.confirmPrimary}`]: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        paddingLeft: theme.spacing(1.5),
    },
}));

export const Error = styled(Box)(({ theme }) => ({
    color: theme.palette.error.main,
    lineHeight: '1.4em',
    gridColumn: '1 / span 2',
}));
