import { createContext, useContext } from 'react';

import type { Region } from '../types';

export type UserRegionContextValue = readonly [
    region: Region | null,
    setRegion: (regionId: Region['id'] | null) => void,
];

export const UserRegionContext = createContext<UserRegionContextValue>([] as unknown as UserRegionContextValue);

export const useUserRegion = (): UserRegionContextValue => {
    const context = useContext(UserRegionContext);

    if (!context) {
        throw new Error('useUserRegion can be used only inside UserRegionProvider');
    }

    return context;
};

export const useUserRegionReady = () => {
    const [region] = useUserRegion();

    return Boolean(region);
};
