import { Link, useTranslate } from 'react-admin';
import type { ListActionsProps } from 'react-admin';
import { Box, Button } from '@mui/material';
import UploadIcon from '@mui/icons-material/UploadFile';

import { ResourceKey } from 'constants/index';
import { useHasAccess } from 'modules/auth';
import { PermissionAction } from 'modules/roles';

import { COD_PAYMENT_IMPORT_ROUTE } from '../../config';

export interface ParcelsListActionsProps extends ListActionsProps {}

export const ParcelsListActions = (props: ParcelsListActionsProps) => {
    const translate = useTranslate();

    const hasMassImportPermission = useHasAccess(ResourceKey.PARCELS, PermissionAction.EDIT_PAYMENT);

    return (
        <Box display="flex" justifyContent="flex-end">
            {hasMassImportPermission && (
                <Link to={COD_PAYMENT_IMPORT_ROUTE}>
                    <Button variant="text" startIcon={<UploadIcon />}>
                        {translate('parcel.paymentImport.button')}
                    </Button>
                </Link>
            )}
        </Box>
    );
};
