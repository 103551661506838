import type { Role } from 'modules/roles';
import { rolePermissions } from 'modules/roles';

import { userStorage } from './authStorage';

export default () => {
    const user = userStorage.get();

    if (!user) {
        return Promise.reject({ message: 'Unauthenticated' });
    }

    const permissions = rolePermissions[user.role as Role];

    return Promise.resolve(permissions);
};
