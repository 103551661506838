import type { FC } from 'react';
import { Suspense, lazy } from 'react';
import { Notification } from 'react-admin';
import { Container } from '@mui/material';
import 'react-phone-input-material-ui/lib/style.css';

import * as Styled from './LoginPage.styles';

const LoginCard = lazy(() => import('../LoginCard'));

const LoginPage: FC = () => {
    return (
        <>
            <Styled.PageContainer>
                <Container maxWidth="sm">
                    <Suspense fallback={null}>
                        <LoginCard />
                    </Suspense>
                </Container>
            </Styled.PageContainer>
            <Notification />
        </>
    );
};

export default LoginPage;
