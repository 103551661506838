import { useTranslate, useRecordContext } from 'react-admin';

const UserTitle = () => {
    const translate = useTranslate();
    const record = useRecordContext();
    const name = record?.name;

    return <>{translate(`users.${name ? 'edit' : 'create'}.title`, { name })}</>;
};

export default UserTitle;
