import {
    BooleanField,
    FormDataConsumer,
    FunctionField,
    Labeled,
    RadioButtonGroupInput,
    ReferenceField,
    useRecordContext,
} from 'react-admin';
import { Stack, Typography } from '@mui/material';

import { useTranslate } from 'hooks';
import { ResourceKey } from 'constants/index';
import type { ExtendedLocation } from 'modules/parcels/types';
import type { PartnerData } from 'modules/partners/types';

import { ReturnLocationSearch } from '../ReturnLocationSearch';
import { ReturnLocationCard } from '../ReturnLocationCard';
import { ReturnApmLocationUsage, returnApmLocationUsageChoices } from '../PartnerReturnLocations';
import { PartnerReturnLocationsLabel } from '../PartnerReturnLocationsLabel';

interface PartnerApmForReturnsProps {
    viewOnly?: boolean;
    regionId?: string;
    setReturnApmLocation: (location: ExtendedLocation | null) => void;
}

export const PartnerApmForReturns = ({
    regionId,
    setReturnApmLocation,
    viewOnly = false,
}: PartnerApmForReturnsProps) => {
    const translate = useTranslate();
    const partner = useRecordContext<PartnerData>();
    return (
        <>
            <Labeled
                mt={3}
                gap={1}
                sx={{ width: 500 }}
                label={<PartnerReturnLocationsLabel translationKey="partners.returns.apm.title" />}
            >
                {viewOnly ? (
                    <ReferenceField
                        source="returnApmLocation.locationId"
                        reference={ResourceKey.LOCATION}
                        queryOptions={{
                            meta: { additionalFilters: { includeApmReference: true } },
                        }}
                    >
                        <ReturnLocationCard />
                    </ReferenceField>
                ) : (
                    <ReturnLocationSearch regionId={regionId} setReturnApmLocation={setReturnApmLocation} />
                )}
            </Labeled>

            {viewOnly ? (
                <Stack direction={'row'} gap={1} mt={2}>
                    {partner?.returnApmLocation ? (
                        <Labeled
                            gap={1}
                            label={<PartnerReturnLocationsLabel translationKey="partners.returns.apm.usage.title" />}
                        >
                            <Stack direction={'row'} gap={1}>
                                <FunctionField
                                    source="apmUsage"
                                    render={(record: PartnerData) => (
                                        <BooleanField
                                            record={{
                                                ...record,
                                                apmUsage: [
                                                    ReturnApmLocationUsage.Yes,
                                                    ReturnApmLocationUsage.WhenAvailable,
                                                ].includes(partner.returnApmLocation?.usage as ReturnApmLocationUsage),
                                            }}
                                            source="apmUsage"
                                        />
                                    )}
                                />
                                <Typography>
                                    {translate(
                                        returnApmLocationUsageChoices.find(
                                            c => c.id === partner.returnApmLocation?.usage,
                                        )?.name as any,
                                    )}
                                </Typography>
                            </Stack>
                        </Labeled>
                    ) : (
                        <Typography color={'grey.600'}>
                            {translate('partners.returns.apm.default.notSelected')}
                        </Typography>
                    )}
                </Stack>
            ) : (
                <FormDataConsumer>
                    {({ formData }) => {
                        const returnApmLocationId = formData.returnApmLocation?.locationId;
                        return returnApmLocationId ? (
                            <Stack mt={2}>
                                <PartnerReturnLocationsLabel translationKey="partners.returns.apm.usage.title" />
                                <RadioButtonGroupInput
                                    source="returnApmLocation.usage"
                                    label={false}
                                    row={false}
                                    defaultValue={ReturnApmLocationUsage.No}
                                    choices={returnApmLocationUsageChoices.filter(
                                        x => x.id !== ReturnApmLocationUsage.WhenAvailable,
                                    )}
                                    format={(v: number) => {
                                        if (v === ReturnApmLocationUsage.WhenAvailable) {
                                            return ReturnApmLocationUsage.Yes;
                                        }
                                        return v;
                                    }}
                                    parse={(v: string) => parseInt(v)}
                                />
                            </Stack>
                        ) : null;
                    }}
                </FormDataConsumer>
            )}
        </>
    );
};
