export enum Environment {
    DEVELOPMENT = 'development',
    STAGE = 'stage',
    PRODUCTION = 'production',
    TEST = 'test',
}

export const currentEnv = process.env.REACT_APP_BUILD_ENV || process.env.NODE_ENV;

export const isEnvProduction = currentEnv === Environment.PRODUCTION;
export const isEnvDevelopment = currentEnv === Environment.DEVELOPMENT;
export const isEnvStage = currentEnv === Environment.STAGE;
export const isEnvTest = currentEnv === Environment.TEST;

export const isDevServerEnv = process.env.REACT_APP_IS_DEV_SERVER === 'true';

export const isLocalhost = Boolean(
    'location' in globalThis &&
        (globalThis.location.hostname === 'localhost' ||
            // [::1] is the IPv6 localhost address.
            globalThis.location.hostname === '[::1]' ||
            // 127.0.0.1/8 is considered localhost for IPv4.
            globalThis.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)),
);
