import { Form, required } from 'react-admin';
import { Dialog, DialogContent, DialogTitle, DialogActions } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { Button, TextInput } from 'modules/ui';
import { useTranslate } from 'hooks';
import type { PartnerDetailUpdate } from 'modules/partners/types';

interface PartnerOverdueChangeDialogProps {
    open: boolean;
    onClose: () => void;
    previousOverdue: PartnerDetailUpdate['overdue'];
    setPreviousOverdue: (overdue: PartnerDetailUpdate['overdue']) => void;
}
export const PartnerOverdueChangeDialog = ({
    open,
    onClose,
    previousOverdue,
    setPreviousOverdue,
}: PartnerOverdueChangeDialogProps) => {
    const translate = useTranslate();
    const { setValue, getValues } = useFormContext();

    const handleCancel = () => {
        setValue('overdue', previousOverdue);
        onClose();
    };

    return (
        <Dialog open={open}>
            <DialogTitle>{translate('partners.overdueChange.title')}</DialogTitle>
            <Form
                onSubmit={formValues => {
                    setValue('overdueChangeReason', formValues.overdueChangeReason);
                    setPreviousOverdue(getValues().overdue);
                    onClose();
                }}
            >
                <DialogContent>
                    <TextInput
                        source="overdueChangeReason"
                        label={'partners.overdueChange.label'}
                        multiline
                        minRows={3}
                        validate={required('partners.overdueChange.required')}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel}>{translate('general.cancel')}</Button>
                    <Button variant="contained" type="submit">
                        {translate('partners.overdueChange.confirm')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
};
