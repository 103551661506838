import type { FC } from 'react';
import { Edit, useTranslate } from 'react-admin';

import { SeoHead } from 'modules/ui';

import { AppConfigurationForm } from '../AppConfigurationForm';

export const AppConfigurationEdit: FC = props => {
    const translate = useTranslate();

    return (
        <>
            <SeoHead title={translate('appConfiguration.edit.title')} />
            <Edit title={translate('appConfiguration.edit.title')}>
                <AppConfigurationForm />
            </Edit>
        </>
    );
};
