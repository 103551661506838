import { FunctionField } from 'react-admin';
import { Typography, Grid, Box } from '@mui/material';

import type { ApmDetail as ApmDetailData } from '../../types';
import { ApmAvailabilityWarning } from '../ApmAvailabilityWarning';

export interface ApmHeaderProps {}

export const ApmHeader = (props: ApmHeaderProps) => {
    return (
        <Box mb={2}>
            <Grid container spacing={2}>
                <Grid item>
                    <FunctionField<ApmDetailData>
                        source="uuid"
                        label=""
                        render={record => <Typography variant="h6">{record?.uuid ?? '-'}</Typography>}
                    />
                </Grid>
                <Grid item>
                    <ApmAvailabilityWarning />
                </Grid>
            </Grid>
        </Box>
    );
};
