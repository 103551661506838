import { Box } from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoRounded';
import type { ReactNode } from 'react';

import type { MessageKey } from 'modules/localization';
import { useTranslate } from 'hooks';

export interface InputHelperTextWithIconProps {
    icon?: ReactNode;
    text: MessageKey;
}

export const InputHelperTextWithIcon = ({ icon = <InfoIcon />, text }: InputHelperTextWithIconProps) => {
    const translate = useTranslate();
    return (
        <Box display="flex" alignItems="center" gap={1} marginLeft="-0.875rem" fontSize="0.875rem">
            {icon} {translate(text)}
        </Box>
    );
};
