import type { FC } from 'react';
import { email } from 'react-admin';
import type { InputProps, Validator } from 'react-admin';

import TextInput from '../TextInput';

export interface EmailInputProps extends InputProps {}

const emailValidator = email();

export const EmailInput: FC<EmailInputProps> = props => {
    const validate: Validator[] = [emailValidator];

    if (props.validate) {
        if (Array.isArray(props.validate)) {
            validate.unshift(...props.validate);
        } else {
            validate.unshift(props.validate);
        }
    }

    return <TextInput {...props} validate={validate} />;
};
