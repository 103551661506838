import { getPhonePrefixFromHostname, Country, Domain, LanguageTag } from 'modules/localization';
import { getDomainFromHostname } from 'modules/localization/utils';

export const defaultPhonePrefix = getPhonePrefixFromHostname();
export const supportedCountries = Object.values(Country);
export const VALIDATE_DEBOUNCE_INTERVAL = 500;

export type AzureRegion = {
    code: Domain;
    languageTag: LanguageTag;
};

export const azureRegions = [
    {
        code: Domain.GR,
        languageTag: LanguageTag.GR,
    },
    {
        code: Domain.HR,
        languageTag: LanguageTag.HR,
    },
    {
        code: Domain.BG,
        languageTag: LanguageTag.BG,
    },
    {
        code: Domain.CY,
        languageTag: LanguageTag.CY,
    },
] as const satisfies ReadonlyArray<AzureRegion>;

const currentDomain = getDomainFromHostname();

export const DEFAULT_AZURE_REGION = azureRegions.find(region => region.code === currentDomain) ?? azureRegions[0];
