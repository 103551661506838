import { Typography, Box } from '@mui/material';

import { useTranslate } from 'hooks';
import type { CurrentApm } from 'modules/parcels/types';

import { PutInLockerDialogFields } from '../PutInLockerDialogFields';

interface PutInLockerDialogContentProps {
    currentApm: CurrentApm;
}
export const PutInLockerDialogContent = ({ currentApm }: PutInLockerDialogContentProps) => {
    const translate = useTranslate();

    if (!currentApm.apm || !currentApm.compartment || !currentApm.compartment.size) {
        return <PutInLockerDialogFields />;
    }

    return (
        <>
            <Box sx={{ my: 4 }}>
                <Typography variant="body2">{translate('parcel.detail.putInLocker.dialog.locker.title')}</Typography>
                <Typography variant="body1">{currentApm.apm.name}</Typography>
                <Typography variant="body1">{`${currentApm.apm.location?.street}, ${currentApm.apm.location?.city}`}</Typography>
            </Box>

            <Box sx={{ my: 4 }}>
                <Typography variant="body2">
                    {translate('parcel.detail.putInLocker.dialog.compartment.title')}
                </Typography>
                <Typography variant="body1">{`${currentApm.compartment.compartmentNumber}, ${translate(
                    `parcel.compartmentSize.${currentApm.compartment.size}`,
                )}`}</Typography>
            </Box>
        </>
    );
};
