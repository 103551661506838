import { Edit, useNotify, useRedirect } from 'react-admin';

import { ResourceKey } from 'constants/index';
import { useServerValidationErrors } from 'modules/errors';

import { WarehouseTitle } from '../WarehouseTitle';
import { ConfirmableEditWarehouseForm } from './ConfirmableEditWarehouseForm';
import { WarehouseSeoHead } from '../WarehouseSeoHead';

export const WarehouseEdit = () => {
    const notify = useNotify();
    const redirect = useRedirect();
    const onFailure = useServerValidationErrors();

    const onSuccess = () => {
        notify('warehouse.edit.saved');
        redirect('list', ResourceKey.WAREHOUSE);
    };

    return (
        <>
            <Edit
                title={<WarehouseTitle />}
                mutationOptions={{ onSuccess, onError: onFailure }}
                mutationMode="pessimistic"
            >
                <WarehouseSeoHead />
                <ConfirmableEditWarehouseForm />
            </Edit>
        </>
    );
};
