import type { ReactNode } from 'react';

import * as Styled from './ParcelSections.styles';

export interface ParcelSectionsProps {
    children: ReactNode;
}

export const ParcelSections = ({ children }: ParcelSectionsProps) => {
    return (
        <div>
            <Styled.Sections>{children}</Styled.Sections>
        </div>
    );
};

ParcelSections.displayName = 'ParcelSections';
