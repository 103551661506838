import { useRecordContext } from 'react-admin';
import type { ReactNode } from 'react';
import { Stack } from '@mui/material';

import type { AddressRecord } from './hooks';
import { useComposeAddress } from './hooks';

interface OptionWithAddressProps {
    children: ReactNode;
}

export const OptionWithAddress = ({ children }: OptionWithAddressProps) => {
    const record = useRecordContext<AddressRecord>();
    const composeAddress = useComposeAddress();

    if (!record) {
        return null;
    }

    return (
        <Stack>
            {children}
            <span>{composeAddress(record)}</span>
        </Stack>
    );
};
