import React from 'react';
import { useTranslate } from 'ra-core';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FileCopy from '@mui/icons-material/FileCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { useRegionalApiUrl } from '../../hooks/useRegionalApiUrl';
import type { ClientInfo } from '../../types';
import * as Styled from './ClientInfoDialog.styles';

export interface ClientInfoDialogProps {
    clientInfo: ClientInfo | null;
    onClose: () => void;
}

type InfoItem = {
    label: string;
    value?: string;
};

type InfoItemOrSpacer = InfoItem | '-';

function isInfoItem(item: InfoItemOrSpacer): item is InfoItem {
    return typeof item !== 'string';
}

const ClientInfoDialog = ({ clientInfo, onClose }: ClientInfoDialogProps) => {
    const translate = useTranslate();
    const { clientId, clientSecret, webhookSecret } = clientInfo ?? {};

    const idLabel = translate('partners.create.clientInfo.id');
    const secretLabel = translate('partners.create.clientInfo.secret');
    const apiUrlLabel = translate('partners.create.clientInfo.apiUrl');
    const webhookSecretLabel = translate('partners.create.clientInfo.webhookSecret');

    const apiUrl = useRegionalApiUrl();
    const infoData: InfoItemOrSpacer[] = [
        { label: idLabel, value: clientId },
        { label: secretLabel, value: clientSecret },
        { label: apiUrlLabel, value: apiUrl },
        '-',
        { label: webhookSecretLabel, value: webhookSecret },
    ];

    const textToCopy = infoData
        .filter(isInfoItem)
        .map(({ label, value }) => `${label}: ${value}`)
        .join(`\n`);

    return (
        <Dialog open={Boolean(clientInfo)} onClose={onClose} maxWidth="md">
            <DialogTitle mb={1}>
                <Styled.IconButton aria-label="close" onClick={onClose}>
                    <CloseIcon />
                </Styled.IconButton>
            </DialogTitle>
            <DialogContent>
                <Styled.DescriptionParagraph>
                    {translate('partners.create.created')}
                    <br />
                    {translate('partners.create.clientId.saveInfo')}
                </Styled.DescriptionParagraph>
                <Styled.ClientInfoContainer>
                    {infoData.map(infoOrSpacer => {
                        if (isInfoItem(infoOrSpacer)) {
                            const { label, value } = infoOrSpacer;

                            return (
                                <React.Fragment key={label}>
                                    <small>{label}:</small>
                                    <strong>{value}</strong>
                                </React.Fragment>
                            );
                        }

                        return <Styled.ClientInfoSpacer />;
                    })}
                </Styled.ClientInfoContainer>
                {/* @ts-expect-error */}
                <CopyToClipboard text={textToCopy}>
                    <Styled.CopyButton variant="contained" endIcon={<FileCopy />}>
                        {translate('partners.create.clientInfo.copy')}
                    </Styled.CopyButton>
                </CopyToClipboard>
            </DialogContent>
        </Dialog>
    );
};
export default ClientInfoDialog;
