/**
 * We must reimplement (with C&P technique ofc 😄) almost entire RadioButtonGroupInput
 *  from RA to be able to use partial disability on choices
 */
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { FormControl, FormHelperText, FormLabel, RadioGroup } from '@mui/material';
import get from 'lodash/get';
import { useInput, FieldTitle, useChoicesContext } from 'ra-core';
import type { RadioButtonGroupInputProps } from 'react-admin';
import { Labeled, LinearProgress, InputHelperText, sanitizeInputRestProps } from 'react-admin';

import { PartnerType } from 'modules/partners/constants';

import { PartnerTypeRadioInputItem } from './PartnerTypeRadioInputItem';

export const typeChoices = [PartnerType.ESHOP, PartnerType.COURIER, PartnerType.P2P].map(type => ({
    id: type,
    name: `partners.characteristics.type.${type}`,
}));

export interface PartnerTypeRadioInputProps extends Omit<RadioButtonGroupInputProps, 'disabled'> {}

export const PartnerTypeRadioInput = (props: PartnerTypeRadioInputProps) => {
    const {
        helperText,
        className,
        format,
        isFetching: isFetchingProp,
        isLoading: isLoadingProp,
        label,
        margin = 'dense',
        onBlur,
        onChange,
        parse,
        resource: resourceProp,
        row = true,
        source: sourceProp,
        validate,
        ...rest
    } = props;
    const { allChoices, isLoading, resource, source } = useChoicesContext({
        choices: typeChoices,
        isFetching: isFetchingProp,
        isLoading: isLoadingProp,
        resource: resourceProp,
        source: sourceProp,
    });

    const { id, isRequired, fieldState, field, formState } = useInput({
        format,
        onBlur,
        onChange,
        parse,
        resource,
        source,
        validate,
        ...rest,
    });

    const { error, isTouched } = fieldState;
    const invalid = Boolean(error);
    const { isSubmitted } = formState;

    if (isLoading) {
        return (
            <Labeled
                htmlFor={id}
                label={label}
                source={source}
                resource={resource}
                className={clsx('ra-input', `ra-input-${source}`, className)}
                isRequired={isRequired}
            >
                <LinearProgress />
            </Labeled>
        );
    }
    return (
        <StyledFormControl
            component="fieldset"
            className={clsx('ra-input', `ra-input-${source}`, className)}
            margin={margin}
            error={(isTouched || isSubmitted) && invalid}
            {...sanitizeRestProps(rest)}
        >
            <FormLabel component="legend" className={PartnerTypeRadioInputClasses.label}>
                <FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />
            </FormLabel>

            <RadioGroup id={id} row={row} {...field} {...sanitizeRestProps(rest)}>
                {allChoices.map(choice => {
                    const value = get(choice, 'id');
                    const label = get(choice, 'name');

                    return (
                        <PartnerTypeRadioInputItem
                            key={value}
                            value={value}
                            label={label}
                            source={source}
                            disabled={field.value === PartnerType.P2P || value === PartnerType.P2P}
                        />
                    );
                })}
            </RadioGroup>
            <FormHelperText sx={{ marginLeft: 0 }}>
                <InputHelperText touched={isTouched || isSubmitted} error={error?.message} helperText={helperText} />
            </FormHelperText>
        </StyledFormControl>
    );
};

PartnerTypeRadioInput.propTypes = {
    choices: PropTypes.arrayOf(PropTypes.any),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.element]),
    resource: PropTypes.string,
    source: PropTypes.string,
};

const sanitizeRestProps = ({
    afterSubmit,
    allowNull,
    beforeSubmit,
    choices,
    className,
    crudGetMatching,
    crudGetOne,
    data,
    filter,
    filterToQuery,
    formatOnBlur,
    isEqual,
    limitChoicesToValue,
    multiple,
    name,
    pagination,
    perPage,
    ref,
    reference,
    refetch,
    render,
    setFilter,
    setPagination,
    setSort,
    sort,
    subscription,
    type,
    validateFields,
    validation,
    value,
    ...rest
}: any) => sanitizeInputRestProps(rest);

const PREFIX = 'PartnerTypeRadioInput';

export const PartnerTypeRadioInputClasses = {
    label: `${PREFIX}-label`,
};

const StyledFormControl = styled(FormControl, {
    name: PREFIX,
    overridesResolver: (_, styles) => styles.root,
})(({ theme }) => ({
    [`& .${PartnerTypeRadioInputClasses.label}`]: {
        transform: 'translate(0, 5px) scale(0.75)',
        transformOrigin: `top ${theme.direction === 'ltr' ? 'left' : 'right'}`,
    },
}));
