import { useCallback, useState } from 'react';

export function useToggle(initState: boolean = false) {
    const [state, setState] = useState(initState);

    const toggle = useCallback((forcedState?: boolean) => {
        if (typeof forcedState === 'boolean') {
            setState(forcedState);
        } else {
            setState(state => !state);
        }
    }, []);

    return [state, toggle] as const;
}
