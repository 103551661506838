import { useState, useEffect } from 'react';
import { Box } from '@mui/material';

import { Button } from 'modules/ui';

import { useUserRegion } from '../../hooks/userRegion';
import { useAvailableRegions } from '../../hooks/useAvailableRegions';
import { userRegionStorage } from '../../services/userRegionStorage';

import { RegionSelectDialog } from '../RegionSelectDialog';
import { RegionFlag } from './RegionFlag';

export const CurrentRegionSwitcher = () => {
    const regions = useAvailableRegions();

    const [dialogVisible, setDialogVisible] = useState(false);
    const handleClickOpen = () => setDialogVisible(true);
    const handleCloseDialog = () => setDialogVisible(false);

    const [currentRegion, setRegion] = useUserRegion();
    const locale = currentRegion?.languageTag.split('-').pop();

    useEffect(() => {
        /**
         * Try to set initial region, if not already, only once, when regions are loaded.
         *  The hook can't depend on currentRegion, etc. to prevent this action when user
         *  change the region in dialog.
         */
        if (regions.isSuccess && !currentRegion) {
            const initialRegionId = userRegionStorage.get() ?? regions.data[0].id;

            setRegion(initialRegionId);
        }

        /**
         *
         * Clear region only on last unrender (= app logout). First unrender is when
         *  we transit from regions loading => loaded.
         */
        return () => {
            if (regions.isSuccess) {
                setRegion(null);
            }
        };

        // For explanation see comments above 👆
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [regions.isSuccess]);

    return (
        <>
            <Box display="inline-flex" alignItems="center" mr={2}>
                <Button
                    type="button"
                    variant="text"
                    sx={{ color: 'white' }}
                    onClick={handleClickOpen}
                    loading={!currentRegion}
                >
                    {locale && <RegionFlag locale={locale} />}
                    {locale}
                </Button>
            </Box>

            {currentRegion && regions.isSuccess && (
                <RegionSelectDialog
                    currentRegionId={currentRegion.id}
                    regions={regions.data}
                    visible={dialogVisible}
                    onClose={handleCloseDialog}
                />
            )}
        </>
    );
};
