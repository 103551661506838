import { required, useTranslate, SaveButton, SimpleForm, useRecordContext } from 'react-admin';
import type { SimpleFormProps } from 'react-admin';
import { Toolbar } from '@mui/material';

import { RegionsListInput } from 'modules/ui';

import { BaseWarehouseInputs } from '../BaseWarehouseInputs';
import { ConnectedPartnersListField } from '../ConnectedPartnersListField';
import type { Warehouse } from '../../types';

export interface WarehouseFormProps extends Omit<SimpleFormProps, 'toolbar' | 'children'> {}

const requiredValidator = required();

export const WarehouseForm = ({ ...props }: WarehouseFormProps) => {
    const translate = useTranslate();
    const record = useRecordContext<Warehouse>();

    return (
        <SimpleForm
            {...props}
            toolbar={
                <Toolbar>
                    <SaveButton />
                </Toolbar>
            }
        >
            <BaseWarehouseInputs sourcePrefix="" data={record} />

            <RegionsListInput
                source="regionId"
                label={translate('warehouse.region')}
                placeholder={translate('action.choose')}
                validate={[requiredValidator]}
                multiSelect={false}
            />

            <ConnectedPartnersListField />
        </SimpleForm>
    );
};
