import { useNotify } from 'react-admin';

import type { ParcelHistory } from 'api/types';
import config, { logger } from 'config';
import { useApiReducer, useTranslate } from 'hooks';
import { adminApiClient } from 'modules/auth/services/authApiClient';

interface UseFetchFullParcelHistoryHooks {
    onSuccess: (data: ParcelHistory) => void;
}

export function useFetchFullParcelHistory(parcelId: string, { onSuccess }: UseFetchFullParcelHistoryHooks) {
    const [apiState, dispatch] = useApiReducer();
    const notify = useNotify();
    const translate = useTranslate();

    const fetchFullParcelHistory = async () => {
        dispatch({ type: 'request' });

        try {
            const data = await adminApiClient.get<ParcelHistory>(
                config.adminApi.endpoints.parcelHistory.replace('{parcelId}', parcelId),
            );
            dispatch({ type: 'success' });

            onSuccess(data);
        } catch (error: any) {
            logger.error(error);
            notify(translate('parcels.history.complete.failure'), { type: 'error' });
            dispatch({ type: 'error', error: error as string });
        }
    };

    return [apiState, fetchFullParcelHistory] as const;
}
