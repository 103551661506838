import { useState } from 'react';
import { Autocomplete, TextField, Box, MenuItem, FormControl } from '@mui/material';
import { useGetList } from 'react-admin';

import { Controller, useFormContext } from 'react-hook-form';

import type { SuggestedApm } from 'modules/apm/types';
import { useTranslate } from 'hooks';
import { ResourceKey } from 'constants/index';

import * as Styled from './PutInLockerDialogFields.styles';

export const PutInLockerDialogFields = () => {
    const translate = useTranslate();

    const [apmInputValue, setApmInputValue] = useState<string>('');
    const [availableCompartments, setAvailableCompartments] = useState<SuggestedApm['availableCompartments'] | null>(
        null,
    );

    const { data: suggestedApms, isLoading } = useGetList<SuggestedApm & { id: string }>(ResourceKey.SUGGESTED_APM, {
        filter: { q: apmInputValue },
    });

    const { control, formState, setValue } = useFormContext();

    return (
        <FormControl fullWidth sx={{ gap: 2 }}>
            <Controller
                render={props => (
                    <Autocomplete<SuggestedApm>
                        {...props.field}
                        options={suggestedApms ?? []}
                        getOptionLabel={option => `${option.name ?? option.identifier} – ${composeAddress(option)}`}
                        renderOption={(props, option) => (
                            <Box {...props} component="li" key={option.id}>
                                <strong>{option.name ?? option.identifier}</strong>
                                <span>{composeAddress(option)}</span>
                            </Box>
                        )}
                        PaperComponent={props => <Styled.AutocompleteDropdown {...props} />}
                        renderInput={params => (
                            <TextField
                                {...params}
                                error={formState.errors.apmId !== undefined}
                                helperText={formState.errors.apmId?.message}
                                label={translate('parcel.detail.putInLocker.dialog.locker.title')}
                            />
                        )}
                        onChange={(_, data) => {
                            props.field.onChange(data?.id);
                            setValue('compartmentNumber', null);

                            const available = data?.availableCompartments;
                            if (available === undefined) {
                                setAvailableCompartments(null);
                                return;
                            }
                            setAvailableCompartments(
                                Array.from(available).sort((a, b) => a.compartmentNumber - b.compartmentNumber),
                            );
                        }}
                        onInputChange={(event, data) => {
                            if (event.type === 'change' || data.length === 0) {
                                setApmInputValue(data);
                            }
                        }}
                        filterOptions={x => x}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        loading={isLoading}
                    />
                )}
                name="apmId"
                control={control}
                rules={{ required: translate('parcel.detail.putInLocker.dialog.locker.required') }}
            />

            <Controller
                render={props => (
                    <TextField
                        {...props.field}
                        error={formState.errors.compartmentNumber !== undefined}
                        select
                        id="compartmentNumber"
                        label={translate('parcel.detail.putInLocker.dialog.compartment.title')}
                        helperText={formState.errors.compartmentNumber?.message}
                        value={props.field.value ?? ''}
                    >
                        {availableCompartments === null && (
                            <MenuItem key={0}>
                                {translate('parcel.detail.putInLocker.dialog.compartment.selectLockerFirst')}
                            </MenuItem>
                        )}
                        {availableCompartments?.length === 0 && (
                            <MenuItem key={0}>
                                {translate('parcel.detail.putInLocker.dialog.compartment.noAvailable')}
                            </MenuItem>
                        )}
                        {availableCompartments?.map(compartment => (
                            <MenuItem key={compartment.compartmentNumber} value={compartment.compartmentNumber}>
                                {compartment.compartmentNumber},{' '}
                                {translate(`parcel.compartmentSize.${compartment.size}`)}
                            </MenuItem>
                        ))}
                    </TextField>
                )}
                name="compartmentNumber"
                control={control}
                rules={{ required: translate('parcel.detail.putInLocker.dialog.compartment.required') }}
            />
        </FormControl>
    );
};

const composeAddress = (record: SuggestedApm): string => {
    if (!record.location) {
        return '';
    }
    return [record.location.street, record.location.city, record.location.postalCode].filter(Boolean).join(', ');
};
