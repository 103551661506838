import { ForcedLogoutCode, HTTPStatusCode } from 'types';

import { createUIErrorMessage, errorMessages } from 'modules/errors';
import type { UIMessageID } from 'modules/errors';

import refresh from './refresh';
import { credentialsStorage } from './authStorage';

interface ErrorResponse {
    message: string;
    status: number;
    body: {
        code?: string;
    };
}

const checkError = async (error: UIMessageID | ErrorResponse) => {
    const isStringError = typeof error === 'string';
    const errorMessage = isStringError ? error : createUIErrorMessage({ status: error.status, code: error.body?.code });

    if (errorMessage === errorMessages.code[ForcedLogoutCode]) {
        throw new Error(errorMessage);
    }

    if (errorMessage === errorMessages.status[HTTPStatusCode.Unauthorized]) {
        const credentials = credentialsStorage.get();

        if (!credentials?.refreshToken) {
            throw new Error(errorMessage);
        }

        await refresh({ refreshToken: credentials.refreshToken });

        /**
         * React Admin doesn't provide mechanism for refreshing access token and reloading
         * current page data when 401 error occur. This is kinda workaround to achieve just
         * exaxt that functionality silently (with no alert toast) with logging user out
         */
        window.location.reload();
        // eslint-disable-next-line no-throw-literal
        throw { logoutUser: false, message: false };
    }
};

export default checkError;
