import { useRecordContext, type RaRecord } from 'react-admin';

export interface ConditionalFieldProps<Record> {
    shouldRender: (record: Record | undefined) => boolean;
    children: React.ReactNode;
}

export const ConditionalField = <Record extends RaRecord>({
    children,
    shouldRender,
}: ConditionalFieldProps<Record>) => {
    const record = useRecordContext<Record>();

    if (!shouldRender(record)) {
        return null;
    }

    return <>{children}</>;
};
