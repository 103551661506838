import { useEffect, useState } from 'react';
import { NotFound as RaNotFound, LoadingPage } from 'react-admin';

import { useUserRegionReady } from 'modules/localization';

export interface NotFoundProps {}

export const NotFound = (props: NotFoundProps) => {
    const userRegionReady = useUserRegionReady();

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (userRegionReady) {
            const delay = setTimeout(() => {
                setIsLoading(false);
            }, 250);

            return () => {
                clearTimeout(delay);
            };
        }
    }, [userRegionReady]);

    if (isLoading) {
        return <LoadingPage />;
    }

    return <RaNotFound {...props} />;
};
