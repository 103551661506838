import { useRecordContext } from 'react-admin';

import type { WarehouseData } from 'modules/partners/types';

import { OptionWithAddress } from '../OptionWithAddress';

export const OptionWarehouse = () => {
    const record = useRecordContext<WarehouseData>();

    if (!record) {
        return null;
    }

    return (
        <OptionWithAddress>
            <strong>{record.name}</strong>
        </OptionWithAddress>
    );
};
