import { Show, TextField, useTranslate, SelectField, ReferenceField } from 'react-admin';
import type { ShowProps } from 'react-admin';
import { BoxedShowLayout, RaBox } from 'ra-compact-ui';

import { ResourceKey } from 'constants/index';
import { SeoHead } from 'modules/ui';
import { Role } from 'modules/roles';
import { RegionsList } from 'modules/ui';

import { DriverPermissions } from './components';

const rolesDisplayNames = [
    Role.SUPER_ADMIN,
    Role.DRIVER,
    Role.DEPOT_WORKER,
    Role.MANAGER,
    Role.OPERATIONS_MANAGER,
    Role.SALES_MANAGER,
    Role.SALES_AND_OPERATION_MANAGER,
    Role.NETWORK_AND_SALES_MANAGER,
    Role.NETWORK_MANAGER,
    Role.CUSTOMER_CARE,
].map(role => ({
    id: role,
    name: `role.${role}`,
}));

export const UserDetail = (props: ShowProps) => {
    const translate = useTranslate();

    return (
        <>
            <SeoHead title={translate('users.list.title')} />
            <Show>
                <BoxedShowLayout>
                    <RaBox>
                        <TextField source="name" label={translate('users.fullname')} />
                        <TextField source="phoneNumber" label={translate('users.phone')} />
                        <SelectField source="role" label={translate('users.role')} choices={rolesDisplayNames} />

                        <ReferenceField
                            source="depot"
                            reference={ResourceKey.LOCATION}
                            label={translate('users.depot')}
                            link={false}
                        >
                            <TextField source="name" />
                        </ReferenceField>

                        <RegionsList label={translate('user.regions')} source="regions" />

                        <DriverPermissions />
                    </RaBox>
                </BoxedShowLayout>
            </Show>
        </>
    );
};
