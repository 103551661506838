import { DateField, FunctionField, TextField, useNotify } from 'react-admin';

import { useToggle, useTranslate } from 'hooks';
import { Button } from 'modules/ui';

import type { ParcelHistoryEventName } from '../../config';
import { parcelStatusOrdered } from '../../config';
import type { Parcel, ParcelStatus } from '../../types';

import { useParcelHistory } from './hooks/useParcelHistory';

import * as Styled from './ParcelHistory.styles';

const sort = { field: 'createdAt', order: 'DESC' } as const;

type HistoryEvent = NonNullable<Parcel['history']>[number] & { eventName?: ParcelHistoryEventName };

function isParcelStateChangeHistoryEvent(eventName: string): eventName is ParcelStatus {
    return parcelStatusOrdered.indexOf(eventName as ParcelStatus) > -1;
}

const ParcelHistory = () => {
    const translate = useTranslate();
    const notify = useNotify();
    const [fullHistoryEnabled, toggleFullHistory] = useToggle();
    const history = useParcelHistory({
        onFullHistoryEmpty() {
            toggleFullHistory(false);
            notify('parcels.history.complete.empty', { type: 'info' });
        },
        onFullHistoryLoaded() {
            toggleFullHistory(true);
        },
    });

    return (
        <Styled.Card elevation={1}>
            <Styled.Title variant="h5">{translate('parcels.history.title')}</Styled.Title>

            <Styled.DataGrid data={history.data} sort={sort}>
                <FunctionField<HistoryEvent>
                    label={translate('parcels.history.event')}
                    render={record => {
                        if (!record?.eventName) {
                            return null;
                        }

                        if (isParcelStateChangeHistoryEvent(record.eventName)) {
                            return translate(`parcel.state.${record.eventName}`);
                        }

                        return translate(`parcels.history.event.${record.eventName}`);
                    }}
                />
                <TextField source="locationName" />
                <TextField source="postalCode" />
                <DateField source="createTime" showTime />
            </Styled.DataGrid>

            <Styled.DataGridFooter>
                <Button
                    color="primary"
                    size="small"
                    disabled={history.state.loading}
                    loading={history.state.loading}
                    onClick={() => {
                        if (!fullHistoryEnabled) {
                            history.showFullHistory();
                        } else {
                            history.hideFullHistory();
                            toggleFullHistory();
                        }
                    }}
                >
                    {translate(fullHistoryEnabled ? 'parcels.history.complete.hide' : 'parcels.history.complete.show')}
                </Button>
            </Styled.DataGridFooter>
        </Styled.Card>
    );
};

export default ParcelHistory;
