import { parsePhoneNumberFromString } from 'libphonenumber-js';

import { useCallback, useEffect, useMemo, useState } from 'react';

import { useRegionPhonePrefix } from 'modules/phone-number/hooks';

import type { ParcelsListFilterValues, ParcelsListFormValues } from '../ParcelsListFilters.types';

function createDefaultFormValues(
    filterValues: ParcelsListFilterValues,
    defaultValues: ParcelsListFormValues,
): ParcelsListFormValues {
    const phone =
        typeof filterValues.customerPhone?.phoneNumber === 'string'
            ? parsePhoneNumberFromString(filterValues.customerPhone?.phoneNumber)
            : undefined;

    return {
        ...defaultValues,
        ...filterValues,
        customerPhone: {
            prefix: (phone?.countryCallingCode as string) ?? defaultValues.customerPhone.prefix,
            number: (phone?.nationalNumber as string) ?? defaultValues.customerPhone.number,
            exactMatch: filterValues.customerPhone?.exactMatch ?? (defaultValues.customerPhone.exactMatch as boolean),
        },
    };
}

export function useParcelsListFiltersDefaultFormValues(filterValues: ParcelsListFilterValues) {
    const regionPhonePrefix = useRegionPhonePrefix();

    // Empty values must be provided, `undefined` value won't reset the form
    const defaultValues = useMemo<ParcelsListFormValues>(
        () => ({
            parcelNumber: null,
            customerName: null,
            customerEmail: null,
            customerIssue: null,
            referenceNumber: null,
            partnerName: null,
            status: null,
            paymentMode: null,
            paymentState: null,
            from: null,
            to: null,
            customerPhone: {
                prefix: regionPhonePrefix ?? null,
                number: null,
                exactMatch: true,
            },
        }),
        [regionPhonePrefix],
    );

    const [defaultFormValues, setDefaultFormValues] = useState<ParcelsListFormValues>(() =>
        createDefaultFormValues(filterValues, defaultValues),
    );

    useEffect(() => {
        setDefaultFormValues(createDefaultFormValues(filterValues, defaultValues));
    }, [defaultValues, filterValues]);

    /**
     * Reset form values to default values without excluding all search params
     */
    const resetToDefaultFormValues = useCallback(() => {
        setDefaultFormValues(defaultValues);
        return defaultValues;
    }, [defaultValues]);

    return {
        defaultFormValues,
        resetToDefaultFormValues,
    } as const;
}
