import { Confirm, useRecordContext } from 'react-admin';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { useConfirmDialog, useTranslate } from 'hooks';
import { ResourceKey } from 'constants/index';
import { PermissionAction } from 'modules/roles';
import { useHasAccess } from 'modules/auth';
import { Button } from 'modules/ui';

import type { Parcel } from 'modules/parcels/types';

import { useChangeToPrepaid } from './hooks/useChangeToPrepaid';

export const ChangeToPrepaidButton = () => {
    const translate = useTranslate();
    const [confirmOpen, openConfirm, closeConfirm] = useConfirmDialog();
    const { payment, id }: Parcel = useRecordContext();
    const { trigger, isLoading } = useChangeToPrepaid(id, { onSettled: closeConfirm });

    const userHasAccess = useHasAccess(ResourceKey.PARCELS, PermissionAction.CHANGE_COD_TO_PREPAID);
    const isCod = payment.mode === 'cod';
    const isUnpaid = payment.state === 'pending';

    if (!userHasAccess || !isCod || !isUnpaid) {
        return null;
    }

    return (
        <>
            <Button color="primary" type="button" size="small" loading={isLoading} onClick={openConfirm}>
                {translate('parcel.detail.changeToPrepaid.button')}
            </Button>
            <Confirm
                isOpen={confirmOpen}
                loading={isLoading}
                title={translate('parcel.detail.changeToPrepaid.dialog.title')}
                content={translate('parcel.detail.changeToPrepaid.dialog.description')}
                confirm={translate('action.confirm')}
                cancel={translate('general.cancel')}
                CancelIcon={ArrowBackIcon}
                onClose={closeConfirm}
                onConfirm={trigger}
            />
        </>
    );
};
