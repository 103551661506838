import { styled } from '@mui/material';
import { TextField } from 'react-admin';
import { RaBox } from 'ra-compact-ui';

export const ParcelNumber = styled(TextField)(() => ({
    fontSize: '1.5rem',
    fontWeight: 'bold',

    '& .RaLabeled-label': {
        fontSize: '0.75rem',
    },
}));

export const Header = styled(RaBox)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    columnGap: theme.spacing(2),

    [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        alignItems: 'center',
    },
}));

export const Actions = styled(RaBox)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: theme.spacing(1),
    flexWrap: 'wrap',

    [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
    },
}));
