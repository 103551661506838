import { styled } from '@mui/material';

import { ImageField as RaImageField, ImageFieldClasses } from 'react-admin';

export const ImageField = styled(RaImageField)(() => ({
    [`& .${ImageFieldClasses.image}`]: {
        maxWidth: '100%',
        width: 'auto',
        height: 'auto',
    },
}));
