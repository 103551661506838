import { Dialog, DialogContent, DialogTitle, DialogActions, TextField, MenuItem } from '@mui/material';
import { Controller } from 'react-hook-form';

import { useTranslate } from 'hooks';
import { Button } from 'modules/ui';
import { isEmptyString } from 'utils';

import { apmIssueTypes } from '../../../constants';
import { useReportIssueModal } from '../hooks';

export interface ReportIssueModalProps {
    open: boolean;
    handleClose: () => void;
}

export const ReportIssueModal = ({ open, handleClose }: ReportIssueModalProps) => {
    const translate = useTranslate();

    const { form, apiState, onCancel, onSubmit, isTypeOther } = useReportIssueModal({ handleClose });

    return (
        <Dialog open={open} onClose={onCancel} fullWidth>
            <DialogTitle>{translate('apm.reportIssue.button')}</DialogTitle>
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <DialogContent>
                    <Controller
                        name="type"
                        control={form.control}
                        rules={{ required: translate('general.required') }}
                        render={({ field, fieldState: { invalid, error } }) => (
                            <TextField
                                label={translate('apm.reportIssue.issue')}
                                select
                                fullWidth
                                error={invalid}
                                helperText={error?.message}
                                {...field}
                            >
                                {apmIssueTypes.map(issue => (
                                    <MenuItem key={issue.value} value={issue.value}>
                                        {translate(`apm.reportIssue.issueType.${issue.messageKey}`)}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                    />
                    <Controller
                        name="description"
                        control={form.control}
                        rules={{
                            validate: (value: string) =>
                                isTypeOther && isEmptyString(value) ? translate('general.required') : undefined,
                        }}
                        render={({ field, fieldState: { invalid, error } }) => (
                            <TextField
                                label={translate('apm.reportIssue.description')}
                                multiline
                                rows={4}
                                fullWidth
                                error={invalid}
                                helperText={error?.message}
                                {...field}
                            />
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel}>{translate('general.cancel')}</Button>
                    <Button variant="contained" type="submit" loading={apiState.loading}>
                        {translate('action.confirm')}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};
