import { Typography } from '@mui/material';
import { useTranslate } from 'react-admin';

import type { FileInputValue } from 'types';

export interface FileImportStatusProps {
    status: 'error' | 'success';
    file?: null | FileInputValue;
}

export const FileImportStatus = ({ status, file }: FileImportStatusProps) => {
    const translate = useTranslate();

    return (
        <Typography variant="body1" mb={1}>
            <span
                dangerouslySetInnerHTML={{
                    __html: translate(`parcel.paymentImport.import.${status}`, {
                        file: `<a href="${file?.src}">${file?.title}</a>`,
                    }),
                }}
            />
        </Typography>
    );
};
