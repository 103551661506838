import { styled, Select as MUISelect, Input as MUIInput } from '@mui/material';

import Button from '../Button';

export const Select = styled(MUISelect)(({ theme }) => ({
    minWidth: 180,
    fontSize: theme.typography.body2.fontSize,
}));

export const Input = styled(MUIInput)(({ theme }) => ({
    minWidth: 250,
    fontSize: theme.typography.body2.fontSize,
}));

export const ChangeButton = styled(Button)(() => ({
    paddingTop: 0,
    paddingBottom: 0,
}));
