import { SimpleFormIterator, ArrayInput } from 'react-admin';
import { styled } from '@mui/material';

export const SetupSection = styled('section')(({ theme }) => ({
    padding: theme.spacing(2),
    paddingBottom: 0,
    marginBottom: theme.spacing(5),
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],
}));

export const NumbersArrayInput = styled(ArrayInput)(({ theme }) => ({
    marginTop: 0,
}));

export const NumbersIterator = styled(SimpleFormIterator)(({ theme }) => ({
    marginTop: 0,
}));
