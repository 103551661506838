import { useRecordContext, useTranslate } from 'react-admin';
import { Typography, Grid } from '@mui/material';

import React from 'react';

import { PermissionAction } from 'modules/roles';
import { ResourceKey } from 'constants/index';
import { useHasAccess } from 'modules/auth';
import { Button } from 'modules/ui';

import type { Parcel } from 'modules/parcels/types';

import * as Styled from './ParcelNote.styles';
import { ParcelNoteForm } from './ParcelNoteForm';
import { DeleteParcelNoteButton } from './DeleteParcelNoteButton';

export const ParcelNote = () => {
    const translate = useTranslate();

    const record = useRecordContext<Parcel>({ resource: ResourceKey.PARCELS });
    const hasEditAccess = useHasAccess(ResourceKey.PARCELS, PermissionAction.EDIT_NOTE);
    const [editMode, setEditMode] = React.useState(false);
    const enableEditMode = () => setEditMode(true);
    const disableEditMode = () => setEditMode(false);

    return (
        <>
            <Grid container alignItems="center" spacing={2}>
                <Grid item>
                    <Typography component="h3" variant="h6">
                        {translate('parcel.note.label')}
                    </Typography>
                </Grid>
                {hasEditAccess && !editMode && (
                    <Grid item>
                        {record.note ? (
                            <Grid container alignItems="center" spacing={2}>
                                <Grid item>
                                    <DeleteParcelNoteButton onSuccess={disableEditMode} />
                                </Grid>
                                <Grid item>
                                    <Button
                                        type="button"
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onClick={enableEditMode}
                                    >
                                        {translate('action.edit')}
                                    </Button>
                                </Grid>
                            </Grid>
                        ) : (
                            <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={enableEditMode}
                            >
                                {translate('parcel.note.add')}
                            </Button>
                        )}
                    </Grid>
                )}
            </Grid>
            <Styled.Note>
                {editMode ? (
                    <ParcelNoteForm onCancel={disableEditMode} onSubmitSucceeded={disableEditMode} />
                ) : (
                    <Styled.NoteTextField source="note" emptyText={translate('parcel.note.empty')} />
                )}
            </Styled.Note>
        </>
    );
};
