import { useMemo } from 'react';
import { useGetList } from 'react-admin';

import { ResourceKey } from 'constants/index';

import type { ExtendedLocation } from '../types';

export const useRoutePlan = () => {
    const { isLoading, error, data } = useGetList(ResourceKey.LOCATION, {
        pagination: { page: 1, perPage: 100 },
    });

    const { depots, other } = useMemo(
        () =>
            (data ?? []).reduce(
                (locations: { depots: ExtendedLocation[]; other: ExtendedLocation[] }, location: ExtendedLocation) => {
                    if (location.locationType === 'depot') {
                        locations.depots.push(location);
                    } else {
                        locations.other.push(location);
                    }

                    return locations;
                },
                { depots: [], other: [] },
            ),
        [data],
    );

    return {
        depots,
        other,
        loading: isLoading,
        error,
    };
};
