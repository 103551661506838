import { useState } from 'react';

import { useTranslate } from 'hooks';
import { Button } from 'modules/ui';

import { SyncLockerModal } from './SyncLockerModal';

export const SyncLockerButton = () => {
    const translate = useTranslate();
    const [modalOpen, setModalOpen] = useState(false);

    return (
        <>
            <Button variant="contained" onClick={() => setModalOpen(true)}>
                {translate('apm.syncLocker.button')}
            </Button>
            <SyncLockerModal open={modalOpen} handleClose={() => setModalOpen(false)} />
        </>
    );
};
