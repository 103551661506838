import { omit } from 'lodash';

import type { components as apiComponents } from 'api/apiSchema';
import type { components as adminApiComponents } from 'api/adminApiSchema';

import { LocalStorage } from 'services/storage';

type User = apiComponents['schemas']['User'] | adminApiComponents['schemas']['User'];
type Credentials = apiComponents['schemas']['AuthTokenResponse'] | adminApiComponents['schemas']['AuthTokenResponse'];

type StoredCredentials = Omit<Credentials, 'expiresIn'> & { expiresAt?: string };

const DEFAULT_TOKEN_EXPIRATION = 3600;

class CredentialsStorage extends LocalStorage<StoredCredentials> {
    save(credentials: Credentials) {
        const now = new Date();
        now.setSeconds(now.getSeconds() + (credentials.expiresIn ?? DEFAULT_TOKEN_EXPIRATION));

        super.save({
            ...omit(credentials, 'expiresIn'),
            expiresAt: now.toISOString(),
        });
    }
}

export const userStorage = new LocalStorage<User>('boxnow_cms.user');
export const credentialsStorage = new CredentialsStorage('boxnow_cms.credentials');
