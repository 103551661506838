import { styled } from '@mui/material';

export const Sections = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '2fr 5fr',
    gridTemplateAreas: `
            'partner parcel'
            'partner payment'
        `,
    gridGap: theme.spacing(2),
    marginTop: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '1fr',
        gridTemplateAreas: `
            'partner'
            'parcel'
            'payment'
        `,
    },
}));
