import { useRecordContext, useTranslate } from 'react-admin';

import type { ExtendedLocation } from '../../../types';

export interface ApmLocationOptionProps {}

const composeIdentifier = (record: ExtendedLocation) =>
    [record.id, record.identifier, record.name, record.postalCode].filter(Boolean).join(', ');

const inputText = (record: ExtendedLocation, translate: (key: string) => string) =>
    record.apm?.state
        ? `loc_id: ${composeIdentifier(record)}; apm_state: ${translate(`apm.state.${record.apm.state}`)}`
        : `loc_id: ${composeIdentifier(record)}`;

const matchSuggestion = (filterValue: string, suggestion?: ExtendedLocation) => {
    if (!suggestion) {
        return false;
    }

    return composeIdentifier(suggestion).toLowerCase().includes(filterValue.toLowerCase());
};

const createInputText = (translate: (key: string) => string) => {
    return (record: ExtendedLocation) => inputText(record, translate);
};

export const ApmLocationOption = (props: ApmLocationOptionProps) => {
    const translate = useTranslate();
    const record = useRecordContext<ExtendedLocation>();

    if (!record) {
        return null;
    }

    return <div>{inputText(record, translate)}</div>;
};

ApmLocationOption.inputText = createInputText;
ApmLocationOption.matchSuggestion = matchSuggestion;
