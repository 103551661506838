import type { MessageKey } from 'modules/localization';
import type { FilterByPrefix } from 'types';

export const apmIssueTypes = [
    { value: 'Visual Locker Damage', messageKey: 'visualLockerDamage' },
    { value: 'Connectivity / BT Issues', messageKey: 'connectivityIssues' },
    { value: 'Faulty Keypad', messageKey: 'faultyKeypad' },
    { value: 'Faulty Door', messageKey: 'faultyDoor' },
    { value: 'Broken Screen', messageKey: 'brokenScreen' },
    { value: 'Locker Not Responding', messageKey: 'lockerNotResponding' },
    { value: 'Other', messageKey: 'other' },
] as const satisfies ReadonlyArray<{
    value: string;
    messageKey: FilterByPrefix<MessageKey, 'apm.reportIssue.issueType.'>;
}>;
