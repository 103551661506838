import { RadioButtonGroupInput, useRecordContext } from 'react-admin';
import { useWatch, useFormContext } from 'react-hook-form';
import { useState } from 'react';
import { Typography, Stack, Tooltip } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';

import { useConfirmDialog, useTranslate } from 'hooks';

import type { PartnerDetailUpdate } from 'modules/partners/types';

import { PartnerVipChangeDialog } from '../PartnerVipChangeDialog';
import { PartnerVip, partnerVipChoices } from '../../constants';

interface PartnerVipSelectProps {
    vipLabel: string;
}

export const PartnerVipSelect = ({ vipLabel }: PartnerVipSelectProps) => {
    const translate = useTranslate();
    const { setValue } = useFormContext();
    const { vip: originalVip } = useRecordContext<PartnerDetailUpdate>() ?? {};

    const [vipChangeDialogOpen, openVipChangeDialog, closeVipChangeDialog] = useConfirmDialog();
    const vipChangeReason = useWatch({ name: 'vipChangeReason' });
    const currentVip = useWatch<PartnerDetailUpdate>({ name: 'vip' });

    const [previousVip, setPreviousVip] = useState<PartnerDetailUpdate['vip']>(originalVip);

    return originalVip ? (
        <>
            <Stack direction="row">
                <RadioButtonGroupInput
                    source="vip"
                    label={vipLabel}
                    choices={partnerVipChoices}
                    defaultValue={PartnerVip.No}
                    translate="no"
                    onChange={e => {
                        if (e.target.value !== originalVip) {
                            openVipChangeDialog();
                        } else {
                            setPreviousVip(originalVip);
                            setValue('vipChangeReason', undefined);
                        }
                    }}
                />
                {vipChangeReason ? (
                    <Tooltip
                        placement="right"
                        sx={{ ml: 2.5, mt: 5 }}
                        title={
                            <Stack p={1} spacing={1}>
                                <Typography variant="caption">
                                    {translate('partners.vipChange.changeDescription', {
                                        previousState: translate(
                                            `partners.characteristics.vip.${originalVip as PartnerVip}`,
                                        ),
                                        currentState: translate(
                                            `partners.characteristics.vip.${currentVip as PartnerVip}`,
                                        ),
                                    })}
                                </Typography>
                                <Typography variant="body1">{vipChangeReason}</Typography>
                            </Stack>
                        }
                    >
                        <ChatIcon />
                    </Tooltip>
                ) : null}
            </Stack>
            <PartnerVipChangeDialog
                open={vipChangeDialogOpen}
                onClose={closeVipChangeDialog}
                setPreviousVip={setPreviousVip}
                previousVip={previousVip}
            />
        </>
    ) : null;
};
