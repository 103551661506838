import type { ApmDetail } from 'modules/apm/types';
import type { Warehouse } from 'modules/warehouses/types';

const isApmLocation = (location: ApmDetail | Warehouse): location is ApmDetail => {
    return (location as ApmDetail).depot !== undefined;
};

export const locationParentDepotValidator = (
    value: string,
    allValues: ApmDetail | Warehouse,
    record?: ApmDetail | Warehouse,
) => {
    if (!record) return;
    if (record.lanes?.length === 0) return;

    const isApm = isApmLocation(record);

    if ((isApm && record.depot !== value) || (!isApm && record.depotId !== value)) {
        return 'error.api.cannotChangeLocationParent';
    }

    return;
};
