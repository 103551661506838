import {
    TextField,
    useTranslate,
    ReferenceField,
    BooleanField,
    useListContext,
    RecordContextProvider,
    useRecordContext,
} from 'react-admin';
import { RaBox } from 'ra-compact-ui';

import { ResourceKey } from 'constants/index';
import { AddressField, LatLngField } from 'modules/ui';
import type { PartnerData } from 'modules/partners/types';

import * as Styled from './WarehousesList.styles';
import { DefaultWarehouseForReturnsField } from '../DefaultWarehouseForReturnsField';

export interface WarehousesListProps {}

export const WarehousesList = (props: WarehousesListProps) => {
    const translate = useTranslate();
    const { data } = useListContext();
    const partner = useRecordContext<PartnerData>();

    return (
        <>
            {data.map(record => (
                <RecordContextProvider value={record} key={record.id}>
                    <Styled.BoxedShowLayoutItem record={record}>
                        <RaBox>
                            <TextField source="id" label={translate('warehouse.id')} />
                            <BooleanField source="deleted" label={translate('partners.warehouse.disabled')} />
                            <DefaultWarehouseForReturnsField partner={partner} />
                            <TextField label={translate('warehouse.name')} source="name" />
                            <TextField source="identifier" label={translate('warehouse.identifier')} />
                            <AddressField />
                            <LatLngField source="coordinates" />
                            <ReferenceField
                                source="depotId"
                                label={translate('warehouse.serviceArea')}
                                reference={ResourceKey.LOCATION}
                                link={false}
                            >
                                <TextField source="name" />
                            </ReferenceField>
                        </RaBox>
                    </Styled.BoxedShowLayoutItem>
                </RecordContextProvider>
            ))}
        </>
    );
};
