import { useTranslate, useRecordContext, useNotify } from 'react-admin';
import { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, DialogActions, RadioGroup, FormControlLabel, Radio } from '@mui/material';

import type { Parcel } from 'modules/parcels/types';
import { usePatchUpdate } from 'hooks';
import { ResourceKey } from 'constants/index';
import { Button } from 'modules/ui';
import { logger } from 'config';

import { FinalDestinationWarning } from '../FinalDestinationWarning';

type ParcelSize = Parcel['compartmentSize'];

const sizes = [0, 1, 2, 3];

export interface EditParcelSizeDialogProps {
    visible: boolean;
    onClose: () => void;
}

export const EditParcelSizeDialog = ({ visible, onClose }: EditParcelSizeDialogProps) => {
    const translate = useTranslate();
    const notify = useNotify();

    const record = useRecordContext<Parcel>();

    const initialParcelSize = record?.compartmentSize;
    const [parcelSize, setParcelSize] = useState<ParcelSize>(initialParcelSize);

    const [updateParcelSize, { isLoading }] = usePatchUpdate(
        ResourceKey.PARCELS,
        { id: record.id, data: { compartmentSize: parcelSize }, previousData: record },
        {
            onSuccess: () => {
                onClose();
                notify('parcel.editParcelSize.success', { type: 'success' });
            },
            onError: error => {
                logger.error(error);
                notify('error.api.general', { type: 'error' });
            },
            mutationMode: 'pessimistic',
        },
    );

    // Update initial parcel size due to stale-while-revalidate rendering
    useEffect(() => {
        if (initialParcelSize !== undefined && parcelSize === undefined) {
            setParcelSize(initialParcelSize);
        }
    }, [initialParcelSize, parcelSize]);

    if (!record) {
        return null;
    }

    return (
        <Dialog open={visible} onClose={onClose} maxWidth="xs" fullWidth>
            <DialogTitle>{translate('parcel.compartmentSize')}</DialogTitle>
            <DialogContent>
                <RadioGroup
                    value={parcelSize}
                    name="compartmentSize"
                    onChange={e => setParcelSize(Number(e.target.value) as ParcelSize)}
                >
                    {sizes.map(size => (
                        <FormControlLabel
                            key={size}
                            value={size}
                            control={<Radio />}
                            label={translate(`parcel.compartmentSize.${size}`)}
                        />
                    ))}
                </RadioGroup>
                {record.status === 'in-final-destination' && <FinalDestinationWarning />}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={isLoading}>
                    {translate('general.cancel')}
                </Button>
                <Button variant="contained" onClick={() => updateParcelSize()} disabled={isLoading} loading={isLoading}>
                    {translate('action.confirm')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
