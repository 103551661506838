import type { ReactElement } from 'react';
import { List, useResourceContext } from 'react-admin';
import type { ListProps, RaRecord } from 'react-admin';

import type { ResourceKey } from 'constants/index';
import { PermissionAction } from 'modules/roles';

import { useHasAccess } from '../../hooks';

export interface ListWithPermissionProps extends ListProps {
    children: ReactElement;
}

export const ListWithPermissions = <RecordType extends RaRecord>({
    filters,
    exporter,
    ...props
}: ListWithPermissionProps) => {
    const resource = useResourceContext() as ResourceKey;
    const access = {
        search: useHasAccess(resource, PermissionAction.SEARCH),
        export: useHasAccess(resource, PermissionAction.EXPORT),
    };

    return (
        <List<RecordType>
            {...props}
            filters={access.search ? filters : undefined}
            exporter={access.export ? exporter : false}
        />
    );
};
