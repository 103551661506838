import { styled, Paper } from '@mui/material';

export const Card = styled(Paper)(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'min-content auto',
    gap: theme.spacing(2),

    padding: theme.spacing(1),
}));

export const Note = styled('small')(({ theme }) => ({
    marginTop: theme.spacing(2),
}));
