import { useListContext, useTranslate } from 'react-admin';
import { Box, Stack, Typography } from '@mui/material';

import GppGoodIcon from '@mui/icons-material/GppGood';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';

import type { SlaSettings } from '../../types';
import { SlaSettingsCard } from './SlaSettingsCard';

const CSV_TYPES = ['holidays', 'sla', 'eta'] as const satisfies SlaSettings['name'][];

export const SlaSettingsPage = () => {
    const { data } = useListContext<SlaSettings>();
    const translate = useTranslate();

    const isActive = data?.length === CSV_TYPES.length;

    return (
        <>
            <Box my={4}>
                <Typography variant="h5" display="flex" gap={0.5} alignItems="center">
                    {isActive ? <GppGoodIcon color="success" /> : <GppMaybeIcon color="warning" />}
                    {translate(isActive ? 'slaSettings.list.subtitle.active' : 'slaSettings.list.subtitle.inactive')}
                </Typography>
                {!isActive && <Typography variant="body2">{translate('slaSettings.inactive.description')}</Typography>}
            </Box>
            <Stack spacing={2} mb={8}>
                {CSV_TYPES.map(type => (
                    <SlaSettingsCard type={type} key={type} />
                ))}
            </Stack>
        </>
    );
};
