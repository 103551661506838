import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { cloneDeep } from 'lodash';

import { useWarehouseEdit } from './useWarehouseEdit';
import { useSetWarehouseValues } from './useSetWarehouseValues';
import type { WarehouseData } from '../types';
import { createWarehouseSourcePrefix } from '../utils';

const useCancelEditation = (warehouseIndex: number, initialData: WarehouseData) => {
    const setValues = useSetWarehouseValues();
    const [, setEditedWarehouse] = useWarehouseEdit();

    return function handleCancelEdit() {
        setValues(warehouseIndex, initialData);
        setEditedWarehouse(null);
    };
};

export const usePartnerWarehouse = (warehouseIndex: number) => {
    const [, setEditedWarehouse] = useWarehouseEdit();
    const { watch, clearErrors } = useFormContext();

    const data = watch().warehouses[warehouseIndex] as WarehouseData;
    const cloneData = () => cloneDeep(data);
    const sourcePrefix = createWarehouseSourcePrefix(warehouseIndex);

    const [initialData, setInitialData] = useState(cloneData);

    const handleSubmit = () => {
        setInitialData(cloneData); // reinitialize warehouse initial data
        setEditedWarehouse(null);
        clearErrors(`${sourcePrefix}depot`); // clear cannot change parent depot error if it was set
    };
    const handleCancel = useCancelEditation(warehouseIndex, initialData);

    // Name is required so it can be empty only for new warehouse
    const isNewWarehouse = !initialData.name;

    return {
        data,
        handleSubmit,
        handleCancel,
        isNew: isNewWarehouse,
    };
};
