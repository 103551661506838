import { styled } from '@mui/material';
import { TextField } from 'react-admin';

export const Note = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(2),
}));

export const NoteTextField = styled(TextField)(() => ({
    whiteSpace: 'pre-line',
}));
