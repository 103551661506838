import { useRecordContext } from 'react-admin';

import { useAvailableRegions } from 'modules/localization';

import type { Lane } from '../types';

export const useUserCanManageLane = () => {
    const { data: userRegions } = useAvailableRegions();
    const { regions: laneRegions } = useRecordContext<Lane>() ?? {};

    if (!userRegions || !laneRegions) {
        return false;
    }

    const userRegionsIds = userRegions.map(({ id }) => id);

    return laneRegions.every(laneRegionId => userRegionsIds.includes(laneRegionId));
};
