import config from 'config';
import { authApiClient } from 'modules/auth/services/authApiClient';
import type { paths } from 'api/apiSchema';

type Endpoint = paths['/api/v1/images']['post'];
type ResponseBody = Endpoint['responses']['200']['content']['application/json'];

export default async (image: { rawFile: File }) => {
    const body = new FormData();
    body.append('files[]', image.rawFile, image.rawFile.name);

    return await authApiClient.post<FormData, ResponseBody>(config.api.endpoints.images, body);
};
