import { Box, Collapse } from '@mui/material';
import { TextInput, minLength } from 'react-admin';

import { useTranslate } from 'hooks';

export interface ParcelsListFiltersExpandedProps {
    expanded: boolean;
    inputWidth: string;
}

export const ParcelsListFiltersExpanded = ({ expanded, inputWidth }: ParcelsListFiltersExpandedProps) => {
    const translate = useTranslate();

    return (
        <Collapse in={expanded} unmountOnExit>
            <Box display="grid" gridTemplateColumns={{ xs: '1fr', md: `repeat(3, ${inputWidth})` }} columnGap={2}>
                <TextInput label={translate('parcels.list.reference')} source="referenceNumber" resettable />
                <TextInput
                    label={translate('parcels.list.customerName')}
                    source="customerName"
                    validate={minLength(3)}
                    resettable
                />
                <TextInput
                    label={translate('parcels.list.customerEmail')}
                    type="email"
                    source="customerEmail"
                    resettable
                    validate={minLength(3)}
                />
            </Box>
        </Collapse>
    );
};
