import { FunctionField, Labeled, NumberField, useTranslate } from 'react-admin';
import { Grid, Typography } from '@mui/material';

import { ParcelSize } from 'modules/parcels';

import type { ApmDetail } from 'modules/apm/types';

import { SizeStatus } from './SizeStatus';
import { probabilityOption } from '../ApmEditProbability';
import { formatProbability } from '../ApmEditProbability/ApmEditProbability.utils';

export interface ApmAvailabilityInfoProps {}

const sizes = [ParcelSize.L, ParcelSize.M, ParcelSize.S] as const;

export const ApmAvailabilityInfo = (props: ApmAvailabilityInfoProps) => {
    const translate = useTranslate();

    return (
        <Grid container columnSpacing={8}>
            <Grid item>
                <Labeled label={translate('apm.edit.availability.courier')}>
                    <>
                        {sizes.map(size => (
                            <SizeStatus key={size} size={size} type="c" />
                        ))}
                    </>
                </Labeled>
            </Grid>
            <Grid item>
                <Labeled label={translate('apm.edit.availability.eshop')}>
                    <>
                        {sizes.map(size => (
                            <SizeStatus key={size} size={size} type="e" />
                        ))}
                    </>
                </Labeled>
            </Grid>
            <Grid item>
                <Labeled label={translate('apm.edit.probability')}>
                    <>
                        {sizes.map(size => {
                            const option = probabilityOption[size];
                            return (
                                <FunctionField<ApmDetail>
                                    key={size}
                                    source={option}
                                    render={record => {
                                        const value = formatProbability(record ? record[option] : null);
                                        const formattedValue = value ? `${value}%` : '–';

                                        return (
                                            <Typography variant="body1">{`${ParcelSize[size]} – ${formattedValue}`}</Typography>
                                        );
                                    }}
                                />
                            );
                        })}
                    </>
                </Labeled>
            </Grid>
            <Grid item>
                <Labeled>
                    <NumberField
                        source="availabilityCoefficient"
                        label={translate('apm.availabilityCoefficient')}
                        options={{
                            minimumFractionDigits: 2,
                        }}
                    />
                </Labeled>
            </Grid>
        </Grid>
    );
};
