import { identity } from 'lodash';
import type { GetListResult, GetOneResult, RaRecord } from 'react-admin';

import type { components } from 'api/adminApiSchema';
import { ResourceKey } from 'constants/index';
import type { PartnerData } from 'modules/partners/types';

import { mapConfigurationFromApi, mapConfigurationToApi } from 'modules/app-configuration';

import uploadImage from './uploadImage';
import { filterPayloadByKeys } from './utils';
import { apmEditPayloadKeys } from './constants';

type Location = components['schemas']['ExtendedLocation'];
type Compartment = Omit<components['schemas']['Compartment'], 'size'> & { size: 1 | 2 | 3 | 'x_1' | 'x_2' };

type DataToApiMapper = (data: Partial<RaRecord>) => Promise<Partial<RaRecord>>;
type DataFromApiMapper = (data: RaRecord) => RaRecord;

type ResourceDataMapping = Record<
    ResourceKey,
    {
        mapFromApi: DataFromApiMapper;
        mapToApi: DataToApiMapper;
    }
>;

const resourceDataMapping: ResourceDataMapping = {
    [ResourceKey.PARCELS]: {
        mapFromApi: identity,
        mapToApi: identity,
    },
    [ResourceKey.APM]: {
        mapFromApi: data => ({
            ...data,
            // Add empty compartments for apm resource to keep the same shape with getOne request and comply with optimistic rendering
            // https://marmelab.com/react-admin/DataProviders.html#writing-your-own-data-provider
            compartments: (data.compartments ?? [])
                .sort((lhs: Compartment, rhs: Compartment) => lhs.compartmentNumber - rhs.compartmentNumber)
                // API treats x-sized compartments as "regular sized" ones, just with xSized flag.
                // Whereas in CMS, we combine both x-sized and regular sized compartments into one array to be able to use regular react-admin components.
                .map((compartment: Compartment) => ({
                    ...compartment,
                    size: compartment.xSized ? `x_${compartment.size}` : compartment.size,
                })),
        }),
        mapToApi: async data => {
            const payload = { ...data };

            // Cannot send null values to the API
            if (!data.photo) {
                delete payload.photo;
            }

            if (data.photo && data.photo.rawFile) {
                const [picture] = await uploadImage(data.photo);

                payload.photo = picture.previewUrl;
            }

            payload.compartments = data.compartments.map((compartment: Compartment) => {
                if (typeof compartment.size === 'string' && compartment.size.startsWith('x_')) {
                    return {
                        ...compartment,
                        size: parseInt(compartment.size.split('_')[1]),
                        xSized: true,
                    };
                }

                return {
                    ...compartment,
                    xSized: false,
                };
            });

            const filteredPayload = filterPayloadByKeys(payload, apmEditPayloadKeys);

            return filteredPayload;
        },
    },
    [ResourceKey.LANES]: {
        mapFromApi: identity,
        mapToApi: async data => {
            const { depot, stops, apmAvailabilityCoefficient, ...rest } = data;

            // Create / Edit EP requires just id to be supplied while there is whole entity in list
            return {
                ...rest,
                depot: depot.id,
                stops: (data.stops ?? []).map((stop: Location) => stop.id),
                ...(rest.type === 'primary' && { apmAvailabilityCoefficient }),
            };
        },
    },
    [ResourceKey.LOCATION]: {
        mapFromApi: identity,
        mapToApi: identity,
    },
    [ResourceKey.USER]: {
        mapFromApi: identity,
        mapToApi: identity,
    },
    [ResourceKey.PARTNER]: {
        mapFromApi: data => ({
            ...data,
            shippingRegions: ((data as PartnerData).shippingRegions ?? []).map(({ id }) => id),
        }),
        mapToApi: async data => {
            const webhook = (data as PartnerData).parcelEventChangeWebhook;
            const parcelEventChangeWebhook = webhook?.url
                ? {
                      ...webhook,
                      header: webhook.header?.name ? webhook.header : undefined,
                  }
                : undefined;

            return {
                ...data,
                contractRegion: data.contractRegion?.id,
                parcelEventChangeWebhook,
            };
        },
    },
    [ResourceKey.REGION]: {
        mapFromApi: identity,
        mapToApi: identity,
    },
    [ResourceKey.DELIVERY_PARTNER]: {
        mapFromApi: identity,
        mapToApi: identity,
    },
    [ResourceKey.WAREHOUSE]: {
        mapFromApi: identity,
        mapToApi: identity,
    },
    [ResourceKey.APP_CONFIGURATION]: {
        mapFromApi: mapConfigurationFromApi,
        mapToApi: mapConfigurationToApi,
    },
    [ResourceKey.SLA_SETTINGS]: {
        mapFromApi: identity,
        mapToApi: identity,
    },
    [ResourceKey.SUGGESTED_WAREHOUSE]: {
        mapFromApi: identity,
        mapToApi: identity,
    },
    [ResourceKey.SUGGESTED_APM]: {
        mapFromApi: identity,
        mapToApi: identity,
    },
};

export const mapFromApi = (resource: ResourceKey, response: GetListResult<RaRecord> | GetOneResult<RaRecord>) => ({
    ...response,
    data: Array.isArray(response.data)
        ? response.data.map(item => resourceDataMapping[resource].mapFromApi(item))
        : resourceDataMapping[resource].mapFromApi(response.data),
});

export const mapToApi = async (resource: ResourceKey, data: Partial<RaRecord>) =>
    await resourceDataMapping[resource].mapToApi(data);
