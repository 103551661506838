import { ArrayField, Datagrid, TextField, SelectField, BooleanField, useRecordContext } from 'react-admin';

import { useTranslate } from 'hooks';
import type { ApmDetail } from 'modules/apm/types';

import { compartmentSizes } from '../../constants';
import { StoredParcelField } from '../StoredParcelField';
import { ApmButtonOpen } from '../ApmButtonOpen';

export const ApmCompartments = () => {
    const translate = useTranslate();
    const record = useRecordContext<ApmDetail>();

    if (!record) {
        return null;
    }

    const noLabel = <></>;

    return (
        <ArrayField source="compartments" label={noLabel}>
            <Datagrid bulkActionButtons={false}>
                <TextField source="compartmentNumber" label="" />
                <SelectField source="size" label={translate('apm.edit.compartments.size')} choices={compartmentSizes} />
                <BooleanField source="availability" label={translate('apm.list.availability')} />

                <StoredParcelField label="apm.edit.compartments.storedParcel" />

                <ApmButtonOpen apmId={record.id} />
            </Datagrid>
        </ArrayField>
    );
};
