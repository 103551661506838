import { useRecordContext } from 'react-admin';

import { useSyncLocker } from '../../../hooks';
import type { Apm } from '../../../types';

interface SyncLockerOptions {
    handleClose: () => void;
}

export const useSyncLockerModal = ({ handleClose }: SyncLockerOptions) => {
    const apmRecord = useRecordContext<Apm>();
    const [apiState, syncLocker, reset, syncLockerApiError] = useSyncLocker(apmRecord?.id, {
        onSuccess: () => {
            setTimeout(() => {
                handleClose();
                reset();
            }, 1000);
        },
    });

    const onSubmit = async () => {
        reset();
        await syncLocker();
    };

    const onCancel = () => {
        handleClose();
        reset();
    };

    return { apiState, onCancel, onSubmit, syncLockerApiError };
};
