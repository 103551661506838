import { Form, useListContext } from 'react-admin';

import { useToggle } from 'hooks';

import { ParcelsListFiltersCollapsed } from '../ParcelsListFiltersCollapsed';
import { ParcelsListFiltersExpanded } from '../ParcelsListFiltersExpanded';
import { ParcelsListFiltersControls } from '../ParcelsListFiltersControls';
import { EnforceDefaultValues } from '../EnforceDefaultValues';
import { useParcelsListFiltersDefaultFormValues } from '../hooks/useParcelsListFiltersDefaultFormValues';
import { prepareParcelFiltersFromFormValues } from '../ParcelsListFilters.utils';

import type { ParcelsListFormValues } from '../ParcelsListFilters.types';

interface ParcelsListFiltersFormProps {
    closeFiltersDrawer?: () => void;
}

export const ParcelsListFiltersForm = ({ closeFiltersDrawer }: ParcelsListFiltersFormProps) => {
    const { setFilters, filterValues } = useListContext();
    const { defaultFormValues, resetToDefaultFormValues } = useParcelsListFiltersDefaultFormValues(filterValues);
    const [expanded, toggleExpanded] = useToggle(false);
    const INPUT_WIDTH = '220px';

    return (
        <Form
            onSubmit={values => {
                const nextFilters = prepareParcelFiltersFromFormValues(values as ParcelsListFormValues);
                setFilters(nextFilters, undefined, false);
                closeFiltersDrawer?.();
                toggleExpanded(false);
            }}
            mode="onChange"
        >
            <ParcelsListFiltersCollapsed inputWidth={INPUT_WIDTH} />
            <ParcelsListFiltersExpanded expanded={expanded} inputWidth={INPUT_WIDTH} />
            <ParcelsListFiltersControls
                expanded={expanded}
                toggleExpanded={toggleExpanded}
                resetFilters={form => {
                    const defaultValues = resetToDefaultFormValues();
                    form.reset(defaultValues);

                    setFilters({}, undefined, false);
                    closeFiltersDrawer?.();
                }}
            />

            {/* NOTE: `defaultValue` prop couldn't be used -> it didn't override the values, so we have to do it manully (e.g. when search params change) */}
            <EnforceDefaultValues defaultValues={defaultFormValues} />
        </Form>
    );
};
