import type { ResourceKey } from 'constants/index';
import type { RolePermissions } from 'modules/roles';
import { PermissionAction, PermissionAll } from 'modules/roles';

export const hasAccess = (
    permissions: RolePermissions,
    resource: ResourceKey,
    ...askedPermissions: PermissionAction[]
) => {
    if (permissions === undefined) {
        return false;
    }

    if (permissions === PermissionAll) {
        return true;
    }

    return askedPermissions.every(permission => permissions[resource]?.includes(permission));
};

export const getResourceAccess = (permissions: RolePermissions, resource: ResourceKey) => {
    const access = {
        list: hasAccess(permissions, resource, PermissionAction.LIST),
        create: hasAccess(permissions, resource, PermissionAction.CREATE),
        edit: hasAccess(permissions, resource, PermissionAction.EDIT),
        show: hasAccess(permissions, resource, PermissionAction.DETAIL),
    };

    const enabled = permissions === PermissionAll || Boolean(permissions?.[resource]);

    return { enabled, access };
};
