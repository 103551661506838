import { useGetOne, useRecordContext, useTranslate } from 'react-admin';
import { Box, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/WarningRounded';

import { ResourceKey } from 'constants/index';
import type { components } from 'api/adminApiSchema';

import type { Parcel } from '../../../types';
import { ParcelSize } from '../../../constants';

export const ParcelSizeWarning = () => {
    const record = useRecordContext<Parcel>();
    const translate = useTranslate();

    const apmId = record.addresses?.toLocation?.apm?.id;
    const { data: apm, isSuccess: apmLoaded } = useGetOne(ResourceKey.APM, { id: apmId }, { enabled: !!apmId });

    const parcelSize = record.compartmentSize as components['schemas']['CompartmentSize'];
    const parcelSizeChar = parcelSize ? ParcelSize[parcelSize].toLowerCase() : null;

    const apmIsAvailable = parcelSizeChar && apmLoaded ? apm.availability[`${parcelSizeChar}4eAvailable`] : true;

    if (apmIsAvailable) {
        return null;
    }

    return (
        <Box display="flex" alignItems="center" my={2} gap={1}>
            <WarningIcon />
            <Typography variant="body2" color="">
                {translate('parcel.extendStorageTime.dialog.warning')}
            </Typography>
        </Box>
    );
};
