import { ArrayField, Datagrid, DateField, FunctionField, TextField } from 'react-admin';

import type { PartnerDetail } from 'modules/partners/types';
import { useTranslate } from 'hooks';

type NonNullablePartnerHistory = NonNullable<PartnerDetail['history']>;

interface PartnerHistoryLogProps {}
export const PartnerHistoryLog = (props: PartnerHistoryLogProps) => {
    const translate = useTranslate();
    return (
        <ArrayField source="history">
            <Datagrid bulkActionButtons={false}>
                <TextField source="user" label={'partners.history.user'} />
                <FunctionField<NonNullablePartnerHistory[number]>
                    source="vip"
                    render={record => (record.vip ? translate(`partners.characteristics.vip.${record.vip}`) : null)}
                />
                <FunctionField<NonNullablePartnerHistory[number]>
                    source="overdue"
                    render={record => translate(`partners.characteristics.overdue.${record.overdue ? 'yes' : 'no'}`)}
                />
                <DateField source="createTime" label={'partners.history.date'} />
                <TextField source="reason" label={'partners.history.reason'} />
            </Datagrid>
        </ArrayField>
    );
};
