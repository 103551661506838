import { ImageInput } from 'react-admin';

import { ImageField } from 'modules/ui';

interface FakeFile {
    src: string;
}

const ApmEditImage = () => {
    return (
        <ImageInput
            source="photo"
            accept="image/*"
            format={(value: string | null) => {
                if (!value) {
                    return null;
                }

                if (typeof value === 'string') {
                    return { src: value };
                }

                return value;
            }}
            parse={(file: null | File | FakeFile) => {
                if (!file) {
                    return null;
                }

                if (file instanceof File) {
                    const preview = URL.createObjectURL(file);

                    return {
                        src: preview,
                        rawFile: file,
                    };
                }

                return file.src;
            }}
        >
            <ImageField source="src" />
        </ImageInput>
    );
};

export default ApmEditImage;
