import { Edit, useNotify, useRedirect, useUpdate } from 'react-admin';

import { useServerValidationErrors } from 'modules/errors';
import { ResourceKey } from 'constants/index';

import { useHandlePartnerSubmit } from 'modules/partners/hooks';

import PartnerForm from '../PartnerForm';
import PartnerTitle from '../PartnerTitle';

const PartnerEdit = () => {
    const notify = useNotify();
    const redirect = useRedirect();
    const onFailure = useServerValidationErrors();

    const [update] = useUpdate(ResourceKey.PARTNER);

    const onSuccess = () => {
        notify('partners.edit.saved');
        redirect('list', ResourceKey.PARTNER);
    };

    const handleSubmit = useHandlePartnerSubmit(update, { onSuccess, onError: onFailure });

    return (
        <>
            <Edit
                title={<PartnerTitle />}
                mutationOptions={{ onSuccess, onError: onFailure }}
                mutationMode="pessimistic"
            >
                <PartnerForm onSubmit={handleSubmit} />
            </Edit>
        </>
    );
};

export default PartnerEdit;
