import { styled, Typography } from '@mui/material';

import Stop from './Stop';

export const Title = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(4),
})) as typeof Typography;

export const DepotSelect = styled(Stop)(({ theme }) => ({
    width: theme.metrics.formItemWrapperWidth,
    maxWidth: '100%',
}));
