import { useLocaleState } from 'react-admin';
import type { InputProps } from 'react-admin';
import countries from 'i18n-iso-countries';
import { useMemo } from 'react';

import * as Styled from './CountryInput.styles';

interface CountryInputProps extends InputProps {
    fullWidth?: boolean;
}

const CountryInput = (props: CountryInputProps) => {
    const [locale] = useLocaleState();

    const allCountries = useMemo(
        () =>
            Object.entries(countries.getNames(locale)).map(([code, name]) => ({
                id: code,
                name,
            })),
        [locale],
    );

    return <Styled.SelectInput {...props} choices={allCountries} />;
};

export default CountryInput;
