import { styled } from '@mui/material';
import { SimpleFormIterator as RASimpleFormIterator, ArrayInput as RAArrayInput } from 'react-admin';

export const ArrayInput = styled(RAArrayInput)(({ theme }) => ({
    marginTop: 0,
}));

export const SimpleFormIterator = styled(RASimpleFormIterator)(({ theme }) => ({
    marginTop: 0,

    '& .RaSimpleFormIterator-indexContainer': {
        display: 'none',
    },
    '& .RaSimpleFormIterator-line': {
        borderBottom: 0,
    },
}));
