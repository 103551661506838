import type { FC } from 'react';
import { useState } from 'react';
import { Create, useTranslate, useRedirect, useCreate } from 'react-admin';
import type { CreateProps } from 'react-admin';

import { ResourceKey } from 'constants/index';
import { SeoHead } from 'modules/ui';
import { useServerValidationErrors } from 'modules/errors';

import PartnerForm from '../PartnerForm';
import PartnerTitle from '../PartnerTitle';
import ClientInfoDialog from '../ClientInfoDialog';

import { useHandlePartnerSubmit } from '../../hooks';
import type { CreatedPartnerData, PartnerData } from '../../types';

type ClientInfo = Omit<CreatedPartnerData, keyof PartnerData>;

const PartnerCreate: FC<CreateProps> = props => {
    const translate = useTranslate();
    const redirect = useRedirect();
    const onFailure = useServerValidationErrors();

    const [create] = useCreate();

    const [clientInfo, setClientInfo] = useState<ClientInfo | null>(null);
    const handleDialogClose = () => {
        setClientInfo(null);
        redirect('list', ResourceKey.PARTNER);
    };

    const onSuccess = (data: CreatedPartnerData) => {
        const { clientId, clientSecret, webhookSecret } = data;
        setClientInfo({ clientId, clientSecret, webhookSecret });
    };

    const handleSubmit = useHandlePartnerSubmit(create, { onSuccess, onError: onFailure });

    return (
        <>
            <SeoHead title={translate('partners.create.title')} />
            <Create title={<PartnerTitle />} {...props}>
                <PartnerForm onSubmit={handleSubmit} />
            </Create>
            <ClientInfoDialog clientInfo={clientInfo} onClose={handleDialogClose} />
        </>
    );
};

export default PartnerCreate;
