import { DeleteButton, useResourceContext } from 'react-admin';
import type { DeleteButtonProps } from 'react-admin';

import type { ResourceKey } from 'constants/index';
import { PermissionAction } from 'modules/roles';

import { useHasAccess } from '../../hooks';

export interface DeleteButtonWithPermissionsProps extends DeleteButtonProps {}

export const DeleteButtonWithPermissions = (props: DeleteButtonWithPermissionsProps) => {
    const resource = useResourceContext() as ResourceKey;
    const isDeleteAllowed = useHasAccess(resource, PermissionAction.DELETE);

    return isDeleteAllowed ? <DeleteButton {...props} /> : null;
};
