import { useCallback, useEffect } from 'react';

import { useLogin } from 'react-admin';

import config from 'config/config';
import { useApiReducer } from 'hooks';

interface UseLoginFormSubmitProps {
    code: string;
    challenge: string;
    devCode?: string;
}

export default ({ code, challenge, devCode }: UseLoginFormSubmitProps) => {
    const [api, dispatch] = useApiReducer();
    const login = useLogin();

    const submitCode = useCallback(
        async (smsCode: string, challenge: string) => {
            dispatch({ type: 'request' });

            if (smsCode) {
                try {
                    await login({ smsCode, challenge });
                    dispatch({ type: 'success' });
                } catch (error) {
                    dispatch({ type: 'error', error: (error as Error)?.message ?? error });
                }
            } else {
                dispatch({ type: 'error', error: 'login.errorText' });
            }
        },
        [dispatch, login],
    );

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        return submitCode(code, challenge);
    };

    useEffect(() => {
        if (devCode && challenge && config.dev?.autoSignInWithSmsCode) {
            submitCode(devCode, challenge);
        }
    }, [challenge, devCode, submitCode]);

    return { api, handleSubmit };
};
