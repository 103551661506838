import { styled, Stack, Drawer as UnstyledDrawer, drawerClasses } from '@mui/material';

export const ContactsStack = styled(Stack)(({ theme }) => ({
    marginTop: theme.spacing(0),
}));

export const Drawer = styled(UnstyledDrawer)(({ theme }) => ({
    [`& .${drawerClasses.paper}`]: {
        minWidth: '100%',

        [theme.breakpoints.up('sm')]: {
            minWidth: '32rem',
        },
    },
}));
