import { useNotify, useRefresh } from 'react-admin';

import { usePatchUpdate } from 'hooks';
import { logger } from 'config';
import { ResourceKey } from 'constants/index';
import type { Parcel, UpdateParcel } from 'modules/parcels/types';

interface Options {
    onSettled?: () => void;
}

export function useChangeToPrepaid(parcelId: string, { onSettled }: Options) {
    const notify = useNotify();
    const refresh = useRefresh();

    const [updateData, { isLoading }] = usePatchUpdate<Parcel>(
        ResourceKey.PARCELS,
        { id: parcelId },
        {
            onSuccess: () => {
                notify('parcel.detail.changeToPrepaid.success', { type: 'success' });
                refresh();
            },
            onError: error => {
                logger.error(error);
                notify('parcel.detail.changeToPrepaid.failure', { type: 'error' });
            },
            onSettled: () => onSettled?.(),
            mutationMode: 'pessimistic',
        },
    );

    const trigger = () =>
        // "paymentMode" property doesn't exist on the "Parcel" resource. Therefore, the UpdateParcel type assertion is needed.
        updateData(undefined, {
            data: { paymentMode: 'prepaid' } as UpdateParcel,
        });

    return {
        trigger,
        isLoading,
    };
}
