import { styled } from '@mui/material';

import MUICancelIcon from '@mui/icons-material/CancelOutlined';

export const InfoText = styled('span')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    paddingInline: theme.spacing(0.5),
}));

export const CancelIcon = styled(MUICancelIcon)(({ theme }) => ({
    marginRight: theme.spacing(1),
}));
