import type { PaymentMode, PaymentState } from '../types';

export enum ParcelSize {
    S = 1,
    M,
    L,
}

export type ParcelSizeKey = 'S' | 'M' | 'L';

export const parcelSizeChoices = [ParcelSize.S, ParcelSize.M, ParcelSize.L].map(size => ({
    id: size,
    name: ParcelSize[size],
}));

export const paymentModes: ReadonlyArray<PaymentMode> = ['prepaid', 'cod'];

export const paymentStates: ReadonlyArray<PaymentState> = ['pending', 'paid-by-customer', 'transferred-to-partner'];

export const CustomerIssueState = {
    ACTIVE: 'active',
    INACTIVE: 'inactive',
} as const;

export enum CodPaymentImportValidationRule {
    PARCELS_FROM_REGION = 'A4900',
    PARCELS_PAYMENT_STATE = 'A4901',
    ONE_PARCEL_PER_ORDER = 'A4903',
    PARCEL_IS_COD = 'A4904',
    SAME_REGION_ONLY = 'A4906',
}

export const EXTEND_STORAGE_MAX_DAYS = 14;
