import { useRecordContext } from 'react-admin';

import * as Styled from './SimpleItemsList.styles';

export interface SimpleItemsListProps {
    source: string;
}

export const SimpleItemsList = ({ source }: SimpleItemsListProps) => {
    const record = useRecordContext();
    const data = record?.[source];

    return (
        <Styled.List>
            {data?.map((item: string | number) => (
                <Styled.Item key={item}>{item}</Styled.Item>
            ))}
        </Styled.List>
    );
};
