import { styled, Box } from '@mui/material';
import { RaBox } from 'ra-compact-ui';

export const Container = styled(RaBox)(({ theme }) => ({
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
    },
}));

export const DataColumn = styled(RaBox)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        order: 2,
    },
}));

export const ImageColumn = styled(Box)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(4),
    },
}));

export const ButtonsContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    gap: theme.spacing(1),

    marginBottom: theme.spacing(2),
}));

export const PropertiesContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(4),
}));
