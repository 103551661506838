import type { FC } from 'react';
import { Create, useTranslate, useNotify, useRedirect } from 'react-admin';
import type { CreateProps } from 'react-admin';

import { SeoHead } from 'modules/ui';
import { useServerValidationErrors } from 'modules/errors';
import { Role } from 'modules/roles';
import { ResourceKey } from 'constants/index';

import UserForm from '../UserForm';
import UserTitle from '../UserTitle';

const UserCreate: FC<CreateProps> = props => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirect = useRedirect();
    const onFailure = useServerValidationErrors();

    const onSuccess = () => {
        notify('users.create.created');
        redirect('list', ResourceKey.USER);
    };

    return (
        <>
            <SeoHead title={translate('users.create.title')} />
            <Create title={<UserTitle />} {...props} mutationOptions={{ onSuccess, onError: onFailure }}>
                <UserForm defaultValues={{ role: Role.DRIVER }} />
            </Create>
        </>
    );
};

export default UserCreate;
