import { useTranslate, useRecordContext } from 'react-admin';

export const useFormPageTitle = (isDetail: boolean = false) => {
    const translate = useTranslate();
    const record = useRecordContext();

    const name = record?.name;

    return translate(`warehouse.${name ? (isDetail ? 'show' : 'edit') : 'create'}.title`, { name });
};
