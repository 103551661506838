import config, { logger } from 'config';
import type { paths } from 'api/apiSchema';

import { apiClient } from 'services/apiClient';

import { credentialsStorage } from './authStorage';

type Endpoint = paths['/api/v1/auth-sessions:refresh']['post'];
type RequestPayload = Endpoint['requestBody']['content']['application/json'];
type ResponseBody = Endpoint['responses']['200']['content']['application/json'];

type AccessToken = NonNullable<ResponseBody['accessToken']>;

let refreshRequest: Promise<AccessToken> | undefined;

async function getRefreshedToken(payload: RequestPayload) {
    let token: AccessToken;

    try {
        const credentials = await apiClient.post<RequestPayload, ResponseBody>(config.api.endpoints.refresh, payload);

        if (credentials?.accessToken === undefined) {
            logger.error('Missing credentials on the server response');
            throw new Error('error.api.500');
        }

        credentialsStorage.save(credentials);

        token = credentials.accessToken;
    } finally {
        refreshRequest = undefined;
    }

    return token;
}

export default async (payload: RequestPayload): Promise<AccessToken> => {
    if (!refreshRequest) {
        refreshRequest = getRefreshedToken(payload);
    }

    const refreshedAccessToken = await refreshRequest;

    return refreshedAccessToken;
};
