import { get } from 'lodash';
import isValidUrl from 'is-url';
import type { Validator } from 'react-admin';

import type { PartnerData } from './types';

export const isUrl: Validator = (value: string) => {
    if (value && !isValidUrl(value)) {
        return 'error.invalidUrl';
    }

    return undefined;
};

export const requiredWhenHookActive: Validator = (value: string, values: PartnerData) => {
    if (values.parcelEventChangeWebhook?.active && !value) {
        return 'partners.characteristics.webhook.error.requiredWhenActive';
    }

    return undefined;
};

export const requiredWhenRelatedFilled =
    (relatedField: string, relatedFieldName: string): Validator =>
    (value: string, values: PartnerData) => {
        if (!value && get(values, relatedField)) {
            return {
                message: `partners.characteristics.webhook.error.requiredWhenField`,
                args: { field: relatedFieldName },
            };
        }
    };

export const requiredWhenHookHeaderFilled: Validator = (webhookValue: string, values: PartnerData) => {
    const { name: headerName, value: headerValue } = values.parcelEventChangeWebhook?.header ?? {};

    if (headerName && headerValue && !webhookValue) {
        return 'partners.characteristics.webhook.error.webhookRequiredWhenHeader';
    }

    return undefined;
};
