import { useTranslate, required, ReferenceInput, AutocompleteInput } from 'react-admin';

import { TextInput, AddressForm, LatLngInput, OptionLocation, useOptionLocation } from 'modules/ui';
import { ResourceKey } from 'constants/index';
import { locationParentDepotValidator } from 'utils/locationParentDepotValidator';

import * as Styled from './BaseWarehouseInputs.styles';
import type { Warehouse } from '../../types';

export interface BaseWarehouseInputsProps {
    sourcePrefix: string;
    onInputBlur?: () => void;
    data?: Warehouse;
}

const requiredValidator = required();

export const BaseWarehouseInputs = ({ sourcePrefix, onInputBlur, data }: BaseWarehouseInputsProps) => {
    const translate = useTranslate();
    const { inputText, matchSuggestion } = useOptionLocation();

    return (
        <Styled.Container>
            <TextInput
                label={translate('warehouse.name')}
                source={`${sourcePrefix}name`}
                validate={requiredValidator}
                onBlur={onInputBlur}
            />

            <TextInput
                source={`${sourcePrefix}identifier`}
                label={translate('warehouse.identifier')}
                validate={requiredValidator}
                onBlur={onInputBlur}
            />
            <AddressForm source={sourcePrefix} onBlur={onInputBlur} />
            <LatLngInput source={`${sourcePrefix}coordinates`} validate={requiredValidator} onBlur={onInputBlur} />

            <ReferenceInput
                source={`${sourcePrefix}depotId`}
                filter={{ type: 'depot' }}
                perPage={50}
                reference={ResourceKey.LOCATION}
            >
                <AutocompleteInput
                    label={translate('warehouse.serviceArea')}
                    validate={[
                        requiredValidator,
                        (value, allValues) => locationParentDepotValidator(value, allValues, data ?? undefined),
                    ]}
                    optionText={<OptionLocation />}
                    inputText={inputText}
                    matchSuggestion={matchSuggestion}
                    fullWidth
                    onChange={onInputBlur}
                />
            </ReferenceInput>
        </Styled.Container>
    );
};
