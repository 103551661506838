import { useCreatePath, useRecordContext, Link, useTranslate } from 'react-admin';

import { ResourceKey } from 'constants/index';
import { useHasAccess } from 'modules/auth';
import { PermissionAction } from 'modules/roles';

export interface StoredParcelFieldProps {
    // eslint-disable-next-line react/no-unused-prop-types
    label?: string;
}

export const StoredParcelField = (props: StoredParcelFieldProps) => {
    const translate = useTranslate();
    const compartment = useRecordContext(props);
    const hasDetailAccess = useHasAccess(ResourceKey.PARCELS, PermissionAction.DETAIL);
    const createPath = useCreatePath();

    if (!compartment.storedParcel) {
        return null;
    }

    const label = `${translate('apm.edit.compartments.storedParcel')} (${compartment.storedParcel.parcelNumber})`;

    if (hasDetailAccess) {
        return (
            <Link to={createPath({ resource: ResourceKey.PARCELS, id: compartment.storedParcel.id, type: 'show' })}>
                {label}
            </Link>
        );
    }

    return <>{label}</>;
};
