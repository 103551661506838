import { useTranslate } from 'react-admin';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

import type { PartnerType } from 'modules/partners/constants';

export interface PartnerTypeRadioInputItemProps {
    label: string;
    value: PartnerType;
    source: string;
    disabled?: boolean;
}

export const PartnerTypeRadioInputItem = ({ value, label, source, disabled }: PartnerTypeRadioInputItemProps) => {
    const translate = useTranslate();

    const nodeId = `${source}_${value}`;

    return (
        <FormControlLabel
            label={translate(label)}
            htmlFor={nodeId}
            value={value}
            control={<Radio id={nodeId} color="primary" />}
            disabled={disabled}
        />
    );
};
