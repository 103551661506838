import { Edit, useNotify, useRedirect } from 'react-admin';

import { useServerValidationErrors } from 'modules/errors';
import { ResourceKey } from 'constants/index';

import UserForm from '../UserForm';
import UserTitle from '../UserTitle';

const UserEdit = () => {
    const notify = useNotify();
    const redirect = useRedirect();
    const onFailure = useServerValidationErrors();

    const onSuccess = () => {
        notify('users.edit.saved');
        redirect('list', ResourceKey.USER);
    };

    return (
        <>
            <Edit title={<UserTitle />} mutationOptions={{ onSuccess, onError: onFailure }} mutationMode="pessimistic">
                <UserForm />
            </Edit>
        </>
    );
};

export default UserEdit;
