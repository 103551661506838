import { autocompleteClasses, styled, Paper } from '@mui/material';

export const AutocompleteDropdown = styled(Paper)(({ theme }) => ({
    [`.${autocompleteClasses.listbox} .${autocompleteClasses.option}`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: theme.spacing(2),
    },
}));
