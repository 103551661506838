import type { DataProvider, DeleteParams, RaRecord, UpdateParams } from 'react-admin';

import type { ResourceKey } from 'constants/index';
import { adminApiClient } from 'modules/auth/services/authApiClient';

import { httpClient, getApiProvider } from './dataProvider';
import { mapFromApi, mapToApi } from './resourceMapping';
import { composeUrl, getApiVersion } from './utils';

export const dataProvider: DataProvider = {
    ...getApiProvider(),

    getList: async (resource: ResourceKey, params) => {
        const { from, to } = params.filter;

        const transformedParams = {
            ...params,
            filter: {
                ...params.filter,
                // Transform date to unix timestamp in ms
                from: from && typeof from !== 'number' ? new Date(from).getTime() : from,
                to: to && typeof to !== 'number' ? new Date(to).getTime() : to,
            },
        };

        if (params.meta?.isPartiallyPaginated) {
            const { field, order } = transformedParams.sort;

            const query = {
                sort: JSON.stringify([field, order]),
                pagination: JSON.stringify(transformedParams.pagination),
                filter: JSON.stringify(transformedParams.filter),
            };

            const url = composeUrl(resource, 'getList', { query });
            const { json } = await httpClient(url);

            const response = {
                data: json.data,
                pageInfo: json.pageInfo,
            };

            return mapFromApi(resource, response);
        }

        if (params.meta?.hasNoPagination) {
            const url = composeUrl(resource, 'getList', {});
            const { json } = await httpClient(url);

            const response = {
                data: json,
                pageInfo: { total: json.length },
            };

            return mapFromApi(resource, response);
        }

        const response = await getApiProvider(getApiVersion(resource, 'getList')).getList(resource, transformedParams);

        return mapFromApi(resource, response);
    },

    getOne: async (resource: ResourceKey, params) => {
        const response = await getApiProvider(getApiVersion(resource, 'getOne')).getOne(resource, params);

        return mapFromApi(resource as ResourceKey, response);
    },

    getManyReference: async (resource: ResourceKey, params) => {
        const response = await getApiProvider(getApiVersion(resource, 'getManyReference')).getManyReference(
            resource,
            params,
        );

        return mapFromApi(resource, response);
    },

    getMany: async (resource: ResourceKey, params) => {
        const additionalFilters = params.meta?.additionalFilters;

        const query = {
            filter: additionalFilters
                ? JSON.stringify({
                      id: params.ids,
                      ...additionalFilters,
                  })
                : JSON.stringify({ id: params.ids }),
            range: JSON.stringify([0, params.ids.length]),
        };

        const url = composeUrl(resource, 'getMany', { query });
        return httpClient(url).then(({ json }) => ({ data: json }));
    },

    update: async (resource: ResourceKey, params: UpdateParams<RaRecord> & { previousData?: RaRecord }) => {
        const data = await mapToApi(resource as ResourceKey, params.data);

        if (params.meta?.patch) {
            const result: any = await adminApiClient.patch(
                `/${getApiVersion(resource, 'update')}/${resource}/${params.id}`,
                data,
            );

            const response = {
                data: {
                    ...params.previousData,
                    ...result,
                    id: params.id,
                },
            };

            return mapFromApi(resource, response);
        }

        if (params.meta?.isApmEditMutation) {
            const url = composeUrl(resource, 'update', {});

            const result = await httpClient(`${url}/${params.id}`, { body: JSON.stringify(data), method: 'PUT' });

            const hasDeactivationFailed = result.headers.get('X-Locker-Deactivation-Failed');

            const response = {
                data: { ...result.json, meta: { lockerDeactivationFailed: Boolean(Number(hasDeactivationFailed)) } },
            };

            return mapFromApi(resource, response);
        }

        const response = await getApiProvider(getApiVersion(resource, 'update')).update(resource, {
            ...params,
            data,
        });

        return mapFromApi(resource, response);
    },

    create: async (resource: ResourceKey, params) => {
        const data = await mapToApi(resource as ResourceKey, params.data);

        const url = composeUrl(resource, 'create', {});

        const { json } = await httpClient(url, {
            method: 'POST',
            body: JSON.stringify(data),
        });
        const response = {
            data: { ...params.data, ...json },
        };

        return mapFromApi(resource, response);
    },

    delete: async (resource: ResourceKey, params: DeleteParams) => {
        await adminApiClient.delete(`/${getApiVersion(resource, 'delete')}/${resource}/${params.id}`);

        return {
            id: params.id,
            data: params.previousData,
        };
    },
} as DataProvider;
// Needs the type cast because of issue https://github.com/marmelab/react-admin/issues/5476
