import { useRecordContext, useTranslate } from 'react-admin';
import { BoxedShowLayout, RaBox } from 'ra-compact-ui';
import { Typography } from '@mui/material';

import { PermissionAction, Role } from 'modules/roles';
import type { User } from 'modules/users/types';
import { useHasAccess } from 'modules/auth';
import { ResourceKey } from 'constants/index';

import { IncompleteStopForm } from '../IncompleteStopForm';

export interface DriverPermissionsProps {}

export const DriverPermissions = () => {
    const translate = useTranslate();
    const record = useRecordContext<User>();

    const hasAccess = useHasAccess(ResourceKey.USER, PermissionAction.EDIT_USER_PERMISSIONS);

    if (!record || record.role !== Role.DRIVER || !hasAccess) {
        return null;
    }

    return (
        <BoxedShowLayout>
            <RaBox>
                <Typography variant="subtitle1">{translate('users.permissions.title')}</Typography>

                <IncompleteStopForm user={record} />
            </RaBox>
        </BoxedShowLayout>
    );
};
