import { useTranslate, TextField, useRecordContext, FunctionField } from 'react-admin';
import type { RaRecord } from 'react-admin';
import { BoxedShowLayout, RaBox } from 'ra-compact-ui';
import get from 'lodash/get';

import { Country } from '../Country';

export interface AddressFieldProps {
    source?: string;
    // eslint-disable-next-line react/no-unused-prop-types
    label?: string;
}

export const AddressField = ({ source }: AddressFieldProps) => {
    const translate = useTranslate();
    const record = useRecordContext();
    const sourcePrefix = source ? `${source}.` : '';

    return (
        <BoxedShowLayout record={record}>
            <RaBox>
                <TextField source={`${sourcePrefix}street`} label={translate('general.address.street')} />
            </RaBox>

            <RaBox display="flex">
                <TextField source={`${sourcePrefix}city`} label={translate('general.address.city')} sx={{ mr: 2 }} />
                <TextField source={`${sourcePrefix}postalCode`} label={translate('general.address.postalCode')} />
            </RaBox>

            <RaBox>
                <FunctionField
                    source={`${sourcePrefix}country`}
                    label={translate('general.address.country')}
                    render={(record: RaRecord, source?: string) =>
                        source && <Country countryCode={get(record, source)} />
                    }
                />
            </RaBox>
        </BoxedShowLayout>
    );
};
