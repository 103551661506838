import * as loglevel from 'loglevel';
import * as Sentry from '@sentry/react';
import { omit, isEmpty } from 'lodash';

import { isEnvProduction } from 'constants/index';
import config from 'config/config';

isEnvProduction ? loglevel.disableAll() : loglevel.enableAll();

const forbiddenExtras = ['authorization'];

const error = (err: any, extra: Record<string, string> | string = {}) => {
    const extras = typeof extra === 'object' ? omit(extra, forbiddenExtras) : { extra };

    if (isEmpty(extras)) {
        loglevel.error(err);
    } else {
        loglevel.error(err, extras);
    }

    if (config.sentry.enable) {
        Sentry.withScope(scope => {
            scope.setExtras(extras);
            Sentry.captureException(err);
        });
    }
};

export default {
    ...loglevel,
    error,
};
