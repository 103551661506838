import { useState } from 'react';
import type { PropsWithChildren } from 'react';

import { WarehouseEditContext } from '../../contexts/WarehouseEditContext';
import type { EditState } from '../../contexts/WarehouseEditContext';

export interface WarehouseEditProviderProps extends PropsWithChildren<{}> {}

export const WarehouseEditProvider = (props: WarehouseEditProviderProps) => {
    const state = useState<EditState>(null);

    return <WarehouseEditContext.Provider value={state} {...props} />;
};
