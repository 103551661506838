import { styled } from '@mui/material';
import { BoxedShowLayout } from 'ra-compact-ui';

import { LatLngInput as UnstyledLatLngInput } from 'modules/ui';

export const LatLngInput = styled(UnstyledLatLngInput)(({ theme }) => ({
    width: theme.metrics.formItemWrapperWidth,
    maxWidth: '100%',
}));

export const LocationIdLayout = styled(BoxedShowLayout)(({ theme }) => ({
    paddingLeft: theme.spacing(1.5),
}));
