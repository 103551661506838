import { styled, Paper, Typography } from '@mui/material';
import { RaGrid } from 'ra-compact-ui';

export const Card = styled(Paper, {
    shouldForwardProp: propName => propName !== 'isError',
})<{ isError: boolean }>(({ theme, isError }) => ({
    backgroundColor: theme.palette.background.default,
    margin: isError ? `${theme.spacing(2)} 0 ${theme.spacing(1)} 0` : `${theme.spacing(2)} 0`,

    border: isError ? `1px solid ${theme.palette.error.main}` : 'none',
}));

export const ErrorMessage = styled(Typography)(({ theme }) => ({
    color: theme.palette.error.main,
}));

export const Actions = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: theme.spacing(1),
}));

export const SharedLabel = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[600],
}));

export const Coordinate = styled(RaGrid)(({ theme }) => ({
    wordBreak: 'break-word',
}));
