import { styled } from '@mui/material';

import { BoxedShowLayout } from 'ra-compact-ui';

export const Layout = styled(BoxedShowLayout)(({ theme }) => ({
    '&:first-of-type': {
        paddingTop: 0,
    },
    '&:last-of-type': {
        paddingBottom: 0,
    },
}));
