import type { RaRecord } from 'react-admin';

export const mapConfigurationFromApi = (data: RaRecord) => {
    if (!data.type || data.type === 'string') {
        return data;
    }

    return {
        ...data,
        value: JSON.parse(data.value),
    };
};

export const mapConfigurationToApi = async ({ type, ...rest }: Partial<RaRecord>) => {
    if (type === 'string') {
        return rest;
    }

    return {
        ...rest,
        value: JSON.stringify(rest.value),
    };
};
