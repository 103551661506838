import { useState } from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { Card, Grid, Button } from '@mui/material';

import { ResourceKey } from 'constants/index';
import type { Parcel } from 'modules/parcels/types';
import { useFormattedDateTime } from 'modules/localization';

import { ExtendStorageTimeDialog } from '../ExtendStorageTimeDialog';

export interface ParcelExtendStorageTimeProps {
    parcelId: string;
}

export const ParcelExtendStorageTime = ({ parcelId }: ParcelExtendStorageTimeProps) => {
    const record: Parcel = useRecordContext({ resource: ResourceKey.PARCELS });
    const translate = useTranslate();

    const [dialogVisible, setDialogVisible] = useState(false);

    const lastPickUpTime = useFormattedDateTime(record?.lastPickUpTime);

    if (record.status !== 'in-final-destination') {
        return null;
    }

    return (
        <Card sx={{ py: 1, px: 2 }}>
            <Grid container spacing={6} alignItems="center">
                <Grid item xs={12} sm={6}>
                    {translate('parcel.extendStorageTime.until', { date: lastPickUpTime || '–' })}
                </Grid>
                <Grid item xs={12} sm={6} display="flex" justifyContent="flex-end">
                    <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        size="medium"
                        onClick={() => setDialogVisible(true)}
                    >
                        {translate('parcel.extendStorageTime.button')}
                    </Button>
                </Grid>
            </Grid>
            <ExtendStorageTimeDialog
                parcelId={parcelId}
                currentPickupTime={record?.lastPickUpTime}
                visible={dialogVisible}
                onClose={() => setDialogVisible(false)}
            />
        </Card>
    );
};
