import { useCallback, useState } from 'react';
import type { MouseEvent } from 'react';

export const useConfirmDialog = (defaultOpen = false) => {
    const [open, setOpen] = useState(defaultOpen);

    const openDialog = useCallback((e?: MouseEvent) => {
        setOpen(true);
        e?.stopPropagation?.();
    }, []);

    const closeDialog = useCallback((e?: MouseEvent) => {
        setOpen(false);
        e?.stopPropagation?.();
    }, []);

    return [open, openDialog, closeDialog] as const;
};
