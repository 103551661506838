import type { components } from 'api/adminApiSchema';

export type TRole = components['schemas']['UserRole'];

export enum Role {
    DRIVER = 'driver',
    DEPOT_WORKER = 'depot-worker',
    MANAGER = 'manager',
    PARTNER = 'partner',
    OPERATIONS_MANAGER = 'operations-manager',
    SALES_MANAGER = 'sales-manager',
    SALES_AND_OPERATION_MANAGER = 'sales-and-operation-manager',
    NETWORK_AND_SALES_MANAGER = 'network-and-sales-manager',
    NETWORK_MANAGER = 'network-manager',
    CUSTOMER_CARE = 'customer-care',
    SUPER_ADMIN = 'super-admin',
}
