type KeyOf<T> = keyof T;

export function filterPayloadByKeys<T extends Record<string, any>>(data: T, keys: KeyOf<T>[]): Partial<T> {
    return Object.keys(data).reduce((filteredData, key) => {
        if (keys.includes(key as KeyOf<T>)) {
            filteredData[key as KeyOf<T>] = data[key];
        }
        return filteredData;
    }, {} as Partial<T>);
}
