import type { AuthProvider } from 'react-admin';

import login from './login';
import getIdentity from './getIdentity';
import getPermissions from './getPermissions';
import handleCallback from './handleCallback';
import logout from './logout';
import checkAuth from './checkAuth';
import checkError from './checkError';

const authProvider: AuthProvider = {
    login,
    getIdentity,
    getPermissions,
    logout,
    checkAuth,
    checkError,
    handleCallback,
};

export default authProvider;
