import {
    required,
    useTranslate,
    SaveButton,
    SimpleForm,
    SelectInput,
    ReferenceInput,
    AutocompleteInput,
    useRecordContext,
} from 'react-admin';
import type { SimpleFormProps } from 'react-admin';
import { FormDataConsumer } from 'ra-core';
import { Toolbar, Box, FormHelperText } from '@mui/material';

import { ResourceKey } from 'constants/index';
import { TextInput, PhoneInput, RegionsListInput } from 'modules/ui';
import { Role, RolesExplanation } from 'modules/roles';
import { DeleteButtonWithPermissions } from 'modules/auth';
import { useUserRegion, useCurrentRegionValidator } from 'modules/localization';

const requiredValidator = required();

export interface UserFormProps extends Omit<SimpleFormProps, 'toolbar' | 'children'> {}

const UserForm = (props: UserFormProps) => {
    const translate = useTranslate();

    const isCreateForm = !useRecordContext();
    const [region] = useUserRegion();
    const currentRegionValidator = useCurrentRegionValidator();

    const defaultValues = isCreateForm ? { regions: [region?.id] } : undefined;

    return (
        <SimpleForm
            {...props}
            toolbar={
                <Toolbar>
                    <Box display="flex" justifyContent="space-between" width="100%">
                        <SaveButton />
                        <DeleteButtonWithPermissions />
                    </Box>
                </Toolbar>
            }
            defaultValues={defaultValues}
        >
            <TextInput source="name" label={translate('users.fullname')} validate={requiredValidator} />
            <PhoneInput source="phoneNumber" label={translate('users.phone')} validate={requiredValidator} />
            <FormHelperText>
                <RolesExplanation />
            </FormHelperText>
            <SelectInput
                source="role"
                label={translate('users.role')}
                choices={[
                    Role.SUPER_ADMIN,
                    Role.DRIVER,
                    Role.DEPOT_WORKER,
                    Role.MANAGER,
                    Role.OPERATIONS_MANAGER,
                    Role.SALES_MANAGER,
                    Role.SALES_AND_OPERATION_MANAGER,
                    Role.NETWORK_AND_SALES_MANAGER,
                    Role.NETWORK_MANAGER,
                    Role.CUSTOMER_CARE,
                ].map(role => ({
                    id: role,
                    name: translate(`role.${role}`),
                }))}
                sx={{ width: 360, maxWidth: '100%' }}
                validate={requiredValidator}
            />

            <FormDataConsumer>
                {({ formData, ...rest }) =>
                    formData.role === Role.DEPOT_WORKER && (
                        <ReferenceInput
                            source="depot"
                            reference={ResourceKey.LOCATION}
                            filter={{ type: 'depot' }}
                            {...rest}
                        >
                            <AutocompleteInput
                                label={translate('users.depot')}
                                validate={requiredValidator}
                                optionText="name"
                                sx={{ width: 360, maxWidth: '100%' }}
                            />
                        </ReferenceInput>
                    )
                }
            </FormDataConsumer>

            <RegionsListInput
                source="regions"
                label={translate('user.regions')}
                validate={[requiredValidator, currentRegionValidator]}
                placeholder={translate('action.choose')}
            />
        </SimpleForm>
    );
};

export default UserForm;
