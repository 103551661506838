import { isUndefined } from 'lodash';

import { createObjectSanitizer, isEmptyString } from 'utils/sanitizer';

import type { ParcelsListFilterValues, ParcelsListFormValues } from './ParcelsListFilters.types';

const sanitizeParcelFilters = createObjectSanitizer(isUndefined, isEmptyString);

const composePhoneNumber = (prefix?: string | null, number?: string | null) => {
    return [prefix && `+${prefix}`, number].filter(Boolean).join('');
};

export function prepareParcelFiltersFromFormValues(formValues: ParcelsListFormValues): ParcelsListFilterValues {
    const { customerPhone, ...rest } = formValues;
    const result: ParcelsListFilterValues = rest;

    if (customerPhone?.number) {
        result.customerPhone = sanitizeParcelFilters({
            phoneNumber: composePhoneNumber(customerPhone?.prefix, customerPhone?.number),
            exactMatch: customerPhone?.exactMatch as boolean,
        });
    }

    return sanitizeParcelFilters(result);
}
