import { useState } from 'react';

import { useApiReducer } from 'hooks';

import { adminApiClient } from 'modules/auth/services/authApiClient';
import type { Options as ApiClientOptions } from 'services/apiClient';
import config from 'config';
import type { paths } from 'api/adminApiSchema';
import type { FileInputValue } from 'types';

import type { CodPaymentImportValidationRule } from '../constants';

type UpdatePaymentStateOperation = paths['/ra-api/v1/parcel:updatePaymentStateFromCsv']['post'];
export type CsvInfo = UpdatePaymentStateOperation['responses']['200']['content']['application/json'];

export interface ValidationRuleError {
    errorCode: CodPaymentImportValidationRule;
    parcels: string[];
}

export interface ValidationError extends Error {
    code: string;
    status: number;
    validationErrors?: ValidationRuleError[];
}

export const isValidationError = (error: unknown): error is ValidationError =>
    (error as ValidationError) && 'validationErrors' in (error as ValidationError);

const updatePaymentStateFromCsv = async (formData?: FormData, options?: ApiClientOptions) => {
    return await adminApiClient.post<FormData, CsvInfo>(
        config.adminApi.endpoints.updatePaymentStateFromCsv,
        formData,
        options,
    );
};

export function useCodPaymentsImportValidation(options?: { onSuccess?: (csvInfo: CsvInfo) => void }) {
    const [apiState, dispatch] = useApiReducer();
    const [file, setFile] = useState<FileInputValue | null>(null);

    const validate = async (csvFile: FileInputValue) => {
        setFile(csvFile);

        dispatch({ type: 'request' });

        try {
            const formData = new FormData();
            formData.append('file', csvFile.rawFile);

            const csvInfo = await updatePaymentStateFromCsv(formData, { rawError: true });

            dispatch({ type: 'success' });

            options?.onSuccess?.(csvInfo);
        } catch (error: unknown) {
            const apiError = isValidationError(error) ? error : 'unknown error';
            dispatch({ type: 'error', error: apiError });
        }
    };

    const reset = () => {
        setFile(null);
        dispatch({ type: 'reset' });
    };

    return {
        file,
        api: apiState,
        validateFile: validate,
        reset,
    } as const;
}

export function useFinishCodPaymentsImport(
    file?: File,
    options?: {
        onSuccess?: () => void;
    },
) {
    const [apiState, dispatch] = useApiReducer();

    const finish = async (confirmationHash: string) => {
        dispatch({ type: 'request' });

        if (!file) {
            dispatch({ type: 'error', error: '' });
            return;
        }

        try {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('confirmationHash', confirmationHash);

            await updatePaymentStateFromCsv(formData);

            dispatch({ type: 'success' });

            options?.onSuccess?.();
        } catch (error: any) {
            dispatch({ type: 'error', error });
        }
    };

    return {
        api: apiState,
        finishImport: finish,
    } as const;
}
