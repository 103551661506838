import { useState } from 'react';

import { Typography } from '@mui/material';

import { useUserRegion } from 'modules/localization';
import { useTranslate } from 'hooks';
import type { ExtendedLocation } from 'modules/parcels/types';

import { PartnerDefaultWarehouse } from './PartnerDefaultWarehouse';
import { PartnerCurrentReturnLocation } from './PartnerCurrentReturnLocation';

import { PartnerApmForReturns } from './PartnerApmForReturns';

export enum ReturnApmLocationUsage {
    No = 0,
    Yes = 1,
    WhenAvailable = -1,
}

export const returnApmLocationUsageChoices = [
    ReturnApmLocationUsage.Yes,
    ReturnApmLocationUsage.No,
    ReturnApmLocationUsage.WhenAvailable,
].map(usage => ({
    id: usage,
    name: `partners.returns.apm.usage.${
        usage === ReturnApmLocationUsage.Yes || usage === ReturnApmLocationUsage.WhenAvailable ? 'yes' : 'no'
    }`,
}));

export interface PartnerReturnLocationsProps {
    viewOnly?: boolean;
}

export const PartnerReturnLocations = ({ viewOnly = false }: PartnerReturnLocationsProps) => {
    const translate = useTranslate();
    const [region] = useUserRegion();
    const [returnApmLocation, setReturnApmLocation] = useState<ExtendedLocation | null>(null);

    return (
        <>
            <Typography variant="h6" component="h3" mt={3}>
                {translate('partners.returns.title')}
            </Typography>

            {viewOnly ? (
                <PartnerCurrentReturnLocation viewOnly apmLocation={returnApmLocation} />
            ) : (
                <PartnerCurrentReturnLocation apmLocation={returnApmLocation} />
            )}

            {viewOnly ? (
                <PartnerApmForReturns viewOnly regionId={region?.id} setReturnApmLocation={setReturnApmLocation} />
            ) : (
                <PartnerApmForReturns regionId={region?.id} setReturnApmLocation={setReturnApmLocation} />
            )}

            {viewOnly ? <PartnerDefaultWarehouse viewOnly /> : <PartnerDefaultWarehouse />}
        </>
    );
};
