import { omit } from 'lodash';

import config from 'config';

import { APP_INFO, APP_INFO_HEADER_NAME } from 'constants/index';
import { createUIErrorMessage } from 'modules/errors';

// import directly from the path to prevent circular modules
import { userRegionStorage } from 'modules/localization/services/userRegionStorage';

export type RequestBody = FormData | object;

export interface Options extends Partial<RequestInit> {
    rawError?: boolean;
}

interface IApiClient {
    get<Response>(endpoint: string): Promise<Response | null>;
    post<Payload extends RequestBody, Response>(endpoint: string, payload: Payload): Promise<Response | null>;
    put<Payload extends RequestBody, Response>(endpoint: string, payload: Payload): Promise<Response | null>;
    delete(endpoint: string): Promise<void>;
}

export class ApiClient implements IApiClient {
    baseUrl: URL;
    headers: Record<string, string>;

    constructor(baseUrl: string, headers?: Record<string, string>) {
        this.baseUrl = new URL(baseUrl);
        this.headers = {
            'Content-Type': 'application/json',
            [APP_INFO_HEADER_NAME]: APP_INFO,
            ...headers,
        };
    }

    get<Response>(endpoint: string, options?: Options) {
        return this.request<never, Response>('GET', endpoint, undefined, options);
    }

    post<Payload extends RequestBody, Response>(endpoint: string, payload?: Payload, options?: Options) {
        return this.request<Payload, Response>('POST', endpoint, payload, options);
    }

    put<Payload extends RequestBody, Response>(endpoint: string, payload: Payload, options?: Options) {
        return this.request<Payload, Response>('PUT', endpoint, payload, options);
    }

    patch<Payload extends RequestBody, Response>(endpoint: string, payload: Payload, options?: Options) {
        return this.request<Payload, Response>('PATCH', endpoint, payload, options);
    }

    async delete(endpoint: string, options?: Options) {
        await this.request('DELETE', endpoint, undefined, options);
    }

    async request<Payload extends RequestBody, Response>(
        method: RequestInit['method'],
        endpoint: string,
        payload?: Payload,
        options?: Options,
    ): Promise<Response> {
        const url = this.baseUrl.toString() + endpoint;

        // https://developer.mozilla.org/en-US/docs/Web/API/FormData/Using_FormData_Objects#sending_files_using_a_formdata_object
        const defaultHeaders = payload instanceof FormData ? omit(this.headers, 'Content-Type') : this.headers;

        const userRegionId = userRegionStorage.get();

        if (userRegionId) {
            defaultHeaders['X-User-Region'] = userRegionId;
        } else {
            delete defaultHeaders['X-User-Region'];
        }

        const { rawError = false, ...requestOptions } = options ?? ({} as Options);
        const request = new Request(url.toString(), {
            ...requestOptions,
            method,
            body: payload instanceof FormData ? payload : JSON.stringify(payload),
            headers: new Headers({ ...defaultHeaders, ...options?.headers }),
        });

        const response = await fetch(request);

        if (response.status < 200 || response.status >= 400) {
            const error = await response.json();

            if (rawError) {
                throw error;
            }

            const uiError = createUIErrorMessage({ status: response.status, code: error?.code });
            throw uiError;
        }

        if (response.headers.get('Content-Type')?.includes('application/json')) {
            const text = await response.text();

            if (text) {
                const body: Response = JSON.parse(text);
                return body;
            }
        }

        if (response.headers.get('Content-Type')?.includes('text/csv')) {
            const text = await response.text();

            if (text) {
                return text as Response;
            }
        }

        // TODO: remove when PUT and PATCH updated to return a response on backend
        // @ts-ignore
        return null;
    }
}

export const apiClient = new ApiClient(config.api.url);
