import { Show, TextField, useTranslate, ReferenceField, TabbedShowLayout, Tab } from 'react-admin';
import { BoxedShowLayout, RaBox } from 'ra-compact-ui';

import { ResourceKey } from 'constants/index';
import { AddressField, LatLngField } from 'modules/ui';

import { ConnectedLanesList } from 'modules/lanes';

import { ConnectedPartnersListField } from '../ConnectedPartnersListField';
import { WarehouseSeoHead } from '../WarehouseSeoHead';

export const WarehouseDetail = () => {
    const translate = useTranslate();

    return (
        <>
            <Show>
                <WarehouseSeoHead detailView />
                <BoxedShowLayout>
                    <RaBox>
                        <TextField label={translate('warehouse.name')} source={'name'} />

                        <AddressField />

                        <LatLngField source="coordinates" />

                        <ReferenceField
                            source={'depotId'}
                            label={translate('warehouse.serviceArea')}
                            reference={ResourceKey.LOCATION}
                            link={false}
                        >
                            <TextField source="name" />
                        </ReferenceField>

                        <ReferenceField
                            source={'regionId'}
                            label={translate('warehouse.region')}
                            reference={ResourceKey.REGION}
                            link={false}
                        >
                            <TextField source="name" />
                        </ReferenceField>

                        <TabbedShowLayout>
                            <Tab label={'warehouse.partners'}>
                                <ConnectedPartnersListField />
                            </Tab>
                            <Tab label={'warehouse.lanes'}>
                                <ConnectedLanesList />
                            </Tab>
                        </TabbedShowLayout>
                    </RaBox>
                </BoxedShowLayout>
            </Show>
        </>
    );
};
