import { styled } from '@mui/material';

export const Container = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(1),

    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}));
