import { useNotify, useRefresh } from 'react-admin';

import { createUIErrorMessage, isHttpError } from '../utils/apiErrors';

const useServerValidationErrors = (resetDataOnFailure = false) => {
    const notify = useNotify();
    const refresh = useRefresh();

    const onFailure = (error: unknown) => {
        const uiError = isHttpError(error)
            ? createUIErrorMessage({ status: error.status, code: error.body?.code })
            : (error as Error).toString();

        notify(uiError, { type: 'warning' });

        if (resetDataOnFailure) {
            refresh();
        }
    };

    return onFailure;
};

export default useServerValidationErrors;
