import { useRecordContext, useTranslate } from 'react-admin';
import { Typography } from '@mui/material';

import { Button } from 'modules/ui';

import type { Apm } from '../../types';
import { useShowLcdText } from '../../hooks';

export interface ShowLcdTextButtonProps {
    displayText?: string;
    btnText?: string;
    loadingBtnText?: string;
}

export const ShowLcdTextButton = ({
    displayText = 'Error',
    btnText = 'apm.displayError.button',
    loadingBtnText = 'apm.displayError.button.loading',
}: ShowLcdTextButtonProps) => {
    const translate = useTranslate();
    const apmId = useRecordContext<Apm>()?.id;

    const [{ loading, error }, toggle] = useShowLcdText(apmId, displayText, {
        successMsg: 'apm.displayError.button.success',
    });

    if (!apmId) {
        return null;
    }

    return (
        <>
            <Button variant="contained" disabled={loading} loading={loading} onClick={toggle}>
                {translate(loading ? loadingBtnText : btnText)}
            </Button>
            {error && (
                <Typography color="error.main" maxWidth="40rem">
                    {typeof error === 'string' ? translate(error) : error.toString()}
                </Typography>
            )}
        </>
    );
};
