import { FormDataConsumer, useGetList, useRecordContext } from 'react-admin';

import InfoIcon from '@mui/icons-material/Info';
import { Box, Typography, Paper, Stack, Chip } from '@mui/material';

import type { ExtendedLocation } from 'modules/parcels/types';
import type { PartnerData } from 'modules/partners/types';
import { useTranslate } from 'hooks';
import { ResourceKey } from 'constants/index';

import { InfoText } from './PartnerCurrentReturnLocation.styles';
import { ReturnApmLocationUsage } from '../PartnerReturnLocations';

interface PartnerCurrentReturnLocationProps {
    apmLocation: ExtendedLocation | null;
    viewOnly?: boolean;
}

export const PartnerCurrentReturnLocation = ({ apmLocation, viewOnly = false }: PartnerCurrentReturnLocationProps) => {
    const translate = useTranslate();
    const partner = useRecordContext<PartnerData>();

    const returnApmLocationId = partner?.returnApmLocation?.locationId;
    const returnApmLocationUsage = partner?.returnApmLocation?.usage;
    const returnLocationAddress =
        partner?.returnLocation && !partner?.returnLocation.locationId ? partner?.returnLocation : undefined;
    const returnLocationId = partner?.returnLocation?.locationId;
    const returnWarehouse = partner?.warehouses?.find(w => w.id === returnLocationId);

    const props = {
        returnApmLocation: apmLocation,
        returnApmLocationId,
        returnApmLocationUsage: returnApmLocationUsage as ReturnApmLocationUsage,
        returnLocationId,
        returnWarehouse,
        returnLocationAddress,
        translate,
        partner,
    };

    if (viewOnly) {
        return (
            <Paper elevation={1} sx={{ padding: 2, marginTop: 3, backgroundColor: 'grey.100', width: 360 }}>
                {returnApmLocationId && returnApmLocationUsage === ReturnApmLocationUsage.Yes ? (
                    <FetchReturnLocationContent {...props} locationId={returnApmLocationId} />
                ) : (
                    <ReturnLocationContent {...props} />
                )}
            </Paper>
        );
    }

    return (
        <FormDataConsumer>
            {({ formData }) => {
                const originReturnApmLocationId = partner?.returnApmLocation?.locationId;
                const originReturnApmLocationUsage = partner?.returnApmLocation?.usage;
                const originReturnLocationId = partner?.returnLocation?.locationId;
                const returnApmLocationId = formData.returnApmLocation?.locationId;
                const returnApmLocationUsage = formData.returnApmLocation?.usage;
                const returnLocationId = formData.returnLocation?.locationId;
                const formDataWarehouses = formData.warehouses;
                const returnWarehouse = formData.warehouses?.find(
                    (w: PartnerData['warehouses'][0]) => w?.id === returnLocationId,
                );

                const returnApmIdsChange = returnApmLocationId !== originReturnApmLocationId;
                const returnApmIdsEquals = returnApmLocationId === originReturnApmLocationId;
                const returnApmChangeAndActive =
                    returnApmIdsChange && returnApmLocationUsage === ReturnApmLocationUsage.Yes;
                const returnApmUsageChange =
                    (returnApmIdsEquals &&
                        returnApmLocationUsage === ReturnApmLocationUsage.Yes &&
                        originReturnApmLocationUsage !== ReturnApmLocationUsage.Yes) ||
                    (returnApmLocationUsage === ReturnApmLocationUsage.No &&
                        originReturnApmLocationUsage === ReturnApmLocationUsage.Yes);
                const returnWarehouseAsCurrent =
                    returnLocationId &&
                    (!returnApmLocationId ||
                        [ReturnApmLocationUsage.No, ReturnApmLocationUsage.WhenAvailable].includes(
                            returnApmLocationUsage,
                        ));
                const returnWarehouseChange = returnWarehouseAsCurrent && returnLocationId !== originReturnLocationId;
                const originalChangeApm =
                    originReturnApmLocationId &&
                    originReturnApmLocationUsage === ReturnApmLocationUsage.Yes &&
                    (!returnApmLocationId || returnApmLocationUsage === ReturnApmLocationUsage.No);
                const originalChangeWarehouse =
                    originReturnLocationId &&
                    returnLocationId !== originReturnLocationId &&
                    returnApmLocationUsage !== ReturnApmLocationUsage.Yes;
                const originalAsCurrent = originalChangeApm || originalChangeWarehouse;

                // partner must be defined in order to display tooltip only in create/edit mode
                const showChangeAfterSavedTooltip =
                    partner &&
                    (returnApmChangeAndActive || returnApmUsageChange || returnWarehouseChange || originalAsCurrent);
                return (
                    <>
                        <Paper elevation={1} sx={{ padding: 2, marginTop: 3, backgroundColor: 'grey.100', width: 360 }}>
                            {originReturnApmLocationId &&
                            returnApmLocationId === originReturnApmLocationId &&
                            (returnApmLocationUsage === ReturnApmLocationUsage.Yes ||
                                originReturnApmLocationUsage === ReturnApmLocationUsage.Yes) ? (
                                <FetchReturnLocationContent
                                    {...props}
                                    locationId={returnApmLocationId}
                                    returnApmLocationUsage={returnApmLocationUsage as ReturnApmLocationUsage}
                                    returnWarehouse={returnWarehouse}
                                    returnLocationId={returnLocationId}
                                />
                            ) : (
                                <ReturnLocationContent
                                    returnApmLocation={apmLocation}
                                    returnApmLocationId={returnApmLocationId}
                                    returnApmLocationUsage={returnApmLocationUsage as ReturnApmLocationUsage}
                                    returnLocationId={returnLocationId}
                                    returnWarehouse={returnWarehouse}
                                    returnLocationAddress={returnLocationAddress}
                                    translate={translate}
                                    partner={partner}
                                    formDataWarehouses={formDataWarehouses}
                                />
                            )}
                        </Paper>
                        {showChangeAfterSavedTooltip && (
                            <InfoText>
                                <InfoIcon sx={{ height: 20 }} />
                                {translate('partners.returns.tooltipText')}
                            </InfoText>
                        )}
                    </>
                );
            }}
        </FormDataConsumer>
    );
};

export const renderCurrentReturnLocation = (location: {
    name?: string;
    identifier?: string;
    street?: string;
    city?: string;
    postalCode?: string;
    zip?: string;
    country?: string;
    title?: string;
}) => (
    <Box>
        <Typography variant="body2" fontWeight={600}>
            {location.name ?? location.title ?? ''}
        </Typography>
        <Typography variant="body2">{location.identifier ?? ''}</Typography>
        <Typography variant="body2">
            {[location.street, location.city, location.postalCode ?? location.zip, location.country]
                .filter(Boolean)
                .join(', ')}
        </Typography>
    </Box>
);

const LocationTypeChip = ({
    returnApmLocationId,
    returnApmLocationUsage,
    returnLocationId,
    returnLocationAddress,
    translate,
    partner,
    formDataWarehouses,
}: Pick<
    ReturnLocationContentProps,
    | 'returnApmLocationId'
    | 'returnApmLocationUsage'
    | 'returnLocationId'
    | 'returnLocationAddress'
    | 'translate'
    | 'partner'
    | 'formDataWarehouses'
>) => {
    const getChipLabel = () => {
        if (returnApmLocationId && returnApmLocationUsage === ReturnApmLocationUsage.Yes) {
            return translate('partners.returns.labels.apm');
        }
        if (
            (returnLocationId && partner?.warehouses?.length) ||
            (returnLocationId && formDataWarehouses?.length && !partner?.warehouses?.length)
        ) {
            return translate('partners.returns.labels.warehouse');
        }
        if (returnLocationAddress) {
            return translate('partners.returns.labels.originalLocation');
        }
        return translate('partners.returns.labels.originalLocationPerParcel');
    };
    return <Chip label={getChipLabel()} variant="outlined" />;
};

interface ReturnLocationContentProps {
    returnApmLocation?: ExtendedLocation | null;
    returnApmLocationId?: string;
    returnApmLocationUsage?: ReturnApmLocationUsage;
    returnLocationId?: string;
    returnWarehouse?: PartnerData['warehouses'][0];
    returnLocationAddress?: PartnerData['returnLocation'];
    translate: Function;
    partner: PartnerData;
    formDataWarehouses?: PartnerData['warehouses'];
}

interface FetchReturnLocationContentProps extends ReturnLocationContentProps {
    locationId: string;
}

const FetchReturnLocationContent = ({ returnApmLocation, ...props }: FetchReturnLocationContentProps) => {
    const { data: locations } = useGetList<ExtendedLocation>(ResourceKey.LOCATION, {
        filter: { id: [props.locationId] },
    });

    if (!locations?.length) return null;
    const location = locations[0];

    const properties = {
        ...props,
        returnApmLocation: location,
        returnApmLocationUsage: props.returnApmLocationUsage,
    };

    return <ReturnLocationContent {...properties} />;
};

const ReturnLocationContent = ({ returnApmLocation, returnWarehouse, ...props }: ReturnLocationContentProps) => {
    return (
        <Stack gap={2}>
            <Typography>{props.translate('partners.returns.currentLocation')}</Typography>
            <Box sx={{ display: 'inline-flex' }}>
                <LocationTypeChip {...props} />
            </Box>
            {returnApmLocation && props.returnApmLocationUsage === ReturnApmLocationUsage.Yes
                ? renderCurrentReturnLocation(returnApmLocation)
                : returnWarehouse
                ? renderCurrentReturnLocation(returnWarehouse)
                : props.returnLocationAddress
                ? renderCurrentReturnLocation(props.returnLocationAddress)
                : null}
        </Stack>
    );
};
