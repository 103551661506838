import type { FC } from 'react';
import { Edit, useNotify, useRedirect, useUpdate } from 'react-admin';
import type { EditProps } from 'react-admin';

import { useServerValidationErrors } from 'modules/errors';
import { ResourceKey } from 'constants/index';

import { useHandleLaneSubmit } from '../../hooks';
import LaneForm from '../LaneForm';
import LaneTitle from '../LaneTitle';

const LaneEdit: FC<EditProps> = () => {
    const notify = useNotify();
    const redirect = useRedirect();

    const [update] = useUpdate();

    const onError = useServerValidationErrors();

    const onSuccess = () => {
        notify('lanes.edit.saved');
        redirect('list', ResourceKey.LANES);
    };

    const handleSubmit = useHandleLaneSubmit(update, { onSuccess, onError });

    return (
        <>
            <Edit title={<LaneTitle />}>
                <LaneForm onSubmit={handleSubmit} />
            </Edit>
        </>
    );
};

export default LaneEdit;
