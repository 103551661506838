import { SingleFieldList, ChipField, ReferenceArrayField } from 'react-admin';
import type { ReferenceArrayFieldProps } from 'react-admin';

import { ResourceKey } from 'constants/index';

export interface RegionsListProps extends Omit<ReferenceArrayFieldProps, 'reference' | 'children'> {}

export const RegionsList = (props: RegionsListProps) => {
    return (
        <ReferenceArrayField {...props} reference={ResourceKey.REGION}>
            <SingleFieldList sx={{ marginTop: 0 }}>
                <ChipField source="name" />
            </SingleFieldList>
        </ReferenceArrayField>
    );
};
