import { logger } from 'config';

export interface Storage<Entity> {
    save(entity: Entity): void;
    get(): Entity | null;
    delete(): void;
}

export class LocalStorage<Entity> implements Storage<Entity> {
    key: string;

    constructor(key: string) {
        this.key = key;
    }

    save(entity: Entity) {
        localStorage.setItem(this.key, JSON.stringify(entity));
    }

    get() {
        const json = localStorage.getItem(this.key);

        if (json) {
            try {
                const entity: Entity = JSON.parse(json);
                return entity;
            } catch (error) {
                logger.error(error);

                if (error instanceof SyntaxError) {
                    return null;
                }
                throw error;
            }
        }

        return null;
    }

    delete() {
        localStorage.removeItem(this.key);
    }
}
