const config = {
    api: {
        url: 'https://api-stage.boxnow.gr/api/v1',
    },
    adminApi: {
        url: 'https://api-stage.boxnow.gr/ra-api',
    },
    sentry: {
        dsn: 'https://4562ab40497b4f86a90b62334ca15ab0@o1006836.ingest.sentry.io/5980664',
    },
} as const;

export type EnvConfig = typeof config;

export default config;
