import { AutocompleteInput, ReferenceInput, useTranslate } from 'react-admin';
import { Stack } from '@mui/material';

import { ResourceKey } from 'constants/index';
import { TextInput } from 'modules/ui';

import type { Parcel } from '../../types';

type LocationName = keyof NonNullable<Required<Parcel['addresses']>>;

export interface DeliveryPartnerFormProps {
    locationName: LocationName;
    parcelIdSource: string;
    disablePartnerSelect?: boolean;
}

export const DeliveryPartnerForm = ({
    locationName,
    parcelIdSource,
    disablePartnerSelect = false,
}: DeliveryPartnerFormProps) => {
    const translate = useTranslate();

    return (
        <Stack>
            <ReferenceInput
                source={`addresses.${locationName}.deliveryPartner`}
                perPage={50}
                reference={ResourceKey.DELIVERY_PARTNER}
            >
                <AutocompleteInput
                    disabled={disablePartnerSelect}
                    label={translate('parcel.delivery.partner')}
                    optionText="name"
                />
            </ReferenceInput>

            <TextInput
                source={parcelIdSource}
                label={translate('parcel.delivery.parcelId')}
                // id can be null which produces React warning about controlled component state change
                format={value => value || ''}
            />
        </Stack>
    );
};
