import { useState } from 'react';

import { Button } from '@mui/material';

import { ResourceKey } from 'constants/index';
import { useTranslate } from 'hooks';
import { useHasAccess } from 'modules/auth';
import { PermissionAction } from 'modules/roles';

import { ConfirmOpeningDialog } from './ConfirmOpeningDialog';

export interface ApmButtonOpenProps {
    apmId: string;
}

export const ApmButtonOpen = ({ apmId }: ApmButtonOpenProps) => {
    const translate = useTranslate();
    const hasDetailAccess = useHasAccess(ResourceKey.APM, PermissionAction.OPEN_COMPARTMENT);
    const [open, setOpen] = useState(false);
    const openDialog = () => setOpen(true);
    const closeDialog = () => setOpen(false);

    if (!hasDetailAccess) {
        return null;
    }

    return (
        <>
            <Button variant="contained" onClick={openDialog} size="small">
                <>{translate('action.open')}</>
            </Button>
            <ConfirmOpeningDialog apmId={apmId} visible={open} onClose={closeDialog} />
        </>
    );
};
