import { TextInput, BooleanInput, Labeled, useTranslate } from 'react-admin';
import { Box } from '@mui/material';
import { useWatch } from 'react-hook-form';

import {
    isUrl,
    requiredWhenHookActive,
    requiredWhenHookHeaderFilled,
    requiredWhenRelatedFilled,
} from '../../validators';

export const WebhookForm = () => {
    const translate = useTranslate();

    const webhookActive = useWatch({ name: 'parcelEventChangeWebhook.active' });

    const label =
        translate('partners.characteristics.webhook.url') +
        ' (' +
        translate(`partners.characteristics.webhook.${webhookActive ? 'active' : 'inactive'}`) +
        ')';

    const nameLabel = translate('partners.characteristics.webhook.name');
    const valueLabel = translate('partners.characteristics.webhook.value');

    return (
        <>
            <Box display="flex" alignItems="center">
                <TextInput
                    label={label}
                    source="parcelEventChangeWebhook.url"
                    fullWidth
                    validate={[isUrl, requiredWhenHookActive, requiredWhenHookHeaderFilled]}
                    defaultValue=""
                />
                <BooleanInput source="parcelEventChangeWebhook.active" label="" sx={{ marginLeft: 2 }} />
            </Box>
            <Labeled label={translate('partners.characteristics.webhook.header')}>
                <Box display="flex" alignItems="center">
                    <TextInput
                        source="parcelEventChangeWebhook.header.name"
                        label={nameLabel}
                        validate={requiredWhenRelatedFilled('parcelEventChangeWebhook.header.value', valueLabel)}
                        defaultValue=""
                    />
                    &nbsp;
                    <TextInput
                        source="parcelEventChangeWebhook.header.value"
                        label={valueLabel}
                        validate={requiredWhenRelatedFilled('parcelEventChangeWebhook.header.name', nameLabel)}
                        defaultValue=""
                    />
                </Box>
            </Labeled>
        </>
    );
};
