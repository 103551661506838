import { styled } from '@mui/material';

export const Container = styled('div')(({ theme }) => ({
    width: '100%',
    marginBottom: 20,

    display: 'flex',
    justifyContent: 'space-between',
}));

export const Action = styled('span')(({ theme }) => ({
    textDecoration: 'underline',
    cursor: 'pointer',

    '&:hover': {
        textDecoration: 'none',
    },
}));

export const Disabled = styled('span')(({ theme }) => ({
    opacity: 0.5,
}));
