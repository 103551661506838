import type { ParcelStatus } from 'api/adminApiSchema';

export const parcelStatusOrdered: Readonly<ParcelStatus[]> = [
    'new',
    'in-transit',
    'in-depot',
    'in-final-destination',
    'delivered',
    'wait-for-load',
    'returned',
    'expired-return',
    'canceled',
    'canceled-return',
    'lost',
    'missing',
    'hold',
    'long-term-hold',
    'unloaded',
] as const;

export const parcelHistoryEvents = [
    'customer-issue-active',
    'customer-issue-inactive',
    'destination-change',
    'open-compartment-to-pickup-failed',
    'open-compartment-to-pickup-warning',
    'open-compartment-to-send-failed',
    'open-compartment-to-send-warning',
    'full_locker',
] as const;

export type ParcelHistoryEventName = (typeof parcelStatusOrdered)[number] | (typeof parcelHistoryEvents)[number];

export const COD_PAYMENT_IMPORT_ROUTE = '/parcel/cod-payment-import' as const;
