import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';

import { Button } from 'modules/ui';
import { useConfirmDialog, useTranslate } from 'hooks';

import { PutInLockerDialog } from './PutInLockerDialog';

export const PutInLockerButton = () => {
    const translate = useTranslate();
    const [open, openDialog, closeDialog] = useConfirmDialog();

    return (
        <>
            <Button variant="contained" onClick={() => openDialog()} startIcon={<MoveToInboxIcon />}>
                {translate('parcel.detail.putInLocker.button')}
            </Button>
            <PutInLockerDialog open={open} onClose={() => closeDialog()} />
        </>
    );
};
