import { useCallback, useState } from 'react';
import { useNotify } from 'react-admin';

import config, { logger } from 'config';
import { adminApiClient } from 'modules/auth/services/authApiClient';
import { useApiReducer, useTranslate } from 'hooks';
import type { CurrentApm } from 'modules/parcels/types';

export function useFetchCurrentApm(parcelId: string) {
    const [apiState, dispatch] = useApiReducer();
    const [currentApm, setCurrentApm] = useState<CurrentApm | null>(null);
    const notify = useNotify();
    const translate = useTranslate();

    const fetchCurrentApm = useCallback(async () => {
        dispatch({ type: 'request' });

        try {
            const data = await adminApiClient.get<CurrentApm>(
                config.adminApi.endpoints.currentApm.replace('{parcelId}', parcelId),
            );
            dispatch({ type: 'success' });
            setCurrentApm(data);
        } catch (error: any) {
            logger.error(error);
            notify(translate('parcel.detail.putInLocker.currentApmRequest.failure'), { type: 'error' });
            dispatch({ type: 'error', error: error as string });
        }
    }, [dispatch, notify, translate, parcelId]);

    return {
        apiState,
        fetchCurrentApm,
        currentApm,
    } as const;
}
