import { styled } from '@mui/material';

export const Resources = styled('div')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',

    marginTop: theme.spacing(2),
}));

export const Actions = styled('ul')(({ theme }) => ({
    listStyleType: 'none',

    marginTop: 0,
    marginLeft: 0,
    marginRight: theme.spacing(8),
    paddingLeft: 0,
}));

export const Action = styled('li')(({ theme }) => ({
    marginTop: theme.spacing(0.5),
}));
