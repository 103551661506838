import { NumberInput } from 'react-admin';
import { InputAdornment } from '@mui/material';
import type { GridProps } from '@mui/material';

import { useTranslate } from 'hooks';
import { ParcelSize } from 'modules/parcels';

import * as Styled from './ApmEditProbability.styles';

export const probabilityOption = {
    [ParcelSize.S]: 'availabilityProbSmall',
    [ParcelSize.M]: 'availabilityProbMedium',
    [ParcelSize.L]: 'availabilityProbLarge',
} as const;

const probabilityOptions = [
    probabilityOption[ParcelSize.S],
    probabilityOption[ParcelSize.M],
    probabilityOption[ParcelSize.L],
] as const;

export const ApmEditProbability = (props: Pick<GridProps, 'mt' | 'mb'>) => {
    const translate = useTranslate();

    return (
        <Styled.Row {...props}>
            {probabilityOptions.map(option => (
                <NumberInput
                    key={option}
                    min={0}
                    max={100}
                    step={1}
                    source={option}
                    label={translate(`apm.edit.${option}` as const)}
                    size="small"
                    InputProps={{
                        endAdornment: <InputAdornment position="start">%</InputAdornment>,
                    }}
                />
            ))}
        </Styled.Row>
    );
};
