import { useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle, DialogActions, Typography, Skeleton } from '@mui/material';
import { useRecordContext, useRefresh } from 'react-admin';
import { useForm, FormProvider } from 'react-hook-form';

import { useTranslate } from 'hooks';
import { Button } from 'modules/ui';

import { useParcelApiAction } from 'modules/parcels/hooks/useParcelApiAction';
import config from 'config/config';

import type { Parcel } from 'modules/parcels/types';

import { PutInLockerDialogContent } from '../PutInLockerDialogContent';
import { useFetchCurrentApm } from '../hooks/useFetchCurrentApm';
interface PutInLockerFormValues {
    apmId?: string;
    compartmentNumber?: number;
}

export interface PutInLockerDialogProps {
    open: boolean;
    onClose: () => void;
}

export const PutInLockerDialog = ({ open, onClose }: PutInLockerDialogProps) => {
    const record = useRecordContext<Parcel>();
    const translate = useTranslate();
    const refresh = useRefresh();
    const { apiState: currentApmApiState, fetchCurrentApm, currentApm } = useFetchCurrentApm(record.id);
    const methods = useForm<PutInLockerFormValues>();

    const [putInLockerApiState, putInLocker] = useParcelApiAction(
        config.adminApi.endpoints.placeIntoLocker,
        record.id,
        {
            successMsg: 'parcel.detail.putInLocker.success',
            errorMsg: 'parcel.detail.putInLocker.error',
            onSuccess: () => {
                onClose();
                methods.reset();
                refresh();
            },
        },
    );

    const onSubmit = (data: PutInLockerFormValues) => {
        putInLocker(data);
    };

    useEffect(() => {
        if (open) {
            fetchCurrentApm();
            methods.reset();
        }
    }, [open, fetchCurrentApm, methods]);

    return (
        <Dialog open={open} fullWidth>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <DialogTitle>{translate('parcel.detail.putInLocker.dialog.title')}</DialogTitle>
                    <DialogContent>
                        <Typography variant="body1">{translate('parcel.detail.putInLocker.dialog.text')}</Typography>

                        {currentApmApiState.loading || !currentApm ? (
                            <Skeleton variant="text" height={150} />
                        ) : (
                            <PutInLockerDialogContent currentApm={currentApm} />
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} disabled={putInLockerApiState.loading}>
                            {translate('general.cancel')}
                        </Button>
                        <Button
                            variant="contained"
                            type="submit"
                            loading={putInLockerApiState.loading}
                            disabled={putInLockerApiState.loading || currentApmApiState.loading}
                        >
                            {translate('action.confirm')}
                        </Button>
                    </DialogActions>
                </form>
            </FormProvider>
        </Dialog>
    );
};
