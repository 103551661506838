import { useRecordContext } from 'react-admin';

import { LocationCardData } from 'modules/parcels/components/ApmSearch/LocationCard';
import type { ExtendedLocation } from 'modules/parcels/types';

export const ReturnLocationCard = () => {
    const location = useRecordContext<ExtendedLocation>();

    if (!location) {
        return null;
    }

    return <LocationCardData location={location} />;
};
