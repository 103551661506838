import { ReferenceInput, useRecordContext } from 'react-admin';
import { useState } from 'react';

import { AddButton } from 'modules/ui';
import { ResourceKey } from 'constants/index';

import { AutocompleteWarehouseInput } from '../AutocompleteWarehouseInput';
import type { PartnerData, WarehouseData } from '../../types';
import { useAddWarehouse } from '../../hooks';

export interface AddWarehouseProps {}

export const AddWarehouse = () => {
    const partner = useRecordContext<PartnerData>();
    const { addWarehouse } = useAddWarehouse();
    const [resetKey, setResetKey] = useState(0);

    const addEmptyWarehouse = () => {
        addWarehouse();
    };

    const addExistingWarehouse = (item: WarehouseData) => {
        addWarehouse(item);
        setResetKey(key => key + 1);
    };

    return (
        <>
            <ReferenceInput
                // Note: resetKey for remounting the autocomplete input to clear its value after selecting the warehouse
                key={resetKey}
                source=""
                reference={ResourceKey.SUGGESTED_WAREHOUSE}
                filter={{
                    partnerId: partner?.id,
                }}
            >
                <AutocompleteWarehouseInput addWarehouse={addExistingWarehouse} />
            </ReferenceInput>
            <AddButton label="partners.warehouse.create" onClick={addEmptyWarehouse} />
        </>
    );
};
