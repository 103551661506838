import { getCountryCallingCode } from 'libphonenumber-js';
import type { CountryCode, CountryCallingCode } from 'libphonenumber-js';

// UI localization locales
export enum Locale {
    EN = 'en',
    EL = 'el',
    HR = 'hr',
    BG = 'bg',
}

// Supported TLD domains
export enum Domain {
    BG = 'bg',
    CY = 'cy',
    HR = 'hr',
    GR = 'gr',
}

export enum Country {
    BG = 'bg',
    CY = 'cy',
    CZ = 'cz',
    HR = 'hr',
    GR = 'gr',
}

export enum LanguageTag {
    BG = 'bg-BG',
    CY = 'el-CY',
    HR = 'hr-HR',
    GR = 'el-GR',
}

export const domainByLocale: Record<Locale, Domain> = {
    [Locale.EL]: Domain.GR,
    [Locale.HR]: Domain.HR,
    [Locale.BG]: Domain.BG,
    [Locale.EN]: Domain.GR,
};

type PhonePrefixes = Record<Country, CountryCallingCode>;

export const PHONE_PREFIXES_BY_COUNTRY = Object.values(Country).reduce((prefixes, country) => {
    const countryCode = country.toUpperCase() as CountryCode;
    prefixes[country] = getCountryCallingCode(countryCode);

    return prefixes;
}, {} as PhonePrefixes);

export const PHONE_PREFIXES_BY_DOMAIN = {
    [Domain.BG]: PHONE_PREFIXES_BY_COUNTRY[Country.BG],
    [Domain.CY]: PHONE_PREFIXES_BY_COUNTRY[Country.CY],
    [Domain.HR]: PHONE_PREFIXES_BY_COUNTRY[Country.HR],
    [Domain.GR]: PHONE_PREFIXES_BY_COUNTRY[Country.GR],
};
