import { useRecordContext } from 'react-admin';
import type { TextFieldProps } from 'react-admin';

import { Typography } from '@mui/material';

export interface RatioFieldProps extends Pick<TextFieldProps, 'source' | 'label'> {}

export const RatioField = (props: RatioFieldProps) => {
    const { source } = props;
    const record = useRecordContext(props);

    const value = source && record && !isNaN(record[source]) ? parseFloat(record[source]) * 100 : '-';

    return (
        <Typography variant="body2">
            {value}
            {value === '-' ? '' : '%'}
        </Typography>
    );
};
