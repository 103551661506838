import { styled } from '@mui/material';
import { Pagination } from 'react-admin';

export const NonClickableContainer = styled('div')(({ theme }) => ({
    position: 'absolute',
    bottom: 0,
    right: 0,
    backgroundColor: 'transparent',
    height: 50,
    width: 90,
    zIndex: 10,
}));

export const Container = styled('div')(({ theme }) => ({
    position: 'relative',
}));

export const RAPagination = styled(Pagination)<{ isFetching: boolean }>(({ theme, isFetching }) => ({
    color: isFetching ? '#888' : '#000',
}));
