import { styled } from '@mui/material';

export const List = styled('ul')(() => ({
    padding: 0,
    listStyleType: 'none',
}));

export const Item = styled('li')(({ theme }) => ({
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(1),
    fontSize: '0.9rem',
    fontWeight: 500,
}));
