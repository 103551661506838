import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { PartnerOverdueSelect } from '../PartnerOverdueSelect';
import { PartnerVipSelect } from '../PartnerVipSelect';

interface PartnerVipOverdueSelectProps {
    vipLabel: string;
    overdueLabel: string;
}

export const PartnerVipOverdueSelect = ({ vipLabel, overdueLabel }: PartnerVipOverdueSelectProps) => {
    const { setValue, watch } = useFormContext();

    const overdueChangeReason = watch('overdueChangeReason');
    const vipChangeReason = watch('vipChangeReason');

    useEffect(() => {
        setValue(
            'overdueVipChangeReason',
            overdueChangeReason && vipChangeReason
                ? `Overdue: ${overdueChangeReason}\nVIP: ${vipChangeReason}`
                : overdueChangeReason
                ? `Overdue: ${overdueChangeReason}`
                : vipChangeReason
                ? `VIP: ${vipChangeReason}`
                : undefined,
        );
    }, [overdueChangeReason, vipChangeReason, setValue]);

    return (
        <>
            <PartnerVipSelect vipLabel={vipLabel} />
            <PartnerOverdueSelect overdueLabel={overdueLabel} />
        </>
    );
};
