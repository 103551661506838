export enum PartnerType {
    ESHOP = 'eshop',
    COURIER = 'courier',
    P2P = 'p2p',
}

export enum PartnerVip {
    Yes = 'yes',
    No = 'no',
    Temporary = 'temporary',
}

export const partnerVipChoices = [PartnerVip.Yes, PartnerVip.No, PartnerVip.Temporary].map(vip => ({
    id: vip,
    name: `partners.characteristics.vip.${vip}`,
}));
