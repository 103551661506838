import type { CountryCode } from 'libphonenumber-js';
import { getPhoneCode } from 'libphonenumber-js';

import { useMemo } from 'react';

import { useUserRegion } from 'modules/localization';

export function useRegionPhonePrefix() {
    const [region] = useUserRegion();

    return useMemo<string | null>(() => {
        if (!region) {
            return null;
        }

        const countryCode = region.languageTag.split('-').pop() as CountryCode;

        return getPhoneCode(countryCode) as string;
    }, [region]);
}
