import { RadioButtonGroupInput, required } from 'react-admin';
import { useWatch } from 'react-hook-form';

import { BinaryChoice, yesNoChoices } from 'constants/index';
import { InputHelperTextWithIcon } from 'modules/ui';

import type { Lane } from '../../types';

export const SameDayDeliveryRadioInput = () => {
    const { type } = useWatch<Lane>();
    if (type !== 'primary') {
        return null;
    }

    return (
        <RadioButtonGroupInput
            source="isSameDayDelivery"
            label="lanes.isSameDayDelivery"
            choices={yesNoChoices}
            validate={required()}
            parse={v => (v === BinaryChoice.YES ? true : false)}
            format={v => {
                if (typeof v === 'boolean') {
                    return v ? BinaryChoice.YES : BinaryChoice.NO;
                }
                return null;
            }}
            helperText={<InputHelperTextWithIcon text="lanes.isSameDayDelivery.description" />}
        />
    );
};
