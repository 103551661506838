import { useNotify } from 'react-admin';

import { useApiReducer } from 'hooks';
import type { paths } from 'api/adminApiSchema';
import type { ApiPrefix, RemoveApiPrefix } from 'types/api';
import { adminApiClient } from 'modules/auth/services/authApiClient';

type ParcelActionEndpoint<ParcelApiPath = RemoveApiPrefix<keyof paths>> =
    ParcelApiPath extends `/v1/parcel/{parcelId}:${string}` ? ParcelApiPath : never;

type ParcelApiParams<Endpoint extends ParcelActionEndpoint> = paths[`${ApiPrefix}${Endpoint}`]['post'] extends {
    requestBody: { content: { 'application/json': object } };
}
    ? paths[`${ApiPrefix}${Endpoint}`]['post']['requestBody']['content']['application/json']
    : undefined;

interface Options {
    successMsg: string;
    errorMsg?: string;
    onSuccess?: () => void;
}

export function useParcelApiAction<Endpoint extends ParcelActionEndpoint, Params extends ParcelApiParams<Endpoint>>(
    endpoint: Endpoint,
    parcelId?: string,
    options: Options = { successMsg: 'Parcel updated' },
) {
    const [apiState, dispatch] = useApiReducer();
    const notify = useNotify();

    const actionTrigger = async (params?: Params) => {
        if (!parcelId) {
            return;
        }

        try {
            dispatch({ type: 'request' });
            await adminApiClient.post(endpoint.replace('{parcelId}', parcelId), params);
            dispatch({ type: 'success' });
            notify(options.successMsg, { type: 'success' });

            options.onSuccess?.();
        } catch (error) {
            dispatch({ type: 'error', error: error as Error });
            notify(options.errorMsg ?? (error as Error).message, { type: 'error' });
        }
    };

    return [apiState, actionTrigger] as const;
}
