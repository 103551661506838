import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import type { ParcelsListFormValues } from '../ParcelsListFilters.types';

export interface EnforceDefaultValuesProps {
    defaultValues: ParcelsListFormValues;
}

export const EnforceDefaultValues = ({ defaultValues }: EnforceDefaultValuesProps) => {
    const form = useFormContext();
    const { setValue } = form;

    useEffect(() => {
        Object.entries(defaultValues).forEach(([key, value]) => {
            setValue(key, value ?? null);
        });
    }, [defaultValues, setValue]);

    return null;
};
