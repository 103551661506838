import { useTranslate } from 'react-admin';
import type { UseUpdateResult } from 'react-admin';
import type { FieldValues } from 'react-hook-form';

import type { components } from 'api/adminApiSchema';
import { ApiErrorCode, createUIErrorMessage, isHttpError } from 'modules/errors';
import { ResourceKey } from 'constants/index';

import type { Lane } from '../types';

interface MutationOptions {
    onSuccess?: () => void;
    onError?: (error: unknown) => void;
}

type MutationMethod = UseUpdateResult[0];

type StopNotFromLaneRegionError = { body: components['schemas']['EA4802'] };
type StopParentLocationMismatchError = { body: components['schemas']['EA4803'] };
type StopAssignedToMorePrimaryLanes = { body: components['schemas']['EA4805'] };

const isStopFromLaneRegionError = (error: unknown): error is StopNotFromLaneRegionError =>
    isHttpError(error) && error.body?.code === ApiErrorCode.STOP_NOT_FROM_LANE_REGIONS;

const isStopParentLocationMismatchError = (error: unknown): error is StopParentLocationMismatchError =>
    isHttpError(error) && error.body?.code === ApiErrorCode.STOP_PARENT_LOCATION_MISMATCH;

const isStopAssignedToMorePrimaryLanesError = (error: unknown): error is StopAssignedToMorePrimaryLanes =>
    isHttpError(error) && error.body?.code === ApiErrorCode.STOP_ASSIGNED_TO_MORE_PRIMARY_LANES;

export const useHandleLaneSubmit = (mutationMethod: MutationMethod, mutationOptions: MutationOptions = {}) => {
    const translate = useTranslate();

    const handleSubmit = async (values: FieldValues) => {
        try {
            await mutationMethod(ResourceKey.LANES, { data: values, id: values.id }, { returnPromise: true });

            mutationOptions.onSuccess?.();
        } catch (error) {
            if (isStopFromLaneRegionError(error)) {
                const errorMessage = translate(createUIErrorMessage({ code: error.body.code }));

                return error.body.invalidStops?.reduce((errors: Record<string, string>, errorStopId: string) => {
                    if ((values as Lane).depot?.id === errorStopId) {
                        return {
                            ...errors,
                            'depot.id': errorMessage,
                        };
                    }

                    const stopIndex = (values as Lane).stops?.findIndex(stop => stop.id === errorStopId) ?? -1;

                    if (stopIndex > -1) {
                        return {
                            ...errors,
                            [`stops.${stopIndex}.id`]: errorMessage,
                        };
                    }

                    return errors;
                }, {});
            }

            if (isStopParentLocationMismatchError(error) || isStopAssignedToMorePrimaryLanesError(error)) {
                const errorMessage = translate(createUIErrorMessage({ code: error.body.code }));

                return error.body.locations?.reduce((errors: Record<string, string>, errorStopId: string) => {
                    const stopIndex = (values as Lane).stops?.findIndex(stop => stop.id === errorStopId) ?? -1;

                    if (stopIndex > -1) {
                        return {
                            ...errors,
                            [`stops.${stopIndex}.id`]: errorMessage,
                        };
                    }

                    return errors;
                }, {});
            }

            mutationOptions.onError?.(error);
        }
    };

    return handleSubmit;
};
