import { EditButton } from 'react-admin';
import { Typography } from '@mui/material';

import type { AppConfigurationByGroup } from 'modules/app-configuration/types';
import { ResourceKey } from 'constants/index';

import * as Styled from './ConfigurationByGroup.styles';

export interface ConfigurationByGroupProps extends AppConfigurationByGroup {}

export const ConfigurationByGroup = ({ id, region, name, type, value, description }: ConfigurationByGroupProps) => {
    const getValue = () => {
        if (type === 'string') {
            return value as string;
        }
        return JSON.stringify(value);
    };

    return (
        <Styled.Container>
            <Styled.Side>
                <Typography variant="subtitle1">
                    {name}: {getValue()}
                </Typography>
                <Typography variant="subtitle2" color="#3f3f3f">
                    {description}
                </Typography>
            </Styled.Side>
            <EditButton label="action.edit" record={{ id }} resource={ResourceKey.APP_CONFIGURATION} />
        </Styled.Container>
    );
};
