import { fetchUtils } from 'react-admin';
import restProvider from 'ra-data-simple-rest';

import config from 'config';
import { APP_INFO_HEADER_NAME, APP_INFO, ApiVersion } from 'constants/index';
import getAccessToken from 'modules/auth/services/getAccessToken';
import { userRegionStorage } from 'modules/localization/services/userRegionStorage';

export const httpClient: typeof fetchUtils.fetchJson = async (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }

    const accessToken = await getAccessToken();
    const headers = options.headers as Headers;

    headers.set('Authorization', `Bearer ${accessToken}`);
    headers.set(APP_INFO_HEADER_NAME, APP_INFO);

    const userRegionId = userRegionStorage.get();

    if (userRegionId) {
        headers.set('X-User-Region', userRegionId);
    }

    return fetchUtils.fetchJson(url, options);
};

export const getApiProvider = (version: ApiVersion = ApiVersion.V1) =>
    restProvider(`${config.adminApi.url}/${version}`, httpClient, 'X-Total-Count');
