import { Typography } from '@mui/material';

import { useTranslate } from 'hooks';

interface PartnerReturnLocationsLabelProps {
    translationKey:
        | 'partners.returns.apm.usage.title'
        | 'partners.returns.apm.title'
        | 'partners.returns.warehouse.title';
}

export const PartnerReturnLocationsLabel = ({ translationKey }: PartnerReturnLocationsLabelProps) => {
    const translate = useTranslate();

    return (
        <Typography variant="subtitle2" color={'grey.700'} fontWeight={600}>
            {translate(translationKey)}
        </Typography>
    );
};
