import { isEqual } from 'lodash';

import { useEffect, useState } from 'react';
import { useRecordContext } from 'react-admin';

import type { Parcel } from 'modules/parcels/types';
import type { ParcelHistory } from 'api/types';

import { useFetchFullParcelHistory } from './useFetchFullParcelHistory';

const createParcelHistoryItem = (item: ParcelHistory[number], index: number) => ({
    ...item,
    id: index,
});

interface ParcelHistoryHooks {
    onFullHistoryEmpty: () => void;
    onFullHistoryLoaded: () => void;
}

export function useParcelHistory({ onFullHistoryEmpty, onFullHistoryLoaded }: ParcelHistoryHooks) {
    const resource = useRecordContext<Parcel>();
    const [history, setHistory] = useState<ParcelHistory>(() => []);

    useEffect(() => {
        if (Array.isArray(resource.history)) {
            setHistory(resource.history.map(createParcelHistoryItem));
        }
    }, [resource.history]);

    const [request, fetchFullHistory] = useFetchFullParcelHistory(resource.id, {
        onSuccess: (fullHistory: ParcelHistory) => {
            const nextHistory = fullHistory.map(createParcelHistoryItem);

            if (isEqual(history, nextHistory)) {
                onFullHistoryEmpty();
            } else {
                setHistory(nextHistory);
                onFullHistoryLoaded();
            }
        },
    });

    return {
        data: history,
        state: request,
        showFullHistory: fetchFullHistory,
        hideFullHistory: () => {
            setHistory((resource.history || []).map(createParcelHistoryItem));
        },
    } as const;
}
