import { useSimpleFormIterator } from 'react-admin';
import { useFormContext } from 'react-hook-form';

import { useWarehouseEdit } from './useWarehouseEdit';
import type { WarehouseData } from '../types';

export const usePartnerWarehouseDelete = (warehouseIndex: number, data: WarehouseData) => {
    const { remove } = useSimpleFormIterator();
    const [editedWarehouse, setEditedWarehouse] = useWarehouseEdit();
    const { setValue, getValues } = useFormContext();
    const defaultWarehouse = getValues()?.returnLocation;

    return function handleDelete() {
        remove(warehouseIndex);
        if (defaultWarehouse?.locationId === data.id) {
            setValue('returnLocation', null);
        }
        if (editedWarehouse !== null && warehouseIndex < editedWarehouse) {
            setEditedWarehouse(editedWarehouse - 1);
        }
    };
};
