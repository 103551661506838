import { AutocompleteInput } from 'react-admin';
import { Typography } from '@mui/material';
import { useWatch } from 'react-hook-form';

import { useTranslate } from 'hooks';

import { ReturnLocationOption } from '../../ReturnLocationSearch/ReturnLocationOption';

export const PartnerDefaultWarehouseEditable = () => {
    const translate = useTranslate();
    const watch = useWatch();

    return watch?.warehouses?.length ? (
        <AutocompleteInput
            label={translate('partners.returns.warehouse.choose')}
            source="returnLocation"
            choices={watch?.warehouses}
            optionText={<ReturnLocationOption />}
            matchSuggestion={ReturnLocationOption.matchSuggestion}
            inputText={ReturnLocationOption.inputText}
            noOptionsText={translate('partners.returns.warehouse.choose.noOptions')}
            format={value => value?.locationId}
            parse={value =>
                value
                    ? {
                          locationId: value,
                      }
                    : null
            }
        />
    ) : (
        <Typography mt={1} color={'grey.600'}>
            {translate('partners.returns.warehouse.notSelected')}
        </Typography>
    );
};
