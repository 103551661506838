import { Confirm, useRecordContext, useRefresh, useTranslate } from 'react-admin';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { ResourceKey } from 'constants/index';
import { useConfirmDialog } from 'hooks';
import { Button } from 'modules/ui';
import { useHasAccess } from 'modules/auth';
import { PermissionAction } from 'modules/roles';
import type { Parcel } from 'modules/parcels/types';

import useEditPaymentState from './hooks/useEditPaymentState';

const EditPaymentStateButton = () => {
    const translate = useTranslate();
    const refresh = useRefresh();
    const { id, payment }: Parcel = useRecordContext();

    const [confirmOpen, openConfirm, closeConfirm] = useConfirmDialog();

    const onPaymentStateChangeSuccess = () => {
        closeConfirm();
        refresh();
    };
    const { inProgress, trigger } = useEditPaymentState('transferred-to-partner', id, onPaymentStateChangeSuccess);

    const userHasPermission = useHasAccess(ResourceKey.PARCELS, PermissionAction.EDIT_PAYMENT);
    const paidByCustomer = payment.state === 'paid-by-customer';

    if (!userHasPermission || !paidByCustomer) {
        return null;
    }

    const buttonLabel = translate('parcel.detail.payment.transferred');

    return (
        <>
            <Button color="primary" type="button" size="small" onClick={openConfirm} loading={inProgress}>
                {buttonLabel}
            </Button>
            <Confirm
                isOpen={confirmOpen}
                loading={inProgress}
                title={translate(`parcel.detail.payment.change.title`)}
                content={translate(`parcel.detail.payment.change.text`)}
                confirm={buttonLabel}
                cancel={translate(`general.cancel`)}
                CancelIcon={ArrowBackIcon}
                onConfirm={trigger}
                onClose={closeConfirm}
            />
        </>
    );
};

export default EditPaymentStateButton;
