import { useCountry } from '../../hooks';

export interface CountryProps {
    countryCode: string;
}

export const Country = ({ countryCode }: CountryProps) => {
    const getCountryName = useCountry();

    return <>{getCountryName(countryCode)}</>;
};
