import type { FC, SVGAttributes } from 'react';
import { CZ, BG, GR, HR, CY } from 'country-flag-icons/react/3x2';
import { Box } from '@mui/material';

import { Country } from '../../../constants';

const countryFlag: Record<string, FC<SVGAttributes<SVGElement>>> = {
    [Country.BG]: BG,
    [Country.CY]: CY,
    [Country.CZ]: CZ,
    [Country.GR]: GR,
    [Country.HR]: HR,
};

interface RegionFlagProps {
    locale: string;
}

export const RegionFlag = ({ locale }: RegionFlagProps) => {
    const localeLower = locale?.toLowerCase();

    const Flag = localeLower && countryFlag[localeLower] ? countryFlag[localeLower] : null;

    return Flag ? (
        <Box width={30} height={20} mr={0.5}>
            <Flag />
        </Box>
    ) : null;
};
