import type { FC } from 'react';

import { ArrayInput, SimpleFormIterator, useTranslate, required } from 'react-admin';

// import { ResourceKey } from 'constants/index';
import { AddButton } from 'modules/ui';

import type { ExtendedLocation } from '../../../types';
import Stop from '../Stop';
const requiredValidator = required('lanes.stops.error.empty');

interface StopsListProps {
    stops?: ExtendedLocation[];
}

const StopsList: FC<StopsListProps> = () => {
    const translate = useTranslate();

    return (
        <ArrayInput source="stops" /* reference={ResourceKey.LOCATION} */>
            <SimpleFormIterator addButton={<AddButton label="lanes.stops.add" />} fullWidth>
                <Stop source="id" label={translate('lanes.stops.next')} validate={requiredValidator} perPage={50} />
            </SimpleFormIterator>
        </ArrayInput>
    );
};

export default StopsList;
