import { useTranslate, FunctionField } from 'react-admin';
import type { LabeledProps } from 'react-admin';

import type { Apm } from '../../types';

export interface ApmStateProps extends Pick<LabeledProps, 'label' | 'source'> {
    source: keyof Apm;
}

export const ApmState = ({ source }: ApmStateProps) => {
    const translate = useTranslate();

    return (
        <FunctionField<Apm>
            source={source}
            render={record => {
                const state = source !== undefined && record?.[source as keyof Apm];

                return state ? translate(`apm.state.${state}`) : null;
            }}
        />
    );
};

ApmState.defaultProps = {
    hasLabel: true,
};
