import { isMessageKey } from 'modules/localization';

const isErrorInstance = (error: unknown): error is Error => {
    return typeof error === 'object' && error instanceof Error;
};

const isString = (error: unknown): error is string => {
    return typeof error === 'string';
};

export const getTitle = (error: unknown) => {
    if (isString(error) && isMessageKey(error)) return error;
    if (isErrorInstance(error) && isMessageKey(error.message)) return error.message;

    return 'ra.page.error';
};

export const getDescription = (error: unknown) => {
    if (isString(error) && isMessageKey(`${error}.description`)) return `${error}.description`;
    if (isErrorInstance(error) && isMessageKey(`${error.message}.description`)) return `${error.message}.description`;

    return 'ra.message.auth_error';
};
