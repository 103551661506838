import { Datagrid as RADatagrid } from 'react-admin';

import { styled, Paper, Typography } from '@mui/material';

export const Card = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2, 0),
    marginTop: theme.spacing(3),
}));

export const Title = styled(Typography)(({ theme }) => ({
    padding: theme.spacing(0, 2),
    marginBottom: theme.spacing(1),
}));

export const DataGrid = styled(RADatagrid)(({ theme }) => ({
    '& th': {
        fontSize: '0.75rem',
        color: theme.palette.text.secondary,
    },
}));

export const DataGridFooter = styled('footer')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(2),
}));
