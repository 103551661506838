import { Paper, Typography, styled } from '@mui/material';

export const Title = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(1),
})) as typeof Typography;

export const ContactCard = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],
    height: '100%',
}));

export const Note = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(2),
})) as typeof Typography;
