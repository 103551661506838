import type { CountryCallingCode } from 'libphonenumber-js';

import { Country, PHONE_PREFIXES_BY_COUNTRY, PHONE_PREFIXES_BY_DOMAIN } from '../constants';
import { getDomainFromHostname } from './getDomainFromHostname';

const defaultPhonePrefixCountry = Country.GR;

export const getPhonePrefixFromHostname = (hostname: string = document.location.hostname): CountryCallingCode => {
    try {
        const domain = getDomainFromHostname(hostname);

        if (!domain) {
            return PHONE_PREFIXES_BY_COUNTRY[defaultPhonePrefixCountry];
        }

        return PHONE_PREFIXES_BY_DOMAIN[domain];
    } catch (err) {
        return PHONE_PREFIXES_BY_COUNTRY[defaultPhonePrefixCountry];
    }
};
