import { RadioButtonGroupInput } from 'react-admin';
import type { RadioButtonGroupInputProps } from 'react-admin';

import { BinaryChoice, yesNoChoices } from 'constants/index';
import type { ChoiceNames } from 'modules/ui/types';

export interface YesNoInputProps extends Pick<RadioButtonGroupInputProps, 'source' | 'label'> {
    defaultValue?: typeof BinaryChoice.YES | typeof BinaryChoice.NO;
    choiceNames?: ChoiceNames;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const YesNoInput = ({ defaultValue, choiceNames, ...props }: YesNoInputProps) => {
    const choices = yesNoChoices.map(choice => ({
        ...choice,
        name: choiceNames?.[choice.id] ?? choice.name,
    }));

    return (
        <RadioButtonGroupInput
            {...props}
            /**
             * RadioButtonGroupInput doesn't support boolean as a value so we need to parse & format it from string.
             *  My guess is that RA developers wanted BooleanInput to be used for two state inputs but it's not so
             *  convinient for us in this particular case.
             */
            defaultValue={defaultValue === BinaryChoice.YES}
            choices={choices}
            format={formValue => (formValue === true ? BinaryChoice.YES : BinaryChoice.NO)}
            parse={inputValue => (inputValue === BinaryChoice.YES ? true : false)}
            onChange={props.onChange}
        />
    );
};
