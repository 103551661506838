import { Box, Checkbox, FormControl, FormHelperText, FormLabel, InputAdornment, TextField } from '@mui/material';

import { omit } from 'lodash';

import { InputHelperText, composeValidators, minLength, number, required, useInput } from 'react-admin';

import { useTranslate } from 'hooks';
import { useRegionPhonePrefix } from 'modules/phone-number/hooks';

const prefixRequired = composeValidators(required('error.prefixForExactMatchRequired'), number());

export const ParcelCustomerPhoneInputGroup = () => {
    const translate = useTranslate();

    const exactMatchInput = useInput<boolean | null>({
        source: 'customerPhone.exactMatch',
        defaultValue: true,
    });
    const exactMatchValue = exactMatchInput.field.value;

    const regionPhonePrefix = useRegionPhonePrefix();
    const prefixInput = useInput<string | null>({
        defaultValue: regionPhonePrefix,
        source: 'customerPhone.prefix',
        validate(value, values) {
            const error = prefixRequired(value, values, {});

            return exactMatchValue ? error : undefined;
        },
    });

    const numberInput = useInput<string | null>({
        source: 'customerPhone.number',
        validate: [number(), minLength(4)],
    });

    const error = prefixInput.fieldState.error || numberInput.fieldState.error;
    const isTouched = prefixInput.fieldState.isTouched || numberInput.fieldState.isTouched;
    const showError = Boolean(error?.message && isTouched);

    return (
        <Box display="flex" flexDirection="column">
            <FormControl error={showError}>
                <Box display={'grid'} gap={1} gridTemplateColumns={'72px 1fr'}>
                    <TextField
                        inputProps={prefixInput.field}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">+</InputAdornment>,
                        }}
                        margin="none"
                        label={translate('parcels.filters.customer.phone.prefix')}
                        error={Boolean(prefixInput.fieldState.error && isTouched)}
                    />
                    <TextField
                        inputProps={numberInput.field}
                        margin="none"
                        label={translate('parcels.filters.customer.phone.number')}
                        error={Boolean(numberInput.fieldState.error && isTouched)}
                    />
                </Box>

                {showError && (
                    <FormHelperText>
                        <InputHelperText error={error?.message} touched={isTouched} />
                    </FormHelperText>
                )}
            </FormControl>
            <FormLabel>
                <Checkbox
                    inputProps={omit(exactMatchInput.field, 'onChange')}
                    onChange={() => {
                        const nextExactMatchValue = !exactMatchValue;
                        exactMatchInput.field.onChange(nextExactMatchValue);

                        if (nextExactMatchValue && !prefixInput.field.value) {
                            prefixInput.field.onChange(regionPhonePrefix);
                        }
                    }}
                    checked={exactMatchInput.field.value}
                />
                {translate('parcels.filters.customer.phone.exact')}
            </FormLabel>
        </Box>
    );
};
