import {
    useTranslate,
    SaveButton,
    SimpleForm,
    useRecordContext,
    required,
    NumberInput,
    useNotify,
    useRedirect,
} from 'react-admin';
import type { FC } from 'react';
import { Typography, Toolbar, Box } from '@mui/material';

import { TextInput, YesNoInput } from 'modules/ui';
import type { AppConfiguration } from 'modules/app-configuration/types';
import { usePatchUpdate } from 'hooks';
import { ResourceKey } from 'constants/index';
import { PermissionAction } from 'modules/roles';
import { useServerValidationErrors } from 'modules/errors';

const requiredValidator = required();

const inputByType: Record<AppConfiguration['type'], FC<any>> = {
    boolean: YesNoInput,
    number: NumberInput,
    string: TextInput,
};

export const AppConfigurationForm = () => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirect = useRedirect();
    const onFailure = useServerValidationErrors();

    const { id, groupId, name, description, type } = useRecordContext<AppConfiguration>();

    const Input = inputByType[type];

    const [updateData, { isLoading: isSaving }] = usePatchUpdate(
        ResourceKey.APP_CONFIGURATION,
        { id },
        {
            onSuccess: () => {
                redirect(PermissionAction.LIST, ResourceKey.APP_CONFIGURATION);
                notify('appConfiguration.edit.success', {
                    type: 'success',
                });
            },
            onError: onFailure,
            mutationMode: 'pessimistic',
        },
    );

    return (
        <SimpleForm
            toolbar={
                <Toolbar>
                    <Box display="flex" justifyContent="space-between" width="100%">
                        <SaveButton disabled={isSaving} />
                    </Box>
                </Toolbar>
            }
            onSubmit={({ value }) => {
                updateData(undefined, { data: { value, type } });
            }}
        >
            <Typography variant="h6" fontWeight={600}>
                {groupId}
            </Typography>

            <Typography variant="subtitle1">{name}</Typography>

            <Typography variant="subtitle2" color="#3f3f3f">
                {description}
            </Typography>

            <Input source="value" label={translate('appConfiguration.value')} validate={requiredValidator} />

            <Typography variant="body2">{translate('appConfiguration.edit.note')}</Typography>
        </SimpleForm>
    );
};
