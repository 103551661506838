import { useListContext } from 'react-admin';
import { useMemo } from 'react';

import { useDownloadSlaSettings } from './useDownloadSlaSettings';
import { useUploadSlaSettings } from './useUploadSlaSettings';
import type { SlaSettings } from '../types';

export const useSlaSettings = (type: SlaSettings['name']) => {
    const { data, isLoading } = useListContext<SlaSettings>();

    const slaSettings = useMemo(() => data?.find(d => d.name === type), [data, type]);

    const { download, downloadState } = useDownloadSlaSettings(slaSettings);
    const { upload, uploadState, validationErrors } = useUploadSlaSettings();

    return { slaSettings, isLoading, download, downloadState, upload, uploadState, validationErrors };
};
