import { Helmet } from 'react-helmet-async';

import config from 'config';

export interface SeoHeadProps {
    title?: string;
}

const SeoHead = ({ title }: SeoHeadProps) => {
    const fullTitle = title ? `${title} | ${config.appName}` : config.appName;

    return (
        <Helmet>
            <title>{fullTitle}</title>
        </Helmet>
    );
};

export default SeoHead;
