import config, { logger } from 'config';
import type { paths as apiPaths } from 'api/apiSchema';
import type { paths as adminApiPaths } from 'api/adminApiSchema';

import { Role } from 'modules/roles';

import { apiClient } from 'services/apiClient';

import { credentialsStorage, userStorage } from './authStorage';

type ApiEndpoint = apiPaths['/api/v1/auth-sessions']['post'] | adminApiPaths['/api/v1/auth-sessions']['post'];
type RequestPayload = ApiEndpoint['requestBody']['content']['application/json'];
type ResponseBody = ApiEndpoint['responses']['200']['content']['application/json'];

const allowedRoles = [
    Role.SUPER_ADMIN,
    Role.MANAGER,
    Role.OPERATIONS_MANAGER,
    Role.SALES_MANAGER,
    Role.SALES_AND_OPERATION_MANAGER,
    Role.NETWORK_AND_SALES_MANAGER,
    Role.NETWORK_MANAGER,
    Role.CUSTOMER_CARE,
];

export default async (payload: RequestPayload) => {
    const { user, credentials } = await apiClient.post<RequestPayload, ResponseBody>(
        config.api.endpoints.login,
        payload,
    );
    const role = user?.role as Role;

    if (!user || !role || !allowedRoles.includes(role)) {
        throw new Error('error.api.403');
    }

    if (!credentials?.accessToken) {
        logger.error('Missing credentials on the server response');
        throw new Error('error.api.500');
    }

    userStorage.save(user);
    credentialsStorage.save(credentials);
};
