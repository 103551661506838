import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

import { useTranslate } from 'hooks';
import { Button } from 'modules/ui';

import { useSyncLockerModal } from '../hooks';
import { SyncLockerModalContent } from './SyncLockerModalContent';

export interface SyncLockerModalProps {
    open: boolean;
    handleClose: () => void;
}

export const SyncLockerModal = ({ open, handleClose }: SyncLockerModalProps) => {
    const translate = useTranslate();
    const { onCancel, apiState, onSubmit, syncLockerApiError } = useSyncLockerModal({ handleClose });

    return (
        <Dialog open={open} onClose={onCancel} maxWidth="xs" fullWidth>
            <DialogTitle>
                {apiState.loading || apiState.error || apiState.success
                    ? translate('apm.syncLocker.dialog.progress.title')
                    : translate('apm.syncLocker.dialog.title')}
            </DialogTitle>
            <DialogContent>
                <SyncLockerModalContent apiState={apiState} syncLockerApiError={syncLockerApiError} />
            </DialogContent>
            {!apiState.loading && !apiState.success && (
                <DialogActions>
                    <Button onClick={onCancel}>{translate('general.cancel')}</Button>
                    <Button variant="contained" type="submit" onClick={onSubmit}>
                        {apiState.error ? translate('apm.syncLocker.dialog.button.retry') : translate('action.confirm')}
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
};
