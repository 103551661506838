import { useCallback } from 'react';

import type { Region } from '../types';
import { useUserRegion } from './userRegion';

export const useCurrentRegionValidator = () => {
    const [region] = useUserRegion();

    return useCallback(
        (value: Region['id'][]) => {
            if (region && !value?.includes(region.id)) {
                return { message: 'error.currentRegion.selected', args: { name: region.name } };
            }

            return undefined;
        },
        [region],
    );
};
