import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import { Admin } from 'modules/application';
import { authProvider } from 'modules/auth';
import { i18nProvider } from 'modules/localization';
import { dataProvider } from 'services/dataProvider';
import { queryClient } from 'services/queryClient';
import { store } from 'services/store';
import theme from 'styles/theme';

const App = () => {
    return (
        <ThemeProvider theme={createTheme(theme)}>
            <SentryErrorBoundary>
                <HelmetProvider>
                    <BrowserRouter>
                        <Admin
                            dataProvider={dataProvider}
                            authProvider={authProvider}
                            i18nProvider={i18nProvider}
                            queryClient={queryClient}
                            store={store}
                        />
                    </BrowserRouter>
                </HelmetProvider>
            </SentryErrorBoundary>
        </ThemeProvider>
    );
};

export default App;
