import {
    Show,
    TextField,
    ArrayField,
    ReferenceField,
    Datagrid,
    TopToolbar,
    FunctionField,
    NumberField,
    Labeled,
    SelectField,
} from 'react-admin';
import type { ShowProps } from 'react-admin';
import { Typography } from '@mui/material';
import { BoxedShowLayout, RaBox } from 'ra-compact-ui';

import { ResourceKey } from 'constants/index';
import { RegionsList, SeoHead, ConditionalField } from 'modules/ui';

import type { Lane } from 'modules/lanes/types';
import { useTranslate } from 'hooks';

import { LaneEditButton } from '../LaneEditButton';

export const LaneDetail = (props: ShowProps) => {
    const translate = useTranslate();

    return (
        <>
            <SeoHead title={translate('lanes.list.title')} />
            <Show
                actions={
                    <TopToolbar>
                        <LaneEditButton />
                    </TopToolbar>
                }
            >
                <BoxedShowLayout>
                    <RaBox mb={2}>
                        <TextField source="name" label={translate('lanes.name')} />
                        <RegionsList source="regions" label={translate('lanes.regions')} />
                        <TextField source="description" label={translate('lanes.description')} />
                        <FunctionField<Lane>
                            label={translate('lanes.type')}
                            render={lane => (lane ? translate(`lanes.type.${lane.type}`) : null)}
                        />
                        <ConditionalField<Lane> shouldRender={lane => lane?.type === 'primary'}>
                            <Labeled>
                                <NumberField
                                    source="apmAvailabilityCoefficient"
                                    label={translate('lanes.apmAvailabilityCoefficient')}
                                    options={{
                                        minimumFractionDigits: 2,
                                    }}
                                />
                            </Labeled>
                        </ConditionalField>
                        <ConditionalField<Lane> shouldRender={lane => lane?.type === 'primary'}>
                            <Labeled>
                                <SelectField
                                    source="isSameDayDelivery"
                                    label="lanes.isSameDayDelivery"
                                    choices={[
                                        { id: true, name: 'general.yes' },
                                        { id: false, name: 'general.no' },
                                    ]}
                                />
                            </Labeled>
                        </ConditionalField>
                    </RaBox>
                    <RaBox>
                        <Typography variant="h6" component="h3">
                            {translate('lanes.routes.title')}
                        </Typography>

                        <ReferenceField
                            source="depot.id"
                            label={translate('lanes.depot')}
                            reference={ResourceKey.LOCATION}
                            link={false}
                        >
                            <TextField source="name" />
                        </ReferenceField>

                        <ArrayField source="stops">
                            <Datagrid bulkActionButtons={false} sx={{ display: 'inline-block' }}>
                                <TextField source="locationType" />
                                <TextField source="name" />
                                <TextField source="street" />
                                <TextField source="city" />
                            </Datagrid>
                        </ArrayField>
                    </RaBox>
                </BoxedShowLayout>
            </Show>
        </>
    );
};
