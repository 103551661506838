import { useCallback, useState, useMemo } from 'react';
import type { ReactNode } from 'react';

import { logger } from 'config';

import { userRegionStorage } from '../../services/userRegionStorage';
import { useAvailableRegions } from '../../hooks/useAvailableRegions';
import { UserRegionContext } from '../../hooks/userRegion';
import type { Region } from '../../types';

export interface UserRegionProviderProps {
    children: ReactNode;
}

export const UserRegionProvider = ({ children }: UserRegionProviderProps) => {
    const regions = useAvailableRegions();
    const [region, setRegion] = useState<Region | null>(null);

    const setUserRegion = useCallback(
        (regionId: Region['id'] | null) => {
            if (regionId === null) {
                setRegion(null);
                userRegionStorage.delete();
            } else {
                const nextRegion = regions.isSuccess ? regions.data.find(({ id }) => id === regionId) : null;

                if (!nextRegion) {
                    logger.error('Selected region is not in the regions list, cannot change');
                    return;
                }

                setRegion(nextRegion);
                userRegionStorage.save(regionId);
            }
        },
        [regions],
    );

    const userRegionState = useMemo(() => [region, setUserRegion] as const, [region, setUserRegion]);

    return <UserRegionContext.Provider value={userRegionState}>{children}</UserRegionContext.Provider>;
};
