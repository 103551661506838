import { Stack, Typography } from '@mui/material';
import { FileField, FileInput, Form, FormDataConsumer } from 'react-admin';
import UploadFileIcon from '@mui/icons-material/UploadFile';

import { type ApiReducerState, useTranslate } from 'hooks';
import { Button } from 'modules/ui';
import type { FileInputValue } from 'types';

import type { SlaSettings } from '../../../types';

export interface UploadCsvFormProps {
    type: SlaSettings['name'];
    upload: (file: FileInputValue, type: SlaSettings['name']) => void;
    uploadState: ApiReducerState;
}

export const UploadCsvForm = ({ upload, type, uploadState }: UploadCsvFormProps) => {
    const translate = useTranslate();

    return (
        <>
            <Stack direction="row" gap={4} flexWrap="wrap">
                <div>
                    <Typography variant="subtitle2" mb={4}>
                        {translate('slaSettings.csv.template.start')}
                        <a href={translate(`slaSettings.csv.template.${type}.link`)} target="_blank" rel="noreferrer">
                            {translate('slaSettings.csv.template.end')}
                        </a>
                    </Typography>

                    <Form onSubmit={async values => await upload(values.file, type)}>
                        <Stack sx={{ minWidth: { md: '40rem' } }}>
                            <FileInput
                                source="file"
                                label="slaSettings.csv.import.label"
                                placeholder={translate('slaSettings.csv.import.instructions')}
                                accept="text/csv"
                                sx={{ maxWidth: '50rem' }}
                            >
                                <FileField source="src" title="title" />
                            </FileInput>

                            <FormDataConsumer<{ file: FileInputValue }>>
                                {({ formData: { file } }) => (
                                    <Button
                                        variant="contained"
                                        disabled={!Boolean(file)}
                                        type="submit"
                                        sx={{ width: 'fit-content' }}
                                        loading={uploadState.loading}
                                        startIcon={<UploadFileIcon />}
                                    >
                                        {translate('slaSettings.csv.import.button')}
                                    </Button>
                                )}
                            </FormDataConsumer>
                        </Stack>
                    </Form>
                </div>

                <Stack>
                    <Typography variant="subtitle1">{translate('slaSettings.csv.cheatSheet.title')}</Typography>
                    <Typography variant="body2" whiteSpace="pre-wrap">
                        <span
                            dangerouslySetInnerHTML={{
                                __html: translate(`slaSettings.csv.cheatSheet.${type}.properties`),
                            }}
                        ></span>
                    </Typography>
                </Stack>
            </Stack>
        </>
    );
};
