import type { FC } from 'react';
import { Box } from '@mui/material';

import { AddWarehouse } from '../AddWarehouse';
import { Warehouse } from '../Warehouse';
import { useWarehouseEdit } from '../../hooks';

import * as Styled from './EditableWarehousesList.styles';

export interface EditableWarehousesListProps {}

export const EditableWarehousesList: FC<EditableWarehousesListProps> = () => {
    const [editedWarehouse] = useWarehouseEdit();
    const disableAdd = editedWarehouse !== null;

    return (
        <Box sx={{ maxWidth: 370 }}>
            <Styled.ArrayInput source="warehouses" label="">
                <Styled.SimpleFormIterator
                    addButton={<AddWarehouse />}
                    disableReordering
                    disableRemove
                    disableAdd={disableAdd}
                >
                    <Warehouse />
                </Styled.SimpleFormIterator>
            </Styled.ArrayInput>
        </Box>
    );
};
