import { ArrayInput, SimpleFormIterator, FormDataConsumer, useTranslate } from 'react-admin';

import { Typography } from '@mui/material';

import { ApmEditProbability } from '../ApmEditProbability';
import { ApmCompartment } from './ApmCompartment';
import * as Styled from './ApmEditCompartments.styles';

const ApmEditCompartments = ({ showAll, compartmentsLimit }: { showAll: boolean; compartmentsLimit: number }) => {
    const translate = useTranslate();

    return (
        <>
            <Typography variant="h6" component="h3" mt={4}>
                {translate('apm.edit.compartments')}
            </Typography>
            <ApmEditProbability mt={3} mb={1} />
            <Styled.Div>
                <ArrayInput source="compartments" label={''}>
                    <SimpleFormIterator disableAdd disableRemove getItemLabel={() => ''} disableReordering fullWidth>
                        <FormDataConsumer>
                            {({ getSource, scopedFormData }) => {
                                if (
                                    !getSource ||
                                    !scopedFormData ||
                                    (scopedFormData.compartmentNumber > compartmentsLimit && !showAll)
                                ) {
                                    return null;
                                }

                                return (
                                    // @ts-expect-error
                                    <ApmCompartment
                                        getSource={getSource}
                                        storedParcel={scopedFormData.storedParcel}
                                        compartmentNumber={scopedFormData.compartmentNumber}
                                    />
                                );
                            }}
                        </FormDataConsumer>
                    </SimpleFormIterator>
                </ArrayInput>
            </Styled.Div>
        </>
    );
};

export default ApmEditCompartments;
