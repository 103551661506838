import { useRecordContext } from 'react-admin';

import type { Parcel } from 'modules/parcels/types';

import * as Styled from './ParcelLabels.styles';
import { ParcelLabelButton } from '../ParcelLabelButton';

export const ParcelLabels = () => {
    const record = useRecordContext() as Parcel;
    const labelUrl = record?.labelUrl;
    const destinationLabelUrl = record?.destinationLabelUrl;
    return (
        <Styled.Container>
            {destinationLabelUrl ? (
                <ParcelLabelButton url={destinationLabelUrl} label="parcel.detail.getThirdPartyLabel" />
            ) : null}
            <ParcelLabelButton url={labelUrl} label="parcel.detail.getLabel" />
        </Styled.Container>
    );
};
