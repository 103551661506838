import { useContext } from 'react';
import { SimpleFormIteratorContext } from 'react-admin';

import type { WarehouseData } from '../types';
import { useWarehouseEdit } from './useWarehouseEdit';

const emptyWarehouse = {
    name: '',
    identifier: '',
    street: '',
    city: '',
    postalCode: '',
    deleted: false,
    country: '',
    coordinates: {
        lat: '',
        lng: '',
    } as unknown as WarehouseData['coordinates'],
    depotId: '',
    shared: false,
};

export const useAddWarehouse = () => {
    const context = useContext(SimpleFormIteratorContext);
    const [, setEditedWarehouse] = useWarehouseEdit();

    const addWarehouse = (item?: WarehouseData) => {
        if (item) {
            // types error - add actually has an item argument in the implementation inside `SimpleFormIterator`
            // @ts-expect-error
            context.add(item);
        } else {
            const newWarehouseIndex = context.total;
            // @ts-expect-error
            context.add(emptyWarehouse);
            setEditedWarehouse(newWarehouseIndex);
        }
    };

    return { addWarehouse };
};
