import { TextInput } from 'react-admin';
import { styled } from '@mui/material';

const noBottomBorder = {
    borderBottom: 0,
};

export const ContractRegionInput = styled(TextInput)(({ theme }) => ({
    width: 'auto',

    '& .MuiInputBase-root': {
        background: 'transparent',

        '&:before': noBottomBorder,
        '&:hover:before': noBottomBorder,
        '&:focus:before': noBottomBorder,
        '&:after': noBottomBorder,
        '&:hover:after': noBottomBorder,
        '&:focus:after': noBottomBorder,
    },
    '& .MuiFormLabel-root': {
        color: theme.palette.text.secondary,
        transform: 'translate(0, 4px) scale(0.75)',
    },
    '& .MuiInputBase-input': {
        color: theme.palette.text.primary,
        textFillColor: theme.palette.text.primary,

        paddingLeft: 0,
    },
    '& .MuiFormHelperText-root': {
        color: theme.palette.text.primary,
        opacity: 0.85,
        marginLeft: theme.spacing(0.5),

        '& svg': {
            marginRight: theme.spacing(0.5),
        },
    },
}));

export const InputNote = styled('span')(() => ({
    display: 'inline-flex',
    lineHeight: '24px',
}));
