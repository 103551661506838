import { useTranslate, useRefresh } from 'react-admin';
import { useState } from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@mui/material';

import { useUserRegion } from '../../hooks/userRegion';
import type { Region } from '../../types';

interface RegionSelectDialogProps {
    visible: boolean;
    onClose: () => void;
    currentRegionId: Region['id'];
    regions: Region[];
}

export const RegionSelectDialog = ({ visible, onClose, currentRegionId, regions }: RegionSelectDialogProps) => {
    const translate = useTranslate();
    const refresh = useRefresh();

    const [, setRegion] = useUserRegion();

    const [selectedRegion, changeSelectedRegion] = useState(currentRegionId);

    return (
        <Dialog open={visible} onClose={onClose} maxWidth="xs" fullWidth>
            <DialogTitle>{translate('regions.dialog.title')}</DialogTitle>
            <DialogContent>
                <RadioGroup value={selectedRegion} name="region" onChange={e => changeSelectedRegion(e.target.value)}>
                    {regions.map(region => (
                        <FormControlLabel key={region.id} value={region.id} control={<Radio />} label={region.name} />
                    ))}
                </RadioGroup>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{translate('general.cancel')}</Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        if (selectedRegion && selectedRegion !== currentRegionId) {
                            setRegion(selectedRegion);
                            onClose();
                            refresh();
                        }
                    }}
                >
                    {translate('action.confirm')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
