import { useTranslate, useRecordContext } from 'react-admin';
import { Grid, TextField as MuiTextField } from '@mui/material';

import React from 'react';

import { ResourceKey } from 'constants/index';
import { Button } from 'modules/ui';

import type { Parcel } from 'modules/parcels/types';

import { useUpdateParcelNote } from '../hooks/useUpdateParcelNote';

export interface ParcelNoteFormProps {
    onCancel: () => void;
    onSubmitSucceeded: () => void;
}

export const ParcelNoteForm = ({ onCancel, onSubmitSucceeded }: ParcelNoteFormProps) => {
    const translate = useTranslate();

    const record: Parcel = useRecordContext({ resource: ResourceKey.PARCELS });
    const [inputValue, setInputValue] = React.useState<string>(record.note || '');
    const [updateParcelNote, { isLoading }] = useUpdateParcelNote(inputValue, { onSuccess: onSubmitSucceeded });

    return (
        <>
            <MuiTextField
                value={inputValue}
                name="note"
                placeholder={translate('parcel.note.empty')}
                multiline={true}
                size="medium"
                minRows={3}
                variant="outlined"
                sx={{ width: '100%', mb: 2 }}
                onChange={e => setInputValue(e.target.value)}
            />
            <Grid container alignItems="center" spacing={2}>
                <Grid item>
                    <Button
                        type="button"
                        onClick={onCancel}
                        color="inherit"
                        size="medium"
                        variant="contained"
                        disabled={isLoading}
                    >
                        {translate('parcel.note.cancel')}
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        type="button"
                        onClick={() => updateParcelNote()}
                        size="medium"
                        variant="contained"
                        disabled={isLoading}
                    >
                        {translate('parcel.note.submit')}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};
