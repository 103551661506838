import { ArrayField, Datagrid, ShowButton, TextField, useTranslate } from 'react-admin';
import { Typography } from '@mui/material';

import { ResourceKey } from 'constants/index';

export const ConnectedPartnersListField = () => {
    const translate = useTranslate();

    return (
        <ArrayField source="partners">
            <Datagrid
                isRowSelectable={() => false}
                bulkActionButtons={false}
                empty={<Typography variant="body1">{translate('warehouse.partners.empty')}</Typography>}
            >
                <TextField source="name" />
                <TextField source="email" />

                <ShowButton resource={ResourceKey.PARTNER} label="apm.partners.detail" />
            </Datagrid>
        </ArrayField>
    );
};
