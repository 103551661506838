import { userStorage } from './authStorage';

export default () => {
    const user = userStorage.get();

    if (!user) {
        return Promise.reject({ message: 'Unauthenticated' });
    }

    return Promise.resolve({
        id: user.id,
        fullName: user.name,
    });
};
