import { Labeled, useTranslate } from 'react-admin';
import { Typography } from '@mui/material';

import type { components } from 'api/adminApiSchema';

import { Country } from '../Country';

export interface AddressProps {
    address: Omit<components['schemas']['Location'], 'coordinates'>;
}

export const Address = ({ address }: AddressProps) => {
    const translate = useTranslate();

    return (
        <Labeled label={translate('address.address')}>
            <Typography variant="subtitle2">
                {address.street}
                <br />
                {address.postalCode}, {address.city}
                <br />
                <Country countryCode={address.country} />
                <br />
            </Typography>
        </Labeled>
    );
};
