import { Menu, MenuItem, Stack, Typography } from '@mui/material';
import { useState } from 'react';


import InventoryOutlined from '@mui/icons-material/InventoryOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useTranslate } from 'hooks';

import { Button } from 'modules/ui';

import { useMarkParcelAsMenuItems } from './hooks';
import { MarkParcelAsConfirmDialog } from './MarkParcelAsConfirmDialog';
import type { MarkParcelAsType } from './types';

export const MarkParcelAsDropdown = () => {
    const translate = useTranslate();
    const menuItems = useMarkParcelAsMenuItems();

    const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
    const [dialogType, setDialogType] = useState<MarkParcelAsType | null>(null);

    const menuOpen = Boolean(menuAnchor);

    const handleDialogClose = () => {
        setDialogType(null);
        setMenuAnchor(null);
    };

    return (
        <>
            <Button
                variant="contained"
                type="button"
                size="medium"
                onClick={e => setMenuAnchor(e.currentTarget)}
                startIcon={<InventoryOutlined />}
                endIcon={<ExpandMoreIcon sx={{ rotate: menuOpen ? '180deg' : '0deg' }} />}
            >
                {translate('parcel.detail.markAs.button')}
            </Button>
            <Menu
                anchorEl={menuAnchor}
                open={menuOpen}
                onClose={() => setMenuAnchor(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                MenuListProps={{ sx: { minWidth: '14rem' } }}
            >
                {menuItems.map(item => (
                    <MenuItem
                        disabled={item.disabled}
                        onClick={() => {
                            setDialogType(item.type);
                        }}
                        key={item.type}
                    >
                        <Stack>
                            {item.title}
                            {item.warning && <Typography variant="caption">{item.warning}</Typography>}
                        </Stack>
                    </MenuItem>
                ))}
            </Menu>
            {dialogType && <MarkParcelAsConfirmDialog onClose={handleDialogClose} type={dialogType} />}
        </>
    );
};
