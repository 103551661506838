import { useAuthState, useNotify } from 'react-admin';
import { useQuery } from 'react-query';

import { adminApiClient } from 'modules/auth/services/authApiClient';
import { ApiVersion, ResourceKey } from 'constants/index';
import { logger } from 'config';

import type { Region } from '../types';

export const useAvailableRegions = () => {
    const authState = useAuthState();
    const notify = useNotify();

    const regions = useQuery<Region[], Error, Region[]>(
        ResourceKey.REGION,
        async () => adminApiClient.get(`/${ApiVersion.V1}/${ResourceKey.REGION}`),
        {
            staleTime: 1000 * 60 * 15,
            enabled: authState.authenticated && !authState.isLoading,
            onError: error => {
                logger.error(error);
                notify('error.api.cannotFetchRegions', { type: 'error' });
            },
        },
    );

    return regions;
};
