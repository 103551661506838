import { useTranslate } from 'react-admin';
import { useState } from 'react';
import { Select, MenuItem, InputLabel } from '@mui/material';
import type { SelectChangeEvent } from '@mui/material';

import type { Permissions, User } from 'modules/users/types';
import { Button } from 'modules/ui';

import { useUpdateUserPermissions } from '../../../../hooks/useUpdateUserPermissions';

type Permission = Permissions['canFinishStopWithoutApproval'];

const options = ['never', 'temporary', 'always'] as Permission[];

const oneHourFromNow = new Date();
oneHourFromNow.setHours(oneHourFromNow.getHours() + 1);

export interface IncompleteStopFormProps {
    user: User;
}

export const IncompleteStopForm = ({ user }: IncompleteStopFormProps) => {
    const translate = useTranslate();

    const initial = user.permissions?.canFinishStopWithoutApproval;
    const canFinishStopWithoutApprovalUntil = user.permissions?.canFinishStopWithoutApprovalUntil;

    const [canFinishStopWithoutApproval, setCanFinishStopWithoutApproval] = useState(initial);
    const { api, updateUserPermissions } = useUpdateUserPermissions(user.id);

    const temporaryDate = canFinishStopWithoutApprovalUntil
        ? new Date(Date.parse(canFinishStopWithoutApprovalUntil))
        : oneHourFromNow;
    const isTemporaryExpired =
        canFinishStopWithoutApprovalUntil && new Date(Date.parse(canFinishStopWithoutApprovalUntil)) < new Date();

    const onSubmit = () => {
        updateUserPermissions({ canFinishStopWithoutApproval });
    };

    const formatOption = (option: Permission) => {
        if (option !== 'temporary') {
            return translate(`users.permissions.incompleteStop.option.${option}`);
        }

        if (isTemporaryExpired) {
            return translate('users.permissions.incompleteStop.option.temporary.expired', {
                date: temporaryDate.toLocaleString(),
            });
        }

        return translate('users.permissions.incompleteStop.option.temporary', {
            time: temporaryDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
        });
    };

    return (
        <>
            <InputLabel htmlFor="canFinishStopWithoutApprovalSelect">
                {translate('users.permissions.incompleteStop.label')}
            </InputLabel>

            <Select
                id="canFinishStopWithoutApprovalSelect"
                value={canFinishStopWithoutApproval}
                onChange={(event: SelectChangeEvent) =>
                    setCanFinishStopWithoutApproval(event.target.value as Permission)
                }
                sx={{ minWidth: 200, mt: 1, mr: 1 }}
            >
                {options.map(option => (
                    <MenuItem key={option} value={option}>
                        {formatOption(option)}
                    </MenuItem>
                ))}
            </Select>

            <Button onClick={onSubmit} disabled={api.loading || canFinishStopWithoutApproval === initial}>
                {api.loading ? translate('users.permissions.loading') : translate('users.permissions.confirm')}
            </Button>
        </>
    );
};
