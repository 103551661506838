import { Create, useNotify, useRedirect } from 'react-admin';
import type { CreateProps } from 'react-admin';

import { useServerValidationErrors } from 'modules/errors';
import { ResourceKey } from 'constants/index';

import { WarehouseForm } from '../WarehouseForm';
import { WarehouseTitle } from '../WarehouseTitle';
import { WarehouseSeoHead } from '../WarehouseSeoHead';

export interface WarehouseCreateProps extends CreateProps {}

export const WarehouseCreate = (props: WarehouseCreateProps) => {
    const notify = useNotify();
    const redirect = useRedirect();
    const onFailure = useServerValidationErrors();

    const onSuccess = () => {
        notify('warehouse.create.created');
        redirect('list', ResourceKey.WAREHOUSE);
    };

    return (
        <>
            <WarehouseSeoHead />
            <Create title={<WarehouseTitle />} {...props} mutationOptions={{ onSuccess, onError: onFailure }}>
                <WarehouseForm />
            </Create>
        </>
    );
};
