import { styled, Typography } from '@mui/material';

import { Logo as UnstyledLogo } from 'modules/ui';

export const Logo = styled(UnstyledLogo)(({ theme }) => ({
    color: '#fff',
    marginRight: theme.spacing(4),
}));

export const Title = styled(Typography)(() => ({
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
}));

export const Spacer = styled('span')(() => ({
    flex: 1,
}));
