import { useTranslate, useRecordContext, TextField, Labeled } from 'react-admin';
import { Dialog, DialogContent, DialogTitle, DialogActions, Typography } from '@mui/material';

import { Button } from 'modules/ui';
import type { ApmCompartment } from 'modules/apm/types';
import { useOpenCompartment } from 'modules/apm/hooks';

export interface ConfirmOpeningDialogProps {
    visible: boolean;
    onClose: () => void;
    apmId: string;
}

export const ConfirmOpeningDialog = ({ apmId, visible, onClose }: ConfirmOpeningDialogProps) => {
    const translate = useTranslate();
    const record = useRecordContext<ApmCompartment>();
    const [{ loading }, trigger] = useOpenCompartment(apmId, record?.compartmentNumber, {
        successMsg: 'apm.detail.confirmOpen.success',
        failureMsg: 'apm.detail.confirmOpen.failure',
    });

    if (!record) {
        return null;
    }

    const { compartmentNumber } = record;

    return (
        <Dialog open={visible} onClose={onClose} maxWidth="xs" fullWidth>
            <DialogTitle>{translate('apm.detail.confirmOpen.title', { compartmentNumber })}</DialogTitle>
            <DialogContent>
                <Typography variant="body2">{translate('apm.detail.confirmOpen.description')}</Typography>
                <Labeled mt={2} label={translate('apm.edit.compartments.storedParcel')}>
                    <TextField
                        source="storedParcel.parcelNumber"
                        emptyText={translate('apm.edit.compartments.storedParcel.empty')}
                    />
                </Labeled>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={loading}>
                    {translate('general.cancel')}
                </Button>
                <Button
                    variant="contained"
                    onClick={async () => {
                        await trigger();
                        onClose();
                    }}
                    disabled={loading}
                    loading={loading}
                >
                    {translate('action.open')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
