import { init } from '@sentry/react';

import config from 'config/config';
import { currentEnv } from 'constants/index';

if (config.sentry.enable) {
    init({
        dsn: config.sentry.dsn,
        debug: false,
        environment: currentEnv,
        release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`,
        normalizeDepth: 5,
    });
}
