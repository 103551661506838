import type { FC } from 'react';
import type { OnSuccess } from 'react-admin';
import { Edit, useTranslate, useNotify, useRedirect } from 'react-admin';

import { ResourceKey } from 'constants/index';
import { SeoHead } from 'modules/ui';
import { useServerValidationErrors } from 'modules/errors';

import type { ApmDetail } from 'modules/apm/types';

import ApmEditTitle from '../ApmEditTitle';

import { ApmEditForm } from '../ApmEditForm';
import { parseProbability } from '../ApmEditProbability/ApmEditProbability.utils';

interface ApmDetailWithMeta extends ApmDetail {
    meta?: {
        lockerDeactivationFailed?: boolean;
    };
}

const ApmEdit: FC = props => {
    const translate = useTranslate();

    const notify = useNotify();
    const redirect = useRedirect();
    const onFailure = useServerValidationErrors();

    const onSuccess: OnSuccess = (response: ApmDetailWithMeta) => {
        if (response.meta?.lockerDeactivationFailed) {
            notify('apm.edit.savedButLockerBridgeFailed', { type: 'warning', autoHideDuration: 8000 });
        } else {
            notify('apm.edit.saved');
        }
        redirect('list', ResourceKey.APM);
    };

    return (
        <>
            <SeoHead title={translate('apm.list.title')} />
            <Edit<ApmDetail>
                title={<ApmEditTitle />}
                mutationOptions={{ onSuccess, onError: onFailure, meta: { isApmEditMutation: true } }}
                mutationMode="pessimistic"
                transform={(data: ApmDetail) => ({
                    ...data,
                    availabilityProbSmall: parseProbability(data.availabilityProbSmall),
                    availabilityProbMedium: parseProbability(data.availabilityProbMedium),
                    availabilityProbLarge: parseProbability(data.availabilityProbLarge),
                })}
            >
                <ApmEditForm />
            </Edit>
        </>
    );
};

export default ApmEdit;
