import { useCallback } from 'react';

import { useCountry } from '../../../hooks/useCountry';

export interface AddressRecord {
    street?: string;
    city?: string;
    country: string;
}

export const useComposeAddress = <Record extends AddressRecord>() => {
    const getCountryName = useCountry();

    const composeAddress = useCallback(
        (record: Record): string =>
            [record.street, record.city, getCountryName(record.country)].filter(Boolean).join(', '),
        [getCountryName],
    );

    return composeAddress;
};

export const useOptionWithAddress = <Record extends AddressRecord>(getText: (record: Record) => string) => {
    const composeAddress = useComposeAddress();

    const inputText = useCallback(
        (record: Record) => `${getText(record)}, ${composeAddress(record)}`,
        [composeAddress, getText],
    );

    const matchSuggestion = useCallback(
        (filterValue: string, suggestion?: Record) => {
            if (!suggestion) {
                return false;
            }

            return inputText(suggestion).toLowerCase().includes(filterValue.toLowerCase());
        },
        [inputText],
    );

    return {
        composeAddress,
        inputText,
        matchSuggestion,
    };
};
