import { required, SaveButton, SimpleForm, maxLength } from 'react-admin';
import type { RaRecord } from 'ra-core';
import type { FieldValues } from 'react-hook-form';
import { Toolbar, Box } from '@mui/material';

import { RegionsListInput, TextInput } from 'modules/ui';
import { DeleteButtonWithPermissions } from 'modules/auth';
import { useTranslate } from 'hooks';

import RoutePlan from '../RoutePlan';
import { LaneTypeRadioInput } from '../LaneTypeRadioInput';
import { AvailabilityCoefficientInput } from '../AvailabilityCoefficientInput';
import { SameDayDeliveryRadioInput } from '../SameDayDeliveryRadioInput';

const requiredValidator = required();

export interface LaneFormProps {
    saving?: boolean;
    record?: RaRecord;
    onSubmit?: (values: FieldValues) => Promise<any>;
}

const LaneForm = (props: LaneFormProps) => {
    const translate = useTranslate();

    return (
        <SimpleForm
            {...props}
            toolbar={
                <Toolbar>
                    <Box display="flex" justifyContent="space-between" width="100%">
                        <SaveButton />
                        <DeleteButtonWithPermissions />
                    </Box>
                </Toolbar>
            }
        >
            <TextInput source="name" label={translate('lanes.name')} validate={[requiredValidator, maxLength(10)]} />
            <RegionsListInput source="regions" label={translate('lanes.regions')} validate={requiredValidator} />
            <TextInput source="description" label={translate('lanes.description')} multiline minRows={3} />
            <LaneTypeRadioInput />
            <AvailabilityCoefficientInput />
            <SameDayDeliveryRadioInput />
            <RoutePlan />
        </SimpleForm>
    );
};

export default LaneForm;
