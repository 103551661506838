import { FunctionField, TextField, BooleanField } from 'react-admin';
import { RaBox } from 'ra-compact-ui';

import type { PartnerData } from '../../types';

import * as Styled from './WebhookInfo.styles';

export const WebhookInfo = () => {
    return (
        <Styled.Container>
            <RaBox>
                <TextField source="parcelEventChangeWebhook.url" label="partners.characteristics.webhook.url" />
                <BooleanField
                    label="partners.characteristics.webhook.activeLabel"
                    source="parcelEventChangeWebhook.active"
                />
                <FunctionField<PartnerData>
                    label="partners.characteristics.webhook.header"
                    render={field => {
                        const header = field?.parcelEventChangeWebhook?.header;

                        if (header && header.name) {
                            return `${header.name}: ${header.value}`;
                        }

                        return undefined;
                    }}
                />
            </RaBox>
        </Styled.Container>
    );
};
