import { Form, required } from 'react-admin';
import { Dialog, DialogContent, DialogTitle, DialogActions } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { Button, TextInput } from 'modules/ui';
import { useTranslate } from 'hooks';
import type { PartnerDetailUpdate } from 'modules/partners/types';

interface PartnerVipChangeDialogProps {
    open: boolean;
    onClose: () => void;
    previousVip: PartnerDetailUpdate['vip'];
    setPreviousVip: (vip: PartnerDetailUpdate['vip']) => void;
}

export const PartnerVipChangeDialog = ({ open, onClose, previousVip, setPreviousVip }: PartnerVipChangeDialogProps) => {
    const translate = useTranslate();
    const { setValue, getValues } = useFormContext();

    const handleCancel = () => {
        setValue('vip', previousVip);
        onClose();
    };

    return (
        <Dialog open={open}>
            <DialogTitle>{translate('partners.vipChange.title')}</DialogTitle>
            <Form
                onSubmit={formValues => {
                    setValue('vipChangeReason', formValues.vipChangeReason);
                    setPreviousVip(getValues().vip);
                    onClose();
                }}
            >
                <DialogContent>
                    <TextInput
                        source="vipChangeReason"
                        label={'partners.vipChange.label'}
                        multiline
                        minRows={3}
                        validate={required('partners.vipChange.required')}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel}>{translate('general.cancel')}</Button>
                    <Button variant="contained" type="submit">
                        {translate('partners.vipChange.confirm')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
};
