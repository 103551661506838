import { styled, Box } from '@mui/material';

import { LabeledField as UnstyledLabeledField } from 'modules/ui';

export const LabeledIdField = styled(UnstyledLabeledField)(({ theme }) => ({
    marginTop: theme.spacing(2),
})) as unknown as typeof UnstyledLabeledField;

export const Actions = styled(Box)(({ theme }) => ({
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
}));
