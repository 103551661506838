export enum HTTPStatusCode {
    BadRequest = 400,
    Unauthorized = 401,
    AccessDenied = 403,
    NotFound = 404,
    UnprocessableEntity = 422,
    InternalServerError = 500,
    ServiceUnavailable = 503,
}

export const ForcedLogoutCode = 'ForcedCMSLogout';
