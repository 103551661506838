import type { ParcelLocation } from '../../../types';

export const getIsRedirected = (newLocation: ParcelLocation, initialLocation?: ParcelLocation) => {
    if (!initialLocation) {
        return true;
    }

    if (newLocation.type !== initialLocation.type) {
        return true;
    }

    if ('id' in newLocation && 'id' in initialLocation && newLocation.type === 'location') {
        return newLocation.id !== initialLocation.id;
    }

    if (newLocation.type === 'address') {
        return (
            newLocation.street !== initialLocation.street ||
            newLocation.city !== initialLocation.city ||
            newLocation.postalCode !== initialLocation.postalCode ||
            newLocation.country !== initialLocation.country
        );
    }

    return false;
};
