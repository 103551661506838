import { DeleteButtonWithPermissions } from 'modules/auth';

import { useUserCanManageLane } from '../../hooks';

export const LaneDeleteButton = () => {
    const userCanDeleteLane = useUserCanManageLane();

    if (!userCanDeleteLane) {
        return null;
    }

    return <DeleteButtonWithPermissions />;
};
