import { Box, Typography, CircularProgress } from '@mui/material';

import SuccessIcon from '@mui/icons-material/CheckCircle';

import { useTranslate, type ApiReducerState } from 'hooks';
import type { SyncLockerApiError } from 'modules/apm/hooks';
import { ApiErrorCode } from 'modules/errors';

interface SyncLockerModalContentProps {
    apiState: ApiReducerState;
    syncLockerApiError: SyncLockerApiError | null;
}
export const SyncLockerModalContent = ({ apiState, syncLockerApiError }: SyncLockerModalContentProps) => {
    const translate = useTranslate();

    if (apiState.success) {
        return (
            <Box>
                <Typography>{translate('apm.syncLocker.success')}</Typography>
                <Box display="flex" justifyContent="center" my={3}>
                    <SuccessIcon color="success" fontSize="large" />
                </Box>
            </Box>
        );
    }

    if (apiState.loading) {
        return (
            <Box>
                <Typography>{translate('apm.syncLocker.dialog.loading.description')}</Typography>
                <Box display="flex" justifyContent="center" my={3}>
                    <CircularProgress size={20} />
                </Box>
            </Box>
        );
    }

    if (apiState.error && syncLockerApiError?.code === ApiErrorCode.SYNC_LOCKER_COMPARTMENT_DELETION) {
        return (
            <Box>
                <Typography>{translate('apm.syncLocker.failure.notEmptyCompartments')}</Typography>
                <Typography>
                    {translate('apm.syncLocker.failure.compartmentNumbers', {
                        compartmentNumbers: (syncLockerApiError.compartmentNumbers ?? []).join(', '),
                    })}
                </Typography>
            </Box>
        );
    }

    if (apiState.error && syncLockerApiError?.code === ApiErrorCode.SYNC_LOCKER_EXISTING_COMPARTMENT_UPDATE) {
        return (
            <Box>
                <Typography>{translate('apm.syncLocker.failure.compartmentsToBeUpdated')}</Typography>
                <Typography>Compartment numbers: {(syncLockerApiError.compartmentNumbers ?? []).join(', ')}</Typography>
            </Box>
        );
    }

    if (apiState.error) {
        return <Typography>{translate('apm.syncLocker.failure')}</Typography>;
    }

    return (
        <Box>
            <Typography>{translate('apm.syncLocker.dialog.description')}</Typography>
            <Typography>{translate('apm.syncLocker.dialog.description.bottom')}</Typography>
        </Box>
    );
};
