import type { FC } from 'react';
import { Datagrid, FunctionField, NumberField, TextField } from 'react-admin';

import { ResourceKey } from 'constants/index';
import { EditButtonWithPermissions, ListWithPermissions, useHasAccess } from 'modules/auth';
import { PermissionAction } from 'modules/roles';
import { LocationField, SeoHead, UUIDField } from 'modules/ui';
import { Pagination } from 'modules/pagination';

import type { Apm } from 'modules/apm/types';

import { useTranslate } from 'hooks';

import { ApmAvailabilityWarning } from '../ApmAvailabilityWarning';
import { ApmState } from '../ApmState';

import { formatProbability } from '../ApmEditProbability/ApmEditProbability.utils';
import { ApmListFilters } from '../ApmListFilters';

const ApmList: FC = props => {
    const translate = useTranslate();
    const hasDetailAccess = useHasAccess(ResourceKey.APM, PermissionAction.DETAIL);
    return (
        <>
            <SeoHead title={translate('apm.list.title')} />

            <ListWithPermissions
                {...props}
                title={translate('apm.list.title')}
                pagination={<Pagination />}
                actions={<ApmListFilters />}
            >
                <Datagrid rowClick={hasDetailAccess ? 'show' : undefined} bulkActionButtons={false}>
                    <UUIDField source="uuid" label={translate('apm.list.uuid')} />
                    <TextField source="name" label={translate('apm.list.name')} />
                    <LocationField source="location" label={translate('apm.list.address')} sortable={false} />
                    <TextField source="identifier" label={translate('apm.list.identifier')} />
                    <ApmState source="state" label={translate('apm.list.status')} />
                    <NumberField source="compartmentsEmptyCount" label={translate('apm.list.compartmentsEmptyCount')} />
                    <NumberField
                        source="compartmentsLargeEmptyCount"
                        label={translate('apm.list.compartmentsLargeEmptyCount')}
                    />
                    <FunctionField<Apm>
                        source="parcelsLargeProbability"
                        label={translate('apm.list.parcelsLargeProbability')}
                        render={record => {
                            const value = formatProbability(record?.parcelsLargeProbability);

                            return value ? `${value}%` : '–';
                        }}
                    />
                    <NumberField source="laneCount" label={translate('apm.list.lanes')} />
                    <NumberField
                        source="compartmentsBlockedCount"
                        label={translate('apm.list.compartmentsBlockedCount')}
                    />
                    <EditButtonWithPermissions />
                    <ApmAvailabilityWarning compact />
                </Datagrid>
            </ListWithPermissions>
        </>
    );
};

export default ApmList;
