import { styled } from '@mui/material';
import Card from '@mui/material/Card';

import type { ParcelSectionProps } from './ParcelSection';

export const ParcelSectionCard = styled(Card, { shouldForwardProp: propName => propName !== 'gridArea' })<
    Pick<ParcelSectionProps, 'gridArea'>
>(({ gridArea }) => ({
    gridArea,
}));
