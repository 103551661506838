import { Typography } from '@mui/material';

import { useTranslate } from 'hooks';

import type { ParcelAddressBoxProps } from '../ParcelAddressBox';

export interface DeliveryParcelIdSectionProps extends Pick<ParcelAddressBoxProps, 'deliveryParcelId'> {}

export const DeliveryParcelIdSection = ({ deliveryParcelId }: DeliveryParcelIdSectionProps) => {
    const translate = useTranslate();

    return (
        <>
            <Typography variant="subtitle1">{translate(`parcel.delivery.parcelId`)}</Typography>
            {deliveryParcelId && (
                <Typography variant="subtitle2" gutterBottom>
                    {deliveryParcelId}
                </Typography>
            )}
        </>
    );
};
