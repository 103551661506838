import { Box, Badge } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { useTranslate } from 'hooks';
import { Button } from 'modules/ui';

import { useActiveFiltersCount } from '../hooks/useActiveFiltersCount';

interface ParcelsListFiltersControlsProps {
    expanded: boolean;
    toggleExpanded: () => void;
    resetFilters: (form: ReturnType<typeof useFormContext>) => void;
}
export const ParcelsListFiltersControls = ({
    expanded,
    toggleExpanded,
    resetFilters,
}: ParcelsListFiltersControlsProps) => {
    const translate = useTranslate();
    const form = useFormContext();
    const { activeExpandedFiltersCount } = useActiveFiltersCount();

    return (
        <Box display="flex" justifyContent="space-between" alignItems="center" gap={2} marginBottom={1.5}>
            <Badge badgeContent={activeExpandedFiltersCount} color="primary">
                <Button type="button" variant="text" onClick={() => toggleExpanded()}>
                    {translate(expanded ? 'general.filters.collapse' : 'general.filters.expand')}
                </Button>
            </Badge>
            <Box display="flex" columnGap={1.5} alignItems="center">
                <div>
                    <Button
                        type="reset"
                        variant="text"
                        onClick={() => {
                            resetFilters(form);
                        }}
                    >
                        {translate('action.reset')}
                    </Button>
                </div>
                <div>
                    <Button type="submit" variant="contained" size="large" translate="yes">
                        {translate('action.apply')}
                    </Button>
                </div>
            </Box>
        </Box>
    );
};
