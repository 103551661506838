import { Show } from 'react-admin';

import ParcelDetailLayout from '../ParcelDetailLayout';

export const ParcelDetail = () => {
    /**
     * Refetching on window focus causes useless data refetch which also breaks editaion forms.
     * That's probably cauased by non-standard view screen with togglable inline forms
     */
    return (
        <Show queryOptions={{ refetchOnWindowFocus: false }}>
            <ParcelDetailLayout />
        </Show>
    );
};
