import { useNotify, useRefresh } from 'react-admin';
import { useState } from 'react';

import { useApiReducer } from 'hooks';
import { adminApiClient } from 'modules/auth/services/authApiClient';
import { composeUrl } from 'services/dataProvider/utils';
import { ResourceKey } from 'constants/index';
import type { components } from 'api/adminApiSchema';
import type { FileInputValue } from 'types';
import { logger } from 'config';

import type { CsvValidationErrors, SlaSettings } from '../types';

export const useUploadSlaSettings = () => {
    const refresh = useRefresh();
    const notify = useNotify();

    const [uploadState, dispatchUploadAction] = useApiReducer();
    const [validationErrors, setValidationErrors] = useState<CsvValidationErrors | null>(null);

    const upload = async (file: FileInputValue, type: SlaSettings['name']) => {
        if (!file?.rawFile) return;

        try {
            dispatchUploadAction({ type: 'request' });

            const formData = new FormData();
            formData.append('file', file.rawFile);
            formData.append('name', type);

            await adminApiClient.post(
                composeUrl(ResourceKey.SLA_SETTINGS, 'create', { includeApiBaseUrl: false }),
                formData,
                {
                    rawError: true,
                },
            );

            refresh();

            notify('slaSettings.csv.import.success', { type: 'success' });
            dispatchUploadAction({ type: 'success' });
        } catch (error) {
            if (isA4907Error(error)) {
                setValidationErrors({ fileName: file.title, errors: error.errors });
            } else {
                notify('slaSettings.csv.import.error', { type: 'error' });
                logger.error(error, "Couldn't upload ETA/SLA settings CSV file");
            }
            dispatchUploadAction({ type: 'error', error: error as Error });
        }
    };

    return { upload, uploadState, validationErrors };
};

const isA4907Error = (error: any): error is components['schemas']['EA4907'] => {
    if ('code' in error && error.code === 'A4907') {
        return true;
    }
    return false;
};
