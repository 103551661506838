import { Domain } from '../constants';

export const getDomainFromHostname = (hostname: string = document.location.hostname): Domain | null => {
    const [topLevelDomain] = hostname.split('.').slice(-1);

    const domain = Object.values(Domain).find(domain => domain === topLevelDomain);

    if (!domain) {
        return null;
    }

    return domain;
};
