import { Box, Card, CardContent, CircularProgress, Typography } from '@mui/material';

import { useTranslate } from 'hooks';

import type { SlaSettings } from '../../../types';
import { useSlaSettings } from '../../../hooks';
import { CsvFileDetails } from '../CsvFileDetails';
import { UploadCsvForm } from '../UploadCsvForm';
import { CsvValidationErrorsModal } from '../CsvValidationErrorsModal';

export interface SlaSettingsCardProps {
    type: SlaSettings['name'];
}

export const SlaSettingsCard = ({ type }: SlaSettingsCardProps) => {
    const translate = useTranslate();
    const { isLoading, slaSettings, download, downloadState, upload, uploadState, validationErrors } =
        useSlaSettings(type);

    return (
        <>
            <Card>
                <CardContent>
                    <Typography variant="h5">{translate(`slaSettings.type.${type}`)}</Typography>
                    {isLoading ? (
                        <Box display="flex" justifyContent="center">
                            <CircularProgress />
                        </Box>
                    ) : (
                        <>
                            {slaSettings ? (
                                <CsvFileDetails
                                    slaSettings={slaSettings}
                                    download={download}
                                    downloadState={downloadState}
                                />
                            ) : (
                                <UploadCsvForm type={type} upload={upload} uploadState={uploadState} />
                            )}
                        </>
                    )}
                </CardContent>
            </Card>
            <CsvValidationErrorsModal validationErrors={validationErrors} />
        </>
    );
};
