import { useTranslate } from 'react-admin';
import { Box, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { Button } from 'modules/ui';
import { useConfirmDialog } from 'hooks';

import type { CsvInfo } from '../../../hooks/useCodPaymentsImport';
import { useFinishCodPaymentsImport } from '../../../hooks/useCodPaymentsImport';

import * as Styled from './FinishParcelsImport.styles';

export interface FinishParcelsImportProps {
    onImportSuccess: () => void;
    onCancel: () => void;
    csvInfo: CsvInfo;
    file?: File;
}

export const FinishParcelsImport = ({
    onImportSuccess,
    onCancel: onCancelImport,
    csvInfo,
    file,
}: FinishParcelsImportProps) => {
    const translate = useTranslate();

    const [confirmOpen, openConfirm, closeConfirm] = useConfirmDialog();
    const { api, finishImport } = useFinishCodPaymentsImport(file, {
        onSuccess: onImportSuccess,
    });

    return (
        <>
            <Styled.Grid>
                <Box>
                    <Typography variant="h6">{csvInfo.parcels?.length ?? '-'}</Typography>
                    <Typography variant="body1">{translate('parcel.paymentImport.import.parcels')}</Typography>
                </Box>
                <Box>
                    <Typography variant="h6">
                        {csvInfo.price.amount} {csvInfo.price.currency}
                    </Typography>
                    <Typography variant="body1">{translate('parcel.paymentImport.import.totalValue')}</Typography>
                </Box>

                {api.error && (
                    <Styled.Error mt={2} mb={1}>
                        {translate('parcel.paymentImport.import.finish.failure')}: <br />
                        {typeof api.error === 'string' ? translate(api.error) : api.error.toString()}
                    </Styled.Error>
                )}

                <Button
                    size="medium"
                    variant="contained"
                    onClick={() => finishImport(csvInfo.confirmationHash)}
                    loading={api.loading}
                >
                    {translate('parcel.paymentImport.import.confirm')}
                </Button>

                <Button size="medium" onClick={openConfirm} disabled={api.loading}>
                    {translate('parcel.paymentImport.import.cancel')}
                </Button>
            </Styled.Grid>

            <Styled.Confirm
                isOpen={confirmOpen}
                title={translate(`parcel.paymentImport.import.cancel.title`)}
                content={translate(`parcel.paymentImport.import.cancel.text`)}
                confirm={translate('parcel.paymentImport.import.cancel.confirm')}
                cancel={translate(`general.cancel`)}
                onConfirm={() => onCancelImport()}
                CancelIcon={ArrowBackIcon}
                confirmColor="primary"
                onClose={closeConfirm}
            />
        </>
    );
};
