import { useTranslate as raUseTranslate } from 'react-admin';

import type en from 'translations/en.json';

export type MessageKey = keyof typeof en;

export type Translate = (key: MessageKey, options?: any) => string;

export function useTranslate() {
    return raUseTranslate() as Translate;
}
