import { useChoicesContext } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { useCallback, useEffect, useRef } from 'react';

import { useTranslate } from 'hooks';
import type { ExtendedLocation } from 'modules/parcels/types';

import { ReturnApmLocationUsage } from '../../PartnerReturnLocations';

export const useReturnApmLocation = (setReturnApmLocation: (location: ExtendedLocation | null) => void) => {
    const translate = useTranslate();
    const { allChoices } = useChoicesContext();
    const { setValue } = useFormContext();

    const allChoicesRef = useRef(allChoices);

    useEffect(() => {
        allChoicesRef.current = allChoices;
    }, [allChoices]);

    const onSelect = useCallback(
        (id: string) => {
            const location = allChoicesRef.current.find(choice => choice.id === id);

            if (!location) {
                setReturnApmLocation(null);
                setValue('returnApmLocation.usage', null);
            } else {
                setValue('returnApmLocation.locationId', location.id);
                setValue('returnApmLocation.usage', ReturnApmLocationUsage.No);
                setReturnApmLocation(location);
            }
        },
        [setReturnApmLocation, setValue],
    );

    return { onSelect, translate };
};
