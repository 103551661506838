import { HTTPStatusCode, ForcedLogoutCode } from 'types';

export type UIMessageID = string;

export interface UIErrorMessages {
    code?: Record<string | number, UIMessageID> & { [ForcedLogoutCode]: UIMessageID };
    status?: Partial<Record<HTTPStatusCode, UIMessageID>>;
    fallbackId: UIMessageID;
}

export enum ApiErrorCode {
    STOP_NOT_FROM_LANE_REGIONS = 'A4802',
    STOP_PARENT_LOCATION_MISMATCH = 'A4803',
    CANNOT_CHANGE_LOCATION_PARENT = 'A4804',
    STOP_ASSIGNED_TO_MORE_PRIMARY_LANES = 'A4805',
    SYNC_LOCKER_COMPARTMENT_DELETION = 'A4806',
    SYNC_LOCKER_EXISTING_COMPARTMENT_UPDATE = 'A4807',
    NO_CMS_ACCOUNT = 'X404',
}

export const errorMessages = {
    code: {
        A4100: 'error.api.invalidPhone',
        A4101: 'error.api.invalidDepot',
        A4102: 'error.api.invalidRole',
        A4104: 'error.api.invalidRegionReference',
        A4105: 'error.invalidUrl',
        A4300: 'error.api.parcelNotReady',
        A4400: 'error.api.phoneTaken',
        A4401: 'error.api.identifierTaken',
        A4500: 'error.api.cancelNotPossible',
        A4600: 'error.api.extendingPickUpTimeFailed',
        A4700: 'error.api.invalidConfigValue',
        A4800: 'error.api.cannotResetParcelDirection',
        A4801: 'error.api.cannotDeleteUsedLane',
        [ApiErrorCode.STOP_NOT_FROM_LANE_REGIONS]: 'error.api.stopNotFromLaneRegion',
        [ApiErrorCode.STOP_PARENT_LOCATION_MISMATCH]: 'error.api.stopParentLocationMismatch',
        [ApiErrorCode.CANNOT_CHANGE_LOCATION_PARENT]: 'error.api.cannotChangeLocationParent',
        [ApiErrorCode.STOP_ASSIGNED_TO_MORE_PRIMARY_LANES]: 'error.api.stopAssignedToMorePrimaryLanes',
        A4900: 'error.api.parcelsFromRegion',
        A4901: 'error.api.parcelsPaymentState',
        A4903: 'error.api.oneParcelPerOrder',
        A4904: 'error.api.parcelIsCod',
        A4905: 'error.api.importConfirmationHashMismatch',
        A4906: 'error.api.sameRegionOnly',
        A6000: 'error.api.lockerCommunicationFailed',
        [ForcedLogoutCode]: 'error.api.forcedLogout',
        [ApiErrorCode.NO_CMS_ACCOUNT]: 'error.api.noCmsAccount',
    },
    status: {
        [HTTPStatusCode.BadRequest]: 'error.api.400',
        [HTTPStatusCode.Unauthorized]: 'error.api.401',
        [HTTPStatusCode.AccessDenied]: 'error.api.403',
        [HTTPStatusCode.NotFound]: 'error.api.404',
        [HTTPStatusCode.UnprocessableEntity]: 'error.api.422',
        [HTTPStatusCode.InternalServerError]: 'error.api.500',
        [HTTPStatusCode.ServiceUnavailable]: 'error.api.503',
    },
    fallbackId: 'error.api.general',
} as const satisfies Required<UIErrorMessages>;
