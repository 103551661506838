import React from 'react';
import { useTranslate, Confirm } from 'react-admin';

import { Button } from 'modules/ui';

import { useUpdateParcelNote } from '../hooks/useUpdateParcelNote';

export interface DeleteParcelNoteButtonProps {
    onSuccess: () => void;
}

export const DeleteParcelNoteButton = ({ onSuccess }: DeleteParcelNoteButtonProps) => {
    const translate = useTranslate();
    const [deleteParcelNote, { isLoading }] = useUpdateParcelNote(null, { onSuccess });
    const [confirmOpened, setConfirmOpened] = React.useState(false);

    return (
        <>
            <Button
                type="button"
                onClick={() => setConfirmOpened(true)}
                size="small"
                variant="contained"
                disabled={isLoading}
            >
                {translate('parcel.note.delete')}
            </Button>
            <Confirm
                isOpen={confirmOpened}
                loading={isLoading}
                title={translate('parcel.detail.delete.confirm.title')}
                content={translate('parcel.detail.delete.confirm.text')}
                onConfirm={() => {
                    deleteParcelNote();
                    setConfirmOpened(false);
                }}
                onClose={() => setConfirmOpened(false)}
            />
        </>
    );
};
