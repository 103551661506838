import { NumberInput, required, minValue, maxValue } from 'react-admin';
import type { NumberInputProps } from 'react-admin';

import { InputAdornment } from '@mui/material';

export interface RatioInputProps extends Pick<NumberInputProps, 'source' | 'label' | 'fullWidth' | 'defaultValue'> {}

export const RatioInput = ({ defaultValue = 0, ...props }: RatioInputProps) => (
    <NumberInput
        {...props}
        defaultValue={defaultValue}
        min={0}
        max={100}
        step={1}
        format={(v: number) => Number((v * 100).toFixed(2))}
        parse={(v: string) => parseFloat(v) / 100}
        validate={[required(), minValue(0), maxValue(100)]}
        InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
    />
);
