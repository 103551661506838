import { logger } from 'config';

import { credentialsStorage } from './authStorage';
import refresh from './refresh';

function isAccessTokenExpired(expiresAt?: string) {
    if (!expiresAt) {
        return true;
    }

    const expireDate = Date.parse(expiresAt);
    const isValidDate = !isNaN(expireDate);

    return isValidDate ? expireDate <= Date.now() : true;
}

export default async function getAccessToken(): Promise<string> {
    const credentials = credentialsStorage.get();

    if (!credentials || !credentials.accessToken || !credentials.refreshToken) {
        logger.error('Credentails are not complete or available');
        throw new Error('error.api.401');
    }

    if (isAccessTokenExpired(credentials.expiresAt)) {
        return refresh({ refreshToken: credentials.refreshToken });
    }

    return credentials.accessToken;
}
