import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { Box, Button, Typography, Grid } from '@mui/material';
import pick from 'lodash/pick';
import { Form, SaveButton, useRecordContext, useTranslate } from 'react-admin';

import type { Parcel } from '../../types';
import { DeliveryPartnerForm } from '../DeliveryPartnerForm';
import { OrderAddressContact } from '../OrderAddressContact';
import { AddressSection } from '../AddressSection';

export interface PickupAddressFormProps {
    saving: boolean;
    handleSubmit?: (values: Pick<Parcel, 'sender' | 'originDeliveryParcelId'>) => void;
    canEditContact: boolean;
    closeDrawer: () => void;
}

export const PickupAddressForm = ({ saving, handleSubmit, canEditContact, closeDrawer }: PickupAddressFormProps) => {
    const translate = useTranslate();
    const { addresses } = useRecordContext<Parcel>();

    return (
        <Box padding={3}>
            <Button color="primary" size="small" onClick={closeDrawer} startIcon={<ArrowBackIcon />} sx={{ mb: 3 }}>
                {translate(`general.back`)}
            </Button>

            <Typography variant="h6" component="h1">
                {translate('parcel.pickup.edit.title')}
            </Typography>

            <Grid container spacing={3} mt={1} mb={3}>
                <Grid item xs={12} sm={6}>
                    <Typography variant="caption">{translate('parcel.pickup.address.pickup')}</Typography>
                    {addresses?.fromLocation && (
                        <AddressSection location={addresses?.fromLocation} typographyVariant="body2" />
                    )}
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="caption">{translate('parcel.pickup.address.return')}</Typography>
                    {addresses?.returnLocation && (
                        <AddressSection location={addresses?.returnLocation} typographyVariant="body2" />
                    )}
                </Grid>
            </Grid>

            <Form
                onSubmit={values => {
                    handleSubmit?.(pick(values, ['sender', 'originDeliveryParcelId']));
                }}
            >
                {canEditContact && <OrderAddressContact sourcePrefix="sender" />}

                <DeliveryPartnerForm
                    locationName="fromLocation"
                    parcelIdSource="originDeliveryParcelId"
                    disablePartnerSelect
                />

                <Box display="flex" justifyContent="flex-end" width="100%">
                    <SaveButton disabled={saving} />
                </Box>
            </Form>
        </Box>
    );
};
