import type { FC } from 'react';
import type { RaRecord } from 'react-admin';
import { Datagrid, FunctionField, TextField, TextInput, useTranslate } from 'react-admin';

import { ResourceKey } from 'constants/index';
import {
    DeleteButtonWithPermissions,
    EditButtonWithPermissions,
    ListWithPermissions,
    useHasAccess,
} from 'modules/auth';
import { Pagination } from 'modules/pagination';
import { PermissionAction } from 'modules/roles';
import { SeoHead } from 'modules/ui';

import type { User } from '../../types';

const UsersList: FC = props => {
    const translate = useTranslate();
    const hasDetailAccess = useHasAccess(ResourceKey.USER, PermissionAction.DETAIL);

    return (
        <>
            <SeoHead title={translate('users.list.title')} />
            <ListWithPermissions
                {...props}
                title={translate('users.list.title')}
                filters={[<TextInput label={translate('users.list.search')} source="q" alwaysOn />]}
                pagination={<Pagination />}
            >
                <Datagrid rowClick={hasDetailAccess ? 'show' : undefined} bulkActionButtons={false}>
                    <TextField source="name" label={translate('users.name')} />
                    <TextField source="phoneNumber" label={translate('users.phone')} />
                    <FunctionField
                        source="role"
                        label={translate('users.role')}
                        render={(record: RaRecord | undefined) => {
                            const { role } = (record as User) ?? {};
                            return <>{role && translate(`role.${role}`)}</>;
                        }}
                    />
                    <TextField source="depotName" label={translate('users.depot')} />
                    <EditButtonWithPermissions />
                    <DeleteButtonWithPermissions mutationMode="pessimistic" />
                </Datagrid>
            </ListWithPermissions>
        </>
    );
};

export default UsersList;
