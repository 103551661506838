import { AutocompleteInput, ReferenceInput } from 'react-admin';
import { useMemo } from 'react';

import type { ReferenceInputProps } from 'react-admin';

import { ResourceKey } from 'constants/index';
import { LocationCard } from 'modules/parcels/components/ApmSearch/LocationCard';
import type { ExtendedLocation } from 'modules/parcels/types';

import { ReturnLocationOption } from './ReturnLocationOption';
import { useReturnApmLocation } from './hooks/useReturnApmLocation';

export interface ReturnLocationSearchProps
    extends Omit<ReferenceInputProps, 'reference' | 'children' | 'source' | 'filter'> {
    regionId?: string;
    setReturnApmLocation: (location: ExtendedLocation | null) => void;
}

export const ReturnLocationSearch = ({ source, type, validate, ...props }: ReturnLocationSearchProps) => {
    return (
        <>
            <ReferenceInput
                {...props}
                source="returnApmLocation.locationId"
                reference={ResourceKey.LOCATION}
                filter={{ type: 'apm', includeApmReference: true, regionId: props.regionId }}
                queryOptions={{
                    meta: { additionalFilters: { includeApmReference: true } },
                }}
                sort={{ field: 'id', order: 'ASC' }}
            >
                <>
                    <AutoCompleteReturnApmLocation
                        validate={validate}
                        setReturnApmLocation={props.setReturnApmLocation}
                    />
                    <LocationCard />
                </>
            </ReferenceInput>
        </>
    );
};

const AutoCompleteReturnApmLocation = ({
    validate,
    setReturnApmLocation,
}: {
    validate: any;
    setReturnApmLocation: (l: ExtendedLocation | null) => void;
}) => {
    const { onSelect, translate } = useReturnApmLocation(setReturnApmLocation);

    const memoizedAutoComplete = useMemo(
        () => (
            <AutocompleteInput
                label={'partners.returns.apm.choose'}
                optionText={<ReturnLocationOption />}
                inputText={ReturnLocationOption.inputText}
                noOptionsText={translate('partners.returns.apm.choose.noOptions')}
                matchSuggestion={ReturnLocationOption.matchSuggestion}
                fullWidth
                validate={validate}
                onChange={onSelect}
            />
        ),
        [onSelect, validate, translate],
    );

    return memoizedAutoComplete;
};
