import { useWatch } from 'react-hook-form';
import { NumberInput, minValue, required } from 'react-admin';

import type { Lane } from '../../types';

export const AvailabilityCoefficientInput = () => {
    const { type } = useWatch<Lane>();
    if (type !== 'primary') {
        return null;
    }

    return (
        <NumberInput
            source="apmAvailabilityCoefficient"
            label="lanes.apmAvailabilityCoefficient"
            helperText="lanes.apmAvailabilityCoefficient.description"
            step=".01"
            defaultValue={1.0}
            format={(value?: number) => (typeof value === 'number' ? value.toFixed(2) : value)}
            validate={[required(), minValue(0)]}
            sx={theme => ({
                maxWidth: theme.metrics.formInputBaseWidth,
                mt: 4,
                mb: 2,
            })}
        />
    );
};
