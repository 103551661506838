import { useRecordContext } from 'react-admin';
import { useMemo } from 'react';

import { useTranslate } from 'hooks';

import { useHasAccess } from 'modules/auth';
import { ResourceKey } from 'constants/index';
import { PermissionAction } from 'modules/roles';

import type { Parcel } from '../../../../types';
import { MarkParcelAsType } from '../types';

export interface MenuItem {
    type: MarkParcelAsType;
    title: string;
    warning: string | null;
    disabled: boolean;
    hasUserAccess: boolean;
}

export const useMarkParcelAsMenuItems = () => {
    const parcel = useRecordContext<Parcel>();
    const translate = useTranslate();

    const hasMarkAsDeliveredAccess = useHasAccess(ResourceKey.PARCELS, PermissionAction.MARK_PARCEL_AS_DELIVERED);
    const hasMarkAsLostAccess = useHasAccess(ResourceKey.PARCELS, PermissionAction.MARK_PARCEL_AS_LOST);
    const hasMarkAsMissingAccess = useHasAccess(ResourceKey.PARCELS, PermissionAction.MARK_PARCEL_AS_MISSING);
    const hasMarkAsReturnedAccess = useHasAccess(ResourceKey.PARCELS, PermissionAction.MARK_PARCEL_AS_RETURNED);

    const markAsDelivered = useMemo<MenuItem>(() => {
        const defaultProps = {
            type: MarkParcelAsType.Delivered,
            title: translate('parcel.detail.markAs.delivered'),
            warning: null,
            disabled: true,
            hasUserAccess: hasMarkAsDeliveredAccess,
        };

        if (parcel.direction === 'origin') {
            return {
                ...defaultProps,
                warning: translate('parcel.detail.markAs.delivered.warning.wrongDirection'),
            };
        }

        if (parcel.status !== 'in-final-destination') {
            return {
                ...defaultProps,
                warning: translate('parcel.detail.markAs.delivered.warning.wrongStatus'),
            };
        }

        if (parcel.payment.state !== 'paid-by-customer' && parcel.payment.state !== 'transferred-to-partner') {
            return {
                ...defaultProps,
                warning: translate('parcel.detail.markAs.delivered.warning.unpaid'),
            };
        }

        return {
            ...defaultProps,
            disabled: false,
        };
    }, [parcel, translate, hasMarkAsDeliveredAccess]);

    const markAsLost = useMemo<MenuItem>(() => {
        const defaultProps = {
            type: MarkParcelAsType.Lost,
            title: translate('parcel.detail.markAs.lost'),
            warning: null,
            disabled: true,
            hasUserAccess: hasMarkAsLostAccess,
        };

        if (parcel.status !== 'in-transit' && parcel.status !== 'in-depot') {
            return {
                ...defaultProps,
                warning: translate('parcel.detail.markAs.lost.warning.wrongStatus'),
            };
        }

        return {
            ...defaultProps,
            disabled: false,
        };
    }, [parcel, translate, hasMarkAsLostAccess]);

    const markAsMissing = useMemo<MenuItem>(() => {
        const defaultProps = {
            type: MarkParcelAsType.Missing,
            title: translate('parcel.detail.markAs.missing'),
            warning: null,
            disabled: true,
            hasUserAccess: hasMarkAsMissingAccess,
        };

        if (parcel.status !== 'in-transit') {
            return {
                ...defaultProps,
                warning: translate('parcel.detail.markAs.missing.warning.wrongStatus'),
            };
        }

        return {
            ...defaultProps,
            disabled: false,
        };
    }, [parcel, translate, hasMarkAsMissingAccess]);

    const markAsReturned = useMemo<MenuItem>(() => {
        const defaultProps = {
            type: MarkParcelAsType.Returned,
            title: translate('parcel.detail.markAs.returned'),
            warning: null,
            disabled: true,
            hasUserAccess: hasMarkAsReturnedAccess,
        };

        if (parcel.direction !== 'origin') {
            return {
                ...defaultProps,
                warning: translate('parcel.detail.markAs.returned.warning.wrongDirection'),
            };
        }

        if (parcel.status !== 'in-final-destination' && parcel.status !== 'in-depot') {
            return {
                ...defaultProps,
                warning: translate('parcel.detail.markAs.returned.warning.wrongStatus'),
            };
        }

        return {
            ...defaultProps,
            disabled: false,
        };
    }, [parcel, translate, hasMarkAsReturnedAccess]);

    return [markAsDelivered, markAsLost, markAsMissing, markAsReturned].filter(item => item.hasUserAccess);
};
