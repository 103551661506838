import { useState } from 'react';
import { useTranslate } from 'ra-core';
import { Button, Link, useNotify, useCreatePath, useRedirect } from 'react-admin';
import { Card, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { ResourceKey } from 'constants/index';

import { useCodPaymentsImportValidation, isValidationError } from '../../hooks/useCodPaymentsImport';
import type { CsvInfo } from '../../hooks/useCodPaymentsImport';
import { CodPaymentImportValidationRule } from '../../constants';
import { ValidationRule } from './ValidationRule';

import { FinishParcelsImport } from './FinishParcelsImport';
import { FileImportStatus } from './FileImportStatus';
import { ImportCsvForm } from './ImportCsvForm';

export const CodPaymentImportPage = () => {
    const translate = useTranslate();
    const notify = useNotify();
    const createPath = useCreatePath();
    const redirect = useRedirect();

    const [csvInfo, setCsvInfo] = useState<null | CsvInfo>(null);
    const {
        file,
        api: validationApi,
        validateFile,
        reset: resetImport,
    } = useCodPaymentsImportValidation({
        onSuccess: setCsvInfo,
    });

    const reset = () => {
        resetImport();
        setCsvInfo(null);
    };

    return (
        <Card sx={{ py: 3, px: 2, my: 3, mx: 1 }}>
            <Button
                component={Link}
                to={createPath({ resource: ResourceKey.PARCELS, type: 'list' })}
                label={translate('general.back')}
                variant="text"
                sx={{ mb: 1 }}
            >
                <ArrowBackIcon />
            </Button>
            <Typography variant="h5" component="h1" mb={2}>
                {translate('parcel.paymentImport.dialog.title')}{' '}
            </Typography>

            {validationApi.error && <FileImportStatus status="error" file={file} />}
            {validationApi.success && <FileImportStatus status="success" file={file} />}

            {!csvInfo &&
                Object.values(CodPaymentImportValidationRule).map(rule => {
                    const errorParcels = isValidationError(validationApi.error)
                        ? validationApi.error.validationErrors?.find(({ errorCode }) => errorCode === rule)?.parcels
                        : undefined;

                    return (
                        <ValidationRule
                            key={rule}
                            rule={rule}
                            errorParcels={errorParcels}
                            success={Boolean(validationApi.success || (validationApi.error && !errorParcels))}
                        />
                    );
                })}

            {csvInfo ? (
                <FinishParcelsImport
                    csvInfo={csvInfo}
                    onCancel={reset}
                    onImportSuccess={() => {
                        notify('parcel.paymentImport.import.finish.success');
                        reset();
                        redirect('list', ResourceKey.PARCELS);
                    }}
                    file={file?.rawFile}
                />
            ) : (
                <ImportCsvForm onSubmit={file => validateFile(file)} loading={validationApi.loading} />
            )}
        </Card>
    );
};
