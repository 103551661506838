import { useRecordContext, useTranslate } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import InfoIcon from '@mui/icons-material/InfoRounded';

import { useEffect } from 'react';

import { useUserRegion } from 'modules/localization';

import type { PartnerData } from '../../types';

import * as Styled from './ContractRegion.styles';

const NAME = 'contractRegion';

export const ContractRegion = () => {
    const translate = useTranslate();
    const { setValue } = useFormContext();

    const record = useRecordContext<PartnerData>();
    const isCreateForm = !record;
    const [userRegion] = useUserRegion();

    const contractRegion = isCreateForm ? userRegion : record.contractRegion;

    useEffect(() => {
        setValue(NAME, contractRegion);
    }, [setValue, contractRegion]);

    return (
        <Styled.ContractRegionInput
            label={translate('partners.region.contract')}
            source={NAME}
            defaultValue={contractRegion}
            inputProps={{ readOnly: true }}
            helperText={
                isCreateForm ? (
                    <Styled.InputNote>
                        <InfoIcon />
                        {translate('partners.region.contract.note')}
                    </Styled.InputNote>
                ) : undefined
            }
            format={region => region?.name ?? ''}
        />
    );
};
