import { Stack } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { DeleteWithConfirmButton } from 'react-admin';

import { type ApiReducerState, useTranslate } from 'hooks';
import { Button, LabeledField } from 'modules/ui';

import type { SlaSettings } from '../../../types';

export interface CsvFileDetailsProps {
    slaSettings: SlaSettings;
    download: () => void;
    downloadState: ApiReducerState;
}

export const CsvFileDetails = ({ slaSettings, download, downloadState }: CsvFileDetailsProps) => {
    const translate = useTranslate();

    return (
        <Stack mt={2}>
            <LabeledField<SlaSettings>
                label={translate('slaSettings.csv.importedFile.label')}
                property="filename"
                record={slaSettings}
            />
            <Stack direction="row" spacing={2}>
                <Button
                    variant="outlined"
                    onClick={async () => await download()}
                    loading={downloadState.loading}
                    startIcon={<DownloadIcon />}
                >
                    {translate('slaSettings.csv.importedFile.download.button')}
                </Button>
                <DeleteWithConfirmButton
                    record={slaSettings}
                    confirmTitle="slaSettings.csv.importedFile.remove.confirm.title"
                    confirmContent="slaSettings.csv.importedFile.remove.confirm.description"
                    label="slaSettings.csv.importedFile.remove.button"
                    variant="outlined"
                    confirmColor="warning"
                />
            </Stack>
        </Stack>
    );
};
