import { AdminContext, localStorageStore } from 'react-admin';
import type { CoreAdminContextProps } from 'react-admin';

import { UserRegionProvider } from 'modules/localization';

import { AdminUI } from '../AdminUI';

export interface AdminProps extends CoreAdminContextProps {}

export const Admin = (props: AdminProps) => {
    return (
        <AdminContext {...props}>
            <UserRegionProvider>
                <AdminUI />
            </UserRegionProvider>
        </AdminContext>
    );
};

Admin.defaultProps = {
    store: localStorageStore(),
};
