import { required, useRecordContext, useTranslate } from 'react-admin';
import { Stack } from '@mui/material';
import get from 'lodash/get';

import { EmailInput, PhoneInput, TextInput } from 'modules/ui';

const requiredValidator = required();

export interface OrderAddressContactProps {
    sourcePrefix?: string;
}

export const OrderAddressContact = ({ sourcePrefix }: OrderAddressContactProps) => {
    const translate = useTranslate();
    const prefix = sourcePrefix ? `${sourcePrefix}` : '';

    const record = useRecordContext();
    const defaultValues = get(record, prefix);

    return (
        <Stack>
            <TextInput
                source={`${prefix}.name`}
                label={translate('parcel.address.fullname')}
                validate={defaultValues.name ? requiredValidator : undefined}
            />
            <PhoneInput
                source={`${prefix}.phoneNumber`}
                label={translate('parcel.address.phone')}
                validate={defaultValues.phoneNumber ? requiredValidator : undefined}
            />
            <EmailInput
                source={`${prefix}.email`}
                label={translate('parcel.address.email')}
                validate={defaultValues.email ? requiredValidator : undefined}
            />
        </Stack>
    );
};
