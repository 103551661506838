import type { FC } from 'react';
import { NumberInput, required, number, useTranslate } from 'react-admin';
import type { InputProps } from 'react-admin';
import { Grid } from '@mui/material';

import TextInput from '../TextInput';
import CountryInput from '../CountryInput';

import * as Styled from './AddressForm.styles';

const requiredValidator = required();
const numberValidator = number();

export interface AddressFormProps extends Pick<InputProps, 'onBlur'> {
    source?: string;
}

const AddressForm: FC<AddressFormProps> = ({ source, onBlur }) => {
    const translate = useTranslate();
    const sourcePrefix = source ? `${source}.` : '';

    return (
        <>
            <TextInput
                source={`${sourcePrefix}street`}
                label={translate('general.address.street')}
                validate={requiredValidator}
                onBlur={onBlur}
            />
            <Styled.SmallContainer container spacing={2}>
                <Grid item xs={8}>
                    <TextInput
                        source={`${sourcePrefix}city`}
                        label={translate('general.address.city')}
                        validate={requiredValidator}
                        onBlur={onBlur}
                    />
                </Grid>
                <Grid item xs={4}>
                    <NumberInput
                        source={`${sourcePrefix}postalCode`}
                        label={translate('general.address.postalCode')}
                        validate={[requiredValidator, numberValidator]}
                        onBlur={onBlur}
                        parse={(v: number) => v.toString()}
                    />
                </Grid>
            </Styled.SmallContainer>
            <CountryInput
                source={`${sourcePrefix}country`}
                label={translate('general.address.country')}
                validate={requiredValidator}
                onBlur={onBlur}
            />
        </>
    );
};

export default AddressForm;
