import type { FC } from 'react';
import type { TextInputProps } from 'react-admin';

import * as Styled from './TextInput.styles';

const TextInput: FC<TextInputProps> = props => {
    return (
        <Styled.TextInput
            {...props}
            /**
             * There is a bug in Chrome regarding autoComplete="off" which doesn't work
             * and this is a hack to turn off auto complete.
             * https://stackoverflow.com/questions/30053167/autocomplete-off-vs-false
             */
            autoComplete={props.autoComplete ?? 'do-not-fill'}
        />
    );
};

export default TextInput;
