import { useTranslate } from 'react-admin';
import QrCodeIcon from '@mui/icons-material/CropFree';

import { Button } from 'modules/ui';

export const ParcelLabelButton = ({ url, label }: { url?: string; label: string }) => {
    const translate = useTranslate();
    return (
        <a href={url} target="_blank" rel="noreferrer">
            <Button variant="contained" type="button" size="medium" disabled={!url}>
                <QrCodeIcon sx={{ mr: 1 }} />
                {translate(label)}
            </Button>
        </a>
    );
};
