import { Stack, Typography } from '@mui/material';
import { useListContext } from 'react-admin';
import { sortBy } from 'lodash';

import type { AppConfiguration, AppConfigurationsByGroup } from 'modules/app-configuration/types';

import { ConfigurationByGroup } from '../ConfigurationByGroup';

export const SimpleAppConfigurationList = () => {
    const { data } = useListContext<AppConfiguration>();

    if (!data) {
        return null;
    }

    const configurationsByGroup = data.reduce((configurationsByGroup: AppConfigurationsByGroup, configuration) => {
        const { groupId, ...rest } = configuration;

        if (!configurationsByGroup[groupId]) {
            configurationsByGroup[groupId] = [];
        }

        configurationsByGroup[groupId].push({ ...rest });
        return configurationsByGroup;
    }, {});

    return (
        <Stack spacing={2} sx={{ padding: 2 }}>
            {Object.entries(configurationsByGroup).map(([groupId, configurations]) => (
                <Stack key={groupId} spacing={2} sx={{ padding: 4 }} style={{ background: 'white' }}>
                    <Typography variant="h6" fontWeight={600}>
                        {groupId}
                    </Typography>
                    {sortBy(configurations, c => c.name).map(configuration => (
                        <ConfigurationByGroup key={configuration.id} {...configuration} />
                    ))}
                </Stack>
            ))}
        </Stack>
    );
};
