import { BooleanInput, useTranslate, TextField, ReferenceField, Confirm, FunctionField } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { Box, Grid, Tooltip, IconButton } from '@mui/material';
import { GridShowLayout, RaGrid } from 'ra-compact-ui';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';

import { useConfirmDialog } from 'hooks';
import { ResourceKey } from 'constants/index';
import { Address } from 'modules/ui';

import { DefaultWarehouseForReturns } from '../DefaultWarehouseForReturns';
import type { WarehouseData } from '../../types';

import * as Styled from './WarehouseCard.styles';

export interface WarehouseCardProps {
    data: WarehouseData;
    sourcePrefix: string;
    onEdit: () => void;
    onDelete: () => void;
}

export const WarehouseCard = ({ data, sourcePrefix, onEdit, onDelete }: WarehouseCardProps) => {
    const { getFieldState } = useFormContext();
    const { error } = getFieldState(`${sourcePrefix}.depot`);

    const translate = useTranslate();

    const [confirmOpen, openConfirm, closeConfirm] = useConfirmDialog();

    const editEnabled = !data.shared;

    return (
        <>
            <Box>
                <Styled.Card elevation={3} isError={Boolean(error)}>
                    <GridShowLayout record={data}>
                        <RaGrid container spacing={1}>
                            <RaGrid item xs={9}>
                                <TextField label={translate('warehouse.name')} source={`${sourcePrefix}name`} />
                                <FunctionField<typeof data>
                                    render={record => (
                                        <>
                                            {record?.shared ? (
                                                <Styled.SharedLabel variant="caption">
                                                    {translate('partners.warehouse.shared')}
                                                </Styled.SharedLabel>
                                            ) : null}
                                        </>
                                    )}
                                />
                            </RaGrid>
                            <RaGrid item xs={3}>
                                <Styled.Actions>
                                    {editEnabled ? (
                                        <EditIcon sx={{ cursor: 'pointer' }} fontSize="small" onClick={onEdit} />
                                    ) : (
                                        <Tooltip title={translate('partners.warehouse.editDisabled')}>
                                            <span>
                                                <IconButton disabled>
                                                    <EditIcon sx={{ cursor: 'pointer' }} fontSize="small" />
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                    )}
                                    <CloseIcon sx={{ cursor: 'pointer' }} fontSize="small" onClick={openConfirm} />
                                </Styled.Actions>
                            </RaGrid>
                            <Grid item xs={12}>
                                <BooleanInput
                                    source={`${sourcePrefix}deleted`}
                                    label={translate('partners.warehouse.disabled')}
                                />
                            </Grid>
                            <RaGrid item xs={12}>
                                <DefaultWarehouseForReturns record={data} />
                            </RaGrid>

                            <RaGrid item xs={12} sm={6}>
                                <Address address={data} />
                            </RaGrid>
                            <RaGrid item xs={12} sm={3} sx={{ wordBreak: 'break-word' }}>
                                <TextField
                                    label={translate('general.coordinates.latitude')}
                                    source={`${sourcePrefix}coordinates.lat`}
                                />
                            </RaGrid>
                            <RaGrid item xs={12} sm={3} sx={{ wordBreak: 'break-word' }}>
                                <TextField
                                    label={translate('general.coordinates.longitude')}
                                    source={`${sourcePrefix}coordinates.lng`}
                                />
                            </RaGrid>
                            <RaGrid item xs={12} sm={6}>
                                <ReferenceField
                                    source={`${sourcePrefix}depotId`}
                                    label={translate('warehouse.serviceArea')}
                                    reference={ResourceKey.LOCATION}
                                    link={false}
                                >
                                    <TextField source="name" />
                                </ReferenceField>
                            </RaGrid>
                        </RaGrid>
                    </GridShowLayout>
                </Styled.Card>
                {error && <Styled.ErrorMessage variant="caption">{error.message}</Styled.ErrorMessage>}
            </Box>
            <Confirm
                isOpen={confirmOpen}
                title={translate(`partners.warehouse.delete.confirm.title`)}
                content={translate(`partners.warehouse.delete.confirm.text`)}
                confirm={translate('action.remove')}
                cancel={translate(`general.cancel`)}
                CancelIcon={CancelIcon}
                onConfirm={onDelete}
                onClose={closeConfirm}
            />
        </>
    );
};
