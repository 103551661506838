import { useState } from 'react';

import { useTranslate } from 'hooks';
import { Button } from 'modules/ui';

import { ReportIssueModal } from './ReportIssueModal';

export const ReportIssueButton = () => {
    const translate = useTranslate();
    const [modalOpen, setModalOpen] = useState(false);

    return (
        <>
            <Button variant="contained" onClick={() => setModalOpen(true)}>
                {translate('apm.reportIssue.button')}
            </Button>
            <ReportIssueModal open={modalOpen} handleClose={() => setModalOpen(false)} />
        </>
    );
};
