import type { HttpError } from 'react-admin';

import type { HTTPStatusCode } from 'types';

import type { UIErrorMessages, UIMessageID } from '../config';
import { errorMessages as defaultErrorMessages } from '../config';

export const isHttpError = (error: unknown): error is HttpError => (error as HttpError).status !== undefined;

interface ErrorKeys {
    status?: HTTPStatusCode;
    code?: string;
}

const isApiError = (error: ErrorKeys) => Boolean(error.code || error.status);

const findErrorMessages = (keys: ErrorKeys, messages: UIErrorMessages): UIMessageID | null => {
    if (messages?.code && keys.code && messages.code[keys.code]) {
        return messages.code[keys.code];
    }

    if (messages?.status && keys.status && messages.status[keys.status]) {
        return messages.status[keys.status] ?? null;
    }

    return null;
};

export const createUIErrorMessage = (
    error: ErrorKeys,
    messages: UIErrorMessages = defaultErrorMessages,
): UIMessageID => {
    if (!isApiError(error)) {
        return messages.fallbackId;
    }

    const errorMessageId = findErrorMessages(error, messages);

    return errorMessageId ?? messages.fallbackId;
};
