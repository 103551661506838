import { useRecordContext, useTranslate } from 'react-admin';
import { Typography } from '@mui/material';

import type { ApmDetail } from 'modules/apm/types';
import type { ParcelSizeKey } from 'modules/parcels';
import { ParcelSize } from 'modules/parcels';
import { toLowerCase } from 'utils/toLowerCase';

export interface ApmAvailabilityInfoProps {}

interface SizeStatusProps {
    size: ParcelSize;
    type: 'c' | 'e';
}

function composeAvailabilityKey(size: ParcelSize, type: 'c' | 'e', real: boolean = false) {
    const parcelSize = ParcelSize[size] as ParcelSizeKey;
    return `${toLowerCase(parcelSize)}4${type}Available${real ? 'Real' : ''}` as const;
}

export const SizeStatus = ({ size, type }: SizeStatusProps) => {
    const record = useRecordContext<ApmDetail>();
    const translate = useTranslate();

    const availability = record?.availability;
    const value = availability ? availability[composeAvailabilityKey(size, type)] : null;
    const realValue = availability ? availability[composeAvailabilityKey(size, type, true)] : null;
    const yes = translate('general.yes').toLowerCase();
    const no = translate('general.no').toLowerCase();

    return (
        <Typography variant="body1">
            {ParcelSize[size]} - {availability ? `${value ? yes : no} (${realValue ? yes : no})` : '?'}
        </Typography>
    );
};
