import { useRecordContext } from 'react-admin';
import type { FieldProps } from 'ra-ui-materialui';

interface LocationFieldProps extends FieldProps {
    source: string;
}

const LocationField = ({ source, ...props }: LocationFieldProps) => {
    const record = useRecordContext(props);
    const location = record[source];

    return <span>{[location.street, location.city].filter(Boolean).join(', ')}</span>;
};

export default LocationField;
