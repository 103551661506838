import { Fragment } from 'react';
import { useTranslate } from 'react-admin';
import { Typography, Stack } from '@mui/material';
import { BoxedShowLayout, RaBox } from 'ra-compact-ui';

import { ResourceKey, BinaryChoice } from 'constants/index';
import { useHasAccess } from 'modules/auth';
import { PermissionAction } from 'modules/roles';

import { YesNoInput, YesNoField } from 'modules/ui';

export interface PartnerPermissionsProps {
    viewOnly?: boolean;
}

const permissions = [
    ['depotAsOrigin', BinaryChoice.NO],
    ['warehouseAsOrigin', BinaryChoice.YES],
    ['anyApmAsOrigin', BinaryChoice.NO],
    ['anyApmToSameApmDelivery', BinaryChoice.NO],
    ['anyApmToSameApmDeliveryWithoutConfirmation', BinaryChoice.YES],
    ['customForm', BinaryChoice.NO],
    ['codPayment', BinaryChoice.NO],
    ['canPartnerCreateCustomerReturns', BinaryChoice.NO],
    ['canCustomerCreateCustomerReturns', BinaryChoice.NO],
    ['addressAsDestination', BinaryChoice.NO],
    ['codAddressAsDestination', BinaryChoice.NO],
    ['largerCompartmentWhileSending', BinaryChoice.NO],
] as const;

export const PartnerPermissions = ({ viewOnly = false }: PartnerPermissionsProps) => {
    const translate = useTranslate();
    const hasAccessToPartnerPermissions = useHasAccess(
        ResourceKey.PARTNER,
        viewOnly ? PermissionAction.DETAIL : PermissionAction.EDIT,
    );

    if (!hasAccessToPartnerPermissions) {
        return null;
    }

    const Container = viewOnly ? BoxedShowLayout : Stack;
    const Item = viewOnly ? RaBox : Fragment;

    return (
        <Container sx={{ padding: 2 }}>
            <Typography variant="h6" component="h3">
                {translate('partners.permissions.title')}
            </Typography>
            {permissions.map(([permission, defaultValue]) => {
                const commonProps = {
                    source: `permission.${permission}`,
                    label: translate(`partners.permissions.${permission}`),
                };
                return (
                    <Item key={permission}>
                        {viewOnly ? (
                            <YesNoField {...commonProps} />
                        ) : (
                            <YesNoInput {...commonProps} defaultValue={defaultValue} />
                        )}
                    </Item>
                );
            })}
        </Container>
    );
};
