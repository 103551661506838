import { useNotify } from 'react-admin';

import { useApiReducer } from 'hooks';
import { adminApiClient } from 'modules/auth/services/authApiClient';
import config from 'config';

interface Options {
    successMsg: string;
}

export function useShowLcdText(apmId?: string, displayText?: string, options: Options = { successMsg: '' }) {
    const [apiState, dispatch] = useApiReducer();
    const notify = useNotify();

    const actionTrigger = async () => {
        if (!apmId) {
            return;
        }

        dispatch({ type: 'request' });

        try {
            await adminApiClient.post(config.adminApi.endpoints.showLcdText.replace('{apmId}', apmId), { displayText });
            dispatch({ type: 'success' });
            notify(options.successMsg, { type: 'success' });
        } catch (error: any) {
            dispatch({ type: 'error', error: error as string });
        }
    };

    return [apiState, actionTrigger] as const;
}
