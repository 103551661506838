import { useReducer } from 'react';

export interface ApiReducerState {
    loading: boolean;
    error?: Error | string | null;
    success: boolean | null;
}

type ActionType = 'request' | 'success' | 'error' | 'reset';

interface Action {
    type: ActionType;
    error?: Error | string;
}

const initialState: ApiReducerState = {
    loading: false,
    error: null,
    success: null,
};

const reducer = (state: ApiReducerState, action: Action): ApiReducerState => {
    switch (action.type) {
        case 'request':
            return {
                ...state,
                loading: true,
            };

        case 'success':
            return {
                loading: false,
                success: true,
                error: null,
            };

        case 'error':
            return {
                loading: false,
                success: false,
                error: action.error,
            };
        case 'reset':
            return initialState;
    }
};

export default () => useReducer(reducer, initialState);
