import { useRecordContext } from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';
import { useState } from 'react';
import { Typography, Stack, Tooltip } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';

import { BinaryChoice } from 'constants/index';
import { useConfirmDialog, useTranslate } from 'hooks';
import type { PartnerDetailUpdate } from 'modules/partners/types';
import { YesNoInput } from 'modules/ui';

import { PartnerOverdueChangeDialog } from '../PartnerOverdueChangeDialog';

interface PartnerOverdueSelectProps {
    overdueLabel: string;
}

export const PartnerOverdueSelect = ({ overdueLabel }: PartnerOverdueSelectProps) => {
    const translate = useTranslate();
    const { setValue } = useFormContext();
    const { overdue: originalOverdue } = useRecordContext<PartnerDetailUpdate>() ?? {};

    const [overdueChangeDialogOpen, openOverdueChangeDialog, closeOverdueChangeDialog] = useConfirmDialog();
    const overdueChangeReason = useWatch({ name: 'overdueChangeReason' });
    const currentOverdue = useWatch<PartnerDetailUpdate>({ name: 'overdue' });

    const [previousOverdue, setPreviousOverdue] = useState<PartnerDetailUpdate['overdue']>(originalOverdue);

    return typeof originalOverdue === 'boolean' ? (
        <>
            <Stack direction="row">
                <YesNoInput
                    source="overdue"
                    label={overdueLabel}
                    defaultValue={BinaryChoice.NO}
                    onChange={e => {
                        const original = originalOverdue ? BinaryChoice.YES : BinaryChoice.NO;
                        if (e.target.value !== original) {
                            openOverdueChangeDialog();
                        } else {
                            setPreviousOverdue(originalOverdue);
                            setValue('overdueChangeReason', undefined);
                        }
                    }}
                />
                {overdueChangeReason ? (
                    <Tooltip
                        placement="right"
                        sx={{ mt: 5 }}
                        title={
                            <Stack p={1} spacing={1}>
                                <Typography variant="caption">
                                    {translate('partners.overdueChange.changeDescription', {
                                        previousState: translate(
                                            `partners.characteristics.overdue.${originalOverdue ? 'yes' : 'no'}`,
                                        ),
                                        currentState: translate(
                                            `partners.characteristics.overdue.${currentOverdue ? 'yes' : 'no'}`,
                                        ),
                                    })}
                                </Typography>
                                <Typography variant="body1">{overdueChangeReason}</Typography>
                            </Stack>
                        }
                    >
                        <ChatIcon />
                    </Tooltip>
                ) : null}
            </Stack>
            <PartnerOverdueChangeDialog
                open={overdueChangeDialogOpen}
                onClose={closeOverdueChangeDialog}
                setPreviousOverdue={setPreviousOverdue}
                previousOverdue={previousOverdue}
            />
        </>
    ) : null;
};
