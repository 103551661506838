import type { FC } from 'react';
import { TextField, useTranslate } from 'react-admin';
import type { RaRecord } from 'react-admin';
import { RaBox } from 'ra-compact-ui';

import * as Styled from './LatLngField.styles';

interface LatLngFieldProps {
    source: string;
    record?: RaRecord;
}

export const LatLngField: FC<LatLngFieldProps> = ({ record, source }) => {
    const translate = useTranslate();

    return (
        <Styled.Layout record={record}>
            <RaBox display="flex">
                <TextField source={`${source}.lat`} label={translate('general.coordinates.latitude')} sx={{ mr: 2 }} />
                <TextField source={`${source}.lng`} label={translate('general.coordinates.longitude')} />
            </RaBox>
        </Styled.Layout>
    );
};
