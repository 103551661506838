import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * CMS previously used hash routes, so we need to redirect them to the new ones
 * to keep the old links working.
 */
export const useHashRouteRedirect = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const hash = window.location.hash;
        if (hash.startsWith('#/')) {
            navigate(hash.replace('#', ''), { replace: true });
        }
    }, [navigate]);
};
