import { Paper, useMediaQuery, Drawer, Box, IconButton, Typography, Badge } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import { useToggle, useTranslate } from 'hooks';
import type { Theme } from 'styles/theme';
import { Button } from 'modules/ui';

import { ParcelsListFiltersForm } from './ParcelListFiltersForm';
import { useActiveFiltersCount } from './hooks/useActiveFiltersCount';

export const ParcelsListFilters = () => {
    const translate = useTranslate();
    const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const [filtersDrawerOpen, toggleFiltersDrawer] = useToggle(false);
    const { activeFiltersCount } = useActiveFiltersCount();

    if (isDesktop) {
        return (
            <Paper
                elevation={1}
                sx={{
                    paddingLeft: 2,
                    paddingRight: 2,
                    paddingTop: 2,
                    paddingBottom: 0,
                    backgroundColor: 'white',
                    marginTop: 1,
                    marginBottom: 3,
                }}
            >
                <ParcelsListFiltersForm />
            </Paper>
        );
    }

    return (
        <>
            <Badge badgeContent={activeFiltersCount} color="primary">
                <Button onClick={() => toggleFiltersDrawer()} variant="contained" startIcon={<FilterAltIcon />}>
                    {translate('general.filters')}
                </Button>
            </Badge>
            <Drawer anchor="top" open={filtersDrawerOpen} onClose={() => toggleFiltersDrawer()}>
                <Box padding={3}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h5">{translate('general.filters')}</Typography>
                        <IconButton onClick={() => toggleFiltersDrawer()} size="large" sx={{ textAlign: 'right' }}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <ParcelsListFiltersForm closeFiltersDrawer={toggleFiltersDrawer} />
                </Box>
            </Drawer>
        </>
    );
};
