import { useNotify } from 'react-admin';

import { composeUrl } from 'services/dataProvider/utils';
import { ResourceKey } from 'constants/index';
import { adminApiClient } from 'modules/auth/services/authApiClient';
import { logger } from 'config';
import { useApiReducer } from 'hooks';

import type { SlaSettings } from '../types';

export const useDownloadSlaSettings = (slaSettings: SlaSettings | undefined) => {
    const notify = useNotify();

    const [downloadState, dispatchDownloadAction] = useApiReducer();

    const download = async () => {
        if (!slaSettings) return;

        try {
            dispatchDownloadAction({ type: 'request' });

            const apiUrl = composeUrl(ResourceKey.SLA_SETTINGS, 'getOne', {
                includeApiBaseUrl: false,
                suffix: `/${slaSettings.id}`,
            });

            const res = await adminApiClient.get(apiUrl);
            const blob = new Blob([res as Blob], { type: 'text/csv' });
            const downloadUrl = URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = downloadUrl;
            a.download = slaSettings.filename ?? 'file.csv';
            a.click();

            dispatchDownloadAction({ type: 'success' });
        } catch (error) {
            notify('slaSettings.csv.importedFile.download.error', { type: 'error' });
            dispatchDownloadAction({ type: 'error', error: error as Error });
            logger.error(error, "Couldn't download ETA/SLA settings CSV file");
        }
    };

    return { download, downloadState };
};
