import type { FC } from 'react';
import type { InputProps, Validator } from 'react-admin';

import { phoneNumberValidator } from 'modules/phone-number/validators';

import TextInput from '../TextInput';

export interface PhoneInputProps extends InputProps {}

const PhoneInput: FC<PhoneInputProps> = props => {
    const validate: Validator[] = [phoneNumberValidator];

    if (props.validate) {
        if (Array.isArray(props.validate)) {
            validate.unshift(...props.validate);
        } else {
            validate.unshift(props.validate);
        }
    }

    return <TextInput {...props} validate={validate} />;
};

export default PhoneInput;
