import { useRefresh } from 'react-admin';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { useTranslate } from 'hooks';

import { useMarkParcelAsMutation } from '../hooks';
import type { MarkParcelAsType } from '../types';
import * as Styled from './MarkParcelAsConfirmDialog.styles';

export interface MarkParcelAsConfirmDialogProps {
    type: MarkParcelAsType;
    onClose: () => void;
}

export const MarkParcelAsConfirmDialog = ({ type, onClose }: MarkParcelAsConfirmDialogProps) => {
    const translate = useTranslate();
    const refresh = useRefresh();
    const [apiState, mutate] = useMarkParcelAsMutation(type, {
        onSuccess: () => {
            onClose();
            refresh();
        },
    });

    return (
        <Styled.Confirm
            isOpen={Boolean(type)}
            loading={apiState.loading}
            title={translate(`parcel.detail.markAs.${type}.dialog.title`)}
            content={translate(`parcel.detail.markAs.${type}.dialog.description`)}
            CancelIcon={ArrowBackIcon}
            onConfirm={() => mutate()}
            onClose={onClose}
        />
    );
};
