import { useNotify, useRefresh } from 'react-admin';

import config from 'config';
import { useApiReducer } from 'hooks';
import { adminApiClient } from 'modules/auth/services/authApiClient';

import type { Permissions } from '../types';

export function useUpdateUserPermissions(userId: string) {
    const [api, dispatch] = useApiReducer();
    const notify = useNotify();
    const refresh = useRefresh();

    const updateUserPermissions = async (permissions: Permissions) => {
        dispatch({ type: 'request' });

        try {
            await adminApiClient.put(
                config.adminApi.endpoints.userPermissions.replace('{userId}', userId),
                permissions,
            );
            dispatch({ type: 'success' });
            notify('users.permissions.success', { type: 'success' });
            refresh();
        } catch (error: any) {
            dispatch({ type: 'error', error: error as string });
            notify((error as Error).message, { type: 'warning' });
        }
    };

    return { updateUserPermissions, api };
}
