import { useNotify, useRecordContext, useRefresh, useTranslate } from 'react-admin';
import FlagFilledIcon from '@mui/icons-material/Flag';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';

import { usePatchUpdate } from 'hooks';
import { ResourceKey } from 'constants/index';
import { Button } from 'modules/ui';

import { CustomerIssueState } from '../../constants';
import type { Parcel } from '../../types';

export const CustomerIssueButton = () => {
    const translate = useTranslate();
    const refresh = useRefresh();
    const notify = useNotify();

    const { id, customerIssueState } = useRecordContext<Parcel>();
    const issueOpened = customerIssueState === CustomerIssueState.ACTIVE;
    const FlagIcon = issueOpened ? FlagFilledIcon : FlagOutlinedIcon;

    const [updateData, { isLoading }] = usePatchUpdate<Parcel>(
        ResourceKey.PARCELS,
        { id },
        {
            onSuccess: () => {
                refresh();
            },
            onError: () => {
                notify(`parcel.detail.customerIssue.${customerIssueState}.error`, { type: 'error' });
            },
            mutationMode: 'pessimistic',
        },
    );
    const toggleCustomerIssue = () =>
        updateData(undefined, {
            data: { customerIssueState: issueOpened ? CustomerIssueState.INACTIVE : CustomerIssueState.ACTIVE },
        });

    return (
        <Button
            variant={issueOpened ? 'outlined' : 'contained'}
            type="button"
            size="medium"
            disabled={isLoading}
            loading={isLoading}
            onClick={toggleCustomerIssue}
        >
            <FlagIcon sx={{ mr: 1 }} />
            {translate(`parcel.detail.customerIssue.${customerIssueState}`)}
        </Button>
    );
};
