import { Paper, useMediaQuery } from '@mui/material';

import type { Theme } from 'styles/theme';

import { ApmListFiltersForm } from './ApmListFiltersForm';
import { ApmListFiltersMobileDrawer } from './ApmListFiltersMobileDrawer';

export const ApmListFilters = () => {
    const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

    if (isDesktop) {
        return (
            <Paper sx={{ width: '100%', p: 2, pb: 2.5, my: 2 }}>
                <ApmListFiltersForm />
            </Paper>
        );
    }

    return <ApmListFiltersMobileDrawer />;
};
