import { useTranslate } from 'ra-core';
import { FileField, FileInput, required, SimpleForm } from 'react-admin';

import UploadIcon from '@mui/icons-material/UploadFile';

import { Button } from 'modules/ui';

import type { FileInputValue } from 'types';

export interface ImportCsvFormProps {
    onSubmit: (csvFile: FileInputValue) => void;
    loading: boolean;
}

export const ImportCsvForm = ({ onSubmit, loading }: ImportCsvFormProps) => {
    const translate = useTranslate();

    return (
        <SimpleForm
            onSubmit={values => onSubmit((values as { csv: FileInputValue }).csv)}
            toolbar={
                <Button variant="contained" type="submit" loading={loading}>
                    <UploadIcon sx={{ mr: 0.5, height: 20 }} />
                    {translate('parcel.paymentImport.import')}
                </Button>
            }
        >
            <FileInput
                source="csv"
                accept=".csv"
                multiple={false}
                label={translate('parcel.paymentImport.input.label')}
                placeholder={<p>{translate('parcel.paymentImport.input.placeholder')}</p>}
                validate={required('parcel.paymentImport.input.required')}
            >
                <FileField source="src" title="title" />
            </FileInput>
        </SimpleForm>
    );
};
