import { SeoHead } from 'modules/ui';

import { useFormPageTitle } from '../../hooks';

interface WarehouseSeoHeadProps {
    detailView?: boolean;
}

export const WarehouseSeoHead = ({ detailView = false }: WarehouseSeoHeadProps) => {
    const title = useFormPageTitle(detailView);

    return <SeoHead title={title} />;
};
