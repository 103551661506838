import { ArrayField, Datagrid, ShowButton, TextField } from 'react-admin';

import { ResourceKey } from 'constants/index';

export const ConnectedLanesList = () => {
    return (
        <ArrayField source="lanes">
            <Datagrid bulkActionButtons={false}>
                <TextField source="name" label={'lanes.name'} />
                <TextField source="description" label={'lanes.description'} />

                <ShowButton resource={ResourceKey.LANES} label="apm.lanes.detail" />
            </Datagrid>
        </ArrayField>
    );
};
