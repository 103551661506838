import { useFormContext } from 'react-hook-form';

import { createWarehouseSourcePrefix } from '../utils';
import type { WarehouseData } from '../types';

export const useSetWarehouseValues = () => {
    const { setValue } = useFormContext();

    return function resetValues(warehouseIndex: number, values: Omit<WarehouseData, 'id'>) {
        const source = createWarehouseSourcePrefix(warehouseIndex, false);

        setValue(source, values);
    };
};
