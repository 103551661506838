import { useTranslate } from 'react-admin';
import { Box, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/WarningRounded';

export interface FinalDestinationWarningProps {}

export const FinalDestinationWarning = () => {
    const translate = useTranslate();

    return (
        <Box display="flex" alignItems="center" my={2} gap={1}>
            <WarningIcon />
            <Typography variant="body2" color="">
                {translate('parcel.compartmentSize.inFinalDestination.warning')}
            </Typography>
        </Box>
    );
};
