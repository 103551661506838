import { useMemo } from 'react';
import { useRefresh, useTranslate } from 'react-admin';
import type { Translate } from 'react-admin';
import { useForm, Controller } from 'react-hook-form';
import endOfDay from 'date-fns/endOfDay';
import addDays from 'date-fns/addDays';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    FormControlLabel,
    FormGroup,
    Select,
    MenuItem,
    Checkbox,
} from '@mui/material';

import config from 'config';
import { useFormattedDateTime } from 'modules/localization';

import { EXTEND_STORAGE_MAX_DAYS } from '../../../constants';
import { useParcelApiAction } from '../../../hooks/useParcelApiAction';
import { ParcelSizeWarning } from '../ParcelSizeWarning';

import * as Styled from './ExtendStorageTimeDialog.styles';

type ExtendStorageAction = 'sendReminderSMS' | 'sendReminderEmail';

const extendStorageActions: ExtendStorageAction[] = ['sendReminderSMS', 'sendReminderEmail'];

interface ExtendStorageFormValues {
    actions: ExtendStorageAction[];
    numberOfDays: number;
}

export interface ExtendStorageTimeDialogProps {
    parcelId: string;
    currentPickupTime?: number;
    visible: boolean;
    onClose: () => void;
}

function generateMenuItems(translate: Translate, currentPickupTime?: number) {
    const generateLabel = (days: number, label: string) =>
        // smart_count is a magic variable used by i18n-polyglot for pluralization, we can't change its name
        // eslint-disable-next-line camelcase
        `${translate('general.days', { smart_count: days })} - ${label}`;

    const labels: string[] = [generateLabel(0, translate('parcel.extendStorageTime.dialog.prolong.none'))];

    if (currentPickupTime) {
        const endOfPickDateDay = endOfDay(new Date(currentPickupTime));

        for (let days = 1; days <= EXTEND_STORAGE_MAX_DAYS; days++) {
            const labelDate = addDays(endOfPickDateDay, days).toLocaleString();

            labels.push(generateLabel(days, labelDate));
        }
    }

    return labels;
}

export const ExtendStorageTimeDialog = ({
    parcelId,
    currentPickupTime,
    visible,
    onClose,
}: ExtendStorageTimeDialogProps) => {
    const translate = useTranslate();
    const refresh = useRefresh();

    const { control, handleSubmit } = useForm<ExtendStorageFormValues>({
        defaultValues: {
            actions: ['sendReminderSMS', 'sendReminderEmail'],
            numberOfDays: 0,
        },
    });

    const lastPickUpTime = useFormattedDateTime(currentPickupTime);

    const [api, prolongPickUpTime] = useParcelApiAction(config.adminApi.endpoints.prolongStorageTime, parcelId, {
        successMsg: 'parcel.extendStorageTime.success',
        onSuccess: refresh,
    });

    const prolongLabels = useMemo(
        () => generateMenuItems(translate, currentPickupTime),
        [translate, currentPickupTime],
    );

    const onSubmit = (values: ExtendStorageFormValues) => {
        prolongPickUpTime(values);
        onClose();
    };

    return (
        <Dialog open={visible} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>{translate('parcel.extendStorageTime.dialog.title')}</DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {translate('parcel.extendStorageTime.current', { date: lastPickUpTime || '–' })}
                    <Styled.SelectFormControl>
                        <Controller
                            name="numberOfDays"
                            control={control}
                            render={({ field }) => (
                                <Styled.SelectFormControlLabel
                                    labelPlacement="top"
                                    id="number-of-days-label"
                                    control={
                                        <Select
                                            labelId="number-of-days-label"
                                            id="number-of-days"
                                            {...field}
                                            disabled={!lastPickUpTime}
                                        >
                                            {prolongLabels.map((label, numberOfDays) => (
                                                <MenuItem key={label} value={numberOfDays}>
                                                    {label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    }
                                    label={translate('parcel.extendStorageTime.dialog.prolong.label', {
                                        days: EXTEND_STORAGE_MAX_DAYS,
                                    })}
                                />
                            )}
                        />
                    </Styled.SelectFormControl>

                    <ParcelSizeWarning />

                    <FormGroup>
                        <Controller
                            name="actions"
                            control={control}
                            render={({ field }) => (
                                <>
                                    {extendStorageActions.map(action => (
                                        <FormControlLabel
                                            key={action}
                                            control={
                                                <Checkbox
                                                    value={action}
                                                    checked={field.value.some(
                                                        checkedAction => checkedAction === action,
                                                    )}
                                                    onChange={({ target }, checked) => {
                                                        if (checked) {
                                                            field.onChange([...field.value, target.value]);
                                                        } else {
                                                            field.onChange(
                                                                field.value.filter(val => val !== target.value),
                                                            );
                                                        }
                                                    }}
                                                />
                                            }
                                            label={translate(`parcel.extendStorageTime.dialog.${action}`)}
                                        />
                                    ))}
                                </>
                            )}
                        />
                    </FormGroup>
                    <DialogActions>
                        <Button onClick={onClose} disabled={api.loading}>
                            {translate('general.cancel')}
                        </Button>
                        <Button variant="contained" type="submit" disabled={api.loading}>
                            {translate('action.confirm')}
                        </Button>
                    </DialogActions>
                </form>
            </DialogContent>
        </Dialog>
    );
};
