import { useState } from 'react';

import { useApiReducer } from 'hooks';

import { defaultPhonePrefix } from '../config';
import requestAuthCode from '../services/requestAuthCode';

export default () => {
    const [api, dispatch] = useApiReducer();
    const [phone, setPhone] = useState(defaultPhonePrefix as string);
    const [challenge, setChallenge] = useState('');
    const [devSmsCode, setDevSmsCode] = useState<string | undefined>(undefined);

    const request = async (phoneNumber: string) => {
        dispatch({ type: 'request' });

        try {
            const { challenge, devSmsCode } = await requestAuthCode({ phoneNumber });

            setChallenge(challenge);
            setDevSmsCode(devSmsCode);
            dispatch({ type: 'success' });
        } catch (error) {
            dispatch({ type: 'error', error: error as Error });
        }
    };

    return {
        phone,
        challenge,
        devSmsCode,
        clearChallenge: () => setChallenge(''),
        setPhone,
        request,
        api,
    } as const;
};
