import { styled } from '@mui/material';

export const Container = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.grey[500],
    marginBottom: theme.spacing(2),
}));

export const Value = styled('span')(({ theme }) => ({
    fontSize: '1.2em',
    color: theme.palette.text.primary,
}));
