import { useTranslate, BooleanInput, Button, IconButtonWithTooltip } from 'react-admin';
import type { UseFormTrigger } from 'react-hook-form';
import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/RemoveCircleOutline';

import { BaseWarehouseInputs } from 'modules/warehouses';

import { DefaultWarehouseForReturns } from '../DefaultWarehouseForReturns';
import type { WarehouseData } from '../../types';

import * as Styled from './WarehouseForm.styles';

export interface WarehouseFormProps {
    data: WarehouseData;
    sourcePrefix: string;
    onSubmit: () => void;
    onCancelSubmit: () => void;
    onDiscard: () => void;
    trigger: UseFormTrigger<any>;
    isNewWarehouse: boolean;
}

export const WarehouseForm = ({
    data,
    sourcePrefix,
    isNewWarehouse,
    trigger,
    onSubmit,
    onCancelSubmit,
    onDiscard,
}: WarehouseFormProps) => {
    const translate = useTranslate();

    const isEditForm = Boolean(data.id);

    const validateForm = () => trigger(sourcePrefix);
    const handleSubmit = async () => {
        const isWarehouseValid = await validateForm();

        if (isWarehouseValid) {
            onSubmit();
        }
    };

    return (
        <>
            {isNewWarehouse && (
                <Styled.Actions>
                    <IconButtonWithTooltip label="ra.action.remove" size="small" onClick={onDiscard} color="warning">
                        <CloseIcon fontSize="small" sx={{ cursor: 'pointer' }} />
                    </IconButtonWithTooltip>
                </Styled.Actions>
            )}

            {isEditForm && (
                <Styled.LabeledIdField<WarehouseData> label={translate('warehouse.id')} record={data} property="id" />
            )}

            <BooleanInput source={`${sourcePrefix}deleted`} label={translate('partners.warehouse.disabled')} />

            <DefaultWarehouseForReturns source={`${sourcePrefix}id`} record={data} />

            <BaseWarehouseInputs sourcePrefix={sourcePrefix} onInputBlur={validateForm} />

            <Box display="flex" sx={{ gap: 3 }}>
                <Button
                    label={`partners.warehouse.${isNewWarehouse ? 'add' : 'save'}`}
                    variant="outlined"
                    onClick={handleSubmit}
                />
                {!isNewWarehouse && (
                    <Button label="general.cancel" type="button" variant="text" onClick={onCancelSubmit} />
                )}
            </Box>
        </>
    );
};
