function bound(value: number, min: number = 0, max: number = 1) {
    return Math.max(Math.min(value, max), min);
}

function toPercentage(value: number) {
    return value > 0 && value <= 1 ? value * 100 : value;
}

export function formatProbability(value: any) {
    if (value === undefined || value === null || value === '') {
        return value;
    }

    const number = Number.parseFloat(value);

    if (Number.isNaN(number)) {
        return value;
    }

    return Math.round(toPercentage(number)).toFixed(0);
}

export function parseProbability(value: any) {
    if (value === undefined || value === null || value === '') {
        return value;
    }

    const number = Number.parseFloat(value);
    const bounded = bound(number, 0, 100);
    const rounded = Math.round(bounded);

    return rounded / 100;
}
