import { AdminUI as RaAdminUI, useTranslate, Resource, CustomRoutes } from 'react-admin';
import { Route } from 'react-router-dom';

import ArchiveIcon from '@mui/icons-material/Archive';
import ConstructionIcon from '@mui/icons-material/Construction';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LocationIcon from '@mui/icons-material/LocationOn';
import PersonIcon from '@mui/icons-material/Person';
import SupervisorIcon from '@mui/icons-material/SupervisorAccount';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import { ResourceKey } from 'constants/index';
import { useUserRegionReady } from 'modules/localization';
import { AuthCallbackPage, LoginPage, getResourceAccess, hasAccess } from 'modules/auth';
import { PermissionAction } from 'modules/roles';
import { ApmList, ApmEdit, ApmDetail } from 'modules/apm';
import { ParcelsList, ParcelDetail, COD_PAYMENT_IMPORT_ROUTE, CodPaymentImportPage } from 'modules/parcels';
import { LanesList, LaneCreate, LaneEdit, LaneDetail } from 'modules/lanes';
import { UsersList, UserCreate, UserEdit, UserDetail } from 'modules/users';
import { PartnersList, PartnerCreate, PartnerEdit, PartnerDetail } from 'modules/partners';
import { WarehouseCreate, WarehouseDetail, WarehouseEdit, WarehousesList } from 'modules/warehouses';
import { AppConfigurationList, AppConfigurationEdit } from 'modules/app-configuration';
import { SlaSettingsList } from 'modules/sla-settings';

import { useHashRouteRedirect } from '../../hooks';

import Layout from '../Layout';
import { NotFound } from '../NotFound';

const authorizableResources = [
    {
        icon: ArchiveIcon,
        name: ResourceKey.PARCELS,
        label: 'parcels.list.title',
        list: ParcelsList,
        show: ParcelDetail,
    },

    {
        icon: DashboardIcon,
        name: ResourceKey.APM,
        label: 'apm.list.title',
        list: ApmList,
        edit: ApmEdit,
        show: ApmDetail,
    },

    {
        icon: LocationIcon,
        name: ResourceKey.LANES,
        label: 'lanes.list.title',
        list: LanesList,
        create: LaneCreate,
        edit: LaneEdit,
        show: LaneDetail,
    },

    {
        icon: PersonIcon,
        name: ResourceKey.USER,
        label: 'users.list.title',
        list: UsersList,
        create: UserCreate,
        edit: UserEdit,
        show: UserDetail,
    },

    {
        icon: SupervisorIcon,
        name: ResourceKey.PARTNER,
        label: 'partners.list.title',
        list: PartnersList,
        create: PartnerCreate,
        edit: PartnerEdit,
        show: PartnerDetail,
    },

    {
        icon: WarehouseIcon,
        name: ResourceKey.WAREHOUSE,
        label: 'warehouses.list.title',
        list: WarehousesList,
        create: WarehouseCreate,
        edit: WarehouseEdit,
        show: WarehouseDetail,
    },

    {
        icon: ConstructionIcon,
        name: ResourceKey.APP_CONFIGURATION,
        label: 'appConfiguration.list.title',
        list: AppConfigurationList,
        edit: AppConfigurationEdit,
    },

    {
        icon: AccessTimeIcon,
        name: ResourceKey.SLA_SETTINGS,
        label: 'slaSettings.list.title',
        list: <SlaSettingsList />,
    },
];

const staticResources = [
    ResourceKey.LOCATION,
    ResourceKey.REGION,
    ResourceKey.DELIVERY_PARTNER,
    ResourceKey.SUGGESTED_WAREHOUSE,
    ResourceKey.SUGGESTED_APM,
];

export const AdminUI = () => {
    const userRegionReady = useUserRegionReady();
    const translate = useTranslate();
    useHashRouteRedirect();

    return (
        <RaAdminUI
            title={translate('general.appName')}
            loginPage={LoginPage}
            layout={Layout}
            catchAll={NotFound}
            disableTelemetry
            requireAuth
            authCallbackPage={AuthCallbackPage}
        >
            {permissions => {
                if (!userRegionReady) {
                    return null;
                }

                return (
                    <>
                        {authorizableResources.map(({ name, icon, label, list, create, edit, show }) => {
                            const { enabled, access } = getResourceAccess(permissions, name);

                            if (!enabled) {
                                return null;
                            }

                            return (
                                <Resource
                                    key={name}
                                    name={name}
                                    icon={icon}
                                    options={{ label: translate(label) }}
                                    list={access.list ? list : undefined}
                                    create={access.create ? create : undefined}
                                    edit={access.edit ? edit : undefined}
                                    show={access.show ? show : undefined}
                                />
                            );
                        })}

                        {staticResources.map(resource => {
                            return <Resource name={resource} key={resource} />;
                        })}
                        <CustomRoutes>
                            {hasAccess(permissions, ResourceKey.PARCELS, PermissionAction.EDIT_PAYMENT) && (
                                <Route path={COD_PAYMENT_IMPORT_ROUTE} element={<CodPaymentImportPage />} />
                            )}
                        </CustomRoutes>
                    </>
                );
            }}
        </RaAdminUI>
    );
};
