import { styled, Typography, DialogTitle, IconButton as MUIIconButton } from '@mui/material';

export const Opener = styled('span')(({ theme }) => ({
    cursor: 'pointer',
    textDecoration: 'underline',
}));

export const Title = styled(DialogTitle)(({ theme }) => ({
    marginBottom: theme.spacing(1),
}));

export const IconButton = styled(MUIIconButton)(({ theme }) => ({
    position: 'absolute',
    right: 0,
    top: 0,
}));

export const SectionHeader = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
    '&:after': {
        content: '" "',

        display: 'block',
        width: '100%',
        height: 1,

        background: theme.palette.grey[500],
    },

    '&:first-child': {
        marginTop: 0,
    },
}));

export const ActionsTitle = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(3),
}));
