import { FunctionField, Labeled, useTranslate } from 'react-admin';
import YesIcon from '@mui/icons-material/Check';
import NoIcon from '@mui/icons-material/Close';

import type { PartnerData, WarehouseData } from 'modules/partners/types';

export interface DefaultWarehouseForReturnsFieldProps {
    partner: PartnerData;
}

export const DefaultWarehouseForReturnsField = ({ partner }: DefaultWarehouseForReturnsFieldProps) => {
    const translate = useTranslate();

    return (
        <Labeled label={translate('partners.warehouse.returnLocation.isDefault')}>
            <FunctionField
                source="id"
                render={(warehouse: WarehouseData) => {
                    const isDefaultWarehouse = partner?.returnLocation?.locationId === warehouse.id;

                    return isDefaultWarehouse ? <YesIcon fontSize="small" /> : <NoIcon fontSize="small" />;
                }}
            />
        </Labeled>
    );
};
