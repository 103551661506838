import { useTranslate, useRecordContext } from 'react-admin';

const PartnerTitle = () => {
    const translate = useTranslate();
    const record = useRecordContext();
    const name = record?.name;

    return <>{translate(`partners.${name ? 'edit' : 'create'}.title`, { name })}</>;
};

export default PartnerTitle;
