import { styled } from '@mui/material';

export const ButtonsContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(2),

    width: '100%',
    paddingBlock: theme.spacing(2),
}));

export const DrawerContentContainer = styled('section')(({ theme }) => ({
    padding: theme.spacing(3),
}));

export const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}));
