import { Show, TextField, useTranslate, ArrayField, TabbedShowLayout, Tab } from 'react-admin';
import type { ShowProps } from 'react-admin';
import { Typography, Grid } from '@mui/material';
import { BoxedShowLayout, RaBox } from 'ra-compact-ui';

import { SeoHead } from 'modules/ui';
import { RegionsList, SimpleItemsList } from 'modules/ui';

import { WarehousesList } from '../WarehousesList';

import { PartnerCharacteristics } from '../PartnerCharacteristics';
import { PartnerPermissions } from '../PartnerPermissions';
import { PartnerHistoryLog } from '../PartnerHistoryLog';
import { PartnerReturnLocations } from '../PartnerReturnLocations';

export const PartnerDetail = (props: ShowProps) => {
    const translate = useTranslate();

    return (
        <>
            <SeoHead title={translate('partners.list.title')} />
            <Show>
                <BoxedShowLayout>
                    <Grid container spacing={8}>
                        <Grid item>
                            <PartnerCharacteristics viewOnly />
                        </Grid>
                        <Grid item>
                            <PartnerPermissions viewOnly />
                        </Grid>
                    </Grid>

                    <RaBox sx={{ paddingX: 2 }}>
                        <Typography variant="h6" component="h3">
                            {translate('partners.details.title')}
                        </Typography>
                        <RaBox mb={2}>
                            <TextField label="partners.region.contract" source="contractRegion.name" />
                            <TextField source="name" label={translate('partners.name')} />
                            <RegionsList label={translate('partners.region.shipping')} source="shippingRegions" />
                            <TextField source="shippingLabelInfo" label={translate('partners.shippingLabel')} />
                            <TextField source="email" label={translate('partners.email')} />
                            <TextField source="vatNumber" label={translate('partners.vat')} />
                            <TextField source="iban" label={translate('partners.iban')} />
                            <TextField source="note" label={translate('partners.note')} />
                        </RaBox>
                        <RaBox>
                            <Typography variant="h6" component="h3">
                                {translate('partners.allowedUsers.title')}
                            </Typography>

                            <SimpleItemsList source="allowedUsers" />
                        </RaBox>
                        <RaBox>
                            <PartnerReturnLocations viewOnly />
                        </RaBox>
                        <RaBox>
                            <Typography variant="h6" component="h3">
                                {translate('warehouse.title')}
                            </Typography>

                            <ArrayField source="warehouses" label="">
                                <WarehousesList />
                            </ArrayField>
                        </RaBox>
                    </RaBox>
                    <TabbedShowLayout>
                        <Tab label={'partners.history.title'}>
                            <PartnerHistoryLog />
                        </Tab>
                    </TabbedShowLayout>
                </BoxedShowLayout>
            </Show>
        </>
    );
};
