import { useEffect } from 'react';
import { Dialog, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { useConfirmDialog, useTranslate } from 'hooks';

import type { CsvValidationErrors } from '../../../types';

export interface CsvValidationErrorsModalProps {
    validationErrors: CsvValidationErrors | null;
}

export const CsvValidationErrorsModal = ({ validationErrors }: CsvValidationErrorsModalProps) => {
    const translate = useTranslate();
    const [isOpen, openDialog, closeDialog] = useConfirmDialog();

    useEffect(() => {
        if (validationErrors) {
            openDialog();
        }
    }, [validationErrors, openDialog]);

    return (
        <Dialog open={isOpen} onClose={() => closeDialog()} fullWidth maxWidth="md">
            <DialogTitle display="flex" justifyContent="space-between">
                {translate('slaSettings.csv.validationErrors.title', { fileName: validationErrors?.fileName })}
                <IconButton onClick={closeDialog} size="medium" sx={{ height: 'fit-content' }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Typography variant="subtitle1">
                        {translate('slaSettings.csv.validationErrors.description', {
                            errors: validationErrors?.errors?.length,
                        })}
                    </Typography>

                    <Grid container maxWidth="40rem" mt={2}>
                        {validationErrors?.errors?.map(err => (
                            <Grid item xs={12}>
                                <Typography key={`${err.column}${err.row}`} variant="body2">
                                    {/* JSON.stringify() is used in order to show user even the invisible sequences such as "\n" because they can cause the validation to fail in specific cases. */}
                                    {translate('slaSettings.csv.validationErrors.cellValue', {
                                        cell: `${err.column}${err.row}`,
                                        value: JSON.stringify(err.invalidValue),
                                    })}
                                </Typography>
                            </Grid>
                        ))}
                    </Grid>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
};
