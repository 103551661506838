import { useRecordContext } from 'react-admin';
import { Box, Typography } from '@mui/material';

import type { ExtendedLocation } from 'modules/parcels/types';

export interface ReturnLocationOptionProps {}

const composeIdentifier = (record: ExtendedLocation) =>
    [record.id, record.identifier, record.name, record.street, record.city, record.postalCode, record.postalCode]
        .filter(Boolean)
        .join(', ');

const inputText = (record: ExtendedLocation) => {
    return record.name ?? record.identifier ?? `loc_id: ${record.id}`;
};

const matchSuggestion = (filterValue: string, suggestion?: ExtendedLocation) => {
    if (!suggestion) {
        return false;
    }

    return composeIdentifier(suggestion).toLowerCase().includes(filterValue.toLowerCase());
};

export const ReturnLocationOption = (props: ReturnLocationOptionProps) => {
    const record = useRecordContext<ExtendedLocation>();

    if (!record) {
        return null;
    }

    return (
        <Box>
            <Typography variant="body2" fontWeight={600}>
                {record.name ?? ''}
            </Typography>
            <Typography variant="body2">{record.identifier ?? ''}</Typography>
            <Typography variant="body2">
                {[record.street, record.city, record.postalCode, record.country].filter(Boolean).join(', ')}
            </Typography>
        </Box>
    );
};

ReturnLocationOption.inputText = inputText;
ReturnLocationOption.matchSuggestion = matchSuggestion;
